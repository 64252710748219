import styled from 'styled-components'
import { ColumnsContainer, RowsContainer } from 'components/container'

export const StyledRowsContainder = styled(RowsContainer)`
  width: 100%;
  justify-content: flex-end;
`

export const StyledColumnsContainer = styled(ColumnsContainer)`
  width: 100%;
`
