import { FC, ReactElement, useCallback, useEffect, useMemo } from 'react'
import Button from 'components/button'
import { useForm } from 'react-hook-form'
import Text from 'components/text'
import { CloseIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import { useQueryParam, StringParam } from 'use-query-params'
import { isClanzyUser, isOnline } from 'utils/contacts'
import { callIdQueryParam } from 'const'
import { makeCall } from 'utils/calls'
import { deleteNewCalls, selectCallById, CallNormalized } from 'reducers/callsSlice'
import { connect, useDispatch } from 'react-redux'
import Logo from 'components/logo'
import {
  StyledLogoWrapper,
  StyledTitleContainer,
  StyledTextContainer,
  StyledButtonsWrapper,
  StyledContentWrapper,
  StyledContentContainer,
  StyledCloseButton,
  StyledHeaderContainer,
  StyledForm,
} from './style'

const { $ } = window

const StartCall: FC<{ getCallById: (id: string) => CallNormalized; onClose: () => void }> = ({
  getCallById,
  onClose,
}): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [callIdParam, setCallIdParam] = useQueryParam(callIdQueryParam, StringParam)
  const { handleSubmit: handleSumbitForm } = useForm()

  const call = useMemo(() => (callIdParam ? getCallById(callIdParam) : null), [getCallById, callIdParam])
  const inviteeContactDisplayName = call?.contacts?.[0]?.displayName || ''

  const handleClose = useCallback(() => {
    onClose()
    if (callIdParam) dispatch(deleteNewCalls([callIdParam]))
    setCallIdParam(null, 'replaceIn')
  }, [callIdParam, dispatch, onClose, setCallIdParam])

  useEffect(() => {
    if (!callIdParam || !call) handleClose()
  }, [call, callIdParam, handleClose])

  const handleSubmit = async () => {
    handleClose()
    makeCall({ call, dispatch })
  }

  useEffect(() => {
    if (call?.contacts?.length && isOnline(call.contacts[0]) && isClanzyUser(call.contacts[0])) {
      handleClose()
      makeCall({ call, dispatch })
    }
  }, [call, dispatch, handleClose])

  useEffect(() => {
    const el = $(`.start-call-send-invites-button-${callIdParam}`)?.[0]
    if (el) el.focus()
  }, [callIdParam])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Text component="h1" mt="0" color="brandblack" font="Poppins">
          {t('CallWith')}&nbsp;{inviteeContactDisplayName}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledForm onSubmit={handleSumbitForm(handleSubmit)}>
        <StyledContentWrapper>
          <StyledLogoWrapper>
            <Logo $small size="lg" />
          </StyledLogoWrapper>
          {call?.contacts?.length && (
            <>
              {!isOnline(call.contacts[0]) && isClanzyUser(call.contacts[0]) ? (
                <>
                  <StyledTitleContainer>
                    <Text size="xl" gradient="radiance" fw="600" font="Poppins" $block>
                      {inviteeContactDisplayName}&nbsp;{t('AppearsToBeOfflineTitle')}
                    </Text>
                  </StyledTitleContainer>
                  <StyledTextContainer>
                    <Text color="black" cop="0.6" fw="500" $block>
                      {t('All')}&nbsp;{inviteeContactDisplayName}&nbsp;
                      {t('NeedsToDoIsClickTheButtonInTheEmailToJoinText')}
                    </Text>
                  </StyledTextContainer>
                </>
              ) : (
                <>
                  <StyledTitleContainer>
                    <Text size="xl" gradient="radiance" fw="600" font="Poppins" $block>
                      {inviteeContactDisplayName}&nbsp;
                      {`${t('IsNotAClanzyUser')}, ${t('ButCanStillJoinYouOnThisCall')}`}
                    </Text>
                  </StyledTitleContainer>
                  <StyledTextContainer>
                    <Text color="black" cop="0.6" fw="500" $block>
                      {t('WeWillEmailAnInviteAndAll')}&nbsp;{inviteeContactDisplayName}&nbsp;
                      {t('NeedsToDoIsClickTheButtonInTheEmailToJoinText')}
                    </Text>
                  </StyledTextContainer>
                </>
              )}
            </>
          )}
        </StyledContentWrapper>
        <StyledButtonsWrapper>
          <Button
            disabled={!call}
            $flat={!call}
            type="submit"
            size="md"
            bcolor="salem"
            hgradient="salem-dim-light"
            agradient="salem-dim-dark"
            bop="1"
            bophov="1"
            bopact="1"
            className={`start-call-send-invites-button-${callIdParam}`}
          >
            <Text size="md" $secondary fw="600">
              {t('SendCallInviteEmailAndStartTheCall')}
            </Text>
          </Button>
          <Button mt="0.5rem" size="md" subtle="true" onClick={handleClose}>
            <Text size="md" color="purplehearth" fw="600">
              {t('Cancel')}
            </Text>
          </Button>
        </StyledButtonsWrapper>
      </StyledForm>
    </StyledContentContainer>
  )
}

const mapStateToProps = (state) => ({
  getCallById: (callId: string) => selectCallById(callId)(state),
})

export default connect(mapStateToProps, null)(StartCall)
