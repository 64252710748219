import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path
      d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
      fill="white"
      fillOpacity="0"
    />
    <circle cx="20" cy="12" r="2.5" fill="white" fillOpacity="0.6" />
    <circle cx="20" cy="21.5" r="2.5" fill="white" fillOpacity="0.6" />
    <circle cx="20" cy="30" r="2.5" fill="white" fillOpacity="0.6" />
  </svg>
)
