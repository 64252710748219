/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import Button from 'components/button'
import { ColumnsContainer, ContentContainer, RowsContainer } from 'components/container'
import { NextIcon } from 'theme'

export const StyledFooterTextWrapper = styled.div``

export const StyledWrapper = styled(ColumnsContainer)`
  position: relative;
  margin: 1.5rem 1rem 0.5rem 1rem;
`

export const StyledButtonsWrapper = styled(ContentContainer).attrs(() => ({
  row: 'true',
}))`
  align-items: flex-start;
  margin: 1.7rem 0 0.7rem 0;
`

export const StyledTextContainer = styled.article`
  text-align: center;
  position: relative;
  padding: 1.8rem;
  margin-top: 1rem;
`

export const StyledColumnsContainer = styled(ColumnsContainer)`
  margin-top: 1rem;
  text-align: center;
  padding: 0 1.8rem;
`

export const StyledNextIcon = styled(NextIcon)`
  margin-left: auto;
  margin-right: 1.5rem;
  align-self: center;
`

export const StyledRowsContainer = styled(RowsContainer)`
  width: 100%;
`

export const StyledTextBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.06;
  background: ${({ theme: { gradients } }) => gradients.magenta};
  width: 100%;
  height: 100%;
`

export const StyledRowsTopContainer = styled(RowsContainer)`
  padding: 2.2rem 0.7rem 0.7rem 1.7rem;
`

export const StyledRowsBottomContainer = styled(RowsContainer)`
  padding: 1rem 0.7rem 1.5rem 1.7rem;
`

export const StyledContentContainer = styled(ContentContainer)`
  margin: 1.125rem;
`

export const StyledHeaderContainer = styled(RowsContainer)`
  height: 2.8125rem;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
`

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`
