import isElectron from 'is-electron'

const userIdQueryParam = 'userId'
const managedUserIdQueryParam = 'managedUserId'
const guardianRequestIdQueryParam = 'guardianRequestId'
const emailQueryParam = 'email'
const contactIdQueryParam = 'contactId'
const groupIdQueryParam = 'groupId'
const callIdQueryParam = 'callId'
const tabQueryParam = 'tab'
const modalQueryParam = 'modal'
const subModalQueryParam = 'subModal'
const tourQueryParam = 'tour'
const chatQueryParam = 'chat'
const providerQueryParam = 'provider'
const notificationQueryParam = 'notification'
const effectQueryParam = 'effect'
const entertainmentQueryParam = 'entertainment'
const inviteQueryParam = 'invite'
const participantIdQueryParam = 'participantId'
const tokenQueryParam = 'token'
const backQueryParam = 'back'
const isNewQueryParam = 'isNew'
const webNotificationsPromptQueryParam = 'webNotificationsPrompt'
const autoJoinQueryParam = 'autoJoin'

const tabRecentCallsQueryParam = 'recent-calls'
const tabContactsQueryParam = 'contacts'
const tabGroupsQueryParam = 'groups'

const passwordRequiredAtLeastOneChar = '1234567890!@#$%&'
const userNormalizedFlagSuffix = 'Flag'
const meetUrl = process.env.REACT_APP_MEET_URL
const apiUrl = process.env.REACT_APP_API_URL
const rummyUrl = process.env.REACT_APP_RUMMY_URL
const youtubeUrl = process.env.REACT_APP_YOUTUBE_URL
const isElectronRuntime = isElectron()

const modalImportContactsSelectProviderQueryParam = 'import-contacts-select-provider'
const modalImportContactsMoreInfoQueryParam = 'import-contacts-more-info'
const modalImportContactsSuccessInfoQueryParam = 'import-contacts-success-info'
const modalImportContactsOtherProvidersQueryParam = 'import-contacts-other-providers'
const modalCreateContactQueryParam = 'create-contact'
const modalEditContactQueryParam = 'edit-contact'
const modalContactInfoQueryParam = 'contact-info'
const modalCreateGroupQueryParam = 'create-group'
const modalCreateCallQueryParam = 'create-call'
const modalAddPeopleQueryParam = 'add-people'
const modalCallScheduledQueryParam = 'call-scheduled'
const modalTourQueryParam = 'tour'
const modalSettingsQueryParam = 'settings'
const modalAllowCamAndMicQueryParam = 'allow-cam-and-mic'
const modalMuteQueryParam = 'mute'
const modalUnmuteQueryParam = 'unmute'
const modalStopVideoQueryParam = 'stop-video'
const modalCameraOnQueryParam = 'camera-on'
const modalRemoveFromCallQueryParam = 'remove-from-call'
const modalStartCallQueryParam = 'start-call'
const modalStartGroupCallQueryParam = 'start-group-call'
const modalEditProfileNameQueryParam = 'edit-profile-name'
const modalEditProfileEmailQueryParam = 'edit-profile-email'
const modalEditProfilePasswordQueryParam = 'edit-profile-password'
const modalNewGameQueryParam = 'new-game'
const modalNewMediaQueryParam = 'new-media'
const modalGameInviteQueryParam = 'game-invite'
const modalAddManagedUserQueryParam = 'add-managed-user'
const modalManagedUserCreatedQueryParam = 'managed-user-created'
const modalExistingManagedUserQueryParam = 'existing-managed-user'
const modalManagedUserRequestSentQueryParam = 'managed-user-request-sent'
const modalManagedUserEnterNameQueryParam = 'managed-user-enter-name'
const modalUpdateManagedUserInfoQueryParam = 'update-managed-user-info'
const modalUpdateManagedUserQueryParam = 'update-managed-user'
const modalNewManagedUserUpdatedQueryParam = 'new-managed-user-updated'
const modalEditManagedUserNameQueryParam = 'edit-managed-user-name'
const modalAddManagedUserContactsQueryParam = 'add-managed-user-contacts'
const modalCreateManagedUserContactQueryParam = 'create-managed-user-contact'
const modalAddManagedUserGroupsQueryParam = 'add-managed-user-groups'
const modalCreateManagedUserGroupQueryParam = 'create-managed-user-group'
const modalAddManagedUserPeopleQueryParam = 'add-managed-people'
const modalGuardianRequestQueryParam = 'guardian-request'
const modalGuardianRequestAcceptedQueryParam = 'guardian-request-accepted'
const modalScreenShareSendRequestQueryParam = 'screen-share-send-request'
const modalScreenShareRequestSentQueryParam = 'screen-share-request-sent'
const modalAllowWebNotificationsQueryParam = 'allow-web-notifications'
const modalScreenShareSelectSourceQueryParam = 'screen-share-select-source'

const dashboardProfilePath = '/dashboard/profile'
const dashboardHomePath = '/dashboard/home'
const dashboardScheduledCallsPath = '/dashboard/scheduled-calls'
const dashboardEntertainmentPath = '/dashboard/entertainment'
const dashboardGuardianToolsPath = '/dashboard/guardian-tools'
const homePath = '/'
const clanzyPath = isElectronRuntime ? homePath : 'https://clanzy.com'
const clanzyMeetPath = 'https://meet.clanzy.com'
const termsOfUsePath = 'https://www.clanzy.com/terms-of-use'
const privacyPolicyPath = 'https://www.clanzy.com/privacy-policy'
const contactUsPath = 'https://www.clanzy.com/contact-us'
const downloadPath = 'https://www.clanzy.com/download-the-app'
const forgotPasswordPath = '/forgot-password'
const forgotPasswordSuccessPath = '/forgot-password-success'
const signInPath = '/sign-in'
const dashboardPath = '/dashboard'
const signUpPath = '/sign-up'
const signUpUsernamePath = '/sign-up/username'
const signUpPasswordPath = '/sign-up/password'
const resetPasswordPath = '/reset-password'
const setPasswordPath = '/set-password'
const callsPath = '/calls'
const callsShortPath = '/c'
const callsDeeplinkPath = '/calls/deeplink'
const callsJoinEnterCodePath = '/calls/join/enter-code'
const callsJoinEnterNamePath = '/calls/join/enter-name'
const callsJoinSignInPath = '/calls/join/sign-in'
const callsJoinPath = '/calls/join'
const callsEndedPath = '/calls/ended'
const callsWaitingPath = '/calls/waiting'

const games = {
  rummy: 'rummy',
}

const media = {
  youtube: 'youtube',
}

export {
  apiUrl,
  rummyUrl,
  youtubeUrl,
  isElectronRuntime,
  games,
  media,
  meetUrl,
  callsPath,
  callsShortPath,
  callsDeeplinkPath,
  callsJoinPath,
  callsEndedPath,
  callsWaitingPath,
  callsJoinEnterCodePath,
  callsJoinEnterNamePath,
  callsJoinSignInPath,
  signInPath,
  homePath,
  clanzyPath,
  clanzyMeetPath,
  dashboardPath,
  signUpPath,
  termsOfUsePath,
  signUpPasswordPath,
  setPasswordPath,
  resetPasswordPath,
  tourQueryParam,
  chatQueryParam,
  privacyPolicyPath,
  contactUsPath,
  downloadPath,
  forgotPasswordPath,
  forgotPasswordSuccessPath,
  effectQueryParam,
  entertainmentQueryParam,
  inviteQueryParam,
  participantIdQueryParam,
  tokenQueryParam,
  backQueryParam,
  isNewQueryParam,
  tabQueryParam,
  modalQueryParam,
  subModalQueryParam,
  notificationQueryParam,
  providerQueryParam,
  userIdQueryParam,
  managedUserIdQueryParam,
  guardianRequestIdQueryParam,
  emailQueryParam,
  contactIdQueryParam,
  callIdQueryParam,
  groupIdQueryParam,
  dashboardProfilePath,
  dashboardHomePath,
  dashboardScheduledCallsPath,
  dashboardEntertainmentPath,
  dashboardGuardianToolsPath,
  signUpUsernamePath,
  tabRecentCallsQueryParam,
  tabContactsQueryParam,
  tabGroupsQueryParam,
  passwordRequiredAtLeastOneChar,
  webNotificationsPromptQueryParam,
  autoJoinQueryParam,
  modalImportContactsSelectProviderQueryParam,
  modalImportContactsMoreInfoQueryParam,
  modalImportContactsSuccessInfoQueryParam,
  modalImportContactsOtherProvidersQueryParam,
  modalCreateContactQueryParam,
  modalEditContactQueryParam,
  modalContactInfoQueryParam,
  modalCreateGroupQueryParam,
  modalCreateCallQueryParam,
  modalAddPeopleQueryParam,
  modalCallScheduledQueryParam,
  modalTourQueryParam,
  modalSettingsQueryParam,
  modalAllowCamAndMicQueryParam,
  modalMuteQueryParam,
  modalUnmuteQueryParam,
  modalStopVideoQueryParam,
  modalCameraOnQueryParam,
  modalRemoveFromCallQueryParam,
  modalStartCallQueryParam,
  modalStartGroupCallQueryParam,
  modalEditProfileNameQueryParam,
  modalEditProfileEmailQueryParam,
  modalEditProfilePasswordQueryParam,
  userNormalizedFlagSuffix,
  modalNewGameQueryParam,
  modalNewMediaQueryParam,
  modalGameInviteQueryParam,
  modalAddManagedUserQueryParam,
  modalManagedUserCreatedQueryParam,
  modalExistingManagedUserQueryParam,
  modalManagedUserRequestSentQueryParam,
  modalManagedUserEnterNameQueryParam,
  modalUpdateManagedUserInfoQueryParam,
  modalUpdateManagedUserQueryParam,
  modalNewManagedUserUpdatedQueryParam,
  modalEditManagedUserNameQueryParam,
  modalAddManagedUserContactsQueryParam,
  modalCreateManagedUserContactQueryParam,
  modalAddManagedUserGroupsQueryParam,
  modalCreateManagedUserGroupQueryParam,
  modalAddManagedUserPeopleQueryParam,
  modalGuardianRequestQueryParam,
  modalGuardianRequestAcceptedQueryParam,
  modalScreenShareSendRequestQueryParam,
  modalScreenShareRequestSentQueryParam,
  modalAllowWebNotificationsQueryParam,
  modalScreenShareSelectSourceQueryParam,
}
