/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components'
import Button from 'components/button'
import { ColumnsContainer, ContentContainer, RowsContainer } from 'components/container'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

export const StyledButtonsWrapper = styled(RowsContainer)`
  justify-content: flex-end;
  margin: 1.7rem 0 1rem 0;
  width: 100%;
`

export const StyledIconTickWrapper = styled.div`
  width: 1rem;
  margin-right: 1rem;
`

export const StyledVerticalScrollbar = styled(OverlayScrollbarsComponent).attrs((props) => ({
  ...props,
  resize: 'vertical',
}))`
  height: 20rem;
  width: 100%;
  overflow-x: hidden;
  margin-right: 0.3rem;

  &.os-host-scrollbar-vertical-hidden .os-content > section {
    margin-right: 0 !important;
  }

  &.os-host-scrollbar-vertical-hidden {
    margin-right: 0 !important;
  }

  .os-scrollbar-horizontal {
    display: none;
  }
`

export const StyledSourceWrapper = styled(RowsContainer)`
  padding: 1rem;
  box-sizing: border-box;
  margin: 0.5rem 1rem 0.5rem 0;
  justify-content: center;
  align-items: center;
  border: 0.1875rem solid ${({ theme: { fn, colors } }) => fn.color(colors.purple)};
  border-radius: 1.5rem;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.purple, '0.16')};
  }

  ${(props) =>
    props.$selected &&
    css`
      background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.purple, '0.16')};
    `}
`

export const StyledContentContainer = styled(ContentContainer)`
  margin: 1.125rem;
`

export const StyledTextWrapper = styled(ColumnsContainer)`
  justify-content: center;
  align-items: center;
  margin: 1rem;
  flex-grow: 1;
  display: inline-block;
`

export const StyledHeaderContainer = styled(RowsContainer)`
  height: 2.8125rem;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
`

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`
