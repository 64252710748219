/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { error } from 'react-toastify-redux'

declare global {
  interface Window {
    JitsiMeetJS: any
  }
}

const { JitsiMeetJS } = window

export default (shouldConnect: boolean): any => {
  const [api, setApi] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (shouldConnect) {
      let cnt = 0
      const options = {
        enableAnalyticsLogging: false,
        dominantSpeaker: true,
        disableAudioLevels: true,
        desktopSharingChromeExtId: 'mbocklcggfhnbahlnepmldehdhpjfcjp',
        desktopSharingChromeDisabled: false,
        desktopSharingChromeSources: ['screen', 'window', 'tab'],
        desktopSharingChromeMinExtVersion: '0.1',
        desktopSharingFirefoxDisabled: false,
      }

      if (JitsiMeetJS) {
        JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR)
        JitsiMeetJS.init(options)
        setApi(JitsiMeetJS)
      } else {
        const refreshTimeout = setTimeout(() => {
          if (JitsiMeetJS) {
            JitsiMeetJS.init(options)
            setApi(JitsiMeetJS)
            clearTimeout(refreshTimeout)
          } else {
            if (cnt === 5) {
              clearTimeout(refreshTimeout)
              dispatch(error('Cannot connect to meet api'))
            }
            cnt += 1
          }
        }, 5000)
      }
    }
  }, [dispatch, shouldConnect])

  return api
}
