import { ReactElement } from 'react'
import { CheckTickIcon } from 'theme'
import Text from 'components/text'
import { StyledCheckBoxWrapper, StyledIconWrapper, StyledWrapper, StyledInput, CheckBoxProps } from './style'

export default ({ $checked, title, alt, name }: CheckBoxProps): ReactElement => {
  const component = !$checked ? (
    <StyledInput name={name} />
  ) : (
    <StyledWrapper>
      <StyledInput $checked={$checked} name={name} />
      <StyledIconWrapper>
        <CheckTickIcon title={alt} />
      </StyledIconWrapper>
    </StyledWrapper>
  )
  if (!title) return component
  return (
    <StyledCheckBoxWrapper>
      {component}
      <Text ml="1rem" fw="500" color="brandblack" cop="0.6" htmlFor={name}>
        {title}
      </Text>
    </StyledCheckBoxWrapper>
  )
}
