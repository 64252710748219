/* eslint-disable no-console */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, useRef, useCallback } from 'react'
import {
  isAudioTrack,
  isVideoTrack,
  hasMicDevice,
  hasCamDevice,
  getTrackId,
  isLocalScreenShare,
  isRemoteTrackDesktop,
} from 'utils/calls'
import { isElectronRuntime } from 'const'
import moment from 'moment'

import useConnection from './useConnection'

declare global {
  interface Window {
    JitsiMeetScreenObtainer: any
  }
}

export default (
  userId: string,
  setLocalAudioOn: (param: boolean) => void,
  setLocalVideoOn: (param: boolean) => void,
  onEnd: () => void,
  onScreenShareRequestDeclined: (id: string) => void,
): any => {
  const conference = useRef(null)
  const conferenceLeaving = useRef(false)
  const [remoteUsersIds, setRemoteUsersIds] = useState([])
  const [chatMessages, setChatMessages] = useState([])
  const [displayNames, setDisplayNames] = useState({ local: null })
  const [clanzyUsersIds, setClanzyUsersIds] = useState({ local: null })
  const [remoteTracksMutedState, setRemoteTracksMutedState] = useState({})
  const [dominantSpeaker, setDominantSpeaker] = useState(null)
  const localTracksRef = useRef([])
  const remoteTracksRef = useRef([])
  const [mediaDevices, setMediaDevices] = useState([])
  const [noMic, setNoMic] = useState(false)
  const [noCam, setNoCam] = useState(false)
  const [micDisabled, setMicDisabled] = useState(false)
  const [camDisabled, setCamDisabled] = useState(false)
  const [callId, setCallId] = useState(null)
  const [hasConference, setHasConference] = useState(false)
  const [api, connection] = useConnection(!!callId)
  const [permissionPromptIsShown, setPermissionPromptIsShown] = useState(false)
  const [screenShareId, setScreenShareId] = useState(null)
  const [isJoined, setIsJoined] = useState(false)
  const [localTracks, setLocalTracks] = useState([])
  const [remoteTracks, setRemoteTracks] = useState([])
  const [selectedAudioInputDeviceId, setSelectedAudioInputDeviceId] = useState('default')
  const [selectedAudioOutputDeviceId, setSelectedAudioOutputDeviceId] = useState('default')
  const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useState('default')
  const [guardianRequests, setGuardianRequestRequests] = useState([])
  const prevVideoDeviceId = useRef(null)

  // Re assure the local tracks is disposed, because sometimes if the invitee reject
  // the instant call very quick or the host cancels the call the local tracks were not disposed
  useEffect(() => {
    if (localTracks.length && conferenceLeaving.current) {
      localTracks?.map((track) => {
        try {
          if (!track.disposed) return track.dispose()
        } catch (e) {
          console.error(e)
        }
        return null
      })
    }
  }, [localTracks])

  const onRemoveGuardianRequest = (type, id?) => {
    setGuardianRequestRequests((prevGuardianRequests) => {
      if (!id) {
        return prevGuardianRequests.filter((req) => req.type !== type)
      }

      return prevGuardianRequests.filter((req) => req.type !== type || req.sender !== id)
    })
  }

  const acceptUnmuteRequest = (id) => {
    setGuardianRequestRequests((prevGuardianRequests) =>
      prevGuardianRequests.filter((req) => req.type !== 'unmute' && req.sender === id),
    )
    setLocalAudioOn(true)
  }

  const acceptCameraOnRequest = (id) => {
    setGuardianRequestRequests((prevGuardianRequests) =>
      prevGuardianRequests.filter((req) => req.type !== 'cameraon' && req.sender === id),
    )
    setLocalVideoOn(true)
  }

  useEffect(() => {
    if (screenShareId) onRemoveGuardianRequest('screenshare')
  }, [screenShareId])

  const onGuardianRequest = useCallback(
    (sender, user, type) => {
      if (!screenShareId)
        setGuardianRequestRequests((prevGuardianRequests) => [...prevGuardianRequests, { sender, user, type }])
    },
    [screenShareId],
  )

  const updatedRemoteTracksMutedStatus = () => {
    setRemoteTracksMutedState(
      remoteTracksRef.current.reduce((res, track) => ({ ...res, [getTrackId(track)]: track.isMuted() }), {}),
    )
  }

  // On add remote track
  const onRemoteTrack = useCallback((track) => {
    if (conferenceLeaving.current || track.isLocal()) return

    const participant = track.getParticipantId()

    setRemoteTracks((prevRemoteTracks) => {
      const exists = prevRemoteTracks.find(
        (prevRemoteTrack) => prevRemoteTrack.getParticipantId() === participant && prevRemoteTrack.type === track.type,
      )

      if (exists) {
        remoteTracksRef.current = prevRemoteTracks.map((prevRemoteTrack) =>
          prevRemoteTrack.getParticipantId() === participant && prevRemoteTrack.type === track.type
            ? track
            : prevRemoteTrack,
        )
      } else {
        remoteTracksRef.current = [...prevRemoteTracks, track]
      }

      return [...remoteTracksRef.current]
    })

    if (isRemoteTrackDesktop(track)) setScreenShareId(participant)

    updatedRemoteTracksMutedStatus()
  }, [])

  // On remove remote track
  const onRemoveTrack = (track) => {
    if (conferenceLeaving.current || track.isLocal()) return

    const participant = track.getParticipantId()

    setRemoteTracks((prevRemoteTracks) => {
      remoteTracksRef.current = prevRemoteTracks.filter(
        (prevRemoteTrack) => prevRemoteTrack.getParticipantId() !== participant || prevRemoteTrack.type !== track.type,
      )

      return [...remoteTracksRef.current]
    })

    if (isRemoteTrackDesktop(track)) setScreenShareId(null)
  }

  const onConferenceJoined = () => {
    if (conferenceLeaving.current) return

    setIsJoined(true)
  }

  const onConferenceLeft = useCallback(() => {
    if (conference.current) {
      conference.current.off(api.events.conference.CONFERENCE_LEFT, onConferenceLeft)
    }

    setHasConference(false)
    setIsJoined(false)
  }, [api])

  const createLocalTracks = useCallback(() => {
    api.mediaDevices?.enumerateDevices((devices) => {
      const reqDevices = []

      if (hasMicDevice(devices)) {
        reqDevices.push('audio')
        setNoMic(false)
      } else {
        setNoMic(true)
      }

      if (hasCamDevice(devices)) {
        reqDevices.push('video')
        setNoCam(false)
      } else {
        setNoCam(true)
      }

      api
        .createLocalTracks({ devices: reqDevices })
        .then(setLocalTracks)
        .catch((error) => {
          console.error(error)

          if (error?.gum?.devices) {
            setMicDisabled(error.gum.devices.includes('audio'))
            setCamDisabled(error.gum.devices.includes('video'))
          } else if (error?.message) {
            setMicDisabled(hasMicDevice(devices))
            setCamDisabled(hasCamDevice(devices))
          }
        })
    })
  }, [api])

  const onDeviceListChanged = useCallback(
    (devices) => {
      if (conferenceLeaving.current) return

      setMediaDevices(devices)
      createLocalTracks()
    },
    [createLocalTracks],
  )

  const onUserJoined = (id) => {
    if (conferenceLeaving.current) return

    setRemoteUsersIds((prevUsersIds) => [...new Set([...prevUsersIds, id])])
  }

  const onUserLeft = (id) => {
    if (conferenceLeaving.current) return

    setRemoteUsersIds((prevUsersIds) => prevUsersIds.filter((userId) => userId !== id))
  }

  const onPermissionPromptIsShown = () => {
    if (conferenceLeaving.current) return

    setPermissionPromptIsShown(true)
  }

  const onDominantSpeakerChanged = (id) => {
    if (conferenceLeaving.current) return

    setDominantSpeaker(id)
  }

  const onTrackMuteChanged = useCallback(
    (track) => {
      if (conferenceLeaving.current) return

      if (track.isLocal() && isAudioTrack(track) && track.isMuted()) {
        // When the host mute the user's mic
        setLocalAudioOn(false)
      } else {
        updatedRemoteTracksMutedStatus()
      }
    },
    [setLocalAudioOn],
  )

  const onDisplayNameChanged = (id: string, displayName: string) => {
    if (!conferenceLeaving.current) {
      setDisplayNames((prevDisplayNames) => ({ ...prevDisplayNames, [id]: displayName }))
    }
  }

  const onParticipantPropertyChanged = (user, propName, _oldValue, val) => {
    if (!conferenceLeaving.current && propName === 'userId') {
      setClanzyUsersIds((prevClanzyUsersIds) => ({ ...prevClanzyUsersIds, [user.getId()]: val }))
    }
  }

  // Commands handler
  const onEndpointMessageReceived = useCallback(
    (sender, msg) => {
      if (conferenceLeaving.current) return

      if (msg?.type === 'command') {
        if (msg.command === 'mute') {
          setLocalAudioOn(false)
        } else if (msg.command === 'stopVideo') {
          setLocalVideoOn(false)
        } else if (msg.command === 'removeFromCall') {
          onEnd()
        } else if (msg.command === 'screenShareRequest') {
          try {
            onGuardianRequest(sender.getId(), JSON.parse(msg.value), 'screenshare')
          } catch (e) {}
        } else if (msg.command === 'cancelScreenShareRequest') {
          onRemoveGuardianRequest('screenshare', sender.getId())
        } else if (msg.command === 'screenShareRequestDeclined') {
          onScreenShareRequestDeclined(sender.getId())
        } else if (msg.command === 'unmuteRequest') {
          try {
            onGuardianRequest(sender.getId(), JSON.parse(msg.value), 'unmute')
          } catch (e) {}
        } else if (msg.command === 'cameraOnRequest') {
          try {
            onGuardianRequest(sender.getId(), JSON.parse(msg.value), 'cameraon')
          } catch (e) {}
        } else if (msg.command === 'share') {
          setScreenShareId(msg.value)
        }
      }
    },
    [setLocalAudioOn, setLocalVideoOn, onEnd, onGuardianRequest, onScreenShareRequestDeclined],
  )

  const onMessageReceived = useCallback(
    (id, text, ts) => {
      try {
        if (conferenceLeaving.current) return

        const msg = JSON.parse(text)
        const isYou = id === conference.current?.myUserId() || msg.userId === userId
        setChatMessages((prevChatMessages) => [
          ...prevChatMessages,
          { id, isYou, data: msg, ts: ts ? moment(ts) : moment() },
        ])
      } catch (e) {}
    },
    [userId],
  )

  const unsubscribe = () => {
    if (api && conference.current) {
      conference.current.off(api.events.conference.DISPLAY_NAME_CHANGED, onDisplayNameChanged)
      conference.current.off(api.events.conference.PARTICIPANT_PROPERTY_CHANGED, onParticipantPropertyChanged)
      conference.current.off(api.events.conference.TRACK_MUTE_CHANGED, onTrackMuteChanged)
      conference.current.off(api.events.conference.DOMINANT_SPEAKER_CHANGED, onDominantSpeakerChanged)
      conference.current.off(api.events.conference.USER_JOINED, onUserJoined)
      conference.current.off(api.events.conference.USER_LEFT, onUserLeft)
      conference.current.off(api.events.conference.TRACK_ADDED, onRemoteTrack)
      conference.current.off(api.events.conference.TRACK_REMOVED, onRemoveTrack)
      conference.current.off(api.events.conference.CONFERENCE_JOINED, onConferenceJoined)
      conference.current.off(api.events.conference.ENDPOINT_MESSAGE_RECEIVED, onEndpointMessageReceived)
      conference.current.off(api.events.conference.MESSAGE_RECEIVED, onMessageReceived)
    }

    api?.mediaDevices?.removeEventListener(
      api?.events?.mediaDevices?.PERMISSION_PROMPT_IS_SHOWN,
      onPermissionPromptIsShown,
    )
    api?.mediaDevices?.removeEventListener(api?.events?.mediaDevices?.DEVICE_LIST_CHANGED, onDeviceListChanged)

    connection?.unsubscribe()
  }

  useEffect(() => {
    if (permissionPromptIsShown && (localTracks.length || micDisabled || camDisabled)) setPermissionPromptIsShown(false)
  }, [camDisabled, localTracks.length, micDisabled, permissionPromptIsShown])

  useEffect(() => {
    if (callId && api && connection && !conference.current) {
      conference.current = connection.initJitsiConference(callId, {
        openBridgeChannel: true,
        p2p: {
          enabled: true,
        },
        startAudioMuted: false,
        startVideoMuted: false,
      })

      conference.current.on(api.events.conference.DISPLAY_NAME_CHANGED, onDisplayNameChanged)
      conference.current.on(api.events.conference.PARTICIPANT_PROPERTY_CHANGED, onParticipantPropertyChanged)
      conference.current.on(api.events.conference.TRACK_MUTE_CHANGED, onTrackMuteChanged)
      conference.current.on(api.events.conference.DOMINANT_SPEAKER_CHANGED, onDominantSpeakerChanged)
      conference.current.on(api.events.conference.USER_JOINED, onUserJoined)
      conference.current.on(api.events.conference.USER_LEFT, onUserLeft)
      conference.current.on(api.events.conference.TRACK_ADDED, onRemoteTrack)
      conference.current.on(api.events.conference.TRACK_REMOVED, onRemoveTrack)
      conference.current.on(api.events.conference.CONFERENCE_JOINED, onConferenceJoined)
      conference.current.on(api.events.conference.CONFERENCE_LEFT, onConferenceLeft)
      conference.current.on(api.events.conference.ENDPOINT_MESSAGE_RECEIVED, onEndpointMessageReceived)
      conference.current.on(api.events.conference.MESSAGE_RECEIVED, onMessageReceived)
      api.mediaDevices?.addEventListener(api.events.mediaDevices.PERMISSION_PROMPT_IS_SHOWN, onPermissionPromptIsShown)
      api.mediaDevices?.addEventListener(api.events.mediaDevices.DEVICE_LIST_CHANGED, onDeviceListChanged)

      navigator?.permissions
        ?.query({ name: 'camera' })
        .then((result) => {
          if (result.state === 'prompt') {
            onPermissionPromptIsShown()
          }
        })
        .catch(() => {})

      try {
        api.mediaDevices?.enumerateDevices((devices) => {
          setMediaDevices(devices)
          createLocalTracks()
        })
      } catch (e) {
        console.error(e)
      }

      if (api.mediaDevices) {
        setSelectedAudioOutputDeviceId(api.mediaDevices.getAudioOutputDevice())
      }

      setHasConference(true)
    }
  }, [
    api,
    callId,
    connection,
    createLocalTracks,
    onConferenceLeft,
    onDeviceListChanged,
    onEndpointMessageReceived,
    onMessageReceived,
    onRemoteTrack,
    onTrackMuteChanged,
  ])

  useEffect(() => {
    try {
      if (!conferenceLeaving.current && isJoined && localTracks.length) {
        localTracks.forEach((track) => {
          const attachedTrack = localTracksRef.current.find(({ type }) => type === track.type)
          if (attachedTrack) {
            conference.current.replaceTrack(attachedTrack, track)
          } else {
            conference.current.addTrack(track)
          }
        })

        localTracksRef.current.forEach((track) => {
          const attachedTrack = localTracks.find(({ type }) => type === track.type)
          if (!attachedTrack) {
            conference.current.removeTrack(track)
          }
        })

        localTracksRef.current = [...localTracks]
      }
    } catch (e) {}
  }, [isJoined, localTracks])

  useEffect(() => {
    if (!conferenceLeaving.current && localTracks.length) {
      localTracks.forEach((track) => {
        if (isAudioTrack(track)) {
          setSelectedAudioInputDeviceId(track.getDeviceId())
        } else if (isVideoTrack(track)) {
          setSelectedVideoDeviceId(track.getDeviceId())
        }
      })
    }
  }, [isJoined, localTracks])

  // Leave the call
  const leave = async (): Promise<any> => {
    conferenceLeaving.current = true

    const disposeLocalTracks = async () => {
      await Promise.all(
        localTracks?.map(async (track) => {
          if (!track.disposed) {
            if (track.stopStream) track.stopStream()

            return track.dispose()?.catch((e) => {
              console.error(e)
            })
          }
          return null
        }),
      )
    }

    unsubscribe()
    await disposeLocalTracks()

    try {
      if (isJoined) await conference?.current?.leave()

      return connection?.disconnect()
    } catch (e) {
      console.error(e)
    }

    return null
  }

  // Join the call
  const join = useCallback(async (): Promise<any> => {
    if (!isJoined && !conferenceLeaving.current && conference.current) {
      return conference.current.join()
    }
    return Promise.resolve()
  }, [isJoined])

  // Change camera device
  const changeCamera = useCallback(
    (deviceId) => {
      api
        .createLocalTracks({ devices: ['video'], cameraDeviceId: deviceId })
        .then((tracks) => setLocalTracks((current) => [...current.filter((track) => !isVideoTrack(track)), ...tracks]))
        .catch(() => {})
    },
    [api],
  )

  // Change microphone device
  const changeMicrophone = useCallback(
    (deviceId) => {
      api
        .createLocalTracks({ devices: ['audio'], micDeviceId: deviceId })
        .then((tracks) => setLocalTracks((current) => [...current.filter((track) => !isAudioTrack(track)), ...tracks]))
        .catch(() => {})
    },
    [api],
  )

  // Change audio output device
  const setAudioOutputDevice = useCallback(
    (deviceId) => {
      if (api?.mediaDevices?.setAudioOutputDevice) {
        api.mediaDevices
          .setAudioOutputDevice(deviceId)
          .then(() => {
            setSelectedAudioOutputDeviceId(deviceId)
          })
          .catch(() => {})
      }
    },
    [api],
  )

  // Propagates user's display name to the other particiapnts in the call
  const setDisplayName = useCallback(
    async (displayName: string): Promise<any> => {
      if (isJoined && conference.current) {
        const remoteDisplayNames = ((await conference.current.getParticipants()) || {}).reduce(
          (res, participant) => ({ ...res, [participant.getId()]: participant.getDisplayName() }),
          {},
        )

        setDisplayNames((prevDisplayNames) => ({ ...prevDisplayNames, ...remoteDisplayNames, local: displayName }))

        return conference.current.setDisplayName(displayName)
      }
      return Promise.resolve()
    },
    [isJoined],
  )

  const setCalnzyUserId = useCallback(
    async (userId: string): Promise<any> => {
      if (isJoined && conference.current) {
        const remoteClanzyUsersIds = ((await conference.current.getParticipants()) || {}).reduce(
          (res, participant) => ({ ...res, [participant.getId()]: participant.getProperty('userId') }),
          {},
        )

        setClanzyUsersIds((prevClanzyUsersIds) => ({ ...prevClanzyUsersIds, ...remoteClanzyUsersIds, local: userId }))

        return conference.current.setLocalParticipantProperty('userId', userId)
      }
      return Promise.resolve()
    },
    [isJoined],
  )

  // Mute remote participant microphone
  const mute = (id) => {
    if (conference.current?.isModerator() && !!conference.current?.muteParticipant) {
      try {
        conference.current?.muteParticipant(id)
      } catch (e) {}
    } else {
      conference.current?.sendEndpointMessage(id, { type: 'command', command: 'mute' })
    }
  }

  // Stop remote participant video
  const stopVideo = (id) => {
    conference.current?.sendEndpointMessage(id, { type: 'command', command: 'stopVideo' })
  }

  // Remove a participant from the call
  const removeFromCall = (id) => {
    conference.current?.sendEndpointMessage(id, { type: 'command', command: 'removeFromCall' })
  }

  const sendGuardianRequest = (command) => (id, { displayName, photo, username }) => {
    try {
      conference.current?.sendEndpointMessage(id, {
        type: 'command',
        command,
        value: JSON.stringify({ displayName, photo, username }),
      })
    } catch (e) {}
  }

  const cancelScreenShareRequest = (id) => {
    conference.current?.sendEndpointMessage(id, {
      type: 'command',
      command: 'cancelScreenShareRequest',
    })
  }

  const declineScreenShareRequest = (id) => {
    onRemoveGuardianRequest('screenshare', id)

    conference.current?.sendEndpointMessage(id, {
      type: 'command',
      command: 'screenShareRequestDeclined',
    })
  }

  // Mute all the remote participants microphones
  const muteAll = () => {
    if (conference.current?.isModerator() && !!conference.current?.muteParticipant) {
      try {
        remoteUsersIds.forEach((id) => conference.current.muteParticipant(id))
      } catch (e) {}
    } else {
      conference.current?.broadcastEndpointMessage({ type: 'command', command: 'mute' })
    }
  }

  // Switch between camera and screen sharing
  const switchVideo = useCallback(
    async ({ isScreenShare, id } = {}) => {
      // eslint-disable-next-line no-param-reassign
      isScreenShare = isScreenShare || isLocalScreenShare(screenShareId)

      const localTrackStopped = () => {
        conference.current?.broadcastEndpointMessage({ type: 'command', command: 'share', value: null })
        switchVideo({ isScreenShare: true })
      }

      const handleScreenShareTracks = (tracks) => {
        if (!isScreenShare) {
          conference.current?.broadcastEndpointMessage({
            type: 'command',
            command: 'share',
            value: conference.current?.myUserId(),
          })
          tracks[0].addEventListener(api.events.track.LOCAL_TRACK_STOPPED, localTrackStopped)
        }

        setLocalTracks((current) => {
          const video = current.find((track) => isVideoTrack(track))
          if (video) {
            if (isScreenShare) {
              video.removeEventListener(api.events.track.LOCAL_TRACK_STOPPED, localTrackStopped)
              setTimeout(() => setLocalVideoOn(video.prevCamOn), 0)
            } else {
              tracks[0].prevCamOn = !video.isMuted()
              prevVideoDeviceId.current = video.deviceId
            }

            video.stopStream()
          } else {
            prevVideoDeviceId.current = null
          }

          return [...current.filter((track) => !isVideoTrack(track)), ...tracks]
        })

        if (isScreenShare) {
          setScreenShareId(null)
        } else {
          setTimeout(() => setLocalVideoOn(true), 0)
          setScreenShareId('local')
        }
      }

      if (isElectronRuntime && !isScreenShare) {
        window.JitsiMeetScreenObtainer = {
          openDesktopPicker(_, onSourceChoose) {
            onSourceChoose(id, id.split(':')[0])
          },
        }
      }

      api
        .createLocalTracks({
          devices: [isScreenShare ? 'video' : 'desktop'],
          cameraDeviceId: prevVideoDeviceId.current,
        })
        .then(handleScreenShareTracks)
        .catch((e) => {
          console.error(e)

          if (isScreenShare) {
            conference.current?.broadcastEndpointMessage({ type: 'command', command: 'share', value: null })
            setScreenShareId(null)
            setLocalTracks((current) => {
              const video = current.find((track) => isVideoTrack(track))
              if (video) {
                video.removeEventListener(api.events.track.LOCAL_TRACK_STOPPED, localTrackStopped)
                setTimeout(() => setLocalVideoOn(video.prevCamOn), 0)

                video.stopStream()
              }

              return [...current.filter((track) => !isVideoTrack(track))]
            })
          }
        })
    },
    [api, screenShareId, setLocalVideoOn],
  )

  // Stop screen sharing
  const stopSharing = useCallback(() => {
    const desktop = localTracks?.find((track) => isVideoTrack(track) && track.videoType === 'desktop')
    desktop?.stopStream()
  }, [localTracks])

  const sendChatMessage = (msg) => {
    try {
      conference.current.sendTextMessage(JSON.stringify({ ...msg, userId }))
    } catch (e) {}
  }

  return [
    {
      remoteUsersIds,
      permissionPromptIsShown,
      remoteTracksMutedState,
      dominantSpeaker,
      selectedAudioInputDeviceId,
      selectedAudioOutputDeviceId,
      selectedVideoDeviceId,
      noMic,
      noCam,
      micDisabled,
      camDisabled,
      localTracks,
      remoteTracks,
      join,
      isJoined,
      hasConference,
      changeCamera,
      changeMicrophone,
      setAudioOutputDevice,
      leave,
      mediaDevices,
      setDisplayName,
      setCalnzyUserId,
      displayNames,
      muteAll,
      mute,
      clanzyUsersIds,
      stopVideo,
      removeFromCall,
      screenShareId,
      shareScreen: switchVideo,
      stopSharing,
      sendChatMessage,
      chatMessages,
      sendScreenShareRequest: sendGuardianRequest('screenShareRequest'),
      cancelScreenShareRequest,
      declineScreenShareRequest,
      declineUnmuteRequest: (id) => onRemoveGuardianRequest('unmute', id),
      declineCameraOnRequest: (id) => onRemoveGuardianRequest('cameraon', id),
      sendUnmuteRequest: sendGuardianRequest('unmuteRequest'),
      sendCameraOnRequest: sendGuardianRequest('cameraOnRequest'),
      acceptUnmuteRequest,
      acceptCameraOnRequest,
      guardianRequests,
    },
    setCallId,
  ]
}
