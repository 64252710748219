import styled, { css } from 'styled-components'
import { ContentContainer, RowsContainer } from 'components/container'
import List from 'components/list'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

export interface OverlayScrollbarsComponentProps {
  /**
   * Callbacks
   */
  options?: unknown
}

export interface StyledListControlsProps {
  /**
   * Whether should show an underlying shadow
   */
  shadow?: boolean
}

export const StyledFooterWrapper = styled(RowsContainer)`
  box-sizing: border-box;
  width: 100%;
  padding: 2rem 1.625rem;
  background: ${({ theme }) => theme.fn.color(theme.colors.brandblack, 0.05)};
`

export const StyledListControlsWrapper = styled(ContentContainer)`
  width: 100%;
  box-sizing: border-box;
  padding: 0 1.5rem 1.5rem 1.5rem;

  ${(props: StyledListControlsProps) =>
    props.shadow &&
    css`
      box-shadow: ${({ theme: { shadows } }) => shadows.outbound};
    `}
`

export const StyledListWrapper = styled(ContentContainer)`
  width: 100%;
  margin-bottom: 1rem;
`

export const StyledOverlayScrollbarsComponent = styled(OverlayScrollbarsComponent).attrs((props) => ({
  ...props,
  resize: 'vertical',
}))<OverlayScrollbarsComponentProps>`
  height: 20.25rem;
  width: 100%;
  overflow-x: hidden;

  &.os-host-scrollbar-vertical-hidden .os-content > section {
    margin-right: 0 !important;
  }

  &.os-host-scrollbar-vertical-hidden {
    margin-right: 0 !important;
  }
`

export const StyledList = styled(List)`
  flex-direction: column;
  margin-right: 1rem;
  width: auto;
`

export const StyledRowsContainer = styled(RowsContainer)`
  width: 100%;
  justify-content: flex-start;
  margin-top: 0.5rem;
`

export const StyledContentContainer = styled(ContentContainer)`
  box-sizing: border-box;
  width: 100%;
  padding: 0 1.5rem 0 1.5rem;
`
