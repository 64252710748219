import styled, { css } from 'styled-components'
import SearchInput from 'react-search-input'

export interface SearchInputProps {
  /**
   * Variant
   */
  $secondary?: boolean
  /**
   * Is interactive
   */
  $interactive?: boolean
  /**
   * Is focused
   */
  $focused?: boolean
  /**
   * Placeholder
   */
  placeholder?: string
  /**
   * Class name
   */
  className?: string
  /**
   * Value
   */
  value?: string
  /**
   * On submit handler
   */
  onChange?: (str: string) => void
  /**
   * On focus handler
   */
  onFocus?: (focused: boolean) => void
}

export const StyledSearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const StyledSearchInput = styled(SearchInput)`
  width: 100%;

  & input {
    padding-left: 3.5rem;
    background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.white, '0.05')};
    border: none;
    width: 100%;
    height: 3rem;
    box-sizing: border-box;
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transform: none;
    border-radius: 0.5rem;
    font-family: Inter;
    font-weight: 500;
    font-size: 1.25rem;
    color: ${({ theme: { fn, palette } }) => fn.color(palette.text.white)};
    outline: none;

    ::-webkit-input-placeholder {
      color: ${({ theme: { fn, palette } }) => fn.color(palette.text.white, '0.6')};
    }

    :-ms-input-placeholder {
      color: ${({ theme: { fn, palette } }) => fn.color(palette.text.white, '0.6')};
    }

    ::placeholder {
      color: ${({ theme: { fn, palette } }) => fn.color(palette.text.white, '0.6')};
    }

    ${(props: SearchInputProps) =>
      props.$secondary &&
      css`
        background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.black, '0.1')};
        color: ${({ theme: { fn, palette } }) => fn.color(palette.text.brandblack, '0.6')};

        ::-webkit-input-placeholder {
          color: ${({ theme: { fn, palette } }) => fn.color(palette.text.brandblack, '0.6')};
        }

        :-ms-input-placeholder {
          color: ${({ theme: { fn, palette } }) => fn.color(palette.text.brandblack, '0.6')};
        }

        ::placeholder {
          color: ${({ theme: { fn, palette } }) => fn.color(palette.text.brandblack, '0.6')};
        }
      `}

    ${(props: SearchInputProps) =>
      props.$focused &&
      css`
        padding-left: 1rem;
        background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.mediumpurple)};
        color: ${({ theme: { fn, palette } }) => fn.color(palette.text.white)};

        ::-webkit-input-placeholder {
          color: ${({ theme: { fn, palette } }) => fn.color(palette.text.white, '0.6')};
        }

        :-ms-input-placeholder {
          color: ${({ theme: { fn, palette } }) => fn.color(palette.text.white, '0.6')};
        }

        ::placeholder {
          color: ${({ theme: { fn, palette } }) => fn.color(palette.text.white, '0.6')};
        }
      `}

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }
`

export const StyledSearchIconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 1rem;
`
