/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FC, ReactElement, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import Button from 'components/button'
import { useForm } from 'react-hook-form'
import Text from 'components/text'
import { CloseIcon, BackIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import { FormFieldValidation, FormFieldInput } from 'components/form'
import isEmail from 'validator/es/lib/isEmail'
import { useQueryParam, StringParam } from 'use-query-params'
import { connect, useDispatch } from 'react-redux'
import { modalQueryParam, modalContactInfoQueryParam, contactIdQueryParam } from 'const'
import { ContactNormalized, selectContactById, updateContacts } from 'reducers/contactsSlice'
import {
  StyledBackButton,
  StyledButtonsWrapper,
  StyledContentWrapper,
  StyledContentContainer,
  StyledCloseButton,
  StyledHeaderContainer,
  StyledForm,
} from './style'

const EditContactModal: FC<{ onClose: () => void; getContactById: (contactId: string) => ContactNormalized }> = ({
  getContactById,
  onClose,
}): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const firstNameRef = useRef<HTMLInputElement | null>(null)
  const [contactIdParam, setContactIdParam] = useQueryParam(contactIdQueryParam, StringParam)
  const contact = useMemo(() => (contactIdParam ? getContactById(contactIdParam) : null), [
    getContactById,
    contactIdParam,
  ])
  const { register, handleSubmit: handleSumbitForm, errors } = useForm()
  const [, setModalParam] = useQueryParam(modalQueryParam, StringParam)
  const [saving, setSaving] = useState(false)

  const handleClose = useCallback(() => {
    setContactIdParam(null, 'replaceIn')
    onClose()
  }, [onClose, setContactIdParam])

  useLayoutEffect(() => {
    if (!contactIdParam) handleClose()
  }, [contactIdParam, handleClose])

  const handleSubmit = async ({ firstname, lastname, email }) => {
    setSaving(true)
    await dispatch(updateContacts({ contacts: [{ id: contact.id, firstName: firstname, lastName: lastname, email }] }))
    setSaving(false)
    handleClose()
  }

  const handleBack = () => {
    setModalParam(modalContactInfoQueryParam, 'replaceIn')
  }

  useEffect(() => {
    firstNameRef.current.focus()
  }, [])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Text component="h1" mt="0" color="brandblack" font="Poppins">
          {t('EditContact')}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
        <StyledBackButton circular="true" subtle="true" onClick={handleBack}>
          <BackIcon title={t('GoBack')} />
        </StyledBackButton>
      </StyledHeaderContainer>
      <StyledContentWrapper>
        {contact && (
          <StyledForm onSubmit={handleSumbitForm(handleSubmit)}>
            <FormFieldInput
              maxLength={64}
              name="firstname"
              defaultValue={contact.firstName}
              label={t('FirstName')}
              placeholder={t('FirstName')}
              ref={(e) => {
                register(e)
                firstNameRef.current = e
              }}
            />
            <FormFieldInput
              maxLength={64}
              name="lastname"
              defaultValue={contact.lastName}
              label={t('LastName')}
              placeholder={t('LastName')}
              ref={register}
            />
            <FormFieldInput
              error={!!errors.email}
              name="email"
              defaultValue={contact.email}
              label={t('Email')}
              maxLength={64}
              placeholder={t('Email')}
              ref={register({ required: true, validate: isEmail })}
            />
            {errors.email?.type === 'required' && (
              <FormFieldValidation mt="0.56rem" mb="1rem" error>
                {t('FormFieldValidationRequiredField')}
              </FormFieldValidation>
            )}
            {errors.email?.type === 'validate' && (
              <FormFieldValidation mt="0.56rem" mb="1rem" error>
                {t('FormFieldValidationInvalidEmail')}
              </FormFieldValidation>
            )}
            <StyledButtonsWrapper>
              <Button size="md" subtle="true" onClick={handleClose}>
                <Text size="md" color="purplehearth" fw="600">
                  {t('Cancel')}
                </Text>
              </Button>
              <Button
                disabled={saving}
                $flat={saving}
                type="submit"
                size="md"
                bcolor="mediumpurple"
                bop="1"
                ml="1rem"
                hgradient="mediumpurple-dim-light"
                agradient="mediumpurple-dim-dark"
              >
                <Text size="md" $secondary fw="600">
                  {t('Save')}
                </Text>
              </Button>
            </StyledButtonsWrapper>
          </StyledForm>
        )}
      </StyledContentWrapper>
    </StyledContentContainer>
  )
}

const mapStateToProps = (state) => ({
  getContactById: (contactId: string) => selectContactById(contactId)(state),
})

export default connect(mapStateToProps, null)(EditContactModal)
