/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement, useCallback, useEffect, useLayoutEffect } from 'react'
import Text from 'components/text'
import { useDispatch, connect } from 'react-redux'
import Button from 'components/button'
import { useTranslation } from 'react-i18next'
import { webNotificationsPromptQueryParam } from 'const'
import { CloseIcon } from 'theme'
import { useQueryParam, BooleanParam } from 'use-query-params'
import { selectAuthUserHasFlag, selectAuthUser, updateFlags, flags } from 'reducers/usersSlice'
import {
  StyledButtonsWrapper,
  StyledContentContainer,
  StyledTitleContainer,
  StyledCloseButton,
  StyledHeaderContainer,
} from './style'

const { $ } = window

const AllowWebNotificationsModal = ({
  setWebNotification,
  authUser,
  hasAllowWebNotificationsPromptShownFlag,
  onClose,
}): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [webNotificationsPrompt, setWebNotificationsPrompt] = useQueryParam(
    webNotificationsPromptQueryParam,
    BooleanParam,
  )

  const handleClose = useCallback(() => {
    dispatch(updateFlags({ id: authUser.id, flags: { add: [flags.allowWebNotificationsPromptShown] } }))
    onClose()
  }, [authUser, dispatch, onClose])

  const handleYes = () => {
    setWebNotificationsPrompt(true, 'replaceIn')
    setWebNotification({
      title: 'Clanzy web notifications',
      timeout: 3000,
      ignore: true,
      notSupported: () => setWebNotificationsPrompt(null, 'replaceIn'),
      onPermissionGranted: () => setWebNotificationsPrompt(null, 'replaceIn'),
      onPermissionDenied: () => setWebNotificationsPrompt(null, 'replaceIn'),
    })
    handleClose()
  }

  useLayoutEffect(() => {
    if (webNotificationsPrompt) setWebNotificationsPrompt(null, 'replaceIn')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    if (hasAllowWebNotificationsPromptShownFlag) {
      setWebNotificationsPrompt(null, 'replaceIn')
      handleClose()
    }
  }, [handleClose, hasAllowWebNotificationsPromptShownFlag, setWebNotificationsPrompt])

  useEffect(() => {
    const el = $('.show-allow-web-notifications-prompt-button')?.[0]
    if (el) el.focus()
  }, [])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledTitleContainer>
        <Text size="xl" gradient="radiance" fw="600" font="Poppins" $block>
          {t('AskWebNotificationsPermissionText1')}
        </Text>
        &nbsp;
        <Text size="xl" fw="600" font="Poppins" $block>
          {t('AskWebNotificationsPermissionText2')}
        </Text>
      </StyledTitleContainer>
      <StyledButtonsWrapper>
        <Button size="md" subtle="true" onClick={handleClose}>
          <Text size="md" color="purplehearth" fw="600">
            {t('No')}
          </Text>
        </Button>
        <Button
          size="md"
          bcolor="mediumpurple"
          bop="1"
          ml="1rem"
          hgradient="mediumpurple-dim-light"
          agradient="mediumpurple-dim-dark"
          onClick={handleYes}
          className="show-allow-web-notifications-prompt-button"
        >
          <Text size="md" $secondary fw="600">
            {t('Yes')}
          </Text>
        </Button>
      </StyledButtonsWrapper>
    </StyledContentContainer>
  )
}

const mapStateToProps = (state) => ({
  authUser: selectAuthUser(state),
  hasAllowWebNotificationsPromptShownFlag: selectAuthUserHasFlag(flags.allowWebNotificationsPromptShown)(state),
})

export default connect(mapStateToProps, null)(AllowWebNotificationsModal)
