/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { ContentContainer } from 'components/container'
import Text from 'components/text'
import { CloseIcon, BackIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import FormFieldDate from 'components/form/form-field-date'
import FormFieldTime from 'components/form/form-field-time'
import Button from 'components/button'
import moment from 'moment'
import {
  StyledContentWrapper,
  StyledButtonsWrapper,
  StyledContentContainer,
  StyledCloseButton,
  StyledBackButton,
  StyledHeaderContainer,
  StyledCard,
  StyledColumnsContainer,
  StyledModal,
} from './style'

const { $ } = window

const SetDateAndTimeModal = ({ value, onClose, onChange }): ReactElement => {
  const { t } = useTranslation()
  const [date, setDate] = useState(value ? value.toDate() : new Date())
  const [time, setTime] = useState(value || moment().set({ seconds: 0 }))
  const [timeError, setTimeError] = useState(null)
  const [timeMinValue, setTimeMinValue] = useState(moment().set({ seconds: 0 }))

  const handleBack = () => onClose()
  const handleClose = () => onClose()

  const focusSaveButton = useCallback(() => {
    const el = $('.set-date-and-time-save-button')?.[0]
    if (el) el.focus()
  }, [])

  const handleSave = useCallback(() => {
    if (onChange) onChange(time)
    onClose()
  }, [onChange, onClose, time])

  const handleDateChange = (date) => {
    if (moment(date).isSame(moment(), 'day')) {
      setTime(moment().set({ seconds: 0 }))
      setTimeMinValue(moment().set({ seconds: 0 }))
    } else {
      setTime(moment(date).startOf('day'))
      setTimeMinValue(moment(date).startOf('day'))
    }
    setTimeError(null)
    setDate(date)
    focusSaveButton()
  }

  const handleTimeChange = (time) => {
    setTime(time)
    setTimeError(null)
    focusSaveButton()
  }

  useEffect(() => {
    focusSaveButton()
  }, [focusSaveButton])

  return (
    <StyledModal component="dialog">
      <StyledColumnsContainer>
        <ContentContainer>
          <StyledCard size="sm" secondary="true">
            <StyledContentContainer>
              <StyledHeaderContainer component="header">
                <Text component="h1" mt="0" color="brandblack" font="Poppins">
                  {t('SetDateAndTime')}
                </Text>
                <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
                  <CloseIcon title={t('CloseThisWindow')} />
                </StyledCloseButton>
                <StyledBackButton circular="true" subtle="true" onClick={handleBack}>
                  <BackIcon title={t('GoBackToCallDetails')} />
                </StyledBackButton>
              </StyledHeaderContainer>
              <StyledContentWrapper>
                <FormFieldDate name="schedule-date" onChange={handleDateChange} value={date} minValue={new Date()} />
                <FormFieldTime
                  mt="0.5rem"
                  error={timeError}
                  minValue={timeMinValue}
                  onChange={handleTimeChange}
                  onError={setTimeError}
                  name="scheduled-time"
                  value={time}
                />
                <StyledButtonsWrapper>
                  <Button size="md" subtle="true" onClick={handleClose}>
                    <Text size="md" color="purplehearth" fw="600">
                      {t('Cancel')}
                    </Text>
                  </Button>
                  <Button
                    $flat={!!timeError}
                    disabled={!!timeError}
                    type="button"
                    size="md"
                    bcolor="mediumpurple"
                    bop="1"
                    ml="1rem"
                    hgradient="mediumpurple-dim-light"
                    agradient="mediumpurple-dim-dark"
                    onClick={handleSave}
                    className="set-date-and-time-save-button"
                  >
                    <Text size="md" $secondary fw="600">
                      {t('Save')}
                    </Text>
                  </Button>
                </StyledButtonsWrapper>
              </StyledContentWrapper>
            </StyledContentContainer>
          </StyledCard>
        </ContentContainer>
      </StyledColumnsContainer>
    </StyledModal>
  )
}

export default SetDateAndTimeModal
