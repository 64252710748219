import styled, { css } from 'styled-components'

export interface ButtonListItemProps {
  /**
   * Href
   */
  link?: string
  /**
   * On click handler
   */
  onClick?: () => void
  /**
   * If the item is selected
   */
  active?: boolean
  /**
   * Size
   */
  size?: string
  /**
   * Margin top
   */
  mt?: string
}

export const StyledListItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  border-radius: 0.5rem;
  padding-left: 2rem;
  color: ${({ theme: { fn, palette } }) => fn.color(palette.text.white)};
  border: 0.125rem solid ${({ theme: { fn, colors } }) => fn.color(colors.black, '0.2')};
  transition: all 0.3s ease-out;

  &:hover {
    border: 0.125rem solid ${({ theme: { fn, colors } }) => fn.color(colors.mediumpurple)};
    box-shadow: ${({ theme: { shadows } }) => shadows.square};
  }

  ${(props: ButtonListItemProps) =>
    !!props.size &&
    props.size === 'xl' &&
    css`
      height: 6rem;
    `}

  ${(props: ButtonListItemProps) =>
    !!props.mt &&
    css`
      margin-top: ${props.mt};
    `}
`

export const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
`
