import { ReactElement, FC } from 'react'
import Image from 'components/image'
import Button from 'components/button'
import { apiUrl } from 'const'
import Text from 'components/text'
import { getAvatarLetters } from 'utils'
import { isOnline } from 'utils/contacts'
import { CloseIcon } from 'theme'
import Presence from './presence'
import { StyledColumnsContainer, StyledWrapper, StyledCloseButton, StyledAvatarWrapper, AvatarProps } from './style'

const Avatar: FC<AvatarProps> = ({
  id,
  bcolor,
  borcolor,
  borop,
  bop,
  onClick,
  onClose,
  $secondary,
  size,
  fontSize,
  buttonSize,
  alt,
  img,
  title,
  name,
  nameCharsCnt = 2,
  $noPresence,
  status,
  bs,
  baseUrl = apiUrl,
}): ReactElement => {
  const component = img ? (
    <Image alt={alt} src={`${baseUrl}${img}`} />
  ) : (
    <Text $secondary={!$secondary} size={fontSize || size} fw="600">
      {getAvatarLetters(name, nameCharsCnt)}
    </Text>
  )

  const handleClick = () => onClick(id)
  const handleClose = () => onClose(id)
  let textMaxWidth = '2.5rem'

  if (size === 'lg') {
    textMaxWidth = '4rem'
  } else if (size === 'xl') {
    textMaxWidth = '5.5rem'
  } else if (size === 'xxxl') {
    textMaxWidth = '10rem'
  }

  return (
    <StyledWrapper>
      <StyledColumnsContainer>
        {onClick ? (
          <Button
            size={buttonSize || size}
            $flat
            circular="true"
            $secondary={$secondary}
            borcolor={!img ? borcolor || ($secondary ? 'black' : 'white') : borcolor}
            borop={!img ? borop || ($secondary ? '0.1' : '0.6') : borop}
            bcolor="transparent"
            onClick={handleClick}
            bs={bs}
          >
            {component}
          </Button>
        ) : (
          <StyledAvatarWrapper
            $secondary={$secondary}
            size={size}
            bop={bop}
            bcolor={bcolor}
            borcolor={!img ? borcolor || ($secondary ? 'black' : 'white') : borcolor}
            borop={!img ? borop || ($secondary ? '0.1' : '0.6') : borop}
            bs={bs}
          >
            {component}
          </StyledAvatarWrapper>
        )}
        {onClose && (
          <>
            <StyledCloseButton circular="true" borcolor="white" bcolor="black" bop="1" $flat onClick={handleClose}>
              <CloseIcon $secondary />
            </StyledCloseButton>
          </>
        )}
        {isOnline({ status }) && !$noPresence && <Presence $secondary={$secondary} />}
      </StyledColumnsContainer>
      {title && (
        <Text mt="0.5rem" maxw={textMaxWidth} $block $ellipsis>
          {title}
        </Text>
      )}
    </StyledWrapper>
  )
}

export * from './style'
export default Avatar
