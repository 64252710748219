/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { GroupNormalized } from 'reducers/groupsSlice'
import { isClanzyUser, isOnline as isContactOnline } from './contacts'
// import { getLetters } from '.'

export const isOnline = (group: GroupNormalized) => group?.contacts?.some(isContactOnline)

export const isClanzyGroup = (group) => group?.contacts?.filter((contact) => contact).every(isClanzyUser)

export const hasClanzyUser = (group) => group?.contacts?.filter((contact) => contact).some(isClanzyUser)

export const getContactsDisplayNames = (group, maxInTitle = 5): [string, number] => {
  const displayNames = group?.contacts?.map(
    // ({ firstName, displayName }) => firstName || getLetters(displayName, 10, true),
    ({ firstName, displayName }) => firstName || displayName,
  )
  if (!displayNames?.length) return ['', 0]
  if (displayNames?.length <= maxInTitle) return [displayNames?.join(', '), 0]
  return [displayNames?.slice(0, maxInTitle).join(', '), displayNames?.length - maxInTitle]
}

export const getContactsAvatarsInfo = (group) => {
  if (!group?.contacts?.length) return null

  if (group.contacts.length === 1)
    return [
      {
        photo: group.contacts[0].photo,
        displayName: group.contacts[0].displayName,
        isClanzy: isClanzyUser(group.contacts[0]),
      },
    ]

  return [...group.contacts]
    .sort((a, b) => {
      const isClanzyB = isClanzyUser(b)
      const isClanzyA = isClanzyUser(a)
      if (isClanzyB === isClanzyA) return Number(!!b.photo) - Number(!!a.photo)
      return Number(isClanzyB) - Number(isClanzyA)
    })
    .slice(0, 2)
    .map((contact) => ({ ...contact, isClanzy: isClanzyUser(contact) }))
}

export const getContactsCount = (group) => group.contacts.length

export const isGroup = (group) => !!group.contacts

export const isNotGroup = (group) => !group.contacts
