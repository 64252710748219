import { ReactElement } from 'react'
import { withTheme } from 'styled-components'

export default withTheme(
  ({ theme: { fn, palette }, success, title, color }): ReactElement => {
    const stroke = (fn.color as (arg0: string) => string)(
      success ? palette.text[color || 'malachite'] : palette.primary.main,
    )
    const strokeOpacity = success ? '1' : '0.2'
    return (
      <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <path
          d="M2 5.9625L6.5242 10.5L14.75 2.25"
          stroke={stroke}
          strokeOpacity={strokeOpacity}
          strokeWidth="3"
          strokeMiterlimit="3.99933"
          strokeLinecap="round"
        />
      </svg>
    )
  },
)
