import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <g opacity="0.6">
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V36C40 38.2091 38.2091 40 36 40H4C1.79086 40 0 38.2091 0 36V4Z"
        fill="white"
        fillOpacity="0.1"
      />
      <rect x="16" y="20" width="8" height="13" rx="2" fill="white" />
      <path
        d="M7.5 16.031C7.5 15.2223 7.89117 14.4635 8.54992 13.9945L18.5499 6.87395C19.4179 6.25592 20.5821 6.25592 21.4501 6.87395L31.4501 13.9945C32.1088 14.4635 32.5 15.2223 32.5 16.031V29C32.5 30.3807 31.3807 31.5 30 31.5H10C8.61929 31.5 7.5 30.3807 7.5 29V16.031Z"
        stroke="white"
        strokeWidth="3"
      />
    </g>
  </svg>
)
