import { ReactElement } from 'react'

export default (): ReactElement => (
  <svg width="52" height="49" viewBox="0 0 52 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.29007 34.5123C8.06249 35.5932 8.75423 36.6539 9.8351 36.8815L27.4489 40.5899C28.5298 40.8175 29.5905 40.1258 29.8181 39.0449C30.0457 37.964 29.3539 36.9033 28.273 36.6758L12.6163 33.3793L15.9127 17.7226C16.1403 16.6417 15.4486 15.581 14.3677 15.3534C13.2868 15.1259 12.2261 15.8176 11.9986 16.8985L8.29007 34.5123ZM42.6594 11.399L9.15465 33.2491L11.3397 36.5996L44.8445 14.7495L42.6594 11.399Z"
      fill="#13BF4D"
    />
  </svg>
)
