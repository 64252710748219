import { ReactElement, FC, useLayoutEffect } from 'react'
import Text from 'components/text'
import { modalQueryParam, tourQueryParam } from 'const'
import { TourWelcomeImage, CloseIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import { selectAuthUser, flags, updateFlags, UserNormalized } from 'reducers/usersSlice'
import { useQueryParam, StringParam, BooleanParam } from 'use-query-params'
import Button from 'components/button'
import { useDispatch, connect } from 'react-redux'
import {
  StyledImageWrapper,
  StyledContentContainer,
  StyledTextContainer,
  StyledCloseButton,
  StyledHeaderContainer,
  StyledColumnsContainer,
} from './style'

const TourModal: FC<{ authUser: UserNormalized; onClose: () => void }> = ({ authUser, onClose }): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [tourParam, setTourParam] = useQueryParam(tourQueryParam, BooleanParam)
  const [, setModalParam] = useQueryParam(modalQueryParam, StringParam)

  useLayoutEffect(() => {
    if (tourParam) onClose()
  }, [onClose, setModalParam, tourParam])

  const handleClose = () => {
    onClose()
    dispatch(updateFlags({ id: authUser.id, flags: { add: [flags.welcomeTourShown] } }))
  }

  const handleNext = () => {
    setTourParam(true, 'replaceIn')
  }

  return (
    <StyledContentContainer>
      <StyledImageWrapper>
        <TourWelcomeImage />
      </StyledImageWrapper>
      <StyledHeaderContainer component="header">
        <Text component="h2" mt="1rem" gradient="magenta" font="Poppins">
          {t('WelcomeToClanzy')}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledTextContainer>
        <Text size="xs" color="black" cop="0.6" ml="1.5rem">
          {t('TourTitle')}
        </Text>
      </StyledTextContainer>
      <Button
        size="md"
        bcolor="mediumpurple"
        bop="1"
        mt="3.5rem"
        hgradient="mediumpurple-dim-light"
        agradient="mediumpurple-dim-dark"
        onClick={handleNext}
      >
        <Text size="md" $secondary fw="600">
          {t('ShowMeAround')}
        </Text>
      </Button>
      <StyledColumnsContainer component="footer">
        <Text size="md" ml="auto" mr="auto" fw="600" color="brandblack" onClick={handleClose} td="none">
          {t('SkipProductTour')}
        </Text>
      </StyledColumnsContainer>
    </StyledContentContainer>
  )
}

const mapStateToProps = (state) => ({
  authUser: selectAuthUser(state),
})

export default connect(mapStateToProps, null)(TourModal)
