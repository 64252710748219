/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ContactListItem } from 'components/list'
import {
  StyledNoScrollWrapper,
  StyledText,
  StyledParticipantsWrapper,
  StyledVerticalScrollbar,
  StyledListColumn,
} from './style'

const ParticipantsList: FC<{
  call: any
  onClose?: any
  $noScroll?: boolean
}> = ({ call, onClose, $noScroll }): ReactElement => {
  const { t } = useTranslation()

  const hasOnlyOneGroupSelected = useMemo(() => (call ? !call.contacts?.length && call.groups?.length === 1 : null), [
    call,
  ])

  const contactsIds = useMemo(
    () => [
      ...new Set([
        ...(call?.contacts?.map(({ id }) => id) || []),
        ...(call?.groups?.flatMap(({ contacts }) => contacts.map(({ id }) => id)) || []),
      ]),
    ],
    [call],
  )

  const contactsToRender = useMemo(
    () =>
      [...(call?.contacts || []), ...(call?.groups?.flatMap(({ contacts }) => contacts) || [])].reduce(
        (res, contact) => {
          if (!res.find(({ id }) => id === contact.id)) res.push(contact)
          return res
        },
        [],
      ),
    [call],
  )

  const component = (
    <StyledListColumn>
      {contactsToRender.map((contact) => (
        <ContactListItem
          $secondary
          $flat
          $wide
          key={contact.id}
          contact={contact}
          onClose={onClose ? onClose(contact) : null}
          $noPresence
        />
      ))}
    </StyledListColumn>
  )

  return (
    <>
      <StyledText size="lg" color="brandblack" cop="0.6" fw="600" mt="1rem" mb="0.5rem" tt="lowercase">
        {hasOnlyOneGroupSelected ? call.groups[0].name : `${contactsIds?.length || 0} ${t('Participants')}`}
      </StyledText>
      {!!contactsToRender?.length && (
        <StyledParticipantsWrapper>
          {$noScroll ? (
            <StyledNoScrollWrapper>{component}</StyledNoScrollWrapper>
          ) : (
            <StyledVerticalScrollbar options={{ className: 'os-theme-light' }}>{component}</StyledVerticalScrollbar>
          )}
        </StyledParticipantsWrapper>
      )}
    </>
  )
}

export default ParticipantsList
