import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <circle cx="24" cy="24" r="24" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6818 9.43934C13.096 8.85355 12.1462 8.85355 11.5604 9.43934C10.9746 10.0251 10.9746 10.9749 11.5604 11.5607L18.9998 19V23C18.9998 25.7614 21.2384 28 23.9998 28C25.1255 28 26.1643 27.628 27 27.0002L28.7796 28.7798C27.4818 29.8543 25.8162 30.5 23.9998 30.5C19.8576 30.5 16.4998 27.1421 16.4998 23C16.4998 22.1716 15.8282 21.5 14.9998 21.5C14.1713 21.5 13.4998 22.1716 13.4998 23C13.4998 28.4617 17.6698 32.9494 22.9998 33.453V39H25.9998V33.3098C27.8563 32.9517 29.5388 32.1046 30.9076 30.9079L36.4391 36.4393C37.0249 37.0251 37.9746 37.0251 38.5604 36.4393C39.1462 35.8536 39.1462 34.9038 38.5604 34.318L32.8675 28.6251C33.9011 26.999 34.4998 25.0694 34.4998 23C34.4998 22.1716 33.8282 21.5 32.9998 21.5C32.1713 21.5 31.4998 22.1716 31.4998 23C31.4998 24.2356 31.201 25.4015 30.6716 26.4292L28.764 24.5216C28.9171 24.0418 28.9998 23.5306 28.9998 23V15C28.9998 12.2386 26.7612 10 23.9998 10C21.3178 10 19.1291 12.1116 19.0053 14.7629L13.6818 9.43934ZM21.9998 22V23C21.9998 24.1046 22.8952 25 23.9998 25C24.2932 25 24.5719 24.9368 24.823 24.8232L21.9998 22ZM25.9998 21.7574L21.9998 17.7574V15C21.9998 13.8954 22.8952 13 23.9998 13C25.1043 13 25.9998 13.8954 25.9998 15V21.7574Z"
      fill="black"
      fillOpacity="0.4"
    />
  </svg>
)
