/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import Button from 'components/button'
import { ContentContainer, RowsContainer, ColumnsContainer } from 'components/container'

export const StyledImageWrapper = styled.div`
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;

  position: relative;
  box-sizing: border-box;

  background-clip: padding-box;
  border: 0.25rem solid transparent;
  border-radius: 2rem;

  & img {
    margin: 0 auto;
    z-index: 1;
  }
`

export const StyledTextContainer = styled.article`
  text-align: center;
  position: relative;
  padding: 1rem 6rem 0 6rem;
`

export const StyledContentContainer = styled(ContentContainer)`
  margin: 1.125rem;
`

export const StyledHeaderContainer = styled(RowsContainer)`
  height: 2.8125rem;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
`

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

export const StyledColumnsContainer = styled(ColumnsContainer)`
  margin-top: 1rem;
  text-align: center;
  padding: 0 1.8rem 0;
`
