import { ReactElement } from 'react'
import { withTheme } from 'styled-components'

export default withTheme(
  ({ size, theme: { fn, palette }, secondary, title }): ReactElement => {
    const color = (fn.color as (arg0: string) => string)(secondary ? palette.secondary.main : palette.primary.main)
    return size === 'lg' ? (
      <svg width="84" height="68" viewBox="0 0 84 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <path
          d="M4.66035 10.8234C4.66035 7.26811 7.54252 4.38594 11.0979 4.38594H72.8978C76.4532 4.38594 79.3354 7.26811 79.3354 10.8234V57.1734C79.3354 60.7288 76.4532 63.6109 72.8979 63.6109H11.0979C7.54253 63.6109 4.66035 60.7288 4.66035 57.1734V10.8234Z"
          stroke="white"
          strokeOpacity="0.6"
          strokeWidth="7.725"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.9988 22.6328L43.8196 24.4536L56.6946 37.3286C57.7002 38.3342 57.7002 39.9646 56.6946 40.9702C55.689 41.9758 54.0586 41.9758 53.053 40.9702L41.9988 29.916L30.9446 40.9702C29.939 41.9758 28.3086 41.9758 27.303 40.9702C26.2974 39.9646 26.2974 38.3342 27.303 37.3286L40.178 24.4536L41.9988 22.6328Z"
          fill="white"
          fillOpacity="0.6"
        />
      </svg>
    ) : (
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <circle cx="24" cy="24" r="24" fill={color} fillOpacity="0.38" />
        <path
          d="M9.5 15C9.5 13.6193 10.6193 12.5 12 12.5H36C37.3807 12.5 38.5 13.6193 38.5 15V33C38.5 34.3807 37.3807 35.5 36 35.5H12C10.6193 35.5 9.5 34.3807 9.5 33V15Z"
          stroke={color}
          strokeOpacity="0.8"
          strokeWidth="3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 19.5859L24.7071 20.293L29.7071 25.293C30.0976 25.6836 30.0976 26.3167 29.7071 26.7073C29.3166 27.0978 28.6834 27.0978 28.2929 26.7073L24 22.4144L19.7071 26.7073C19.3166 27.0978 18.6834 27.0978 18.2929 26.7073C17.9024 26.3167 17.9024 25.6836 18.2929 25.293L23.2929 20.293L24 19.5859Z"
          fill={color}
          fillOpacity="0.8"
        />
      </svg>
    )
  },
)
