/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FC, ReactElement, useCallback, useEffect, useLayoutEffect } from 'react'
import Button from 'components/button'
import Text from 'components/text'
import Divider from 'components/divider'
import { entertainmentQueryParam, media } from 'const'
import { CloseIcon, YouTubePartyIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import { useQueryParam, StringParam } from 'use-query-params'
import { useDispatch } from 'react-redux'
import { addCallEntertainment, CallNormalized } from 'reducers/callsSlice'
import {
  StyledIconWrapper,
  StyledTextWrapper,
  StyledTextAgreementWrapper,
  StyledButtonsWrapper,
  StyledContentWrapper,
  StyledContentContainer,
  StyledCloseButton,
  StyledHeaderContainer,
} from './style'

const { $ } = window

const NewMedia: FC<{ onClose: () => void; call: CallNormalized }> = ({ onClose, call }): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [entertainmentParam, setEntertainmentParam] = useQueryParam(entertainmentQueryParam, StringParam)

  useLayoutEffect(() => {
    if (!entertainmentParam || !Object.values(media).includes(entertainmentParam)) onClose()
  }, [entertainmentParam, onClose])

  const handleStart = () => {
    dispatch(
      addCallEntertainment({
        callId: call.id,
        entertainment: {
          type: 'media',
          name: entertainmentParam,
          participants: call.callParticipants?.map(({ id }) => id),
        },
      }),
    )
    setEntertainmentParam(null, 'replaceIn')
    onClose()
  }

  const focusStartMediaButton = useCallback(() => {
    if (call) {
      const el = $(`.start-media-${call.id}`)?.[0]
      if (el) el.focus()
    }
  }, [call])

  useEffect(() => {
    focusStartMediaButton()
  }, [focusStartMediaButton])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <StyledCloseButton circular="true" subtle="true" onClick={onClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledContentWrapper>
        {entertainmentParam === media.youtube && (
          <>
            <StyledIconWrapper>
              <YouTubePartyIcon />
            </StyledIconWrapper>
            <Text component="h1" mt="2.125rem" color="brandblack" font="Poppins">
              {t('YouTubeWatchParty')}
            </Text>
            <StyledTextWrapper>
              <Text fw="500" color="brandblack">
                {t('YouTubePartyDescriptionText')}
              </Text>
            </StyledTextWrapper>
            <Divider mt="1.125rem" />
            <StyledTextAgreementWrapper>
              <Text size="xs" color="brandblack" cop="0.6">
                {t('YouTubeWatchPartyAdditionalText')}
              </Text>
            </StyledTextAgreementWrapper>
          </>
        )}
        <StyledButtonsWrapper>
          <Button
            mt="2rem"
            type="submit"
            size="md"
            bcolor="mediumpurple"
            bop="1"
            hgradient="mediumpurple-dim-light"
            agradient="mediumpurple-dim-dark"
            onClick={handleStart}
            className={`start-media-${call?.id}`}
          >
            <Text size="md" $secondary fw="600">
              {t('CreateYouTubeWatchParty')}
            </Text>
          </Button>
          <Button mt="0.5rem" size="md" subtle="true" borcolor="mediumpurple" borsize="0.125rem" onClick={onClose}>
            <Text size="md" color="brandblack" fw="600">
              {t('NotNow')}
            </Text>
          </Button>
        </StyledButtonsWrapper>
      </StyledContentWrapper>
    </StyledContentContainer>
  )
}

export default NewMedia
