import { ReactElement } from 'react'

export default (): ReactElement => (
  <svg width="55" height="93" viewBox="0 0 55 93" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.4754 5.41676C40.9598 4.43992 39.75 4.06599 38.7731 4.58158L22.8544 12.9837C21.8776 13.4992 21.5036 14.7091 22.0192 15.686C22.5348 16.6628 23.7447 17.0367 24.7215 16.5211L38.8715 9.05264L46.34 23.2026C46.8556 24.1795 48.0655 24.5534 49.0423 24.0378C50.0192 23.5222 50.3931 22.3123 49.8775 21.3355L41.4754 5.41676ZM16.2233 89.1062L41.6175 6.94089L37.7959 5.75977L12.4017 87.9251L16.2233 89.1062Z"
      fill="#13BF4D"
    />
  </svg>
)
