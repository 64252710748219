/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FC, ReactElement, useEffect, useLayoutEffect } from 'react'
import Button from 'components/button'
import Text from 'components/text'
import { modalGameInviteQueryParam, entertainmentQueryParam, modalQueryParam, games } from 'const'
import { CloseIcon, RummyImage } from 'theme'
import { useTranslation } from 'react-i18next'
import { useQueryParam, StringParam } from 'use-query-params'
import { CallNormalized } from 'reducers/callsSlice'
import {
  StyledTextWrapper,
  StyledButtonsWrapper,
  StyledContentWrapper,
  StyledContentContainer,
  StyledCloseButton,
  StyledHeaderContainer,
} from './style'

const { $ } = window

const NewGame: FC<{ call: CallNormalized; onClose: () => void }> = ({ call, onClose }): ReactElement => {
  const { t } = useTranslation()
  const [, setModalParam] = useQueryParam(modalQueryParam, StringParam)
  const [entertainmentParam] = useQueryParam(entertainmentQueryParam, StringParam)

  useLayoutEffect(() => {
    if (!entertainmentParam || !Object.values(games).includes(entertainmentParam)) onClose()
  }, [entertainmentParam, onClose])

  const handleInvite = () => {
    setModalParam(modalGameInviteQueryParam, 'replaceIn')
  }

  useEffect(() => {
    if (call) {
      const el = $(`.invite-players-${call.id}`)?.[0]
      if (el) el.focus()
    }
  }, [call])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Text component="h1" mt="0" color="brandblack" font="Poppins">
          {entertainmentParam === games.rummy ? t('Rummy') : ''}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={onClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledContentWrapper>
        {entertainmentParam === games.rummy && <RummyImage />}
        <StyledTextWrapper>
          {entertainmentParam === games.rummy && (
            <>
              <Text mt="1rem" color="brandblack" cop="0.6">
                {t('RummyGameDescriptionText')}
              </Text>
              <br />
              <br />
              <Text mt="1rem" color="brandblack" cop="0.6">
                {t('RummyGameMoreInstructionsText')}
              </Text>
            </>
          )}
        </StyledTextWrapper>
        <StyledButtonsWrapper>
          <Button
            mt="2rem"
            type="submit"
            size="md"
            bcolor="mediumpurple"
            bop="1"
            hgradient="mediumpurple-dim-light"
            agradient="mediumpurple-dim-dark"
            onClick={handleInvite}
            className={`invite-players-${call.id}`}
          >
            <Text size="md" $secondary fw="600">
              {t('InvitePlayers')}
            </Text>
          </Button>
          <Button mt="0.5rem" size="md" subtle="true" borcolor="mediumpurple" borsize="0.125rem" onClick={onClose}>
            <Text size="md" color="brandblack" fw="600">
              {t('NotNow')}
            </Text>
          </Button>
        </StyledButtonsWrapper>
      </StyledContentWrapper>
    </StyledContentContainer>
  )
}

export default NewGame
