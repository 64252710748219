/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { addedNotification, updated, removed } from './guardianRequestsSlice'

export const actionsTypesMapping = {}

export const eventsMapping = {
  guardianRequest: {
    action: 'guardianRequests/added',
    actionCreator: addedNotification,
  },
  guardianRequestUpdated: {
    action: 'guardianRequests/updated',
    actionCreator: updated,
  },
  guardianRequestRemoved: {
    action: 'guardianRequests/removed',
    actionCreator: removed,
  },
}
