import { Helmet } from 'react-helmet'
import { withTheme } from 'styled-components'
import { effectQueryParam } from 'const'
import { useQueryParam, StringParam } from 'use-query-params'
import { useLayoutEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default withTheme(({ theme: { fn, palette, fonts }, children }) => {
  const [efectParam, setEfectParam] = useQueryParam(effectQueryParam, StringParam)
  useLayoutEffect(() => {
    if (efectParam) setEfectParam(null, 'replaceIn')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Helmet>
        <style>{`body { background-color: ${fn.color(palette.background.charade)}; color: ${fn.color(
          palette.secondary.main,
        )}; font-family: ${fonts.Inter}; }`}</style>
      </Helmet>
      {children}
    </>
  )
})
