import { useEffect, useState } from 'react'

export default (int = 60000): number => {
  const [ticker, setTicker] = useState(null)
  useEffect(() => {
    const interval = setInterval(() => {
      setTicker(new Date().valueOf())
    }, int)
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return ticker
}
