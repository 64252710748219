import { ReactElement } from 'react'
import { CheckTickIcon } from 'theme'
import { StyledIconWrapper, StyledWrapper, StyledInput, CheckCircleProps } from './style'

export default ({ $checked, alt }: CheckCircleProps): ReactElement => {
  if ($checked)
    return (
      <StyledWrapper>
        <StyledInput $checked={$checked} />
        <StyledIconWrapper>
          <CheckTickIcon title={alt} />
        </StyledIconWrapper>
      </StyledWrapper>
    )
  return <StyledInput />
}
