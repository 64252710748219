import { ReactElement } from 'react'

export default ({ color, title }: { color: string; title: string }): ReactElement => (
  <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path
      d="M32 61.334C15.9837 61.334 3 48.3502 3 32.334H-3C-3 51.664 12.67 67.334 32 67.334V61.334ZM61 32.334C61 48.3502 48.0163 61.334 32 61.334V67.334C51.33 67.334 67 51.664 67 32.334H61ZM32 3.33398C48.0163 3.33398 61 16.3177 61 32.334H67C67 13.004 51.33 -2.66602 32 -2.66602V3.33398ZM32 -2.66602C12.67 -2.66602 -3 13.004 -3 32.334H3C3 16.3177 15.9837 3.33398 32 3.33398V-2.66602Z"
      fill="#11111B"
      fillOpacity="0.05"
    />
    <line x1="49" y1="32.291" x2="14" y2="32.291" stroke={color} strokeWidth="3" />
    <line x1="32.043" y1="50.334" x2="32.043" y2="15.334" stroke={color} strokeWidth="3" />
  </svg>
)
