/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import Button from 'components/button'
import { ContentContainer, RowsContainer } from 'components/container'
import { FormFieldSelect } from 'components/form'

export const StyledFormFieldSelect = styled(FormFieldSelect)`
  margin-top: 1rem;
`

export const StyledContentContainer = styled(ContentContainer)`
  margin: 1.125rem;
`

export const StyledContentWrapper = styled(ContentContainer)`
  margin-top: 0.5rem;
  width: 100%;
`

export const StyledHeaderContainer = styled(RowsContainer)`
  height: 2.8125rem;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
`

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`
