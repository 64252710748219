import { FC, ReactElement, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import Button from 'components/button'
import { useForm } from 'react-hook-form'
import Text from 'components/text'
import { ContentContainer, RowsContainer } from 'components/container'
import { AddPhoto } from 'components/photo'
import { CloseIcon, BackIcon, PhotoIcon } from 'theme'
import { nanoid } from 'nanoid'
import { useTranslation } from 'react-i18next'
import { useQueryParam, StringParam } from 'use-query-params'
import { modalQueryParam, callIdQueryParam, modalAddPeopleQueryParam, modalCallScheduledQueryParam } from 'const'
import { upsert, update, deleteNewCalls, selectCallById, CallNormalized } from 'reducers/callsSlice'
import { createGroups } from 'reducers/groupsSlice'
import { connect, useDispatch } from 'react-redux'
import { ParticipantsList } from 'components/list'
import { CallStatuses } from 'apis'
import { CheckBox } from 'components/check'
import { makeCall, getCallDateTimeMoment } from 'utils/calls'
import moment from 'moment'
import Divider from 'components/divider'
import {
  StyledVerticalScrollbar,
  StyledCheckBoxWrapper,
  StyledButtonsWrapper,
  StyledContentWrapper,
  StyledCloseButton,
  StyledBackButton,
  StyledHeaderContainer,
  StyledForm,
  StyledFormFieldInput,
  StyledFormFieldSchedule,
  StyledFormFieldValidation,
} from './style'

const { $ } = window

const CreateCallModal: FC<{ getCallById: (id: string) => CallNormalized; onClose: () => void }> = ({
  getCallById,
  onClose,
}): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [, setModalParam] = useQueryParam(modalQueryParam, StringParam)
  const [callIdParam, setCallIdParam] = useQueryParam(callIdQueryParam, StringParam)
  const { register, handleSubmit: handleSumbitForm, watch } = useForm()
  const [errorGroupName, setErrorGroupName] = useState(null)
  const [groupNameTouched, setGroupNameTouched] = useState(false)
  const watchCallDescription = watch('calldescription')
  const watchGroupName = watch('groupname')
  const scrollRef = useRef(null)
  const [scrollTop, setScrollTop] = useState(false)
  const [bottomReached, setBottomReached] = useState(false)
  const [saving, setSaving] = useState(false)

  const onScroll = ({ srcElement }) => {
    if ((srcElement.scrollTop && !scrollTop) || (!srcElement.scrollTop && scrollTop)) {
      setScrollTop(!!srcElement.scrollTop)
    }

    const scrollInfo = scrollRef.current.osInstance().scroll()
    if (scrollInfo.position.y === scrollInfo.max.y) {
      if (!bottomReached) setBottomReached(true)
    } else if (bottomReached) {
      setBottomReached(false)
    }
  }

  const onUpdated = () => {
    const scrollInfo = scrollRef.current?.osInstance()?.scroll()
    if (scrollInfo && !scrollInfo.max.y && !bottomReached) {
      setBottomReached(true)
    }
  }

  const call = useMemo(() => (callIdParam ? getCallById(callIdParam) : null), [getCallById, callIdParam])
  const hasOnlyOneGroupSelected = useMemo(() => (call ? !call.contacts.length && call.groups.length === 1 : null), [
    call,
  ])
  const contactsIds = useMemo(
    () => [
      ...new Set([
        ...(call?.contacts?.map(({ id }) => id) || []),
        ...(call?.groups?.flatMap(({ contacts }) => contacts.map(({ id }) => id)) || []),
      ]),
    ],
    [call],
  )

  const focusCallSchedule = useCallback(() => {
    if (callIdParam) {
      const el = $(`.call-schedule button`)?.[0]
      if (el) el.focus()
    }
  }, [callIdParam])

  useEffect(() => {
    focusCallSchedule()
  }, [focusCallSchedule])

  useEffect(() => {
    if (callIdParam) {
      if (watchCallDescription !== call?.description) {
        // TODO: debounce
        dispatch(update([{ id: callIdParam, changes: { description: watchCallDescription } }]))
      }
    }
  }, [dispatch, call, callIdParam, watchCallDescription])

  useEffect(() => {
    if (callIdParam) {
      if (watchGroupName !== call?.groupName) {
        // TODO: debounce
        dispatch(update([{ id: callIdParam, changes: { groupName: watchGroupName } }]))

        if (groupNameTouched) {
          if (watchGroupName) {
            setErrorGroupName(null)
          } else {
            setErrorGroupName('Required')
          }
        }
      }
    }
  }, [dispatch, call, callIdParam, watchGroupName, groupNameTouched])

  useLayoutEffect(() => {
    if (!callIdParam || !call) {
      const callId = call?.id || nanoid()

      dispatch(
        upsert([
          {
            id: callId,
            isInstant: true,
            callStatus: CallStatuses.notStarted,
            contacts: [],
            groups: [],
            initialPeopleSelected: false,
          },
        ]),
      )

      setCallIdParam(callId, 'replaceIn')
      setModalParam(modalAddPeopleQueryParam, 'replaceIn')
    }
  }, [dispatch, call, callIdParam, setCallIdParam, setModalParam])

  const handleClose = () => {
    onClose()
    if (callIdParam) dispatch(deleteNewCalls([callIdParam]))
    setCallIdParam(null, 'replaceIn')
  }

  const handleSubmit = async ({ calldescription }) => {
    setSaving(true)
    const res = await makeCall({
      call: { ...call, description: calldescription },
      dispatch,
      setCallIdParam,
      setModalParam,
    })
    setSaving(false)

    if (call.isInstant) {
      handleClose()
    } else {
      onClose()
      setCallIdParam(res.payload.id, 'replaceIn')
      setModalParam(modalCallScheduledQueryParam, 'replaceIn')
    }
  }

  const handleAddToTheCall = () => {
    setModalParam(modalAddPeopleQueryParam, 'replaceIn')
  }

  const handleRemoveContact = ({ id: contactId }) => () => {
    const filteredContacts = call.contacts.filter(({ id }) => id !== contactId)
    const filteredGroups = call.groups.filter(({ contacts }) => contacts.every(({ id }) => id !== contactId))
    const groupsHasContact = call.groups.filter(({ contacts }) => contacts.some(({ id }) => id === contactId))
    groupsHasContact.forEach(({ contacts }) =>
      contacts.forEach((contact) => {
        if (contact.id !== contactId && !filteredContacts.find(({ id }) => id === contact.id)) {
          filteredContacts.push(contact)
        }
      }),
    )
    dispatch(
      update([
        {
          id: call.id,
          changes: {
            contacts: filteredContacts,
            groups: filteredGroups,
          },
        },
      ]),
    )
  }

  const handleScheduleChange = (scheduledTime) => {
    if (moment(scheduledTime).subtract(1, 'minutes').isBefore(moment())) {
      dispatch(update([{ id: callIdParam, changes: { scheduledTime: null, isInstant: true } }]))
    } else {
      dispatch(
        update([
          {
            id: callIdParam,
            changes: { scheduledTime: scheduledTime.format(), isInstant: false },
          },
        ]),
      )
    }
  }

  const handleSaveGroupCheck = () => {
    dispatch(update([{ id: callIdParam, changes: { saveGroup: !call?.saveGroup } }]))
  }

  const handleAddGroupPhoto = (photo) => {
    dispatch(update([{ id: callIdParam, changes: { groupPhoto: photo } }]))
  }

  const handleSaveGroup = async () => {
    setGroupNameTouched(true)
    if (call?.groupName) {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { payload } = await dispatch(
          createGroups({
            groups: [{ name: call.groupName, description: '', photo: call.groupPhoto, contacts: contactsIds }],
          }),
        )

        dispatch(update([{ id: callIdParam, changes: { contacts: [], groups: payload, groupSaved: true } }]))
        // eslint-disable-next-line no-empty
      } catch (e) {}
    } else {
      setErrorGroupName('Required')
    }
  }

  useEffect(() => {
    const el = $(`.create-call-start-button-${callIdParam}`)?.[0]
    if (el) el.focus()
  }, [callIdParam])

  return (
    <ContentContainer>
      <StyledHeaderContainer component="header" $shadow={scrollTop}>
        <Text component="h1" mt="0" color="brandblack" font="Poppins">
          {t('CallDetails')}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
        <StyledBackButton circular="true" subtle="true" onClick={handleAddToTheCall}>
          <BackIcon title={t('ButtonToAddAdditionalParticipantsToTheCall')} />
        </StyledBackButton>
      </StyledHeaderContainer>
      <StyledForm onSubmit={handleSumbitForm(handleSubmit)}>
        <StyledVerticalScrollbar
          ref={scrollRef}
          options={{ callbacks: { onScroll, onUpdated }, className: 'os-theme-light' }}
        >
          <StyledFormFieldSchedule
            value={getCallDateTimeMoment(call)}
            p="1.3rem"
            placeholder={t('ScheduledForNow')}
            title={t('ScheduleIcon')}
            name="callschedule"
            bcolor="white"
            onChange={handleScheduleChange}
            className="call-schedule"
          />
          <StyledFormFieldInput
            maxLength={64}
            p="1.6rem"
            name="calldescription"
            bcolor="white"
            defaultValue={call?.description}
            placeholder={`${t('TypeACallDescription')} (${t('optional')})`}
            ref={register()}
          />
          <StyledContentWrapper>
            <ParticipantsList $noScroll onClose={handleRemoveContact} call={call} />
            {(call?.groupSaved || (!hasOnlyOneGroupSelected && contactsIds?.length > 1)) && (
              <>
                <Divider mt="2rem" />
                <StyledCheckBoxWrapper type="button" onClick={handleSaveGroupCheck}>
                  <CheckBox
                    $checked={call?.groupSaved || call?.saveGroup}
                    name="save-group"
                    title={`${t('SaveTheGroupForFutureCalls')} (${t('optional')})`}
                  />
                </StyledCheckBoxWrapper>
                {(call?.groupSaved || call?.saveGroup) && (
                  <>
                    <Text size="md" color="brandblack" cop="1" fw="600" mb="0.5rem" mt="1.5rem" htmlFor="groupname">
                      {t('PleaseProvideGroupNameAndOptionalGroupPicture')}
                    </Text>
                    <StyledFormFieldInput
                      disabled={call?.groupSaved ? 'true' : null}
                      error={!!errorGroupName}
                      maxLength={64}
                      name="groupname"
                      bcolor="white"
                      placeholder={t('GroupName')}
                      defaultValue={call?.groupName}
                      ref={register()}
                      onBtnClick={handleSaveGroup}
                      btnTitle={t('Save')}
                      checked={call?.groupSaved ? 'true' : null}
                      checkedTitle={t('Saved')}
                    />
                    {errorGroupName && (
                      <StyledFormFieldValidation mt="0.56rem" mb="1rem" error>
                        {t('FormFieldValidationRequiredField')}
                      </StyledFormFieldValidation>
                    )}
                    <RowsContainer mt="1rem">
                      <AddPhoto
                        $disabled={call?.groupSaved}
                        title={t('AddPicture')}
                        updateTitle={t('ChangePicture')}
                        src={call?.groupPhoto}
                        icon={PhotoIcon}
                        onAdd={handleAddGroupPhoto}
                        $secondary
                      />
                    </RowsContainer>
                  </>
                )}
              </>
            )}
          </StyledContentWrapper>
        </StyledVerticalScrollbar>
        <StyledButtonsWrapper $shadow={!bottomReached}>
          <Button
            disabled={saving || (!call?.contacts?.length && !call?.groups?.length)}
            $flat={saving || (!call?.contacts?.length && !call?.groups?.length)}
            type="submit"
            size="md"
            bcolor={call?.isInstant ? 'salem' : 'mediumpurple'}
            hgradient={`${call?.isInstant ? 'salem' : 'mediumpurple'}-dim-light`}
            agradient={`${call?.isInstant ? 'salem' : 'mediumpurple'}-dim-dark`}
            bop="1"
            bophov="1"
            bopact="1"
            className={`create-call-start-button-${callIdParam}`}
          >
            <Text size="md" $secondary fw="600">
              {call?.isInstant ? t('StartThisCallNow') : t('ScheduleThisCall')}
            </Text>
          </Button>
          <Button mt="0.5rem" size="md" subtle="true" onClick={handleClose}>
            <Text size="md" color="purplehearth" fw="600">
              {t('Cancel')}
            </Text>
          </Button>
        </StyledButtonsWrapper>
      </StyledForm>
    </ContentContainer>
  )
}

const mapStateToProps = (state) => ({
  getCallById: (callId: string) => selectCallById(callId)(state),
})

export default connect(mapStateToProps, null)(CreateCallModal)
