/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components'
import Button from 'components/button'
import { ContentContainer, ColumnsContainer, RowsContainer } from 'components/container'
import List from 'components/list'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

export interface OverlayScrollbarsComponentProps {
  /**
   * Offset from the top
   */
  offset?: number
  /**
   * Callbacks
   */
  options?: unknown
}

export interface StyledGroupsControlsProps {
  /**
   * Whether should show an underlying shadow
   */
  shadow?: boolean
}

export interface SearchInputWrapperProps {
  /**
   * Is focused
   */
  $focused?: boolean
}

export const StyledContentWrapper = styled(ColumnsContainer)`
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
`

export const StyledHeaderContainer = styled(RowsContainer)`
  margin: 1.125rem;
  height: 2.8125rem;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
`

export const StyledButton = styled(Button)`
  position: absolute;
  left: 1rem;
  top: 1rem;
`

export const SearchInputWrapper = styled.div`
  width: 100%;
  padding: 1rem 1.125rem;
  box-sizing: border-box;

  ${(props: SearchInputWrapperProps) =>
    props.$focused &&
    css`
      background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.mediumpurple)};
    `}
`

export const StyledDoneButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

export const StyledGroupsControlsWrapper = styled(ContentContainer)`
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 0 0 0;

  ${(props: StyledGroupsControlsProps) =>
    props.shadow &&
    css`
      box-shadow: ${({ theme: { shadows } }) => shadows.outbound};
    `}
`

export const StyledRowsContainer = styled(RowsContainer)`
  width: 100%;
  margin-top: 1rem;
  padding: 0 1.125rem;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
`

export const StyledGroupsWrapper = styled(ContentContainer)`
  width: 100%;
`

export const StyledSelectedGroupsWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
`

export const StyledHorizontalScrollbar = styled(OverlayScrollbarsComponent).attrs((props) => ({
  ...props,
  resize: 'horizontal',
}))<OverlayScrollbarsComponentProps>`
  overflow-y: hidden;
  height: 8rem;
  margin-bottom: 0.3rem;

  &.os-host-scrollbar-horizontal-hidden .os-content > section {
    margin-bottom: 0 !important;
  }

  &.os-host-scrollbar-horizontal-hidden {
    margin-bottom: 0 !important;
  }

  .os-scrollbar-vertical {
    display: none;
  }
`

export const StyledVerticalScrollbar = styled(OverlayScrollbarsComponent).attrs((props) => ({
  ...props,
  resize: 'vertical',
}))<OverlayScrollbarsComponentProps>`
  height: 30rem;
  width: 100%;
  overflow-x: hidden;
  margin-right: 0.3rem;

  &.os-host-scrollbar-vertical-hidden .os-content > section {
    margin-right: 0 !important;
  }

  &.os-host-scrollbar-vertical-hidden {
    margin-right: 0 !important;
  }

  .os-scrollbar-horizontal {
    display: none;
  }

  ${(props: OverlayScrollbarsComponentProps) =>
    !!props.offset &&
    css`
      height: calc(100vh - ${props.offset}px);
    `}
`

export const StyledListColumn = styled(List)`
  flex-direction: column;
  margin-right: 1rem;
  margin-top: 1rem;
  width: auto;
`

export const StyledListRow = styled(List)`
  flex-direction: row;
  width: 100%;
`

export const StyledAvatarWrapper = styled.div`
  margin: 0.5rem;

  & > * {
    margin-left: 1rem;
  }
`
