import { ReactElement } from 'react'
import { withTheme } from 'styled-components'

const getColorName = (bcolor, theme, $secondary) => {
  if (bcolor) return bcolor
  return $secondary ? theme.colors.white : theme.colors.jumbo
}

export default withTheme(
  ({ theme, $secondary, title, bcolor }): ReactElement => {
    const color = (theme.fn.color as (arg0: string) => string)(getColorName(bcolor, theme, $secondary))
    return (
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5607 2.56066C18.1464 1.97487 18.1464 1.02513 17.5607 0.43934C16.9749 -0.146447 16.0251 -0.146447 15.4393 0.43934L9 6.87868L2.56066 0.43934C1.97487 -0.146447 1.02513 -0.146447 0.43934 0.43934C-0.146446 1.02513 -0.146446 1.97487 0.43934 2.56066L6.87868 9L0.43934 15.4393C-0.146447 16.0251 -0.146447 16.9749 0.43934 17.5607C1.02513 18.1464 1.97487 18.1464 2.56066 17.5607L9 11.1213L15.4393 17.5607C16.0251 18.1464 16.9749 18.1464 17.5607 17.5607C18.1464 16.9749 18.1464 16.0251 17.5607 15.4393L11.1213 9L17.5607 2.56066Z"
          fill={color}
        />
      </svg>
    )
  },
)
