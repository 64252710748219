/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { ReactElement, FC } from 'react'
import { Link } from 'react-router-dom'
import { StyledListItem, StyledButton, ButtonListItemProps } from './style'

const ButtonListItem: FC<ButtonListItemProps> = ({ children, mt, onClick, link, ...rest }): ReactElement => {
  const listItem = <StyledListItem {...rest}>{children}</StyledListItem>

  return link ? (
    <Link tabIndex={0} style={{ marginTop: mt, textDecoration: 'none' }} to={link}>
      {listItem}
    </Link>
  ) : (
    <StyledButton type="button" tabIndex={0} style={{ marginTop: mt }} onClick={onClick}>
      {listItem}
    </StyledButton>
  )
}

export * from './style'

export default ButtonListItem
