/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createAsyncThunk, createEntityAdapter, createSlice, createSelector, EntityState } from '@reduxjs/toolkit'
import { error } from 'react-toastify-redux'
import {
  apiGetAllContacts,
  apiPostContacts,
  apiPutContacts,
  ApiPostContacts,
  ApiPutContacts,
  apiImportContacts,
  ApiImportContacts,
  apiGetManagedContacts,
  ApiGetManagedContacts,
  apiPostManagedContacts,
  ApiPostManagedContacts,
  ContactsStatuses,
  apiAddManagedContacts,
  ApiAddManagedContacts,
} from 'apis'
import { flagsUpdated, flags } from './usersSlice'

interface Contact {
  id?: string
  displayName?: string
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  photo?: string
  userId?: string
  status?: ContactsStatuses
}

const isNew = Symbol.for('isNew')
const isSelected = Symbol.for('isSelected')

export interface ContactNormalized extends Contact {
  [isNew]?: boolean
  managedUserId?: string
}

interface ContactsState {
  contacts: EntityState<ContactNormalized>
}

const contactsAdapter = createEntityAdapter({
  sortComparer: (a: ContactNormalized, b: ContactNormalized) => a.displayName.localeCompare(b.displayName),
})
const initialState = contactsAdapter.getInitialState([])

const normalizeUsersContacts = (contacts: Contact[]) => {
  const contactsNormalized = contacts.map((contact) => ({
    ...contact,
    [isNew]: false,
  }))
  return contactsNormalized
}

const contactsAdded = (state, action) => contactsAdapter.addMany(state, normalizeUsersContacts(action.payload))

const contactsUpdated = (state, action) => {
  if (!action.payload) return
  ;(Array.isArray(action.payload) ? action.payload : [action.payload]).forEach(({ id, changes, ...rest }) => {
    if (state.ids.includes(id)) {
      state.entities[id] = {
        ...state.entities[id],
        ...(changes || rest),
      }
    }
  })
}

export const getAllContacts = createAsyncThunk('contacts/getAll', async (_, { dispatch, rejectWithValue }) => {
  try {
    return await apiGetAllContacts()
  } catch (e) {
    dispatch(error(e.message))
    return rejectWithValue(null)
  }
})

export const getManagedContacts = createAsyncThunk(
  'contacts/getManaged',
  async ({ id: userId }: ApiGetManagedContacts, { dispatch, rejectWithValue }) => {
    try {
      return await apiGetManagedContacts(userId)
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null)
    }
  },
)

export const createManagedContacts = createAsyncThunk(
  'contacts/createManaged',
  async (data: ApiPostManagedContacts, { dispatch, rejectWithValue }): Promise<Contact[] | null> => {
    try {
      return await apiPostManagedContacts(data)
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null) as null
    }
  },
)

export const addManagedContacts = createAsyncThunk(
  'contacts/addManaged',
  async (data: ApiAddManagedContacts, { dispatch, rejectWithValue }): Promise<Contact[] | null> => {
    try {
      const res = await apiAddManagedContacts(data)
      return res
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null) as null
    }
  },
)

export const createContacts = createAsyncThunk(
  'contacts/create',
  async (data: ApiPostContacts, { dispatch, rejectWithValue }): Promise<Contact[] | null> => {
    try {
      return await apiPostContacts(data.contacts)
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null) as null
    }
  },
)

export const updateContacts = createAsyncThunk(
  'contacts/update',
  async (data: ApiPutContacts, { dispatch, rejectWithValue }): Promise<Contact[] | null> => {
    try {
      return await apiPutContacts(data.contacts)
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null) as null
    }
  },
)

export const importContacts = createAsyncThunk(
  'contacts/import',
  async (data: ApiImportContacts, { dispatch, rejectWithValue }): Promise<Contact[] | null> => {
    try {
      const res = await apiImportContacts(data)
      dispatch(flagsUpdated({ id: data.userId, flags: { add: [flags.contactsImported] } }))
      return res
    } catch (e) {
      return rejectWithValue(null) as null
    }
  },
)

const removeAuthUserContacts = (state) => {
  const prevAuthUserContactsIds = state.ids?.filter((id) => {
    return !(state.entities[id] as ContactNormalized).managedUserId
  })
  if (prevAuthUserContactsIds?.length) contactsAdapter.removeMany(state, prevAuthUserContactsIds)
}

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    added: contactsAdapter.addMany,
    updated: contactsUpdated,
    setSelected: (state, action) => {
      const { id: contactId, replace } = action.payload
      if (!state.ids.includes(contactId)) return
      if (replace) {
        state.ids.forEach((id) => {
          state.entities[id][isSelected] = (state.entities[id] as ContactNormalized).id === contactId
        })
      } else {
        state.entities[contactId][isSelected] = true
      }
    },
    unselectAll: (state) => {
      state.ids.forEach((id) => {
        state.entities[id][isSelected] = false
      })
    },
  },
  extraReducers: {
    [importContacts.pending.toString()]: removeAuthUserContacts,
    [getAllContacts.pending.toString()]: removeAuthUserContacts,
    [getManagedContacts.pending.toString()]: (state, action) => {
      const prevManagedUserContactsIds = state.ids?.filter((id) => {
        return (state.entities[id] as ContactNormalized).managedUserId === action.meta.arg.id
      })
      if (prevManagedUserContactsIds?.length) contactsAdapter.removeMany(state, prevManagedUserContactsIds)
    },
    [importContacts.fulfilled.toString()]: contactsAdded,
    [getAllContacts.fulfilled.toString()]: contactsAdded,
    [createContacts.fulfilled.toString()]: contactsAdded,
    [updateContacts.fulfilled.toString()]: contactsUpdated,
    [createManagedContacts.fulfilled.toString()]: contactsAdded,
    [addManagedContacts.fulfilled.toString()]: contactsAdded,
    [getManagedContacts.fulfilled.toString()]: contactsAdded,
  },
})

export const { added, updated, setSelected, unselectAll } = contactsSlice.actions

const contactsSelectors = contactsAdapter.getSelectors((state: ContactsState) => state.contacts)
export const selectAllContacts = (state: ContactsState): ContactNormalized[] => contactsSelectors.selectAll(state)
export const selectAllAuthUserContacts = createSelector(selectAllContacts, (contacts) =>
  contacts.filter((contact) => !contact.managedUserId),
)
export const selectContactById = (id: string) =>
  createSelector(selectAllContacts, (contacts) => contacts.find((contact) => contact.id === id))
export const selectContactsByManagedUserId = (id: string) =>
  createSelector(selectAllContacts, (contacts) => contacts.filter((contact) => contact.managedUserId === id))

export default contactsSlice.reducer
