import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <circle cx="24" cy="24" r="24" fill="white" fillOpacity="0.38" />
    <path
      d="M34.25 21.8332C35.9167 22.7954 35.9167 25.2011 34.25 26.1633L19.25 34.8236C17.5833 35.7858 15.5 34.583 15.5 32.6585L15.5 15.338C15.5 13.4135 17.5833 12.2107 19.25 13.1729L34.25 21.8332Z"
      stroke="white"
      strokeOpacity="0.6"
      strokeWidth="3"
    />
  </svg>
)
