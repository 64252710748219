/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/button'
import Text from 'components/text'
import { getAvatarBorColor, getAvatarBorOp } from 'utils'
import Avatar from 'components/avatar'
import { CloseIcon } from 'theme'
import {
  StyledRowsRightContainer,
  StyledHeaderContainer,
  StyledCloseButton,
  StyledContentContainer,
  StyledTextContainer,
  StyledTextWrapper,
  StyledRowsContainer,
} from './style'

export default ({ declineUnmuteRequest, acceptUnmuteRequest, guardianRequest }): ReactElement => {
  const { t } = useTranslation()

  const handleAccept = async () => {
    acceptUnmuteRequest(guardianRequest.sender)
  }

  const handleDecline = async () => {
    declineUnmuteRequest(guardianRequest.sender)
  }

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Text size="xl" mt="1rem" bcolor="brandblack" font="Poppins" fw="600" $block>
          {t('UnmuteRequest')}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={handleDecline}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledRowsContainer>
        <Avatar
          size="xl"
          fontSize="xxxl"
          name={guardianRequest.user.displayName}
          alt={t('PhotoOfContact')}
          img={guardianRequest.user.photo}
          borcolor={getAvatarBorColor(true, true)}
          borop={getAvatarBorOp(true, true)}
        />
        <StyledTextWrapper>
          <Text size="md" fw="600" color="brandblack" mt="0.3rem">
            {guardianRequest.user.displayName}
          </Text>
          <Text fw="500" size="xs" color="brandblack" cop="0.6" mt="0.3rem">
            {guardianRequest.user.username}
          </Text>
        </StyledTextWrapper>
      </StyledRowsContainer>
      <StyledTextContainer>
        <Text size="lg" mt="1.5rem" gradient="purple">
          {t('YourGuardian')}&nbsp;
          {guardianRequest.user.displayName}&nbsp;
          {t('IsRequestingPermissionToUnmuteYourselves')}
        </Text>
      </StyledTextContainer>
      <StyledRowsRightContainer>
        <Button subtle="true" size="md" mr="1rem" onClick={handleAccept}>
          <Text fw="600" size="md" color="purple" $secondary>
            {t('Accept')}
          </Text>
        </Button>
        <Button
          bophov="1"
          bopact="1"
          hgradient="tallpoppy-dim-light"
          agradient="tallpoppy-dim-dark"
          size="md"
          bcolor="tallpoppy"
          bop="1"
          mr="1rem"
          onClick={handleDecline}
        >
          <Text fw="600" size="md" $secondary>
            {t('Decline')}
          </Text>
        </Button>
      </StyledRowsRightContainer>
    </StyledContentContainer>
  )
}
