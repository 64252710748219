/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLayoutEffect, useState } from 'react'
import { removeFromLS, getUserFromLS } from 'utils/storage'
import { getAnonUser, getUser } from 'reducers/usersSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'

export default (hasUser?: boolean, isAnon = false): boolean => {
  const [userId, setUserId] = useState(null)
  const [token, setToken] = useState()
  const [loadingUser, setIsLoadingUser] = useState(!hasUser)
  const dispatch: AppDispatch = useDispatch()

  useLayoutEffect(() => {
    if (!hasUser && !token) {
      const authFromLs = getUserFromLS(isAnon)
      if (authFromLs) {
        const [userIdFromLs, tokelFromLs] = authFromLs

        if (tokelFromLs) {
          setUserId(userIdFromLs)
          setToken(tokelFromLs)
        } else {
          setIsLoadingUser(false)
        }
      } else {
        setIsLoadingUser(false)
      }
    }
  }, [hasUser, isAnon, token])

  useLayoutEffect(() => {
    const getUserFromApi = async () => {
      try {
        if (isAnon) {
          const { payload } = (await dispatch(getAnonUser())) as any
          if (!payload) removeFromLS('auth')
        } else {
          const { payload } = (await dispatch(getUser({ id: userId }))) as any
          if (!payload) removeFromLS('auth')
        }
      } finally {
        setIsLoadingUser(false)
      }
    }

    if (loadingUser && !hasUser && userId && token) {
      getUserFromApi()
      dispatch({ type: 'server/auth', payload: { token } })
    }
  }, [dispatch, hasUser, isAnon, loadingUser, token, userId])

  return loadingUser
}
