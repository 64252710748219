/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import Button from 'components/button'
import { RowsContainer, ContentContainer } from 'components/container'
import Card from 'components/card'

export const StyledTourArrow1Wrapper = styled.div`
  position: absolute;
  top: 15%;
  left: 82%;
`

export const StyledTourArrow2Wrapper = styled.div`
  position: absolute;
  top: 60%;
  left: 62%;
`

export const StyledTourArrow3Wrapper = styled.div`
  position: absolute;
  top: 52%;
  left: 22%;
`

export const StyledTourArrow4Wrapper = styled.div`
  position: absolute;
  top: 47%;
  left: 67%;
`

export const StyledTourArrow5Wrapper = styled.div`
  position: absolute;
  top: 65%;
  left: 72%;
}
`

export const StyledTourArrow6Wrapper = styled.div`
  position: absolute;
  top: 88%;
  left: 36%;
`

export const StyledContentContainer = styled(ContentContainer)`
  margin: 1.5rem 1.5rem 0 1.5rem;
`

export const StyledCard = styled(Card)`
  width: 29rem;
  padding: 0;
  border-radius: 0.5rem;
`

export const StyledImageWrapper = styled.div`
  margin-top: 1.1875rem;
`

export const StyledVisualImageWrapper = styled.div`
  position: relative;
`

export const StyledVisualImage1Wrapper = styled.div`
  position: relative;
  min-width: 60%;
  width: 60%;
`

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
`

export const StyledDescriptionWrapper = styled.div`
  margin-top: 0.5rem;
`

export const StepWrapper = styled.div`
  text-align: left;
`

export const StyledVisualWrapper = styled.div`
  width: 100%;
  margin-top: 1.75rem;
`

export const StyledFooterWrapper = styled(RowsContainer).attrs(() => ({
  component: 'footer',
}))`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
`

export const StyledRowsLeftContainer = styled(RowsContainer)`
  flex-grow: 1;
`

export const StyledVisual = styled.div`
  background: ${({ theme: { gradients } }) => gradients.magenta};
`

export const StyledTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
