/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement } from 'react'
import Card from 'components/card'
import { games } from 'const'
import {
  getCurrentEntertainment,
  getCallParticipantByUserId,
  getEntertainmentParticipantByCallParticipant,
  isEntertainmentParticipantStatusPending,
  isEntertainmentOf,
} from 'utils/calls'
import { TransparentBackground } from '../../backgrounds'
import GameInvitation from './games/invitation'

export default ({ user, call }): ReactElement => {
  const currentEntertainment = getCurrentEntertainment(call, user)

  if (currentEntertainment && isEntertainmentOf(currentEntertainment, games.rummy)) {
    const callParticipant = getCallParticipantByUserId(call, user.id)
    const entertainmentParticipant = getEntertainmentParticipantByCallParticipant(currentEntertainment, callParticipant)

    if (isEntertainmentParticipantStatusPending(entertainmentParticipant)) {
      return (
        <TransparentBackground>
          <Card size="sm" secondary="true">
            <GameInvitation user={user} call={call} />
          </Card>
        </TransparentBackground>
      )
    }
  }

  return null
}
