/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SearchIcon } from 'theme'
import { FC, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchInputProps, StyledSearchIconWrapper, StyledSearchInputWrapper, StyledSearchInput } from './style'

export const SearchInput: FC<SearchInputProps> = ({
  $interactive,
  onChange,
  onFocus,
  $secondary,
  placeholder,
  ...rest
}): ReactElement => {
  const { t } = useTranslation()
  const [focused, setFocused] = useState(false)

  const handleOnFocus = () => {
    if ($interactive) {
      setFocused(true)
      if (onFocus) onFocus(true)
    }
  }
  const handleOnBlur = () => {
    if ($interactive) {
      setFocused(false)
      if (onFocus) onFocus(false)
    }
  }

  return (
    <StyledSearchInputWrapper>
      {!focused && (
        <StyledSearchIconWrapper>
          <SearchIcon $secondary={$secondary} title={t('SearchIcon')} />
        </StyledSearchIconWrapper>
      )}
      <StyledSearchInput
        $secondary={$secondary}
        $focused={focused}
        placeholder={placeholder}
        onChange={onChange}
        {...rest}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
    </StyledSearchInputWrapper>
  )
}

export default SearchInput
