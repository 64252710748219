/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement, useEffect, useState } from 'react'
import Text from 'components/text'
import Button from 'components/button'
import { useTranslation } from 'react-i18next'
import { CloseIcon, RegularTickIcon } from 'theme'
import { ColumnsContainer } from 'components/container'
import {
  StyledVerticalScrollbar,
  StyledSourceWrapper,
  StyledButtonsWrapper,
  StyledContentContainer,
  StyledCloseButton,
  StyledHeaderContainer,
  StyledTextWrapper,
  StyledIconTickWrapper,
} from './style'

declare global {
  interface Window {
    electronNodeAPI: any
  }
}

export default ({ onClose, screenShareId, shareScreen }): ReactElement => {
  const { t } = useTranslation()
  const [sources, setSources] = useState(null)
  const [selected, setSelected] = useState(null)

  const handleShare = () => {
    shareScreen({ id: selected })
    onClose()
  }

  useEffect(() => {
    if (selected) {
      if (!sources || !sources.length || !sources.find(({ id }) => id === selected)) setSelected(null)
    }
  }, [selected, sources])

  useEffect(() => {
    const updateSources = () => {
      window.electronNodeAPI?.screenShare?.getSources().then((sources) => setSources(sources))
    }
    const interval = setInterval(() => {
      updateSources()
    }, 3000)
    updateSources()

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (screenShareId) onClose()
  }, [onClose, screenShareId])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Text component="h1" mt="0" color="brandblack" font="Poppins">
          {t('SelectWhatToShare')}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={onClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      {sources?.length && (
        <StyledVerticalScrollbar options={{ className: 'os-theme-light' }}>
          {sources.map(({ name, id, thumbnail }) => (
            <StyledSourceWrapper key={id} $selected={selected === id} onClick={() => setSelected(id)}>
              {selected === id && (
                <StyledIconTickWrapper>
                  <RegularTickIcon success="true" color="purplehearth" />
                </StyledIconTickWrapper>
              )}
              <img src={thumbnail} alt={t('ScreenShareSource')} />
              <StyledTextWrapper>
                <ColumnsContainer>
                  <Text size="md" color="purplehearth" fw="600">
                    {t('Share')}
                    &nbsp;
                    {name}
                  </Text>
                </ColumnsContainer>
              </StyledTextWrapper>
            </StyledSourceWrapper>
          ))}
        </StyledVerticalScrollbar>
      )}
      <StyledButtonsWrapper>
        <Button size="md" subtle="true" onClick={onClose}>
          <Text size="md" color="purplehearth" fw="600">
            {t('Cancel')}
          </Text>
        </Button>
        <Button
          disabled={!selected}
          $flat={!selected}
          size="md"
          bcolor="mediumpurple"
          bop="1"
          ml="1rem"
          hgradient="mediumpurple-dim-light"
          agradient="mediumpurple-dim-dark"
          onClick={handleShare}
        >
          <Text size="md" $secondary fw="600">
            {t('Share')}
          </Text>
        </Button>
      </StyledButtonsWrapper>
    </StyledContentContainer>
  )
}
