/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FC, ReactElement, useEffect, useState } from 'react'
import Button from 'components/button'
import { useForm } from 'react-hook-form'
import Text from 'components/text'
import { CloseIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import { FormFieldValidation, FormFieldInput } from 'components/form'
import { connect, useDispatch } from 'react-redux'
import { usePassword } from 'hooks'
import { selectAuthUser, UserNormalized, updatePassword } from 'reducers/usersSlice'
import {
  StyledButtonsWrapper,
  StyledContentWrapper,
  StyledContentContainer,
  StyledCloseButton,
  StyledHeaderContainer,
  StyledForm,
} from './style'

const EditProfilePassword: FC<{ onClose: () => void; authUser: UserNormalized }> = ({
  onClose,
  authUser,
}): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [saving, setSaving] = useState(false)
  const { register, handleSubmit: handleSumbitForm, errors, watch } = useForm()
  const {
    passwordFocus,
    passwordRef,
    handlePasswordTouched,
    watchPassword,
    validateMinLength,
    validateSpecialChar,
  } = usePassword(watch)

  const handleSubmit = async ({ password }) => {
    setSaving(true)
    await dispatch(updatePassword({ id: authUser.id, password }))
    setSaving(false)
    onClose()
  }

  useEffect(() => {
    if (passwordRef) passwordRef.current.focus()
  }, [passwordRef])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Text component="h1" mt="0" color="brandblack" font="Poppins">
          {t('TypeYourNewPassword')}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={onClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledContentWrapper>
        {authUser && (
          <StyledForm onSubmit={handleSumbitForm(handleSubmit)}>
            <FormFieldInput
              error={!!errors.password}
              onFocus={handlePasswordTouched}
              elRef={passwordRef}
              type="password"
              name="password"
              label={t('Password')}
              placeholder={t('Password')}
              maxLength={36}
              revealPassword="true"
              ref={(e) => {
                register(e, {
                  required: true,
                  minLength: 8,
                  validate: {
                    specialChar: validateSpecialChar,
                  },
                })
                passwordRef.current = e
              }}
            />
            {passwordFocus && !errors.password?.types?.minLength && !errors.password?.types?.required && (
              <FormFieldValidation success={validateMinLength(watchPassword)} mt="0.56rem">
                {t('PasswordMinLength')}
              </FormFieldValidation>
            )}
            {(errors.password?.types?.minLength || errors.password?.types?.required) && (
              <FormFieldValidation mt="0.56rem" error>
                {t('PasswordMinLength')}
              </FormFieldValidation>
            )}
            {passwordFocus && !errors.password?.types?.specialChar && (
              <FormFieldValidation success={validateSpecialChar(watchPassword)} mt="0.56rem" mb="1.6rem">
                {t('PasswordSpecialChar')}
              </FormFieldValidation>
            )}
            {errors.password?.types?.specialChar && (
              <FormFieldValidation mt="0.56rem" mb="1.6rem" error>
                {t('PasswordSpecialChar')}
              </FormFieldValidation>
            )}
            <StyledButtonsWrapper>
              <Button size="md" subtle="true" onClick={onClose}>
                <Text size="md" color="purplehearth" fw="600">
                  {t('Cancel')}
                </Text>
              </Button>
              <Button
                disabled={saving}
                $flat={saving}
                type="submit"
                size="md"
                bcolor="mediumpurple"
                bop="1"
                ml="1rem"
                hgradient="mediumpurple-dim-light"
                agradient="mediumpurple-dim-dark"
              >
                <Text size="md" $secondary fw="600">
                  {t('Save')}
                </Text>
              </Button>
            </StyledButtonsWrapper>
          </StyledForm>
        )}
      </StyledContentWrapper>
    </StyledContentContainer>
  )
}

const mapStateToProps = (state) => ({
  authUser: selectAuthUser(state),
})

export default connect(mapStateToProps, null)(EditProfilePassword)
