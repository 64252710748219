import { ReactElement } from 'react'

export default (): ReactElement => (
  <svg width="22" height="29" viewBox="0 0 22 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.25 9.66667H17.875V6.90476C17.875 3.09333 14.795 0 11 0C7.205 0 4.125 3.09333 4.125 6.90476V9.66667H2.75C1.2375 9.66667 0 10.9095 0 12.4286V26.2381C0 27.7571 1.2375 29 2.75 29H19.25C20.7625 29 22 27.7571 22 26.2381V12.4286C22 10.9095 20.7625 9.66667 19.25 9.66667ZM11 22.0952C9.4875 22.0952 8.25 20.8524 8.25 19.3333C8.25 17.8143 9.4875 16.5714 11 16.5714C12.5125 16.5714 13.75 17.8143 13.75 19.3333C13.75 20.8524 12.5125 22.0952 11 22.0952ZM15.2625 9.66667H6.7375V6.90476C6.7375 4.54333 8.64875 2.62381 11 2.62381C13.3512 2.62381 15.2625 4.54333 15.2625 6.90476V9.66667Z"
      fill="#11111B"
    />
  </svg>
)
