/* eslint-disable react/require-default-props */
import { ReactElement } from 'react'

export default ({ size, color, title }: { size?: string; color?: string; title?: string }): ReactElement =>
  size === 'xxl' ? (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M51.5625 6.25L90.8043 28.9062V74.2188L51.5625 96.875L12.3207 74.2188V28.9062L51.5625 6.25Z"
        fill="#935DEA"
        fillOpacity="0.16"
      />
      <path
        d="M56.675 25H45.8107L46.7873 60.0098H55.7228L56.675 25ZM51.255 75.6348C54.38 75.6348 57.09 73.0225 57.1144 69.7754C57.09 66.5771 54.38 63.9648 51.255 63.9648C48.0324 63.9648 45.3713 66.5771 45.3957 69.7754C45.3713 73.0225 48.0324 75.6348 51.255 75.6348Z"
        fill="#8D53E9"
      />
    </svg>
  ) : (
    <svg width="4" height="12" viewBox="0 0 4 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <path
        d="M0.8526 12H3.14069V3.75H0.8526V12ZM2.00201 2.68652C2.68414 2.68652 3.24274 2.16553 3.24274 1.52637C3.24274 0.892578 2.68414 0.371581 2.00201 0.371581C1.32526 0.371581 0.766663 0.892578 0.766663 1.52637C0.766663 2.16553 1.32526 2.68652 2.00201 2.68652Z"
        fill={color}
      />
    </svg>
  )
