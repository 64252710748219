/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FC, ReactElement, useEffect, useRef, useState } from 'react'
import Button from 'components/button'
import { useForm } from 'react-hook-form'
import Text from 'components/text'
import { CloseIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import { FormFieldValidation, FormFieldInput } from 'components/form'
import { connect, useDispatch } from 'react-redux'
import { selectAuthUser, UserNormalized, updateProfile } from 'reducers/usersSlice'
import {
  StyledButtonsWrapper,
  StyledContentWrapper,
  StyledContentContainer,
  StyledCloseButton,
  StyledHeaderContainer,
  StyledForm,
} from './style'

const EditProfileName: FC<{ onClose: () => void; authUser: UserNormalized }> = ({
  onClose,
  authUser,
}): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [saving, setSaving] = useState(false)
  const firstNameRef = useRef<HTMLInputElement | null>(null)
  const { register, handleSubmit: handleSumbitForm, errors } = useForm()

  const handleSubmit = async ({ firstname, lastname }) => {
    setSaving(true)
    await dispatch(updateProfile({ id: authUser.id, firstName: firstname, lastName: lastname }))
    setSaving(false)
    onClose()
  }

  useEffect(() => {
    firstNameRef.current.focus()
  }, [])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Text component="h1" mt="0" color="brandblack" font="Poppins">
          {t('ChangeName')}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={onClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledContentWrapper>
        {authUser && (
          <StyledForm onSubmit={handleSumbitForm(handleSubmit)}>
            <FormFieldInput
              error={!!errors.firstname}
              maxLength={64}
              name="firstname"
              defaultValue={authUser.firstName}
              label={t('FirstName')}
              placeholder={t('FirstName')}
              ref={(e) => {
                register({ required: true })(e)
                firstNameRef.current = e
              }}
            />
            {errors.firstname?.type === 'required' && (
              <FormFieldValidation mt="0.56rem" mb="1rem" error>
                {t('FormFieldValidationRequiredField')}
              </FormFieldValidation>
            )}
            <FormFieldInput
              error={!!errors.lastname}
              maxLength={64}
              name="lastname"
              defaultValue={authUser.lastName}
              label={t('LastName')}
              placeholder={t('LastName')}
              ref={register({ required: true })}
            />
            {errors.lastname?.type === 'required' && (
              <FormFieldValidation mt="0.56rem" mb="1rem" error>
                {t('FormFieldValidationRequiredField')}
              </FormFieldValidation>
            )}
            <StyledButtonsWrapper>
              <Button size="md" subtle="true" onClick={onClose}>
                <Text size="md" color="purplehearth" fw="600">
                  {t('Cancel')}
                </Text>
              </Button>
              <Button
                disabled={saving}
                $flat={saving}
                type="submit"
                size="md"
                bcolor="mediumpurple"
                bop="1"
                ml="1rem"
                hgradient="mediumpurple-dim-light"
                agradient="mediumpurple-dim-dark"
              >
                <Text size="md" $secondary fw="600">
                  {t('Save')}
                </Text>
              </Button>
            </StyledButtonsWrapper>
          </StyledForm>
        )}
      </StyledContentWrapper>
    </StyledContentContainer>
  )
}

const mapStateToProps = (state) => ({
  authUser: selectAuthUser(state),
})

export default connect(mapStateToProps, null)(EditProfileName)
