import { ReactElement } from 'react'

export default ({ color, title }: { color: string; title: string }): ReactElement => (
  <svg width="35" height="24" viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.238503 10.9843C0.334943 6.93364 0.383163 4.90832 2.32962 2.90096C4.27607 0.893597 6.39629 0.777696 10.6367 0.545893C11.4693 0.500382 12.3072 0.473633 13.1268 0.473633C13.9612 0.473633 14.8147 0.50136 15.6621 0.548385C19.8742 0.782124 21.9803 0.898993 23.9246 2.90512C25.8689 4.91126 25.9174 6.92027 26.0144 10.9383C26.0226 11.2799 26.027 11.6254 26.027 11.9736C26.027 12.2954 26.0233 12.6148 26.0162 12.9309C25.9255 16.9859 25.8801 19.0134 23.9386 21.0231C21.9971 23.0328 19.8589 23.1547 15.5824 23.3983C14.7508 23.4457 13.9228 23.4736 13.1268 23.4736C12.3453 23.4736 11.5331 23.4467 10.7168 23.4009C6.41155 23.1592 4.25895 23.0384 2.31527 21.0274C0.371583 19.0163 0.326626 16.9725 0.236712 12.8848C0.230078 12.5832 0.226562 12.2792 0.226562 11.9736C0.226562 11.6416 0.230713 11.3115 0.238503 10.9843ZM29.6154 8.04681L28.0784 9.09863V15.4096L29.6154 16.4614L32.6895 18.5651L34.2266 19.6169V4.89132L32.6895 5.94315L29.6154 8.04681ZM19.484 16.1097C18.3068 18.053 16.1092 19.2897 13.5977 19.2897C9.45773 19.2897 6.45572 16.1883 6.45572 12.1447C6.45572 8.10106 9.45773 4.99966 13.5977 4.99966C16.0896 4.99966 18.2871 6.2363 19.484 8.17958L16.7763 9.74991C16.1681 8.68994 14.9908 8.06181 13.5977 8.06181C11.1647 8.06181 9.59507 9.69102 9.59507 12.1447C9.59507 14.5983 11.1647 16.2275 13.5977 16.2275C14.9908 16.2275 16.1877 15.5994 16.7763 14.5394L19.484 16.1097Z"
      fill={color}
    />
  </svg>
)
