import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.18899 11.4273C5.24254 13.4347 5.19432 15.46 5.09788 19.5106C5.09009 19.8379 5.08594 20.168 5.08594 20.5C5.08594 20.8056 5.08945 21.1096 5.09609 21.4111C5.186 25.4988 5.23096 27.5427 7.17464 29.5537C9.11832 31.5648 11.2709 31.6856 15.5761 31.9272C16.3924 31.9731 17.2047 32 17.9862 32C18.7822 32 19.6101 31.972 20.4418 31.9247C24.7183 31.681 26.8565 31.5592 28.798 29.5495C30.044 28.2597 30.5091 26.9625 30.7063 25.1267L31.0859 25.3571L35.0859 27.7857C35.9634 28.3185 37.0859 27.6868 37.0859 26.6602V14.3398C37.0859 13.3132 35.9634 12.6815 35.0859 13.2143L31.0859 15.6429L30.7039 15.8748C30.5067 14.0298 30.0425 12.73 28.784 11.4315C26.8397 9.42536 24.7336 9.30849 20.5215 9.07475C19.6741 9.02773 18.8206 9 17.9862 9C17.1666 9 16.3286 9.02675 15.4961 9.07226C11.2557 9.30406 9.13545 9.41996 7.18899 11.4273Z"
      fill="white"
      fillOpacity="0.6"
    />
    <path
      d="M12.5 19.9656L16.6472 24.125L24.1875 16.5625"
      stroke="white"
      strokeWidth="3"
      strokeMiterlimit="3.99933"
      strokeLinecap="round"
    />
  </svg>
)
