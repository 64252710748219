import axios from 'axios'
import { apiUrl } from 'const'
import { setTokenInterceptor, responseInterceptorError } from 'utils/apis'

export interface AnonUserData {
  id: string
  username?: string
}

export interface ApiGetAnonUser {
  id: string
}

export interface ApiSignUpAnon {
  name: string
}

export interface ApiSetEmail {
  email: string
}

const instance = axios.create({
  baseURL: `${apiUrl}/anon-users`,
})

instance.interceptors.request.use(setTokenInterceptor(false, true))
instance.interceptors.response.use(undefined, responseInterceptorError)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const apiGetAnonUser = async (): Promise<AnonUserData> => {
  const res = await instance({
    url: `/profile`,
    method: 'GET',
  })
  return res.data
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const apiSignUpAnon = async (data: ApiSignUpAnon) => {
  const res = await instance({
    url: '/',
    method: 'POST',
    data,
  })
  return res.data
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const apiSetEmail = async (data: ApiSetEmail) => {
  const res = await instance({
    url: '/set-email',
    method: 'POST',
    data,
  })
  return res.data
}
