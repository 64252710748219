import { ReactElement } from 'react'

// eslint-disable-next-line react/require-default-props
export default ({ title, $secondary }: { title: string; $secondary?: boolean }): ReactElement => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path
      d="M24.75 3L43.5861 13.875V35.625L24.75 46.5L5.91395 35.625V13.875L24.75 3Z"
      fill={$secondary ? 'white' : '#C82E25'}
    />
    <path
      d="M27.2042 12H21.9893L22.4581 28.8047H26.7472L27.2042 12ZM24.6026 36.3047C26.1026 36.3047 27.4034 35.0508 27.4151 33.4922C27.4034 31.957 26.1026 30.7031 24.6026 30.7031C23.0557 30.7031 21.7784 31.957 21.7901 33.4922C21.7784 35.0508 23.0557 36.3047 24.6026 36.3047Z"
      fill={$secondary ? '#C8418B' : 'white'}
    />
  </svg>
)
