import { ReactElement, FC, useMemo, useState, useEffect } from 'react'
import Text from 'components/text'
import { ScheduledCallsTabImage, CloseIcon, ScheduledCallsArrowIcon, RegularTickIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import Button from 'components/button'
import { useQueryParam, StringParam } from 'use-query-params'
import { CallNormalized, selectCallById } from 'reducers/callsSlice'
import { callIdQueryParam, callsShortPath, isElectronRuntime, clanzyMeetPath } from 'const'
import { connect } from 'react-redux'
import moment from 'moment'
import Divider from 'components/divider'
import { RowsContainer } from 'components/container'
import {
  StyledText,
  StyledOptionsWrapper,
  StyledIconWrapper,
  StyledContentWrapper,
  StyledImageWrapper,
  StyledContentContainer,
  StyledTextContainer,
  StyledCallTimeInfoContainer,
  StyledCloseButton,
  StyledHeaderContainer,
} from './style'

const { $ } = window

const CallScheduledModal: FC<{ onClose: () => void; getCallById: (id: string) => CallNormalized }> = ({
  onClose,
  getCallById,
}): ReactElement => {
  const { t } = useTranslation()
  const [linkCopied, setlLinkCopied] = useState(false)
  const [callIdParam, setCallIdParam] = useQueryParam(callIdQueryParam, StringParam)

  const call = useMemo(() => (callIdParam ? getCallById(callIdParam) : null), [getCallById, callIdParam])

  const handleClose = () => {
    setCallIdParam(null, 'replaceIn')
    onClose()
  }

  const handleCopyLinkClick = () => {
    setlLinkCopied(!linkCopied)
    try {
      navigator.clipboard.writeText(
        `${isElectronRuntime ? clanzyMeetPath : window.location.origin.toString()}${callsShortPath}/${call.callId}`,
      )
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  useEffect(() => {
    const el = $(`.call-scheduled-done-button-${callIdParam}`)?.[0]
    if (el) el.focus()
  }, [callIdParam])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      {call && (
        <>
          <StyledTextContainer>
            <Text component="h1" mt="0">
              {t('Success')}!
            </Text>
            <StyledCallTimeInfoContainer>
              <Text size="lg">{t('ThisCallHasBeenScheduledFor')}&nbsp;</Text>
              <Text size="lg" gradient="radiance">
                {moment(call.scheduledTime).format('h:mm A on dddd Do of MMMM')}&nbsp;
              </Text>
              <Text size="lg">{t('AndInvitationsToCallParticipantsSentOut')}&nbsp;</Text>
            </StyledCallTimeInfoContainer>
          </StyledTextContainer>
          <StyledContentWrapper>
            <StyledText $center color="brandblack" fw="500" cop="0.6">
              {t('YouCanFindthisCallInYourListOfScheduledCalls')}
            </StyledText>
            <StyledIconWrapper>
              <ScheduledCallsArrowIcon />
            </StyledIconWrapper>
            <StyledImageWrapper>
              <ScheduledCallsTabImage alt={t('GraphicDepictingTheScheduledCallsTab')} />
            </StyledImageWrapper>
          </StyledContentWrapper>
          <StyledOptionsWrapper $center>
            <RowsContainer>
              <Text size="md" color="brandblack" cop="1" mr="1rem" fw="500" td="none">
                {`${t('Optional')}: ${t('CopyCallLink')}`}
              </Text>
              {linkCopied ? (
                <>
                  <RegularTickIcon success="true" color="purplehearth" />
                  <Text ml="0.75rem" size="md" color="purplehearth" cop="1" mr="1rem" fw="500" td="none">
                    {t('LinkCopied')}
                  </Text>
                </>
              ) : (
                <Text size="md" color="purplehearth" cop="1" mr="1rem" fw="500" td="none" onClick={handleCopyLinkClick}>
                  {t('CopyLink')}
                </Text>
              )}
            </RowsContainer>
            <Divider mt="2rem" />
          </StyledOptionsWrapper>
          <Button
            size="md"
            bcolor="mediumpurple"
            bop="1"
            hgradient="mediumpurple-dim-light"
            agradient="mediumpurple-dim-dark"
            onClick={handleClose}
            className={`call-scheduled-done-button-${callIdParam}`}
          >
            <Text size="md" $secondary fw="600">
              {t('Done')}
            </Text>
          </Button>
        </>
      )}
    </StyledContentContainer>
  )
}

const mapStateToProps = (state) => ({
  getCallById: (callId: string) => selectCallById(callId)(state),
})

export default connect(mapStateToProps, null)(CallScheduledModal)
