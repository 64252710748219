import { ReactElement, FC } from 'react'
import GroupAvatar from 'components/avatar/group'
import CallButton from 'components/button/call'
import Button from 'components/button'
import { CloseIcon } from 'theme'
import { isClanzyGroup, getContactsDisplayNames } from 'utils/groups'
import { CheckCircle } from 'components/check'
import { useTranslation } from 'react-i18next'
import { getTextColor } from 'utils'
import Text from 'components/text'
import {
  StyledControlsWrapper,
  GroupListItemProps,
  StyledTextWrapper,
  StyledTextCallTypeWrapper,
  StyledListItem,
} from './style'

const GroupListItem: FC<GroupListItemProps> = ({
  children,
  group,
  $selected,
  $flat,
  $hovered,
  onMouseEnter,
  onMouseLeave,
  onCallClick,
  onClick,
  onClose,
  bcolor,
  bcolorhov,
  title,
  $secondary,
  $noPresence,
  ...rest
}): ReactElement => {
  const { t } = useTranslation()
  const isGroupClanzyUsers = isClanzyGroup(group)

  const handleMouseEnter = () => {
    if (onMouseEnter) onMouseEnter(group)
  }
  const handleMouseLeave = () => {
    if (onMouseLeave) onMouseLeave(group)
  }
  const handleCallClick = () => {
    onCallClick(group)
  }
  const handleClick = () => {
    if (onClick) onClick(group)
  }
  const handleClose = () => {
    if (onClose) onClose(group)
  }

  const [groupDisplayName, restCount] = getContactsDisplayNames(group)

  return (
    <StyledListItem
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      $secondary={$secondary}
      $flat={$flat}
      onClick={handleClick}
      {...rest}
    >
      <GroupAvatar
        $noPresence={$noPresence}
        bcolor={bcolor}
        bcolorhov={bcolorhov}
        $secondary={$secondary}
        group={group}
        $hovered={$hovered}
      />
      <StyledTextWrapper>
        <Text ml="1rem" size="md" $secondary={!$secondary}>
          {group.name}
        </Text>
        <Text fw="400" mt="0.2rem" ml="1rem" size="xs" color={getTextColor($secondary)} cop="0.6">
          {groupDisplayName}
          {!!restCount && ` ${t('And')} ${restCount} ${restCount === 1 ? t('Other') : t('Others')}...`}
        </Text>
      </StyledTextWrapper>
      <StyledControlsWrapper>
        {typeof $selected !== 'undefined' && (
          <CheckCircle alt={`${group.name} ${t('IsSelected')}`} $checked={$selected} />
        )}
        {onCallClick && $hovered && (
          <StyledTextCallTypeWrapper>
            <Text size="md" mr="1rem" $secondary={!$secondary} onClick={handleCallClick} td="none">
              {t(isGroupClanzyUsers ? 'ClanzyGroupCall' : 'GroupCall')}
            </Text>
          </StyledTextCallTypeWrapper>
        )}
        {onCallClick && (
          <CallButton
            title={t(isGroupClanzyUsers ? 'ClanzyGroupCallButton' : 'GroupCallButton')}
            $active={$hovered}
            clanzy={isGroupClanzyUsers}
            onClick={handleCallClick}
          />
        )}
        {onClose && (
          <Button circular="true" subtle="true" onClick={handleClose}>
            <CloseIcon />
          </Button>
        )}
      </StyledControlsWrapper>
    </StyledListItem>
  )
}

export default GroupListItem
