import styled, { css } from 'styled-components'
import { CallNormalized } from 'reducers/callsSlice'
import { RowsContainer, ColumnsContainer } from 'components/container'
import { UserNormalized } from 'reducers/usersSlice'
import Button from 'components/button'

export interface ScheduledCallListItemProps {
  /**
   * On scheduledCall click handler
   */
  onClick?: (scheduledCall: CallNormalized) => void
  /**
   * On mouse enter handler
   */
  onMouseEnter?: (scheduledCall: CallNormalized) => void
  /**
   * On mouse leave handler
   */
  onMouseLeave?: (scheduledCall: CallNormalized) => void
  /**
   * On join call handler
   */
  onJoinClick?: (scheduledCall: CallNormalized) => void
  /**
   * The scheduledCall info
   */
  scheduledCall: CallNormalized
  /**
   * Background color
   */
  bcolor?: string
  /**
   * Is hovered
   */
  $hovered?: boolean
  /**
   * Ticker
   */
  ticker?: number
  /**
   * Call icon title
   */
  title?: string
  /**
   * No hover or active states
   */
  $flat?: boolean
  /**
   * No padding left or right
   */
  $wide?: boolean
  /**
   * Variant
   */
  $secondary?: boolean
  /**
   * The authenticated user
   */
  authUser?: UserNormalized
}

export const StyledListItem = styled(RowsContainer)`
  padding: 0.75rem 1.5rem;
  align-items: center;

  ${(props: ScheduledCallListItemProps) =>
    props.$wide &&
    css`
      padding: 0.75rem 0;
    `}

  ${(props: ScheduledCallListItemProps) =>
    !props.$flat &&
    css`
      &:hover {
        background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.white, '0.04')};
      }
    `}

  ${(props: ScheduledCallListItemProps) =>
    props.$secondary &&
    !props.$flat &&
    css`
      &:hover {
        background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.black, '0.1')};
      }
    `}
`

export const StyledTextWrapper = styled(ColumnsContainer)`
  max-width: 65%;
  justify-content: center;
`

export const StyledButton = styled(Button)`
  padding: 0.8rem 1.5rem;
  height: auto;
`

export const StyledCopyButton = styled(Button)`
  padding: 0.3rem;
`

export const StyledControlsWrapper = styled(RowsContainer)`
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
`
