/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactElement } from 'react'
import { Route } from 'react-router'
import { Redirect } from 'react-router-dom'
import { selectAuthUser, selectAnonUser } from 'reducers/usersSlice'
import { connect } from 'react-redux'
import { dashboardHomePath, inviteQueryParam, callIdQueryParam, callsPath } from 'const'
import useUser from 'hooks/useUser'
import { useQueryParam, StringParam, BooleanParam } from 'use-query-params'
import themeComponents from './theme'

interface AnonymousRouteProps {
  authUser?: any
  anonUser?: any
  render?: any
  component?: FC
  exact?: boolean
  path: string
  themeRoute?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AnonymousRoute: FC<AnonymousRouteProps> = ({
  themeRoute,
  authUser,
  anonUser,
  render,
  component: Component,
  exact,
  ...rest
}): ReactElement => {
  const loadingAuthUser = useUser(!!authUser)
  const loadingAnonUser = useUser(!!authUser && !!anonUser, true)
  const [callIdParam] = useQueryParam(callIdQueryParam, StringParam)
  const [inviteParam] = useQueryParam(inviteQueryParam, BooleanParam)

  if (loadingAuthUser || loadingAnonUser) return <></>

  const ThemeComponent = themeComponents[themeRoute] || null

  const redirect = callIdParam ? (
    <Redirect to={`${callsPath}/${callIdParam}?${inviteQueryParam}=${inviteParam ? '1' : '0'}`} />
  ) : (
    <Redirect to={dashboardHomePath} />
  )

  return (
    <Route
      {...rest}
      render={(props: unknown) =>
        !authUser ? <ThemeComponent>{render ? render() : <Component {...props} />}</ThemeComponent> : redirect
      }
    />
  )
}

const mapStateToProps = (state) => ({
  authUser: selectAuthUser(state),
  anonUser: selectAnonUser(state),
})

export default connect(mapStateToProps, null)(AnonymousRoute)
