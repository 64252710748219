import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.68175 5.43934C9.09597 4.85355 8.14622 4.85355 7.56043 5.43934C6.97465 6.02513 6.97465 6.97487 7.56043 7.56066L14.9998 15V19C14.9998 21.7614 17.2384 24 19.9998 24C21.1255 24 22.1643 23.628 23 23.0002L24.7796 24.7798C23.4818 25.8543 21.8162 26.5 19.9998 26.5C15.8576 26.5 12.4998 23.1421 12.4998 19C12.4998 18.1716 11.8282 17.5 10.9998 17.5C10.1713 17.5 9.49977 18.1716 9.49977 19C9.49977 24.4617 13.6698 28.9494 18.9998 29.453V35H21.9998V29.3098C23.8563 28.9517 25.5388 28.1046 26.9076 26.9079L32.4391 32.4393C33.0249 33.0251 33.9746 33.0251 34.5604 32.4393C35.1462 31.8536 35.1462 30.9038 34.5604 30.318L28.8675 24.6251C29.9011 22.999 30.4998 21.0694 30.4998 19C30.4998 18.1716 29.8282 17.5 28.9998 17.5C28.1713 17.5 27.4998 18.1716 27.4998 19C27.4998 20.2356 27.201 21.4015 26.6716 22.4292L24.764 20.5216C24.9171 20.0418 24.9998 19.5306 24.9998 19V11C24.9998 8.23858 22.7612 6 19.9998 6C17.3178 6 15.1291 8.11156 15.0053 10.7629L9.68175 5.43934ZM17.9998 18V19C17.9998 20.1046 18.8952 21 19.9998 21C20.2932 21 20.5719 20.9368 20.823 20.8232L17.9998 18ZM21.9998 17.7574L17.9998 13.7574V11C17.9998 9.89543 18.8952 9 19.9998 9C21.1043 9 21.9998 9.89543 21.9998 11V17.7574Z"
      fill="white"
      fillOpacity="0.6"
    />
  </svg>
)
