import styled, { css } from 'styled-components'

export interface ContentContainerProps {
  /**
   * Size
   */
  size?: string
  /**
   * Flex direction row
   */
  row?: string
}

export default styled.section<ContentContainerProps>`
  outline: none;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${(props: ContentContainerProps) =>
    !!props.size &&
    css`
      width: ${({ theme }) => theme.sizes[props.size]};
      max-width: ${({ theme }) => theme.sizes[props.size]};
    `}

  ${(props: ContentContainerProps) =>
    !!props.row &&
    css`
      flex-direction: row;
    `}

  ${({ theme }) => theme.fn.down(theme.breakpoints.md)} {
    max-width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
  }
`
