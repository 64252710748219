import { isGroup } from './groups'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const capFirstLetter = (str: string): string => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const sortContactsAndGroups = (a, b) => {
  const strA = isGroup(a) ? a.name : a.displayName
  const strB = isGroup(b) ? b.name : b.displayName
  if (strA < strB) {
    return -1
  }
  if (strA > strB) {
    return 1
  }
  return 0
}

export const getAvatarLetters = (str: string, cnt: number): string => {
  if (!str) return ''
  if (str.length <= cnt) return str.toUpperCase()
  return str
    .split(' ')
    .map((part) => part[0])
    .join('')
    .slice(0, cnt)
    .toUpperCase()
}

export const getLetters = (str: string, cnt: number, etc?: boolean): string => {
  if (!str) return ''
  if (str.length <= cnt) return str
  return `${str.slice(0, cnt)}${etc ? '...' : ''}`
}

export const getTextColor = ($secondary) => {
  return $secondary ? 'brandblack' : 'white'
}

export const getAvatarBorColor = (isClanzy, $secondary) => {
  if (isClanzy) return 'magentafuchsia'
  return $secondary ? 'brandblack' : 'white'
}

export const getAvatarBorOp = (isClanzy, $secondary) => {
  if (isClanzy) return '1'
  return $secondary ? '0.6' : '0.38'
}
