/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement, useEffect, useMemo, useState, useCallback } from 'react'
import Text from 'components/text'
import Divider from 'components/divider'
import Button from 'components/button'
import { useTranslation } from 'react-i18next'
import { getAvatarBorColor, getAvatarBorOp } from 'utils'
import Avatar from 'components/avatar'
import { forgottenPassword, selectUserById, updateManagedUserProfile, removeManagedUser } from 'reducers/usersSlice'
import { getManagedContacts, selectContactsByManagedUserId } from 'reducers/contactsSlice'
import { getManagedGroups, selectNotNewGroupsByManagedUserId } from 'reducers/groupsSlice'
import {
  modalQueryParam,
  managedUserIdQueryParam,
  modalNewManagedUserUpdatedQueryParam,
  modalEditManagedUserNameQueryParam,
  subModalQueryParam,
  isNewQueryParam,
} from 'const'
import { useQueryParam, StringParam, BooleanParam } from 'use-query-params'
import { useDispatch, connect } from 'react-redux'
import { AddPhoto } from 'components/photo'
import ManagedUserContacts from './contacts'
import ManagedUserGroups from './groups'
import {
  StyledText,
  StyledDivider,
  StyledCard,
  StyledVerList,
  StyledListItem,
  StyledTabContainer,
  StyledTextWrapper,
  StyledColumnsContainer,
  StyledRowsContainer,
  StyledContentContainer,
  StyledHeaderContainer,
  StyledAddPhotoWrapper,
  StyledContentWrapper,
  StyledComponentWrapper,
  StyledControlsWrapper,
  StyledListItemWrapper,
  StyledHorList,
  StyledAsideContainer,
} from './style'

const { $ } = window

const UpdateManagedUser = ({
  getUserById,
  onClose,
  getContactsByManagedUserId,
  getGroupsByManagedUserId,
}): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [active, setActive] = useState('accountInfo')
  const [contactListActive, setContactListActive] = useState('contacts')
  const [, setModalParam] = useQueryParam(modalQueryParam, StringParam)
  const [subModalParam, setSubModalParam] = useQueryParam(subModalQueryParam, StringParam)
  const [managedUserIdParam, setManagedUserIdQueryParam] = useQueryParam(managedUserIdQueryParam, StringParam)
  const [isNew, setIsNew] = useQueryParam(isNewQueryParam, BooleanParam)

  const contacts = useMemo(() => (managedUserIdParam ? getContactsByManagedUserId(managedUserIdParam) : []), [
    getContactsByManagedUserId,
    managedUserIdParam,
  ])

  const groups = useMemo(() => (managedUserIdParam ? getGroupsByManagedUserId(managedUserIdParam) : []), [
    getGroupsByManagedUserId,
    managedUserIdParam,
  ])

  const user = useMemo(() => (managedUserIdParam ? getUserById(managedUserIdParam) : null), [
    getUserById,
    managedUserIdParam,
  ])

  const focusDoneButton = useCallback(() => {
    const el = $('.update-managed-user-done-button')?.[0]
    if (el) el.focus()
  }, [])

  useEffect(() => {
    if (!subModalParam) focusDoneButton()
  }, [user, contacts, groups, focusDoneButton, subModalParam])

  const handleAddPhoto = (photo) => {
    dispatch(updateManagedUserProfile({ id: user.id, photo }))
  }

  const handleEdinNameClick = () => {
    setSubModalParam(modalEditManagedUserNameQueryParam, 'replaceIn')
  }

  const handleClose = useCallback(() => {
    setManagedUserIdQueryParam(null, 'replaceIn')
    setIsNew(null, 'replaceIn')
    onClose()
  }, [onClose, setIsNew, setManagedUserIdQueryParam])

  const handleRemove = () => {
    dispatch(removeManagedUser(user.id))
    handleClose()
  }

  useEffect(() => {
    if (!managedUserIdParam) handleClose()

    dispatch(getManagedContacts({ id: managedUserIdParam }))
    dispatch(getManagedGroups({ id: managedUserIdParam }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, managedUserIdParam])

  const handleNext = () => {
    if (isNew) {
      setModalParam(modalNewManagedUserUpdatedQueryParam, 'replaceIn')
    } else {
      handleClose()
    }
  }

  const handleResetPassword = () => {
    dispatch(forgottenPassword({ email: user.email }))
  }

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Button
          size="md"
          bcolor="mediumpurple"
          bop="1"
          ml="1rem"
          hgradient="mediumpurple-dim-light"
          agradient="mediumpurple-dim-dark"
          onClick={handleNext}
          className="update-managed-user-done-button"
        >
          <Text size="md" $secondary fw="600">
            {t('Done')}
          </Text>
        </Button>
      </StyledHeaderContainer>
      <Divider mt="1.125rem" />
      <StyledContentWrapper>
        <StyledAsideContainer>
          <StyledRowsContainer>
            <StyledColumnsContainer $center>
              <Avatar
                size="xl"
                fontSize="xxxl"
                $secondary
                borcolor={getAvatarBorColor(true, false)}
                borop={getAvatarBorOp(true, false)}
                name={user?.displayName}
                alt={t('PhotoOfContact')}
                img={user?.photo}
                $noPresence
              />
              <StyledAddPhotoWrapper>
                <AddPhoto
                  $noPreview
                  title={t('AddPhoto')}
                  updateTitle={t('ChangePhoto')}
                  src={user?.photo}
                  onAdd={handleAddPhoto}
                />
              </StyledAddPhotoWrapper>
            </StyledColumnsContainer>
            <StyledTextWrapper>
              <Text size="lg" fw="600" mt="0.3rem">
                {user?.firstName}&nbsp;{user?.lastName}
              </Text>
              <Text fw="500" color="black" cop="0.4" mt="0.3rem">
                {user?.username}
              </Text>
            </StyledTextWrapper>
          </StyledRowsContainer>
          <StyledVerList>
            <StyledListItem secondary="true" active={active === 'accountInfo'} onClick={() => setActive('accountInfo')}>
              <Text color="inherit" size="md" ml="1rem" fw="600">
                {t('AccountInfo')}
              </Text>
            </StyledListItem>
            <StyledListItem secondary="true" active={active === 'contactList'} onClick={() => setActive('contactList')}>
              <Text color="inherit" size="md" ml="1rem" fw="600">
                {t('ContactList')}
              </Text>
            </StyledListItem>
          </StyledVerList>
        </StyledAsideContainer>
        <StyledTabContainer>
          {active === 'accountInfo' ? (
            <>
              <StyledCard size="xxl">
                <StyledComponentWrapper>
                  <StyledTextWrapper>
                    <Text size="xl" fw="600" mt="0.3rem" $block>
                      {t('AccountInfo')}
                    </Text>
                  </StyledTextWrapper>
                  <StyledTextWrapper>
                    <Text size="md" fw="600" mt="2.3rem">
                      {t('Name')}
                    </Text>
                    <Text fw="500" color="brandblack" cop="0.6" mt="0.3rem">
                      {user?.firstName}&nbsp;{user?.lastName}
                    </Text>
                    <StyledText
                      color="purplehearth"
                      $block
                      fw="600"
                      mt="0.3rem"
                      td="none"
                      onClick={handleEdinNameClick}
                    >
                      {t('Edit')}
                    </StyledText>
                  </StyledTextWrapper>
                  <StyledDivider mt="2.3rem" />
                  {user?.hasPassword ? (
                    <StyledTextWrapper>
                      <StyledText
                        mt="2.3rem"
                        color="purplehearth"
                        $block
                        $link
                        fw="600"
                        td="none"
                        onClick={handleResetPassword}
                      >
                        {t('ResetPassword')}
                      </StyledText>
                      <Text fw="500" color="brandblack" cop="0.6" mt="0.3rem" mb="1rem">
                        {user?.displayName}&nbsp;{t('WillResetTheirPasswordText')}
                      </Text>
                    </StyledTextWrapper>
                  ) : (
                    <StyledTextWrapper>
                      <Text size="md" fw="600" mt="2.3rem">
                        {`${t('Password')} - ${t('NotSetYet')}`}
                      </Text>
                      <Text fw="500" color="brandblack" cop="0.6" mt="0.3rem" mb="1rem">
                        {t('ThisWillEmailInstructionsTo')}&nbsp;{user?.displayName}&nbsp;
                        {t('ExplainingHowToSetANewPassword')}
                      </Text>
                    </StyledTextWrapper>
                  )}
                </StyledComponentWrapper>
              </StyledCard>
              <StyledControlsWrapper>
                <Text color="purplehearth" $block $link fw="600" mt="0.3rem" td="none" onClick={handleRemove}>
                  {t('RemoveFromYourManagedUsersList')}
                </Text>
              </StyledControlsWrapper>
            </>
          ) : (
            <StyledCard size="xxl">
              <StyledComponentWrapper>
                <StyledHorList>
                  <StyledListItemWrapper
                    secondary="true"
                    active={contactListActive === 'contacts'}
                    onClick={() => setContactListActive('contacts')}
                  >
                    <Text size="lg" mb="0.2rem" color="brandblack" fw="600" font="Poppins">
                      {t('Contacts')}
                    </Text>
                  </StyledListItemWrapper>
                  <StyledListItemWrapper
                    secondary="true"
                    active={contactListActive === 'groups'}
                    onClick={() => setContactListActive('groups')}
                  >
                    <Text size="lg" mb="0.2rem" color="brandblack" fw="600" font="Poppins">
                      {t('Groups')}
                    </Text>
                  </StyledListItemWrapper>
                </StyledHorList>
              </StyledComponentWrapper>
              {contactListActive === 'contacts' ? (
                <ManagedUserContacts user={user} contacts={contacts} />
              ) : (
                <ManagedUserGroups user={user} groups={groups} />
              )}
            </StyledCard>
          )}
        </StyledTabContainer>
      </StyledContentWrapper>
    </StyledContentContainer>
  )
}

const mapStateToProps = (state) => ({
  getContactsByManagedUserId: (userId: string) => selectContactsByManagedUserId(userId)(state),
  getGroupsByManagedUserId: (userId: string) => selectNotNewGroupsByManagedUserId(userId)(state),
  getUserById: (userId: string) => selectUserById(userId)(state),
})

export default connect(mapStateToProps, null)(UpdateManagedUser)
