import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path
      d="M1.01033 10.8839C0.522181 10.3957 0.52218 9.60428 1.01033 9.11612L8.96528 1.16117C9.45344 0.673012 10.2449 0.673012 10.7331 1.16117C11.2212 1.64932 11.2212 2.44078 10.7331 2.92893L3.66199 10L10.7331 17.0711C11.2212 17.5592 11.2212 18.3507 10.7331 18.8388C10.2449 19.327 9.45344 19.327 8.96529 18.8388L1.01033 10.8839ZM23.1074 11.25L1.89422 11.25L1.89422 8.75L23.1074 8.75L23.1074 11.25Z"
      fill="#707076"
    />
  </svg>
)
