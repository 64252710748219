import { ReactElement, FC, useEffect } from 'react'
import Text from 'components/text'
import { ContactsTabImage, CloseIcon, ContactsArrowIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import Button from 'components/button'
import {
  StyledIconWrapper,
  StyledContentWrapper,
  StyledImageWrapper,
  StyledContentContainer,
  StyledTextContainer,
  StyledCloseButton,
  StyledHeaderContainer,
} from './style'

const { $ } = window

const ImportContactsSuccessInfoModal: FC<{ onClose: () => void }> = ({ onClose }): ReactElement => {
  const { t } = useTranslation()

  useEffect(() => {
    const el = $('.import-contacts-success-info-done-button')?.[0]
    if (el) el.focus()
  }, [])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <StyledCloseButton circular="true" subtle="true" onClick={onClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledTextContainer>
        <Text component="h1" gradient="radiance">
          {t('Success')}!&nbsp;{t('YourAddressBookContactsHaveBeenImported')}
        </Text>
      </StyledTextContainer>
      <StyledContentWrapper>
        <Text size="lg" $center $secondary $block>
          {t('YouWillFindYourContactsUnderTheContactsTab')}
        </Text>
        <StyledImageWrapper>
          <StyledIconWrapper>
            <ContactsArrowIcon />
          </StyledIconWrapper>
          <ContactsTabImage alt={t('ContactsTabImageAltText')} />
        </StyledImageWrapper>
      </StyledContentWrapper>
      <Button
        size="md"
        bcolor="mediumpurple"
        bop="1"
        mt="2rem"
        hgradient="mediumpurple-dim-light"
        agradient="mediumpurple-dim-dark"
        onClick={onClose}
        className="import-contacts-success-info-done-button"
      >
        <Text size="md" $secondary fw="600">
          {t('Done')}
        </Text>
      </Button>
    </StyledContentContainer>
  )
}

export default ImportContactsSuccessInfoModal
