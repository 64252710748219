import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path
      d="M15.6845 45.8V35.7019C15.6845 31.4958 15.6699 27.2897 15.6407 23.0836C15.593 22.7773 15.6545 22.4639 15.8145 22.1984C15.9745 21.9329 16.2228 21.732 16.5159 21.631C27.9163 16.5907 39.3079 11.5299 50.6908 6.44878C50.9484 6.3181 51.2333 6.25 51.5222 6.25C51.8111 6.25 52.0959 6.3181 52.3536 6.44878C63.7715 11.5649 75.1922 16.6432 86.616 21.6835C86.8936 21.7574 87.1351 21.9292 87.296 22.1672C87.4568 22.4053 87.5261 22.6935 87.4911 22.9786C87.4911 30.7753 87.4911 38.5633 87.4474 46.3601C87.4343 48.9124 87.2295 51.4601 86.8348 53.9818C85.553 62.0041 82.3426 69.5952 77.4793 76.1032C71.2657 84.4775 63.3193 90.4629 53.255 93.4905C52.1675 93.8365 50.9994 93.8365 49.9119 93.4905C40.6273 90.7032 32.471 85.0334 26.624 77.302C19.496 68.3534 15.6367 57.2399 15.6845 45.8Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M59.4184 41.583H68.7563C67.8244 33.7051 61.0399 28.125 52.2052 28.125C42.0099 28.125 33.958 35.5588 33.958 48.4954C33.958 61.0073 41.3762 68.75 52.317 68.75C62.1396 68.75 69.1663 62.4361 69.1663 51.8165V46.5839H52.7084V53.5542H60.2757C60.1825 57.7249 57.3867 60.3701 52.3916 60.3701C46.6695 60.3701 43.3705 55.9871 43.3705 48.3796C43.3705 40.83 46.8559 36.5049 52.3916 36.5049C56.082 36.5049 58.5983 38.3778 59.4184 41.583Z"
      fill="#1D1D26"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="18.7054"
        y1="91.7742"
        x2="71.1047"
        y2="62.4638"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0520833" stopColor="#F6BF4F" />
        <stop offset="0.5625" stopColor="#FF00E7" />
        <stop offset="1" stopColor="#935DEA" />
      </linearGradient>
    </defs>
  </svg>
)
