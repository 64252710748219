import { ReactElement, FC, useState, useRef } from 'react'
import { withTheme } from 'styled-components'
import Button, { ButtonProps } from 'components/button'
import Image from 'components/image'
import Text from 'components/text'
import { Theme } from 'theme'
import { useTranslation } from 'react-i18next'
import { RowsContainer } from 'components/container'

export interface AddPhotoProps extends ButtonProps {
  /**
   * On add handler
   */
  onAdd?: (img: string) => void
  /**
   * Theme
   */
  theme: Theme
  /**
   * Title
   */
  title: string
  /**
   * Update title
   */
  updateTitle: string
  /**
   * Photo src
   */
  src?: string
  /**
   * Variant
   */
  $secondary?: boolean
  /**
   * No photo preview
   */
  $noPreview?: boolean
  /**
   * Is disabled
   */
  $disabled?: boolean
  /**
   * Icon
   */
  icon?: FC<{ color: string; title: string }>
}

const getBase64 = (file, cb) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => cb(reader.result)
}

const AddPhoto: FC<AddPhotoProps> = ({
  icon,
  $disabled,
  theme,
  $secondary,
  title,
  updateTitle,
  $noPreview,
  src,
  onAdd,
  ...rest
}): ReactElement => {
  const { t } = useTranslation()
  const fileRef = useRef(null)
  const [image, setImage] = useState(src)
  const getColor = theme.fn.color as (arg0: string, arg1?: string) => string
  const color = getColor(theme.colors.black)

  const handleChange = (e) => {
    if (e?.target?.files?.length) {
      getBase64(e.target.files[0], (result: string) => {
        setImage(URL.createObjectURL(e.target.files[0]))
        onAdd(result)
      })
    }
  }

  const handleClick = async () => {
    if (!$disabled) fileRef.current?.click()
  }

  return (
    <RowsContainer $center>
      {!$noPreview && (
        <Button
          borcolor={$secondary ? null : 'magentafuchsia'}
          bcolor={$secondary ? 'white' : 'transparent'}
          bop="1"
          borop="1"
          {...rest}
          size="xxl"
          circular="true"
          $secondary
          $flat
          onClick={handleClick}
          style={{ position: 'relative', overflow: 'hidden' }}
        >
          {image ? <Image src={image} alt={t('Photo')} /> : icon({ color, title })}
        </Button>
      )}
      <Text color="purplehearth" fw="600" ml={$noPreview ? '0' : '0.25rem'} onClick={handleClick} td="none">
        {image ? updateTitle : title}
      </Text>
      <input style={{ display: 'none' }} type="file" ref={fileRef} onChange={handleChange} />
    </RowsContainer>
  )
}

export default withTheme(AddPhoto)
