/* eslint-disable react/require-default-props */
import { ReactElement } from 'react'
import { withTheme } from 'styled-components'

// eslint-disable-next-line react/require-default-props
export default withTheme(
  ({ theme: { fn, palette }, color, title, $gradient }): ReactElement => {
    const fillColor = (fn.color as (arg0: string) => string)(palette.text[color || 'white'])

    return !$gradient ? (
      <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <path
          d="M22.1518 10.8839C22.6399 10.3957 22.6399 9.60428 22.1518 9.11612L14.1968 1.16117C13.7087 0.673012 12.9172 0.673012 12.4291 1.16117C11.9409 1.64932 11.9409 2.44078 12.4291 2.92893L19.5001 10L12.4291 17.0711C11.9409 17.5592 11.9409 18.3507 12.4291 18.8388C12.9172 19.327 13.7087 19.327 14.1968 18.8388L22.1518 10.8839ZM0.0546873 11.25L21.2679 11.25L21.2679 8.75L0.0546877 8.75L0.0546873 11.25Z"
          fill={fillColor}
        />
      </svg>
    ) : (
      <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <path
          d="M22.1518 10.8839C22.6399 10.3957 22.6399 9.60428 22.1518 9.11612L14.1968 1.16117C13.7087 0.673012 12.9172 0.673012 12.4291 1.16117C11.9409 1.64932 11.9409 2.44078 12.4291 2.92893L19.5001 10L12.4291 17.0711C11.9409 17.5592 11.9409 18.3507 12.4291 18.8388C12.9172 19.327 13.7087 19.327 14.1968 18.8388L22.1518 10.8839ZM0.0546873 11.25L21.2679 11.25L21.2679 8.75L0.0546877 8.75L0.0546873 11.25Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="3.98592"
            y1="11.252"
            x2="4.15435"
            y2="9.07993"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F6BF4F" />
            <stop offset="1" stopColor="#FF00E7" />
          </linearGradient>
        </defs>
      </svg>
    )
  },
)
