/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import Button from 'components/button'
import { ContentContainer, RowsContainer } from 'components/container'

export const StyledTitleContainer = styled.title`
  display: inline-block;
  text-align: center;
  position: relative;
  padding: 1rem 4rem 0 4rem;
`

export const StyledTextContainer = styled.span`
  text-align: center;
  position: relative;
  padding: 0.5rem 5rem 0 5rem;
`

export const StyledButtonsWrapper = styled(RowsContainer)`
  justify-content: flex-end;
  margin: 1.7rem 0 1rem 0;
  width: 100%;
`

export const StyledContentContainer = styled(ContentContainer)`
  margin: 1.125rem;
`

export const StyledHeaderContainer = styled(RowsContainer)`
  height: 2.8125rem;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
`

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`
