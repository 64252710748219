import { ReactElement, FC } from 'react'
import { withTheme } from 'styled-components'
import { ButtonProps } from 'components/button'
import { Theme, AddNewIcon } from 'theme'
import StyledButton from './style'

export interface AddButtonProps extends ButtonProps {
  /**
   * On click handler
   */
  onClick?: () => void
  /**
   * Theme
   */
  theme: Theme
  /**
   * Icon title
   */
  title: string
}

const AddButton: FC<AddButtonProps> = ({ theme, title, onClick, ...rest }): ReactElement => {
  const getColor = theme.fn.color as (arg0: string, arg1?: string) => string
  const color = getColor(theme.colors.mediumpurple)
  const component = AddNewIcon({ color, title })

  return (
    <StyledButton {...rest} size="lg" circular="true" $secondary onClick={onClick}>
      {component}
    </StyledButton>
  )
}

export default withTheme(AddButton)
