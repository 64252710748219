import { ReactElement } from 'react'

export default (): ReactElement => (
  <svg width="60" height="41" viewBox="0 0 60 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M59.5873 7.53971C59.8848 6.47595 59.2636 5.37246 58.1998 5.075L40.8648 0.227557C39.8011 -0.0699066 38.6976 0.551302 38.4001 1.61506C38.1027 2.67883 38.7239 3.78232 39.7876 4.07978L55.1965 8.38862L50.8877 23.7975C50.5902 24.8613 51.2114 25.9648 52.2752 26.2622C53.3389 26.5597 54.4424 25.9385 54.7399 24.8747L59.5873 7.53971ZM1.98112 40.6413L58.6423 8.74393L56.6801 5.25829L0.0188844 37.1556L1.98112 40.6413Z"
      fill="#13BF4D"
    />
  </svg>
)
