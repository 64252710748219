import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path
      d="M2 6.94999L8.03226 13L19 2"
      stroke="white"
      strokeWidth="3"
      strokeMiterlimit="3.99933"
      strokeLinecap="round"
    />
  </svg>
)
