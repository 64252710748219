import { ReactElement } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default ({ title, ...rest }): ReactElement => (
  <svg {...rest} width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path
      d="M22.9897 10.8839C23.4778 10.3957 23.4778 9.60428 22.9897 9.11612L15.0347 1.16117C14.5466 0.673012 13.7551 0.673012 13.2669 1.16117C12.7788 1.64932 12.7788 2.44078 13.2669 2.92893L20.338 10L13.2669 17.0711C12.7788 17.5592 12.7788 18.3507 13.2669 18.8388C13.7551 19.327 14.5466 19.327 15.0347 18.8388L22.9897 10.8839ZM0.892578 11.25L22.1058 11.25L22.1058 8.75L0.892578 8.75L0.892578 11.25Z"
      fill="#11111B"
    />
  </svg>
)
