import { ReactElement } from 'react'

export default ({ color, title }: { color: string; title: string }): ReactElement => (
  <svg width="35" height="24" viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.32962 2.90096C0.383163 4.90832 0.334943 6.93364 0.238503 10.9843C0.230713 11.3115 0.226562 11.6416 0.226562 11.9736C0.226562 12.2792 0.230078 12.5832 0.236712 12.8848C0.326626 16.9725 0.371583 19.0163 2.31527 21.0274C4.25895 23.0384 6.41155 23.1592 10.7168 23.4009C11.5331 23.4467 12.3453 23.4736 13.1268 23.4736C13.9228 23.4736 14.7508 23.4457 15.5824 23.3983C19.8589 23.1547 21.9971 23.0328 23.9386 21.0231C25.8801 19.0134 25.9255 16.9859 26.0162 12.9309C26.0233 12.6148 26.027 12.2954 26.027 11.9736C26.027 11.6254 26.0226 11.2799 26.0144 10.9383C25.9174 6.92027 25.8689 4.91126 23.9246 2.90512C21.9803 0.898993 19.8742 0.782124 15.6621 0.548385C14.8147 0.50136 13.9612 0.473633 13.1268 0.473633C12.3072 0.473633 11.4693 0.500382 10.6367 0.545893C6.39629 0.777696 4.27607 0.893597 2.32962 2.90096ZM28.0784 9.09863L29.6154 8.04681L32.6895 5.94315L34.2266 4.89132V19.6169L32.6895 18.5651L29.6154 16.4614L28.0784 15.4096V9.09863Z"
      fill={color}
    />
  </svg>
)
