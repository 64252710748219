import { ReactElement, FC } from 'react'
import Text from 'components/text'
import {
  modalQueryParam,
  providerQueryParam,
  modalImportContactsMoreInfoQueryParam,
  modalImportContactsOtherProvidersQueryParam,
  privacyPolicyPath,
} from 'const'
import List, { ButtonListItem } from 'components/list'
import { GoogleProviderImage, OutlookProviderImage, YahooProviderImage, CloseIcon } from 'theme'
import Divider from 'components/divider'
import { useTranslation } from 'react-i18next'
import { useQueryParam, StringParam } from 'use-query-params'
import {
  StyledFooterTextWrapper,
  StyledNextIcon,
  StyledContentContainer,
  StyledTextBackground,
  StyledTextContainer,
  StyledCloseButton,
  StyledHeaderContainer,
  StyledRowsContainer,
  StyledColumnsContainer,
} from './style'

const ImportContactsSelectProviderModal: FC<{ onClose: () => void }> = ({ onClose }): ReactElement => {
  const { t } = useTranslation()
  const [, setModalParam] = useQueryParam(modalQueryParam, StringParam)
  const [, setProviderParam] = useQueryParam(providerQueryParam, StringParam)

  const addressBookProviders = [
    {
      id: 'gmail',
      image: GoogleProviderImage,
      alt: t('ButtonToImportFromGmail'),
    },
    {
      id: 'windowslive',
      image: OutlookProviderImage,
      alt: t('ButtonToImportFromOutlook'),
    },
    {
      id: 'yahoo',
      image: YahooProviderImage,
      alt: t('ButtonToImportFromYahoo'),
    },
  ]

  const handleNext = (provider: string) => () => {
    setModalParam(modalImportContactsMoreInfoQueryParam, 'replaceIn')
    setProviderParam(provider, 'replaceIn')
  }

  const handleOtherProviders = () => {
    setModalParam(modalImportContactsOtherProvidersQueryParam, 'replaceIn')
  }

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Text component="h1" mt="0" color="brandblack" font="Poppins">
          {t('ConnectYourAddressBook')}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={onClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledTextContainer>
        <Text fw="500" gradient="radiance" size="md">
          {t('ConnectAddressBookTitle')}
        </Text>
        <StyledTextBackground />
      </StyledTextContainer>
      <Text size="lg" mt="1.375rem" mr="auto">
        {t('ChooseYourEmailProvider')}
      </Text>
      <List column="true" mt="0.3rem">
        {addressBookProviders.map(({ id, image: Image, alt }) => (
          <ButtonListItem key={id} size="xl" mt="1rem" onClick={handleNext(id)}>
            <StyledRowsContainer>
              <Image alt={alt} />
              <StyledNextIcon />
            </StyledRowsContainer>
          </ButtonListItem>
        ))}
      </List>
      <Text size="md" mt="2rem" mr="auto" fw="600" color="purplehearth" td="none" onClick={handleOtherProviders}>
        {t('OtherEmailProviders')}
      </Text>
      <Divider mt="2rem" />
      <StyledColumnsContainer component="footer">
        <StyledFooterTextWrapper>
          <Text color="brandblack" size="xs">
            {t('ImportContactsPrivacyText')}
          </Text>
          &nbsp;
          <Text td="none" color="purplehearth" size="xs" linkBlank="true" link={privacyPolicyPath}>
            {t('PrivacyPolicy')}
          </Text>
        </StyledFooterTextWrapper>
        <Text
          size="md"
          mt="2rem"
          mb="1rem"
          ml="auto"
          mr="auto"
          fw="600"
          color="purplehearth"
          onClick={onClose}
          td="none"
        >
          {t('Cancel')}
        </Text>
      </StyledColumnsContainer>
    </StyledContentContainer>
  )
}

export default ImportContactsSelectProviderModal
