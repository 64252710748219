import styled from 'styled-components'
import { ColumnsContainer } from 'components/container'
import List from 'components/list'

export const StyledComponentWrapper = styled(ColumnsContainer)`
  padding: 1rem 0;
`

export const StyledList = styled(List)`
  flex-direction: column;
  width: 100%;
`

export const StyledListControlsWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 0.5rem;
  padding: 0 1.5rem 1rem 1.5rem;
  text-align: left;
`
