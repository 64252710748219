/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement, useCallback, useEffect, useLayoutEffect } from 'react'
import Text from 'components/text'
import Button from 'components/button'
import { useTranslation } from 'react-i18next'
import { participantIdQueryParam } from 'const'
import { useQueryParam, StringParam } from 'use-query-params'
import { CloseIcon } from 'theme'
import {
  StyledButtonsWrapper,
  StyledContentContainer,
  StyledTextContainer,
  StyledTitleContainer,
  StyledCloseButton,
  StyledHeaderContainer,
} from './style'

const { $ } = window

export default ({ onClose, displayNames, mute, clanzyUsersIds, managedUsers }): ReactElement => {
  const { t } = useTranslation()
  const [participantIdParam, setParticipantIdParam] = useQueryParam(participantIdQueryParam, StringParam)
  const isGuardian = !!managedUsers.find((managedUser) => clanzyUsersIds[participantIdParam] === managedUser.id)

  const handleClose = useCallback(() => {
    setParticipantIdParam(null, 'replaceIn')
    onClose()
  }, [onClose, setParticipantIdParam])

  const handleMute = useCallback(() => {
    mute(participantIdParam)
    handleClose()
  }, [handleClose, mute, participantIdParam])

  useLayoutEffect(() => {
    if (!participantIdParam) handleClose()
  }, [handleClose, participantIdParam])

  useEffect(() => {
    if (participantIdParam) {
      const el = $(`.mute-participant-button-${participantIdParam}`)?.[0]
      if (el) el.focus()
    }
  }, [participantIdParam])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledTitleContainer>
        <Text size="xl" gradient="purple" fw="600" font="Poppins" $block>
          {`${t('Mute')} ${displayNames[participantIdParam]} ${t('ForEveryoneInTheCall')}?`}
        </Text>
      </StyledTitleContainer>
      <StyledTextContainer>
        <Text color="black" fw="500" $block>
          {!isGuardian && `${t('Only')} ${displayNames[participantIdParam]} ${t('WouldBeAbleToUnmuteThemselves')}`}
          {isGuardian &&
            `${displayNames[participantIdParam]} ${t('IsUnderYourManagedUsersListSoYouWillBeAbleTo')} ${t(
              'UnmuteThemOr',
            )} ${displayNames[participantIdParam]} ${t('CanStillUnmuteThemSelf')}`}
        </Text>
      </StyledTextContainer>
      <StyledButtonsWrapper>
        <Button size="md" subtle="true" onClick={handleClose}>
          <Text size="md" color="purplehearth" fw="600">
            {t('Cancel')}
          </Text>
        </Button>
        <Button
          size="md"
          bcolor="mediumpurple"
          bop="1"
          ml="1rem"
          hgradient="mediumpurple-dim-light"
          agradient="mediumpurple-dim-dark"
          onClick={handleMute}
          className={`mute-participant-button-${participantIdParam}`}
        >
          <Text size="md" $secondary fw="600">
            {t('Mute')}
          </Text>
        </Button>
      </StyledButtonsWrapper>
    </StyledContentContainer>
  )
}
