import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <circle cx="24" cy="24" r="24" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 15C19 12.2386 21.2386 10 24 10C26.7614 10 29 12.2386 29 15V23C29 25.7614 26.7614 28 24 28C21.2386 28 19 25.7614 19 23V15ZM15 21.5C15.8284 21.5 16.5 22.1716 16.5 23C16.5 27.1421 19.8579 30.5 24 30.5C28.1421 30.5 31.5 27.1421 31.5 23C31.5 22.1716 32.1716 21.5 33 21.5C33.8284 21.5 34.5 22.1716 34.5 23C34.5 28.1151 30.8424 32.3759 26 33.3098V39H23V33.453C17.6701 32.9494 13.5 28.4617 13.5 23C13.5 22.1716 14.1716 21.5 15 21.5Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="17.3917"
        y1="46.3066"
        x2="40.7588"
        y2="36.02"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6BF4F" />
        <stop offset="1" stopColor="#FF00E7" />
      </linearGradient>
    </defs>
  </svg>
)
