import { ReactElement, useEffect, useLayoutEffect } from 'react'
import Text from 'components/text'
import { useTranslation } from 'react-i18next'
import Button from 'components/button'
import Divider from 'components/divider'
import { useQueryParam, StringParam } from 'use-query-params'
import { CloseIcon } from 'theme'
import { emailQueryParam } from 'const'
import {
  StyledTitleContainer,
  StyledContentContainer,
  StyledTextContainer,
  StyledDescriptionContainer,
  StyledCloseButton,
  StyledHeaderContainer,
} from './style'

const { $ } = window

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ManagedUserRequestSentModal = ({ onClose }): ReactElement => {
  const { t } = useTranslation()
  const [emailParam, setEmailQueryParam] = useQueryParam(emailQueryParam, StringParam)

  const handleClose = () => {
    setEmailQueryParam(null, 'replaceIn')
    onClose()
  }

  useLayoutEffect(() => {
    if (!emailParam) handleClose()
  })

  useEffect(() => {
    const el = $('.managed-user-request-sent-done-button')?.[0]
    if (el) el.focus()
  }, [])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledTitleContainer>
        <Text size="xl" gradient="purple" fw="600" font="Poppins" $block>
          {t('RequestSent')}
        </Text>
      </StyledTitleContainer>
      <StyledTextContainer>
        <StyledDescriptionContainer>
          <Text color="brandblack">{t('TheClanzyUser')}&nbsp;</Text>
          <Text color="mediumpurple">{decodeURIComponent(emailParam)}&nbsp;</Text>
          <Text color="brandblack">{t('WillNeedToConfirmDescriptionText')}&nbsp;</Text>
        </StyledDescriptionContainer>
        <Divider mt="2rem" />
        <Text mt="2rem" color="black" cop="0.6" fw="500" $block>
          {t('YouWillBeNotifiedOnceTheyHaveAcceptedYourRequest')}
        </Text>
      </StyledTextContainer>
      <Button
        size="md"
        bcolor="mediumpurple"
        bop="1"
        mt="3rem"
        hgradient="mediumpurple-dim-light"
        agradient="mediumpurple-dim-dark"
        onClick={handleClose}
        className="managed-user-request-sent-done-button"
      >
        <Text size="md" $secondary fw="600">
          {t('Done')}
        </Text>
      </Button>
    </StyledContentContainer>
  )
}

export default ManagedUserRequestSentModal
