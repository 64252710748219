import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <ellipse cx="20.3985" cy="14.9219" rx="4.1153" ry="4.7032" fill="#AB81EE" />
    <ellipse cx="10.1108" cy="12.6065" rx="4.90572" ry="5.60653" fill="#AB81EE" />
    <path
      d="M19.2212 26.8236C19.2212 30.8047 15.1423 30.8283 10.1106 30.8283C5.07896 30.8283 1 30.8047 1 26.8236C1 22.8425 5.07896 19.6152 10.1106 19.6152C15.1423 19.6152 19.2212 22.8425 19.2212 26.8236Z"
      fill="#AB81EE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1749 30.2071C20.249 30.2072 20.3233 30.2072 20.3979 30.2072C24.6189 30.2072 28.0406 30.1874 28.0406 26.8478C28.0406 23.5081 24.6189 20.8008 20.3979 20.8008C19.8158 20.8008 19.2489 20.8523 18.7041 20.9498C20.2124 22.5017 21.1545 24.5029 21.1545 26.8225C21.1545 28.0738 20.8576 29.2329 20.1749 30.2071Z"
      fill="#AB81EE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5 2.5C27.5 1.67157 26.8284 1 26 1C25.1716 1 24.5 1.67157 24.5 2.5V4.5L22.5 4.5C21.6716 4.5 21 5.17157 21 6C21 6.82843 21.6716 7.5 22.5 7.5L24.5 7.5V9.5C24.5 10.3284 25.1716 11 26 11C26.8284 11 27.5 10.3284 27.5 9.5V7.5L29.5 7.5C30.3284 7.5 31 6.82843 31 6C31 5.17157 30.3284 4.5 29.5 4.5L27.5 4.5V2.5Z"
      fill="#AB81EE"
    />
  </svg>
)
