/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit'
import { isCallNotEndedOrCancelled, hasParticipantLeft, isInvitationRejected, isCanceled } from 'utils/calls'
import {
  CallData,
  apiCreateCall,
  ApiCreateCall,
  apiAddCallEntertainment,
  ApiAddCallEntertainment,
  apiCreateParticipant,
  apiGetCall,
  apiGetAllCalls,
  CallTypes,
  CallStatuses,
  CallInviteeStatuses,
  CallParticipantRoles,
  CallEntertainmentStatuses,
  ApiUpdateCallInvitation,
  ApiUpdateCallParticipant,
  ApiUpdateCallEntertainmentParticipant,
  apiUpdateCallEntertainmentParticipant,
  ApiUpdateCallEntertainment,
  apiUpdateCallEntertainment,
  apiUpdateCallInvitation,
  apiUpdateCallParticipant,
  ContactsStatuses,
  CallEntertainmentParticipantRoles,
  CallEntertainmentParticipantStatuses,
  CallEntertainmentTypes,
} from 'apis'
import { error } from 'react-toastify-redux'
import moment from 'moment'
import { ContactNormalized } from './contactsSlice'
import { GroupNormalized } from './groupsSlice'

const isNew = Symbol.for('isNew')
const isCurrent = Symbol.for('isCurrent')

export interface CallInviteeNormalized {
  id: string
  userId?: string
  contactId?: string
  status: CallInviteeStatuses
  contactStatus?: ContactsStatuses
  displayName?: string
  photo?: string
}

export interface CallParticipantNormalized {
  id: string
  userId: string
  role: CallParticipantRoles
  displayName?: string
  photo?: string
  leaveTime?: string
}

export interface CallEntertainmentParticipantNormalized {
  id: string
  callParticipantId: string
  role: CallEntertainmentParticipantRoles
  status?: CallEntertainmentParticipantStatuses
}

export interface CallEntertainmentNormalized {
  id: string
  password: string
  type: CallEntertainmentTypes
  name: string
  data?: string
  status: CallEntertainmentStatuses
  participants: CallEntertainmentParticipantNormalized[]
}

export interface Call {
  id: string
  callStatus: CallStatuses
  type?: CallTypes
  isInstant?: boolean
  isRecent?: boolean
  isScheduled?: boolean
  canJoin?: boolean
  scheduledTime?: string
  description?: string
  startTime?: string
  endTime?: string
  createdAt?: string
  callId?: string
  callInvitees?: CallInviteeNormalized[]
  callParticipants?: CallParticipantNormalized[]
  groupIds?: string[]
  contacts?: ContactNormalized[]
  groups?: GroupNormalized[]
  initialPeopleSelected?: boolean
  entertainments?: CallEntertainmentNormalized[]
}

export interface NewCall extends Call {
  saveGroup?: boolean
  groupSaved?: boolean
  groupName?: string
  groupPhoto?: string
}

export interface CallNormalized extends NewCall {
  [isNew]?: boolean
  [isCurrent]?: boolean
}

interface CallsState {
  calls: EntityState<CallNormalized>
}

const callsAdapter = createEntityAdapter({
  sortComparer: (a: CallNormalized, b: CallNormalized) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf(),
})

const initialState = callsAdapter.getInitialState({
  loading: 'idle',
})

export const getAllCalls = createAsyncThunk('calls/getAll', async (_, { rejectWithValue, dispatch }) => {
  try {
    return await apiGetAllCalls()
  } catch (e) {
    dispatch(error(e.message))
    return rejectWithValue(null)
  }
})

export const getCall = createAsyncThunk('calls/get', async (callId: string, { rejectWithValue, dispatch }) => {
  try {
    const call = await apiGetCall(callId)
    return call
  } catch (e) {
    dispatch(error(e.message))
    return rejectWithValue(null)
  }
})

export const createCall = createAsyncThunk(
  'calls/create',
  async (callData: ApiCreateCall, { rejectWithValue, dispatch }) => {
    try {
      const call = await apiCreateCall(callData)
      return call
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null)
    }
  },
)

export const addCallEntertainment = createAsyncThunk(
  'calls/add-entertainment',
  async (entertainmentData: ApiAddCallEntertainment, { rejectWithValue, dispatch }) => {
    try {
      const call = await apiAddCallEntertainment(entertainmentData)
      return call
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null)
    }
  },
)

export const createParticipant = createAsyncThunk(
  'calls/createParticipant',
  async (callId: string, { rejectWithValue, dispatch }) => {
    try {
      const call = await apiCreateParticipant(callId)
      return call
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null)
    }
  },
)

export const updateInvitation = createAsyncThunk(
  'calls/updateInvitation',
  async (data: ApiUpdateCallInvitation, { rejectWithValue, dispatch }) => {
    try {
      const call = await apiUpdateCallInvitation(data)
      return call
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null)
    }
  },
)

export const updateEntertainmentParticipant = createAsyncThunk(
  'calls/updateEntertainmentParticipant',
  async (data: ApiUpdateCallEntertainmentParticipant, { rejectWithValue, dispatch }) => {
    try {
      const call = await apiUpdateCallEntertainmentParticipant(data)
      return call
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null)
    }
  },
)

export const updateEntertainment = createAsyncThunk(
  'calls/updateCallEntertainment',
  async (data: ApiUpdateCallEntertainment, { rejectWithValue, dispatch }) => {
    try {
      const call = await apiUpdateCallEntertainment(data)
      return call
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null)
    }
  },
)

export const leaveCall = createAsyncThunk(
  'calls/leaveCall',
  async (data: ApiUpdateCallParticipant, { rejectWithValue, dispatch }) => {
    try {
      const call = await apiUpdateCallParticipant(data)
      return call
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null)
    }
  },
)

const setAllNotCurrent = (state) => {
  Object.keys(state.entities).forEach((id) => {
    if (state.entities[id][isCurrent]) state.entities[id][isCurrent] = false
  })
}

const hasAnotherCurrent = (state, action) => {
  const currentId = Object.keys(state.entities).find((id) => state.entities[id][isCurrent])
  return currentId ? currentId !== action.payload.id : false
}

const normalizeCalls = (calls: CallData[], hasAnotherCurrentCall, forceNoCurrent = false): CallNormalized[] =>
  calls.map((call) => ({
    ...call,
    [isNew]: false,
    [isCurrent]: !hasAnotherCurrentCall && isCallNotEndedOrCancelled(call) && !forceNoCurrent,
  }))

const callLoaded = (state, action) => {
  callsAdapter.addMany(state, normalizeCalls([action.payload], hasAnotherCurrent(state, action)))
  state.loading = 'finished'
}

const callAdded = (state, action) => {
  let anotherCurrent = false
  const currentId = Object.keys(state.entities).find((id) => state.entities[id][isCurrent])

  if (currentId && currentId !== action.payload.id) {
    anotherCurrent = true
    const currentCall = state.entities[currentId]

    if (currentCall && isCanceled(currentCall)) {
      state.entities[currentId][isCurrent] = false
      anotherCurrent = false
    }
  }

  callsAdapter.addMany(state, normalizeCalls([action.payload], anotherCurrent, !action.payload.isInstant))
  state.loading = 'finished'
}

const callsLoaded = (state, action) => {
  callsAdapter.setAll(state, normalizeCalls(action.payload, null, true))
  state.loading = 'finished'
}

const callsUpdated = (state, action) => {
  if (!action.payload) return
  ;(Array.isArray(action.payload) ? action.payload : [action.payload]).forEach(({ id, changes, ...rest }) => {
    if (state.ids.includes(id)) {
      state.entities[id] = {
        ...state.entities[id],
        ...(changes || rest),
      }
    }
  })
}

const callsSlice = createSlice({
  name: 'calls',
  initialState,
  reducers: {
    added: callAdded,
    updated: callsUpdated,
    deleteAll: (state) => {
      state.loading = 'idle'
      callsAdapter.removeAll(state)
    },
    deleteNewCalls: callsAdapter.removeMany,
    update: (state, action) => {
      action.payload.forEach(({ id, changes }) => {
        if (state.entities[id]) state.entities[id] = { ...(state.entities[id] as CallNormalized), ...changes }
      })
    },
    upsert: {
      reducer: callsAdapter.upsertMany,
      prepare: (calls: CallNormalized[]) => {
        return { payload: calls.map((call) => ({ ...call, [isNew]: true })) }
      },
    },
    setCallCurrent: (state, action) => {
      if (state.ids.includes(action.payload)) {
        state.entities[action.payload][isCurrent] = true
      } else {
        const id = state.ids?.find((id) => state.entities[id].callId === action.payload)
        if (id) state.entities[id][isCurrent] = true
      }
    },
    setCallNotCurrent: (state, action) => {
      const id = action.payload
      if (state.ids.includes(id)) state.entities[id][isCurrent] = false
    },
  },
  extraReducers: {
    [createCall.pending.toString()]: (state) => {
      state.loading = 'loading'
    },
    [getCall.pending.toString()]: (state) => {
      state.loading = 'loading'
    },
    [getAllCalls.pending.toString()]: (state) => {
      callsAdapter.removeAll(state)
      state.loading = 'loading'
    },
    [createCall.fulfilled.toString()]: callAdded,
    [getCall.fulfilled.toString()]: callLoaded,
    [createParticipant.fulfilled.toString()]: callsUpdated,
    [updateInvitation.fulfilled.toString()]: callsUpdated,
    [addCallEntertainment.fulfilled.toString()]: callsUpdated,
    [updateEntertainment.fulfilled.toString()]: callsUpdated,
    [updateEntertainmentParticipant.fulfilled.toString()]: callsUpdated,
    [getAllCalls.fulfilled.toString()]: callsLoaded,
    [leaveCall.fulfilled.toString()]: (state, action) => {
      setAllNotCurrent(state)
      callsUpdated(state, action)
    },
    [leaveCall.rejected.toString()]: setAllNotCurrent,
    [createCall.rejected.toString()]: (state) => {
      state.loading = 'finished'
    },
    [getCall.rejected.toString()]: (state) => {
      state.loading = 'finished'
    },
    [getAllCalls.rejected.toString()]: (state) => {
      state.loading = 'finished'
    },
  },
})

export const {
  deleteAll,
  update,
  upsert,
  deleteNewCalls,
  added,
  updated,
  setCallCurrent,
  setCallNotCurrent,
} = callsSlice.actions

const selectSelf = (state) => state
const callsSelectors = callsAdapter.getSelectors((state: CallsState) => state.calls)
export const selectAllCalls = (state: CallsState): any => callsSelectors.selectAll(state)
export const selectAllRecentCalls = createSelector(selectAllCalls, (calls) =>
  calls.filter((call) => call.isRecent && !call[isNew]),
)
export const selectAllScheduledCalls = createSelector(selectAllCalls, (calls) =>
  calls.filter((call) => call.isScheduled && !call.isRecent && !call.isInstant && !call[isNew]),
)

export const selectAllActiveCalls = (user) =>
  createSelector(selectAllCalls, (calls) =>
    calls.filter(
      (call) =>
        !call.isRecent &&
        ((call.isScheduled && call.canJoin) || call.isInstant) &&
        isCallNotEndedOrCancelled(call) &&
        !hasParticipantLeft(call, user) &&
        !isInvitationRejected(call, user) &&
        !call[isNew],
    ),
  )
export const selectCallById = (id: string) =>
  createSelector(selectAllCalls, (calls) => calls.find((call) => call.id === id))
export const selectCallByCallId = (callId: string) =>
  createSelector(selectAllCalls, (calls) => calls.find((call) => call.callId === callId))
export const selectHaveScheduledCall = createSelector(selectAllCalls, (calls) =>
  calls.some((call) => call.isScheduled && !call.isRecent && !call.isInstant),
)
export const selectCurrentCall = createSelector(selectAllCalls, (calls) => calls.find((call) => call[isCurrent]))

export const selectAreCallsLoading = createSelector(selectSelf, (state) => state.calls.loading === 'loading')
export const selectAreCallsLoaded = createSelector(selectSelf, (state) => state.calls.loading === 'finished')

export default callsSlice.reducer
