import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <circle cx="32" cy="32" r="32" fill="#8D53E9" />
    <line x1="49.7129" y1="32.4673" x2="14.976" y2="32.4673" stroke="white" strokeWidth="4" strokeLinecap="round" />
    <line x1="31.7246" y1="49.2163" x2="31.7246" y2="14.4795" stroke="white" strokeWidth="4" strokeLinecap="round" />
  </svg>
)
