import { FC, ReactElement, useCallback, useEffect, useMemo } from 'react'
import Button from 'components/button'
import { useForm } from 'react-hook-form'
import Text from 'components/text'
import { CloseIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import { useQueryParam, StringParam } from 'use-query-params'
import { callIdQueryParam } from 'const'
import { makeCall, getCallTitle, isNewCallMixed } from 'utils/calls'
import { deleteNewCalls, selectCallById, CallNormalized } from 'reducers/callsSlice'
import { connect, useDispatch } from 'react-redux'
import Logo from 'components/logo'
import Divider from 'components/divider'
import { ParticipantsList } from 'components/list'
import { ColumnsContainer, RowsContainer } from 'components/container'
import {
  StyledContactsWrapper,
  StyledLogoWrapper,
  StyledTitleContainer,
  StyledTextContainer,
  StyledButtonsWrapper,
  StyledContentWrapper,
  StyledContentContainer,
  StyledCloseButton,
  StyledHeaderContainer,
  StyledForm,
} from './style'

const { $ } = window

const StartGroupCall: FC<{ getCallById: (id: string) => CallNormalized; onClose: () => void }> = ({
  getCallById,
  onClose,
}): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [callIdParam, setCallIdParam] = useQueryParam(callIdQueryParam, StringParam)
  const { handleSubmit: handleSumbitForm } = useForm()

  const call = useMemo(() => (callIdParam ? getCallById(callIdParam) : null), [getCallById, callIdParam])

  const handleClose = useCallback(() => {
    onClose()
    if (callIdParam) dispatch(deleteNewCalls([callIdParam]))
    setCallIdParam(null, 'replaceIn')
  }, [callIdParam, dispatch, onClose, setCallIdParam])

  useEffect(() => {
    if (!callIdParam || !call) handleClose()
  }, [call, callIdParam, handleClose])

  const handleSubmit = async () => {
    handleClose()
    makeCall({ call, dispatch })
  }

  const [callTitle, restCount] = getCallTitle(call, 1)

  useEffect(() => {
    const el = $(`.start-group-call-send-invites-button-${callIdParam}`)?.[0]
    if (el) el.focus()
  }, [callIdParam])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Text component="h1" $ellipsis mt="0" color="brandblack" font="Poppins">
          {`${t('Call')}${callTitle ? ` ${t('With')} ${callTitle}${restCount ? ` + ${restCount}` : ''}` : ''}`}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledForm onSubmit={handleSumbitForm(handleSubmit)}>
        <StyledContentWrapper>
          {(!!call?.contacts?.length || !!call?.groups?.length) && (
            <RowsContainer>
              <StyledLogoWrapper>
                <Logo $small size="lg" />
              </StyledLogoWrapper>
              <ColumnsContainer>
                <StyledTitleContainer>
                  {isNewCallMixed(call) ? (
                    <Text size="md" gradient="radiance" fw="600" font="Poppins">
                      {t('NotAllGroupMembersAreClanzyUsersButTheyCanStillJoinYouOnThisCall')}
                    </Text>
                  ) : (
                    <Text size="md" gradient="radiance" fw="600" font="Poppins">
                      {t('GroupMembersAreNotYetAClanzyUsersButTheyCanStillJoinYouOnThisCall')}
                    </Text>
                  )}
                </StyledTitleContainer>
                <StyledTextContainer>
                  <Text color="black" cop="0.6" fw="500" $block>
                    {t('WeWillSendThemAnEmailInviteAndAllTheyNeedToDoIsClickALinkInTheEmail')}
                  </Text>
                </StyledTextContainer>
              </ColumnsContainer>
            </RowsContainer>
          )}
          <Divider $secondary cop="1" mt="1rem" />
          <StyledContactsWrapper>
            <ParticipantsList call={call} />
          </StyledContactsWrapper>
        </StyledContentWrapper>
        <StyledButtonsWrapper>
          <Button
            disabled={!call}
            $flat={!call}
            type="submit"
            size="md"
            bcolor="salem"
            hgradient="salem-dim-light"
            agradient="salem-dim-dark"
            bop="1"
            bophov="1"
            bopact="1"
            className={`start-group-call-send-invites-button-${callIdParam}`}
          >
            <Text size="md" $secondary fw="600">
              {isNewCallMixed(call) ? t('StartThisCall') : t('SendCallInviteEmailsAndStartTheCall')}
            </Text>
          </Button>
          <Button mt="0.5rem" size="md" subtle="true" onClick={handleClose}>
            <Text size="md" color="purplehearth" fw="600">
              {t('Cancel')}
            </Text>
          </Button>
        </StyledButtonsWrapper>
      </StyledForm>
    </StyledContentContainer>
  )
}

const mapStateToProps = (state) => ({
  getCallById: (callId: string) => selectCallById(callId)(state),
})

export default connect(mapStateToProps, null)(StartGroupCall)
