import { ReactElement } from 'react'
import { withTheme } from 'styled-components'

export default withTheme(
  ({ bcolor, theme: { fn, palette }, secondary, title }): ReactElement => {
    const color = (fn.color as (arg0: string) => string)(secondary ? palette.secondary.main : palette.primary.main)
    return (
      <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        {bcolor !== 'transparent' && <circle cx="24.5" cy="24" r="24" fill={bcolor || color} fillOpacity="0.38" />}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.4143 13H34.7857C35.1802 13 35.5 13.3198 35.5 13.7143V27.0857C35.5 27.4802 35.1802 27.8 34.7857 27.8H31.3V20.9142C31.3 18.8629 29.6371 17.2 27.5857 17.2H20.7V13.7143C20.7 13.3198 21.0198 13 21.4143 13ZM17.7 17.2V13.7143C17.7 11.6629 19.363 10 21.4143 10H34.7857C36.8371 10 38.5 11.6629 38.5 13.7143V27.0857C38.5 29.1371 36.8371 30.8 34.7857 30.8H31.3V34.2857C31.3 36.337 29.6371 38 27.5857 38H14.2143C12.1629 38 10.5 36.337 10.5 34.2857V20.9142C10.5 18.8629 12.1629 17.2 14.2143 17.2H17.7ZM28.3 30.8V34.2857C28.3 34.6802 27.9802 35 27.5857 35H14.2143C13.8198 35 13.5 34.6802 13.5 34.2857V20.9142C13.5 20.5197 13.8198 20.2 14.2143 20.2H17.7V27.0857C17.7 29.1371 19.363 30.8 21.4143 30.8H28.3ZM28.3 27.8H21.4143C21.0198 27.8 20.7 27.4802 20.7 27.0857V20.2H27.5857C27.9802 20.2 28.3 20.5197 28.3 20.9142V27.8Z"
          fill={color}
          fillOpacity="0.8"
        />
      </svg>
    )
  },
)
