/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { GuardianRequestStatuses } from 'apis'

const isNotification = Symbol.for('isNotification')

export const isNotNotification = (guardianRequest) => {
  return !guardianRequest[isNotification]
}

export const getOnePending = (guardianRequests) => {
  return guardianRequests?.find((guardianRequest) => guardianRequest.status === GuardianRequestStatuses.pending)
}

export const isPending = (guardianRequest) => {
  return guardianRequest.status === GuardianRequestStatuses.pending
}

export const isRejected = (guardianRequest) => {
  return guardianRequest.status === GuardianRequestStatuses.rejected
}
