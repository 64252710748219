/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createAsyncThunk, createEntityAdapter, createSlice, createSelector, EntityState } from '@reduxjs/toolkit'
import {
  apiGetAllGroups,
  GroupData,
  apiPostGroups,
  ApiPostGroups,
  apiGetManagedGroups,
  ApiGetManagedGroups,
  apiAddManagedGroups,
  ApiAddManagedGroups,
  ApiPostManagedGroups,
  apiPostManagedGroups,
} from 'apis'
import { error } from 'react-toastify-redux'
import { ContactNormalized } from './contactsSlice'

interface Group {
  id?: string
  name?: string
  description?: string
  photo?: string
  contacts?: ContactNormalized[]
}

const isNew = Symbol.for('isNew')
const isSelected = Symbol.for('isSelected')

export interface GroupNormalized extends Group {
  [isNew]?: boolean
  managedUserId?: string
}

interface GroupsState {
  groups: EntityState<GroupNormalized>
}

const groupsAdapter = createEntityAdapter({
  sortComparer: (a: Group, b: Group) => a.name?.localeCompare(b.name),
})
const initialState = groupsAdapter.getInitialState([])

const normalizeUsersGroups = (groups: Group[]) => {
  const groupsNormalized = groups.map((group) => ({
    ...group,
    [isNew]: typeof group[isNew] !== 'undefined' ? group[isNew] : false,
  }))
  return groupsNormalized
}

const groupsAdded = (state, action) => groupsAdapter.addMany(state, normalizeUsersGroups(action.payload))

const groupsUpdated = (state, action) => {
  if (!action.payload) return
  ;(Array.isArray(action.payload) ? action.payload : [action.payload]).forEach(({ id, changes, ...rest }) => {
    if (state.ids.includes(id)) {
      state.entities[id] = {
        ...state.entities[id],
        ...(changes || rest),
      }
    }
  })
}

export const getAllGroups = createAsyncThunk('groups/getAll', async (_, { rejectWithValue, dispatch }) => {
  try {
    return await apiGetAllGroups()
  } catch (e) {
    dispatch(error(e.message))
    return rejectWithValue(null)
  }
})

export const createGroups = createAsyncThunk(
  'groups/create',
  async (data: ApiPostGroups, { rejectWithValue, dispatch }): Promise<GroupData[] | null> => {
    try {
      return await apiPostGroups(data)
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null) as null
    }
  },
)

export const getManagedGroups = createAsyncThunk(
  'groups/getManaged',
  async ({ id: userId }: ApiGetManagedGroups, { dispatch, rejectWithValue }) => {
    try {
      return await apiGetManagedGroups(userId)
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null)
    }
  },
)

export const addManagedGroups = createAsyncThunk(
  'groups/addManaged',
  async (data: ApiAddManagedGroups, { dispatch, rejectWithValue }): Promise<Group[] | null> => {
    try {
      const res = await apiAddManagedGroups(data)
      return res
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null) as null
    }
  },
)

export const createManagedGroups = createAsyncThunk(
  'groups/createManaged',
  async (data: ApiPostManagedGroups, { dispatch, rejectWithValue }): Promise<Group[] | null> => {
    try {
      return await apiPostManagedGroups(data)
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null) as null
    }
  },
)

const removeAuthUserGroups = (state) => {
  const prevAuthUserGroupsIds = state.ids?.filter((id) => {
    return !(state.entities[id] as GroupNormalized).managedUserId
  })
  if (prevAuthUserGroupsIds?.length) groupsAdapter.removeMany(state, prevAuthUserGroupsIds)
}

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    added: groupsAdapter.addMany,
    deleteGroups: groupsAdapter.removeMany,
    update: (state, action) => {
      action.payload.forEach(({ id, changes }) => {
        if (state.entities[id]) state.entities[id] = { ...state.entities[id], ...changes }
      })
    },
    updated: groupsUpdated,
    upsert: {
      reducer: groupsAdapter.upsertMany,
      prepare: (groups: GroupNormalized[]) => {
        return { payload: groups.map((group) => ({ ...group, [isNew]: true })) }
      },
    },
    setSelected: (state, action) => {
      const { id: groupId, replace } = action.payload
      if (!state.ids.includes(groupId)) return
      if (replace) {
        state.ids.forEach((id) => {
          state.entities[id][isSelected] = (state.entities[id] as GroupNormalized).id === groupId
        })
      } else {
        state.entities[groupId][isSelected] = true
      }
    },
    unselectAll: (state) => {
      state.ids.forEach((id) => {
        state.entities[id][isSelected] = false
      })
    },
  },
  extraReducers: {
    [getAllGroups.pending.toString()]: removeAuthUserGroups,
    [getManagedGroups.pending.toString()]: (state, action) => {
      const prevManagedUserGroupsIds = state.ids?.filter((id) => {
        return (state.entities[id] as GroupNormalized).managedUserId === action.meta.arg.id
      })
      if (prevManagedUserGroupsIds?.length) groupsAdapter.removeMany(state, prevManagedUserGroupsIds)
    },
    [getAllGroups.fulfilled.toString()]: groupsAdded,
    [createGroups.fulfilled.toString()]: groupsAdded,
    [addManagedGroups.fulfilled.toString()]: groupsAdded,
    [createManagedGroups.fulfilled.toString()]: groupsAdded,
    [getManagedGroups.fulfilled.toString()]: groupsAdded,
  },
})

export const { added, deleteGroups, update, updated, upsert, setSelected, unselectAll } = groupsSlice.actions

const groupsSelectors = groupsAdapter.getSelectors((state: GroupsState) => state.groups)
export const selectAllGroups = (state: GroupsState): GroupNormalized[] =>
  groupsSelectors.selectAll(state) as GroupNormalized[]

export const selectAllAuthUserGroups = createSelector(selectAllGroups, (groups) =>
  groups.filter((group) => !group.managedUserId),
)
export const selectGroupById = (id: string) =>
  createSelector(selectAllGroups, (groups) => groups.find((group) => group.id === id))

export const selectAllNotNewGroups = createSelector(selectAllAuthUserGroups, (groups) =>
  groups.filter((group) => !group[isNew]),
)
export const selectNotNewGroupsByManagedUserId = (id: string) =>
  createSelector(selectAllGroups, (groups) => groups.filter((group) => group.managedUserId === id && !group[isNew]))

export default groupsSlice.reducer
