import { ReactElement } from 'react'

export default ({ color, title }: { color: string; title: string }): ReactElement => (
  <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <ellipse cx="33.7252" cy="19.3163" rx="6.36585" ry="7.27526" fill={color} fillOpacity="0.4" />
    <ellipse cx="17.8112" cy="15.7351" rx="7.58853" ry="8.67261" fill={color} fillOpacity="0.4" />
    <path
      d="M31.9047 37.7267C31.9047 43.8849 25.5951 43.9214 17.8117 43.9214C10.0284 43.9214 3.71875 43.8849 3.71875 37.7267C3.71875 31.5684 10.0284 26.5762 17.8117 26.5762C25.5951 26.5762 31.9047 31.5684 31.9047 37.7267Z"
      fill={color}
      fillOpacity="0.4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.3824 42.9645C33.4965 42.9646 33.6111 42.9646 33.726 42.9646C40.2553 42.9646 45.5483 42.934 45.5483 37.768C45.5483 32.6019 40.2553 28.4141 33.726 28.4141C32.8261 28.4141 31.9497 28.4936 31.1074 28.6443C33.4406 31.0449 34.8978 34.1404 34.8978 37.7286C34.8978 39.6643 34.4385 41.4575 33.3824 42.9645Z"
      fill={color}
      fillOpacity="0.4"
    />
  </svg>
)
