/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components'

export interface FormFieldSelectProps {
  /**
   * Items
   */
  items: any
  /**
   * The name of the field
   */
  name: string
  /**
   * Value
   */
  value: string
  /**
   * The for field label
   */
  label?: string
  /**
   * The label when there are no items
   */
  emptyLabel?: string
  /**
   * On field change
   */
  onChange?: (id: string) => void
}

export const StyledWrapper = styled.div`
  width: 100%;
`

export const StyledSelectedItemWrapper = styled.div`
  position: relative;
  display: flex;
`

export const StyledIconDropdownWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
`

export const StyledFormField = styled.input.attrs((props) => ({
  id: props.id,
  type: props.type,
  tabIndex: 0,
}))`
  flex-grow: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transform: none;
  font-family: ${({ theme }) => theme.fonts.Inter};
  color: ${({ theme: { fn, palette } }) => fn.color(palette.text.primary)};
  font-size: 1.125rem;
  padding: 1rem;
  border-radius: 0.375rem;
  border: 2px solid ${({ theme: { fn, colors } }) => fn.color(colors.black, '0.2')};
  background-color: transparent;
  display: block;
  outline: none;
  display: inline-block;
  margin: 0.4rem 0;

  &:hover {
    transition: border-color 0.1s linear;
    border-color: ${({ theme: { fn, colors } }) => fn.color(colors.brandblack)};
  }

  &:focus,
  &:active {
    transition: none;
    border-color: ${({ theme: { fn, colors } }) => fn.color(colors.mediumpurple)};
  }
`

export const StyledFormFieldLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.Inter};
  color: ${({ theme: { fn, palette } }) => fn.color(palette.text.primary, '0.6')};
  transform: none;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 4%;
  margin-top: 1.5rem;

  input:-webkit-autofill::first-line {
    font-size: 0.875rem;
  }
`
