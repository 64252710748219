import { ReactElement, FC } from 'react'
import { withTheme } from 'styled-components'
import Button from 'components/button'
import { Theme, InfoIcon } from 'theme'

export interface InfoButtonProps {
  /**
   * On click handler
   */
  onClick?: () => void
  /**
   * Theme
   */
  theme: Theme
  /**
   * Icon title
   */
  title: string
  /**
   * Margin left
   */
  ml?: string
}

const InfoButton: FC<InfoButtonProps> = ({ ml, theme, title, onClick }): ReactElement => {
  const getColor = theme.fn.color as (arg0: string, arg1?: string) => string
  const color = getColor(theme.colors.white)
  const component = InfoIcon({ color, title })

  return (
    <Button bcolor="transparent" ml={ml} size="xs" $flat circular="true" borcolor="white" onClick={onClick}>
      {component}
    </Button>
  )
}

export default withTheme(InfoButton)
