/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement, useLayoutEffect, useEffect, useMemo, useState, useCallback } from 'react'
import { SearchInput } from 'components/form'
import Text from 'components/text'
import { BackIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import { createFilter } from 'react-search-input'
import { selectAllAuthUserGroups, GroupNormalized, addManagedGroups } from 'reducers/groupsSlice'
import { GroupListItem } from 'components/list'
import { useQueryParam, StringParam } from 'use-query-params'
import { managedUserIdQueryParam } from 'const'
import { connect, useDispatch } from 'react-redux'
import { ContentContainer } from 'components/container'
import GroupAvatar from 'components/avatar/group'
import { sortContactsAndGroups } from 'utils'
import {
  SearchInputWrapper,
  StyledGroupsControlsWrapper,
  StyledContentWrapper,
  StyledGroupsWrapper,
  StyledButton,
  StyledRowsContainer,
  StyledListColumn,
  StyledListRow,
  StyledVerticalScrollbar,
  StyledHorizontalScrollbar,
  StyledHeaderContainer,
  StyledDoneButton,
  StyledAvatarWrapper,
  StyledSelectedGroupsWrapper,
} from './style'

const { $ } = window

const AddManagedUserGroups = ({ groups, onClose }): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [managedUserIdParam] = useQueryParam(managedUserIdQueryParam, StringParam)
  const [searchInputFocused, setSearchInputFocused] = useState(false)
  const [scrollTop, setScrollTop] = useState(false)

  useLayoutEffect(() => {
    if (!managedUserIdParam) onClose()
  })

  const [selected, setSelected] = useState([])

  const [hovered, setHovered] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [filtered, setFiltered] = useState([])

  const selectedIds = useMemo(() => selected.map(({ id }) => id), [selected])

  const focusAddManagedUserGroupsSearch = useCallback(() => {
    const el = $(`.search-groups-${managedUserIdParam} input`)?.[0]
    if (el) el.focus()
  }, [managedUserIdParam])

  useEffect(() => {
    focusAddManagedUserGroupsSearch()
  }, [focusAddManagedUserGroupsSearch])

  const searchUpdated = (str) => setSearchTerm(str)
  const onScroll = ({ srcElement }) => {
    if ((srcElement.scrollTop && !scrollTop) || (!srcElement.scrollTop && scrollTop)) {
      setScrollTop(!!srcElement.scrollTop)
    }
  }

  const handleBackClick = () => {
    onClose()
  }

  const handleMouseEnter = ({ id }) => {
    setHovered(id)
  }

  const handleMouseLeave = () => {
    setHovered(null)
  }

  const handleSelectedAvatarClose = (selId) => {
    setSelected((prevSelected) => prevSelected.filter(({ id }) => id !== selId))

    focusAddManagedUserGroupsSearch()
  }

  const handleClick = (item) => {
    setSelected((prevSelected) => {
      if (prevSelected.map(({ id }) => id).includes(item.id)) {
        return prevSelected.filter(({ id }) => id !== item.id)
      }
      return [...prevSelected, item]
    })

    focusAddManagedUserGroupsSearch()
  }

  const handleDone = async () => {
    await dispatch(
      addManagedGroups({
        userId: managedUserIdParam,
        groupIds: selectedIds,
      }),
    )
    onClose()
  }

  useEffect(() => {
    if (searchTerm)
      setFiltered(
        [
          ...(groups || []).filter(
            createFilter(searchTerm, [
              'name',
              'description',
              'contacts.displayName',
              'contacts.email',
              'contacts.phone',
            ]),
          ),
        ].sort(sortContactsAndGroups),
      )
    else setFiltered([...(groups || [])].sort(sortContactsAndGroups))
  }, [groups, searchTerm])

  return (
    <ContentContainer>
      <StyledHeaderContainer component="header">
        <StyledButton circular="true" subtle="true" onClick={handleBackClick}>
          <BackIcon title={t('GoBack')} />
        </StyledButton>
        <Text component="h1" mt="0" color="brandblack" font="Poppins">
          {t('AddGroups')}
        </Text>
        <StyledDoneButton
          disabled={!selected.length}
          $flat={!selected.length}
          onClick={handleDone}
          type="submit"
          size="md"
          bcolor="mediumpurple"
          bop="1"
          ml="1rem"
          hgradient="mediumpurple-dim-light"
          agradient="mediumpurple-dim-dark"
        >
          <Text size="md" $secondary fw="600">
            {t('Add')}
          </Text>
        </StyledDoneButton>
      </StyledHeaderContainer>
      <StyledContentWrapper>
        <StyledGroupsControlsWrapper shadow={scrollTop}>
          <SearchInputWrapper $focused={searchInputFocused}>
            <SearchInput
              className={`search-groups-${managedUserIdParam}`}
              $secondary
              $interactive
              onFocus={setSearchInputFocused}
              placeholder={t('ChooseFromTheListOrTypeToFilter')}
              onChange={searchUpdated}
            />
          </SearchInputWrapper>
          {!!selected.length && (
            <StyledSelectedGroupsWrapper>
              <StyledHorizontalScrollbar options={{ overflowBehavior: { y: 'scroll' }, className: 'os-theme-light' }}>
                <StyledListRow>
                  {selected.map((item) => (
                    <StyledAvatarWrapper key={item.id}>
                      <GroupAvatar
                        $secondary
                        group={item}
                        onClose={handleSelectedAvatarClose}
                        name={(item as GroupNormalized).name}
                      />
                    </StyledAvatarWrapper>
                  ))}
                </StyledListRow>
              </StyledHorizontalScrollbar>
            </StyledSelectedGroupsWrapper>
          )}
        </StyledGroupsControlsWrapper>
        <StyledGroupsWrapper>
          <StyledVerticalScrollbar options={{ callbacks: { onScroll }, className: 'os-theme-light' }}>
            <StyledRowsContainer>
              <Text size="lg" color="brandblack" cop="0.6" fw="600">
                {t('GroupsInYourAddressBook')}
              </Text>
            </StyledRowsContainer>
            <StyledListColumn>
              {filtered.map((item) => (
                <GroupListItem
                  $secondary
                  key={item.id}
                  group={item}
                  $hovered={item.id === hovered}
                  $selected={selectedIds.includes(item.id)}
                  title={`${t('Select')} ${(item as GroupNormalized).name}`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={handleClick}
                  $noPresence
                />
              ))}
            </StyledListColumn>
          </StyledVerticalScrollbar>
        </StyledGroupsWrapper>
      </StyledContentWrapper>
    </ContentContainer>
  )
}

const mapStateToProps = (state) => ({
  groups: selectAllAuthUserGroups(state),
})

export default connect(mapStateToProps, null)(AddManagedUserGroups)
