/* eslint-disable @typescript-eslint/no-explicit-any */
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { ArrowUpIcon, ArrowDownIcon } from 'theme'
import { useEffect, useRef, useState } from 'react'
import {
  StyledWrapper,
  FormFieldSelectProps,
  StyledFormFieldLabel,
  StyledFormField,
  StyledSelectedItemWrapper,
  StyledIconDropdownWrapper,
} from './style'

export default ({
  items,
  label,
  emptyLabel,
  name,
  value,
  onChange,
  ...rest
}: FormFieldSelectProps): React.ReactElement => {
  const anchorEl = useRef(null)
  const [inputValue, setinputValue] = useState('')
  const [openDropdown, setOpenDropdown] = useState(false)

  useEffect(() => {
    setinputValue(items?.find(({ id }) => id === value)?.label || '')
  }, [items, value])

  const handleClickDropdown = (isOpened) => (e) => {
    e.preventDefault()
    setOpenDropdown(isOpened)
    return false
  }

  const handleClose = (e) => {
    e.preventDefault()
    setOpenDropdown(false)
  }

  const handleCloseDropdown = (e) => {
    e.preventDefault()
    setOpenDropdown(false)
    if (onChange) onChange(e.target.id)
  }

  return (
    <StyledWrapper ref={anchorEl} {...rest}>
      {!!label && !!items.length && <StyledFormFieldLabel htmlFor={name}>{label}</StyledFormFieldLabel>}
      {!!emptyLabel && !items.length && <StyledFormFieldLabel htmlFor={name}>{emptyLabel}</StyledFormFieldLabel>}
      <StyledSelectedItemWrapper>
        <StyledFormField name={name} value={inputValue} readOnly />
        {items.length > 1 && (
          <StyledIconDropdownWrapper>
            {openDropdown ? (
              <ArrowUpIcon onClick={handleClickDropdown(false)} />
            ) : (
              <ArrowDownIcon onClick={handleClickDropdown(true)} />
            )}
          </StyledIconDropdownWrapper>
        )}
      </StyledSelectedItemWrapper>
      {!!items.length && items.length > 1 && (
        <Menu anchorEl={anchorEl.current} keepMounted open={openDropdown} onClose={handleClose}>
          {items.map((item) => (
            <MenuItem key={item.id} id={item.id} selected={item.id === value} onClick={handleCloseDropdown}>
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </StyledWrapper>
  )
}
