import styled from 'styled-components'
import { ContentContainer } from 'components/container'
import Form from 'components/form'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import List from 'components/list'
import Text from 'components/text'

export const StyledForm = styled(Form)`
  width: 100%;

  ${({ theme }) => theme.fn.down(theme.breakpoints.md)} {
    margin-top: 0.5rem;
  }
`

export const StyledParticipantsWrapper = styled(ContentContainer)`
  width: 100%;
`

export const StyledText = styled(Text)`
  text-align: left;
  width: 100%;
`

export const StyledNoScrollWrapper = styled.div`
  width: 100%;
`

export const StyledVerticalScrollbar = styled(OverlayScrollbarsComponent).attrs((props) => ({
  ...props,
  resize: 'vertical',
}))`
  height: 13rem;
  width: 100%;
  overflow-x: hidden;
  margin-right: 0.3rem;

  &.os-host-scrollbar-vertical-hidden .os-content > section {
    margin-right: 0 !important;
  }

  &.os-host-scrollbar-vertical-hidden {
    margin-right: 0 !important;
  }

  .os-scrollbar-horizontal {
    display: none;
  }
`

export const StyledListColumn = styled(List)`
  flex-direction: column;
  margin-right: 1rem;
  width: auto;
`
