/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement, useEffect, useMemo } from 'react'
import Text from 'components/text'
import Button from 'components/button'
import { useTranslation } from 'react-i18next'
import { selectUserById } from 'reducers/usersSlice'
import { modalQueryParam, isNewQueryParam, managedUserIdQueryParam, modalUpdateManagedUserQueryParam } from 'const'
import { useQueryParam, StringParam, BooleanParam } from 'use-query-params'
import { CloseIcon } from 'theme'
import { connect } from 'react-redux'
import {
  StyledButtonsWrapper,
  StyledContentContainer,
  StyledTextContainer,
  StyledCloseButton,
  StyledHeaderContainer,
} from './style'

const { $ } = window

const UpdateManagedUserInfo = ({ getUserById, onClose }): ReactElement => {
  const { t } = useTranslation()
  const [, setModalParam] = useQueryParam(modalQueryParam, StringParam)
  const [managedUserIdParam, setManagedUserIdQueryParam] = useQueryParam(managedUserIdQueryParam, StringParam)
  const [, setIsNew] = useQueryParam(isNewQueryParam, BooleanParam)

  const user = useMemo(() => (managedUserIdParam ? getUserById(managedUserIdParam) : null), [
    getUserById,
    managedUserIdParam,
  ])

  const handleClose = () => {
    setManagedUserIdQueryParam(null, 'replaceIn')
    onClose()
  }

  useEffect(() => {
    if (!managedUserIdParam) handleClose()
  })

  const handleNext = () => {
    setIsNew(true, 'replaceIn')
    setModalParam(modalUpdateManagedUserQueryParam, 'replaceIn')
  }

  useEffect(() => {
    const el = $('.update-managed-user-info-next-button')?.[0]
    if (el) el.focus()
  }, [])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledTextContainer>
        <Text color="brandblack" fw="600" font="Poppins">
          {t('OnTheNextScreenYouCanOptionallyAddAProfilePhotoFor')}&nbsp;
        </Text>
        <Text color="mediumpurple" fw="600" font="Poppins">
          {user?.firstName}&nbsp;
        </Text>
        <Text color="mediumpurple" fw="600" font="Poppins">
          {user?.lastName}&nbsp;
        </Text>
        <Text color="brandblack" fw="600" font="Poppins">
          {t('AndPrePopulateTheirContactsListForThem')}&nbsp;
        </Text>
      </StyledTextContainer>
      <StyledButtonsWrapper>
        <Button size="md" subtle="true" onClick={handleClose}>
          <Text size="md" color="purplehearth" fw="600">
            {t('SkipForNow')}
          </Text>
        </Button>
        <Button
          size="md"
          bcolor="mediumpurple"
          bop="1"
          ml="1rem"
          hgradient="mediumpurple-dim-light"
          agradient="mediumpurple-dim-dark"
          onClick={handleNext}
          className="update-managed-user-info-next-button"
        >
          <Text size="md" $secondary fw="600">
            {t('Continue')}
          </Text>
        </Button>
      </StyledButtonsWrapper>
    </StyledContentContainer>
  )
}

const mapStateToProps = (state) => ({
  getUserById: (userId: string) => selectUserById(userId)(state),
})

export default connect(mapStateToProps, null)(UpdateManagedUserInfo)
