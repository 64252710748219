import { ReactElement, FC } from 'react'
import { withTheme } from 'styled-components'
import Button from 'components/button'
import { Theme, CallIcon, CallClanzyIcon } from 'theme'

export interface CallButtonProps {
  /**
   * On click handler
   */
  onClick?: () => void
  /**
   * Should use Video call Clanzy icon
   */
  clanzy: boolean
  /**
   * Is active
   */
  $active: boolean
  /**
   * Is disabled
   */
  disabled?: boolean
  /**
   * No hover or active states
   */
  $flat?: boolean
  /**
   * Theme
   */
  theme: Theme
  /**
   * Icon title
   */
  title: string
}

const CallButton: FC<CallButtonProps> = ({ disabled, $flat, theme, title, onClick, clanzy, $active }): ReactElement => {
  const getColor = theme.fn.color as (arg0: string, arg1?: string) => string
  const color = $active && !$flat ? getColor(theme.colors.white) : getColor(theme.colors.white, '0.38')
  const component = clanzy ? CallClanzyIcon({ color, title }) : CallIcon({ color, title })

  return (
    <Button
      size="lg"
      circular="true"
      disabled={disabled}
      $flat={$flat}
      bcolorsel="malachite"
      hgradient="malachite-dim-light"
      agradient="malachite-dim-dark"
      selected={$active}
      bcolor="white"
      bop="0.05"
      onClick={onClick}
    >
      {component}
    </Button>
  )
}

export default withTheme(CallButton)
