import { ReactElement } from 'react'
import { withTheme } from 'styled-components'

export default withTheme(
  ({ theme, $noSuccess = false, title }): ReactElement => {
    const color = (theme.fn.color as (arg0: string) => string)($noSuccess ? theme.colors.tallpoppy : theme.colors.white)
    const cop = $noSuccess ? '1' : '0.4'
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <g opacity={cop}>
          <path d="M6.89286 17.6074L18.5 6.00028" stroke={color} strokeWidth="2" strokeLinecap="round" />
          <path
            d="M14.9277 18.5L6.49916 18.5C6.22302 18.5 5.99916 18.2761 5.99916 18L5.99916 9.57143"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </g>
      </svg>
    )
  },
)
