import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <circle cx="24" cy="24" r="24" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.189 15.4273C9.24254 17.4347 9.19432 19.46 9.09788 23.5106C9.09009 23.8379 9.08594 24.168 9.08594 24.5C9.08594 24.8056 9.08945 25.1096 9.09609 25.4111C9.186 29.4988 9.23096 31.5427 11.1746 33.5537C13.1183 35.5648 15.2709 35.6856 19.5761 35.9272C20.3924 35.9731 21.2047 36 21.9862 36C22.7822 36 23.6101 35.972 24.4418 35.9247C28.7183 35.681 30.8565 35.5592 32.798 33.5495C34.044 32.2597 34.5091 30.9625 34.7063 29.1267L35.0859 29.3571L39.0859 31.7857C39.9634 32.3185 41.0859 31.6868 41.0859 30.6602V18.3398C41.0859 17.3132 39.9634 16.6815 39.0859 17.2143L35.0859 19.6429L34.7039 19.8748C34.5067 18.0298 34.0425 16.73 32.784 15.4315C30.8397 13.4254 28.7336 13.3085 24.5215 13.0748C23.6741 13.0277 22.8206 13 21.9862 13C21.1666 13 20.3286 13.0267 19.4961 13.0723C15.2557 13.3041 13.1354 13.42 11.189 15.4273Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M16.5 23.9656L20.6472 28.125L28.1875 20.5625"
      stroke="white"
      strokeWidth="3"
      strokeMiterlimit="3.99933"
      strokeLinecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="15.0162"
        y1="41.7949"
        x2="39.7963"
        y2="20.8358"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6BF4F" />
        <stop offset="1" stopColor="#FF00E7" />
      </linearGradient>
    </defs>
  </svg>
)
