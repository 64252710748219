import { ReactElement, useEffect, useLayoutEffect, useMemo } from 'react'
import Text from 'components/text'
import { useTranslation } from 'react-i18next'
import Button from 'components/button'
import Divider from 'components/divider'
import { useQueryParam, StringParam } from 'use-query-params'
import { CloseIcon } from 'theme'
import { connect } from 'react-redux'
import { guardianRequestIdQueryParam } from 'const'
import { selectGuardianRequestById } from 'reducers/guardianRequestsSlice'
import {
  StyledTitleContainer,
  StyledContentContainer,
  StyledTextContainer,
  StyledDescriptionContainer,
  StyledCloseButton,
  StyledHeaderContainer,
} from './style'

const { $ } = window

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const GuardianRequestAcceptedModal = ({ getGuardianRequestById, onClose }): ReactElement => {
  const { t } = useTranslation()
  const [guardianRequestIdParam, setGuardianRequestIdParam] = useQueryParam(guardianRequestIdQueryParam, StringParam)

  const guardianRequest = useMemo(
    () => (guardianRequestIdParam ? getGuardianRequestById(guardianRequestIdParam) : null),
    [getGuardianRequestById, guardianRequestIdParam],
  )

  const handleClose = () => {
    setGuardianRequestIdParam(null, 'replaceIn')
    onClose()
  }

  useLayoutEffect(() => {
    if (!guardianRequestIdParam) handleClose()
  })

  useEffect(() => {
    const el = $('.guardian-tools-request-accepted-done-button')?.[0]
    if (el) el.focus()
  }, [])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledTitleContainer>
        <Text size="xl" gradient="purple" fw="600" font="Poppins" $block>
          {t('Success')}
        </Text>
      </StyledTitleContainer>
      <StyledTextContainer>
        <StyledDescriptionContainer>
          <Text size="md" fw="600" color="brandblack">
            {t('YourClanzyAccountIsNowManagedBy')}&nbsp;{guardianRequest?.from.displayName}
          </Text>
        </StyledDescriptionContainer>
        <Divider mt="2rem" />
        <Text mt="2rem" color="black" fw="500" $block>
          {t('YouCanRevoke')}&nbsp;{guardianRequest?.from.displayName}&nbsp;
          {t('AccessFromYourAccountAtAnyTimeByGoingToGuardianTools')}
        </Text>
      </StyledTextContainer>
      <Button
        size="md"
        bcolor="mediumpurple"
        bop="1"
        mt="3rem"
        hgradient="mediumpurple-dim-light"
        agradient="mediumpurple-dim-dark"
        onClick={handleClose}
        className="guardian-tools-request-accepted-done-button"
      >
        <Text size="md" $secondary fw="600">
          {t('Done')}
        </Text>
      </Button>
    </StyledContentContainer>
  )
}

const mapStateToProps = (state) => ({
  getGuardianRequestById: (id: string) => selectGuardianRequestById(id)(state),
})

export default connect(mapStateToProps, null)(GuardianRequestAcceptedModal)
