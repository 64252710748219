/* eslint-disable import/prefer-default-export */
import axios from 'axios'
import { apiUrl } from 'const'
import { responseInterceptorError } from 'utils/apis'

export interface ApiSignUp {
  username: string
  password: string
  firstName: string
  lastName: string
}

export interface ApiSignIn {
  username: string
  password: string
}

export interface ApiForgottenPassword {
  email: string
}

export interface ApiResetPassword {
  password: string
  resetToken: string
}

export interface ApiSetPassword {
  password: string
  resetToken: string
}

const instance = axios.create({
  baseURL: `${apiUrl}/auth`,
})

instance.interceptors.response.use(undefined, responseInterceptorError)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const apiSignUp = async (data: ApiSignUp) => {
  const res = await instance({
    url: '/signup',
    method: 'POST',
    data,
  })
  return res.data
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const apiSignIn = async (data: ApiSignIn) => {
  const res = await instance({
    url: '/signin',
    method: 'POST',
    data,
  })

  return res.data
}

export const apiForgottenPassword = async (data: ApiForgottenPassword): Promise<void> => {
  await instance({
    url: `/reset-password`,
    method: 'POST',
    data,
  })
}

export const apiResetPassword = async (data: ApiResetPassword): Promise<void> => {
  await instance({
    url: `/new-password`,
    method: 'POST',
    data,
  })
}

export const apiSetPassword = async (data: ApiSetPassword): Promise<void> => {
  await instance({
    url: `/set-password`,
    method: 'POST',
    data,
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const apiCheckEmail = async (email: string): Promise<any> => {
  return instance({
    url: `/check-email?email=${email}`,
    method: 'GET',
  })
}
