import { ReactElement } from 'react'

// eslint-disable-next-line react/require-default-props
export default ({ size = 'md' }: { size?: string }): ReactElement =>
  size === 'md' ? (
    <svg width="182" height="12" viewBox="0 0 182 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 10.0001C32.8333 6.00006 112.5 -0.799945 180.5 4.00006"
        stroke="url(#paint0_linear)"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="34.6723"
          y1="11.9277"
          x2="35.842"
          y2="-4.70794"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6BF4F" />
          <stop offset="1" stopColor="#FF00E7" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg width="183" height="14" viewBox="0 0 183 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.06597 11.9587C33.3375 7.50098 112.896 -0.46318 180.959 3.34201"
        stroke="url(#paint0_linear)"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="-70.9291"
          y1="12.9838"
          x2="213.213"
          y2="8.82864"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0099FF" />
          <stop offset="0.739035" stopColor="#CC00B9" />
          <stop offset="1" stopColor="#FF00E7" />
        </linearGradient>
      </defs>
    </svg>
  )
