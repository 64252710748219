/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import { ColumnsContainer, RowsContainer } from 'components/container'

export const StyledNotificationContent = styled(ColumnsContainer)`
  width: 100%;
  max-height: 40%;
  flex-grow: 1;
  justify-content: center;
`

export const StyledLogoWrapper = styled.div`
  padding-top: 2.5rem;

  ${({ theme }) => theme.fn.down(theme.breakpoints.md)} {
    padding-top: 1rem;
  }
`

export const StyledNotificationContainer = styled(ColumnsContainer)`
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  padding-top: 2.5rem;
  align-items: center;
  width: 100%;
`

export const StyledIconWrapper = styled(RowsContainer)`
  width: 40%;
  align-items: flex-start;
`
