import { FC, ReactElement, useCallback, useLayoutEffect, useMemo } from 'react'
import Text from 'components/text'
import { isClanzyUser } from 'utils/contacts'
import { getAvatarBorColor, getAvatarBorOp } from 'utils'
import { CloseIcon } from 'theme'
import Avatar from 'components/avatar'
import { useQueryParam, StringParam } from 'use-query-params'
import { modalEditContactQueryParam, contactIdQueryParam, modalQueryParam, callIdQueryParam } from 'const'
import CallButton from 'components/button/call'
import { useTranslation } from 'react-i18next'
import { ContactNormalized, selectContactById } from 'reducers/contactsSlice'
import { connect, useDispatch } from 'react-redux'
import { RowsContainer } from 'components/container'
import { makeCall } from 'utils/calls'
import { StyledContentWrapper, StyledContentContainer, StyledCloseButton, StyledHeaderContainer } from './style'

const ContactInfoModal: FC<{
  getContactById: (contactId: string) => ContactNormalized
  onClose: () => void
}> = ({ getContactById, onClose }): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [contactIdParam, setContactIdParam] = useQueryParam(contactIdQueryParam, StringParam)
  const contact = useMemo(() => (contactIdParam ? getContactById(contactIdParam) : null), [
    getContactById,
    contactIdParam,
  ])
  const isContactClanzyUser = isClanzyUser(contact)
  const [, setCallIdParam] = useQueryParam(callIdQueryParam, StringParam)
  const [, setModalParam] = useQueryParam(modalQueryParam, StringParam)

  const handleClose = useCallback(() => {
    setContactIdParam(null, 'replaceIn')
    onClose()
  }, [onClose, setContactIdParam])

  useLayoutEffect(() => {
    if (!contactIdParam) handleClose()
  }, [contactIdParam, handleClose])

  const handleEditContact = () => {
    setModalParam(modalEditContactQueryParam, 'replaceIn')
  }

  const handleCallClick = () => {
    handleClose()
    makeCall({ contacts: [contact], dispatch, setCallIdParam, setModalParam, isInstant: true })
  }

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Avatar
          size="xl"
          $secondary
          fontSize="xxxl"
          borcolor={getAvatarBorColor(isContactClanzyUser, true)}
          borop={getAvatarBorOp(isContactClanzyUser, true)}
          name={contact?.displayName}
          img={contact?.photo}
          alt={t('PhotoOfContact')}
          status={contact?.status}
        />
        <Text component="h1" mt="1rem" color="brandblack" font="Poppins">
          {contact?.displayName}
        </Text>
        <Text fw="500" color="brandblack" cop="0.6">
          {contact?.email}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      {!!contact && (
        <StyledContentWrapper>
          <Text fw="600" mt="0.5rem" color="purplehearth" size="md" onClick={handleEditContact} td="none">
            {t('EditContact')}
          </Text>
          <RowsContainer mt="1rem" $center>
            <CallButton
              $active
              title={t(isContactClanzyUser ? 'ClanzyCallButton' : 'CallButton')}
              clanzy={isContactClanzyUser}
              onClick={handleCallClick}
            />
            <Text ml="1rem" fw="600" size="md" mr="1rem">
              {t(isContactClanzyUser ? 'ClanzyCall' : 'Call')}
            </Text>
          </RowsContainer>
        </StyledContentWrapper>
      )}
    </StyledContentContainer>
  )
}

const mapStateToProps = (state) => ({
  getContactById: (contactId: string) => selectContactById(contactId)(state),
})

export default connect(mapStateToProps, null)(ContactInfoModal)
