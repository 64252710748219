/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components'
import { Moment } from 'moment'
import Text from 'components/text'
import Popper from '@material-ui/core/Popper'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import List from 'components/list'

export interface StyledTextProps {
  /**
   * Is selected
   */
  $selected?: boolean
}

export interface StyledFormFieldProps {
  /**
   * Error state
   */
  $error?: boolean
  /**
   * Value
   */
  value?: string
}

export interface FormFieldTimeProps {
  /**
   * The name of the field
   */
  name?: string
  /**
   * Error
   */
  error?: string
  /**
   * Value
   */
  value?: Moment
  /**
   * Min value
   */
  minValue?: Moment
  /**
   * Margin top
   */
  mt?: string
  /**
   * On field change
   */
  onChange?: (time: Moment) => void
  /**
   * On field error
   */
  onError?: (error: string) => void
}

export const StyledWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`

export const StyledInputWrapper = styled.div`
  position: relative;
  display: flex;
`

export const StyledIconDropdownWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 30%;
  justify-content: flex-end;
  cursor: pointer;
`

export const StyledFormField = styled.input.attrs(() => ({
  type: 'text',
  tabIndex: 0,
}))<StyledFormFieldProps>`
  flex-grow: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transform: none;
  font-family: ${({ theme }) => theme.fonts.Inter};
  color: ${({ theme: { fn, palette } }) => fn.color(palette.text.primary)};
  font-size: 1.125rem;
  padding: 1rem;
  border-radius: 0.375rem;
  border: 2px solid ${({ theme: { fn, colors } }) => fn.color(colors.black, '0.2')};
  background-color: transparent;
  display: block;
  outline: none;
  display: inline-block;
  margin: 0.4rem 0;

  &:hover {
    transition: border-color 0.1s linear;
    border-color: ${({ theme: { fn, colors } }) => fn.color(colors.brandblack)};
  }

  &:focus,
  &:active {
    transition: none;
    border-color: ${({ theme: { fn, colors } }) => fn.color(colors.mediumpurple)};
  }

  ${(props: StyledFormFieldProps) =>
    props.$error &&
    css`
      border-color: ${({ theme: { fn, colors } }) => fn.color(colors.tallpoppy)};

      &:hover {
        border-color: ${({ theme: { fn, colors } }) => fn.color(colors.tallpoppy)};
      }

      &:focus,
      &:active {
        border-color: ${({ theme: { fn, colors } }) => fn.color(colors.tallpoppy)};
      }

      ::selection {
        background: ${({ theme: { fn, palette } }) => fn.color(palette.background.black, '0.1')};
      }
      ::-moz-selection {
        background: ${({ theme: { fn, palette } }) => fn.color(palette.background.black, '0.1')};
      }
    `}
`

export const StyledFormFieldLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.Inter};
  color: ${({ theme: { fn, palette } }) => fn.color(palette.text.primary, '0.6')};
  transform: none;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 4%;
  margin-top: 1.5rem;

  input:-webkit-autofill::first-line {
    font-size: 0.875rem;
  }
`

export const StyledTimePickerWrapper = styled.div``

export const StyledPopperWrapper = styled(Popper)`
  border-radius: 0.5rem;
  filter: drop-shadow(0px 14px 26px rgba(0, 0, 0, 0.25));
  overflow: hidden;
  background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.white)};
`

export const StyledVerticalScrollbar = styled(OverlayScrollbarsComponent).attrs((props) => ({
  ...props,
  resize: 'vertical',
}))`
  height: 20rem;
  overflow-x: hidden;
  margin-right: 0.3rem;

  .os-scrollbar-horizontal {
    display: none;
  }
`

export const StyledListColumn = styled(List)`
  flex-direction: column;
  margin-right: 1rem;
  width: auto;
`

export const StyledText = styled(Text)`
  padding: 0.5rem;
  background: transparent;
  overflow: hidden;
  white-space: nowrap;

  ${(props: StyledTextProps) =>
    props.$selected &&
    css`
      transition: none;
      background: ${({ theme: { fn, colors } }) => fn.color(colors.brandblack, '0.1')};
    `}
`
