import { ReactElement } from 'react'
import { withTheme } from 'styled-components'

export default withTheme(
  ({ theme: { fn, palette }, secondary, title }): ReactElement => {
    const color = (fn.color as (arg0: string, arg1: string) => string)(
      secondary ? palette.secondary.main : palette.primary.main,
      '0.6',
    )
    return (
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H32C36.4183 0 40 3.58172 40 8V32C40 36.4183 36.4183 40 32 40H8C3.58172 40 0 36.4183 0 32V8Z"
          fill={color}
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5 16.5V11.5H22.5V16.5H17.5ZM15 11C15 9.89543 15.8954 9 17 9H23C24.1046 9 25 9.89543 25 11V17C25 18.1046 24.1046 19 23 19H17C15.8954 19 15 18.1046 15 17V11ZM11.5 23.5V28.5H16.5V23.5H11.5ZM11 21C9.89543 21 9 21.8954 9 23V29C9 30.1046 9.89543 31 11 31H17C18.1046 31 19 30.1046 19 29V23C19 21.8954 18.1046 21 17 21H11ZM23.5 23.5V28.5H28.5V23.5H23.5ZM23 21C21.8954 21 21 21.8954 21 23V29C21 30.1046 21.8954 31 23 31H29C30.1046 31 31 30.1046 31 29V23C31 21.8954 30.1046 21 29 21H23Z"
          fill={color}
          fillOpacity="1"
        />
      </svg>
    )
  },
)
