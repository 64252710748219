/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import { apiUrl } from 'const'
import { setTokenInterceptor, responseInterceptorError } from 'utils/apis'

export interface GroupData {
  id?: string
  description?: string
  name?: string
  photo?: string
  contacts: any[]
}

export interface ApiPostGroups {
  groups: GroupData[]
}

const instance = axios.create({
  baseURL: `${apiUrl}/groups`,
})

instance.interceptors.request.use(setTokenInterceptor(true, false))
instance.interceptors.response.use(undefined, responseInterceptorError)

export const apiPostGroups = async (data: ApiPostGroups): Promise<GroupData[]> => {
  const res = await instance({
    url: `/`,
    method: 'POST',
    data: data.groups[0],
  })
  return [res.data]
}

export const apiGetAllGroups = async (): Promise<GroupData[]> => {
  const res = await instance({
    url: `/`,
    method: 'GET',
  })
  return res.data
}
