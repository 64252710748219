import { FC, ReactElement } from 'react'
import StyledForm from './style'

export interface FormFieldProps {
  /**
   * On submit handler
   */
  onSubmit?: () => void
}

const Form: FC<FormFieldProps> = ({ children, onSubmit, ...rest }): ReactElement => {
  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit()
  }

  return (
    <StyledForm onSubmit={handleSubmit} {...rest}>
      {children}
    </StyledForm>
  )
}

export { default as SearchInput } from './search-input'
export { default as FormFieldInput } from './form-field-input'
export { default as FormFieldSchedule } from './form-field-schedule'
export { default as FormFieldSelect } from './form-field-select'
export { default as FormButton } from './form-button'
export { default as FormFieldValidation } from './form-field-validation'

export default Form
