/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FC, useLayoutEffect, ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import Text from 'components/text'
import Button from 'components/button'
import AddButton from 'components/button/add'
import { useForm } from 'react-hook-form'
import { CloseIcon, PeopleIcon } from 'theme'
import { RowsContainer } from 'components/container'
import { AddPhoto } from 'components/photo'
import { nanoid } from 'nanoid'
import { useTranslation } from 'react-i18next'
import { useQueryParam, StringParam } from 'use-query-params'
import {
  subModalQueryParam,
  managedUserIdQueryParam,
  groupIdQueryParam,
  modalAddManagedUserPeopleQueryParam,
} from 'const'
import {
  upsert,
  update,
  deleteGroups,
  selectGroupById,
  createManagedGroups,
  GroupNormalized,
} from 'reducers/groupsSlice'
import { connect, useDispatch } from 'react-redux'
import { ContactListItem } from 'components/list'
import {
  StyledContactsWrapper,
  StyledVerticalScrollbar,
  StyledButtonsWrapper,
  StyledContentWrapper,
  StyledContentContainer,
  StyledCloseButton,
  StyledHeaderContainer,
  StyledForm,
  StyledFormFieldInput,
  StyledFormFieldValidation,
  StyledListColumn,
} from './style'

const CreateManagedUserGroupModal: FC<{ getGroupById: (id: string) => GroupNormalized; onClose: () => void }> = ({
  getGroupById,
  onClose,
}): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [saving, setSaving] = useState(false)
  const groupNameRef = useRef<HTMLInputElement | null>(null)
  const [, setSubModalParam] = useQueryParam(subModalQueryParam, StringParam)
  const [managedUserIdParam] = useQueryParam(managedUserIdQueryParam, StringParam)
  const [groupIdParam, setGroupIdParam] = useQueryParam(groupIdQueryParam, StringParam)
  const { register, handleSubmit: handleSumbitForm, watch, errors } = useForm()
  const watchGroupName = watch('groupname')

  const group = useMemo(() => (groupIdParam ? getGroupById(groupIdParam) : null), [getGroupById, groupIdParam])

  useEffect(() => {
    groupNameRef.current.focus()
  }, [])

  useLayoutEffect(() => {
    if (!managedUserIdParam) onClose()
  })

  useEffect(() => {
    if (groupIdParam) {
      if (!group) {
        if (watchGroupName !== group?.name) {
          // TODO: debounce
          dispatch(upsert([{ id: groupIdParam, name: watchGroupName }]))
        }
      } else if (watchGroupName !== group?.name) {
        // TODO: debounce
        dispatch(update([{ id: groupIdParam, changes: { name: watchGroupName } }]))
      }
    }
  }, [dispatch, group, groupIdParam, watchGroupName])

  useEffect(() => {
    if (!groupIdParam) {
      const groupId = group?.id || nanoid()

      dispatch(upsert([{ id: groupId, managedUserId: managedUserIdParam }]))
      setGroupIdParam(groupId, 'replaceIn')
    }
  }, [dispatch, group, groupIdParam, managedUserIdParam, setGroupIdParam])

  const handleClose = () => {
    onClose()
    if (groupIdParam) dispatch(deleteGroups([groupIdParam]))
    setGroupIdParam(null, 'replaceIn')
  }

  const handleSubmit = async () => {
    setSaving(true)
    await dispatch(
      createManagedGroups({
        userId: managedUserIdParam,
        groups: [
          { name: group.name, description: '', photo: group.photo, contacts: group.contacts.map(({ id }) => id) },
        ],
      }),
    )
    setSaving(false)
    handleClose()
  }

  const handleAddContacts = () => {
    setSubModalParam(modalAddManagedUserPeopleQueryParam, 'replaceIn')
  }

  const handleContactClose = ({ id: selId }) => {
    dispatch(update([{ id: group.id, changes: { contacts: group.contacts.filter(({ id }) => id !== selId) } }]))
  }

  const handleAddPhoto = (photo) => {
    dispatch(update([{ id: group.id, changes: { photo } }]))
  }

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Text component="h1" mt="0" color="brandblack" font="Poppins">
          {t('CreateNewGroup')}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledForm onSubmit={handleSumbitForm(handleSubmit)}>
        <StyledContentWrapper>
          <RowsContainer>
            <AddPhoto
              title={t('AddPicture')}
              updateTitle={t('ChangePicture')}
              onAdd={handleAddPhoto}
              icon={PeopleIcon}
              src={group?.photo}
            />
          </RowsContainer>
          <Text size="lg" color="brandblack" cop="0.6" fw="600" mb="0.5rem" mt="1.5rem" htmlFor="groupname">
            {t('GroupName')}
          </Text>
          <StyledFormFieldInput
            maxLength={64}
            name="groupname"
            bcolor="white"
            defaultValue={group?.name}
            placeholder={t('GroupName')}
            ref={(e) => {
              register({ required: true })(e)
              groupNameRef.current = e
            }}
          />
          {errors.groupname?.type === 'required' && (
            <StyledFormFieldValidation mt="0.56rem" mb="1rem" error>
              {t('FormFieldValidationRequiredField')}
            </StyledFormFieldValidation>
          )}
          <Text size="lg" color="brandblack" cop="0.6" fw="600" mt="1rem" mb="0.5rem" tt="lowercase">
            {group?.contacts?.length || 0}&nbsp;{t('Participants')}
          </Text>
          {!!group?.contacts?.length && (
            <StyledContactsWrapper>
              <StyledVerticalScrollbar options={{ className: 'os-theme-light' }}>
                <StyledListColumn>
                  {group.contacts.map((contact) => (
                    <ContactListItem
                      $secondary
                      $flat
                      $wide
                      key={contact.id}
                      contact={contact}
                      onClose={handleContactClose}
                      $noPresence
                    />
                  ))}
                </StyledListColumn>
              </StyledVerticalScrollbar>
            </StyledContactsWrapper>
          )}
          <AddButton mt="1rem" title={t('AddGroupContacts')} onClick={handleAddContacts} />
        </StyledContentWrapper>
        <StyledButtonsWrapper>
          <Button size="md" subtle="true" onClick={handleClose}>
            <Text size="md" color="purplehearth" fw="600">
              {t('Cancel')}
            </Text>
          </Button>
          <Button
            disabled={saving || !group?.contacts?.length}
            $flat={saving || !group?.contacts?.length}
            type="submit"
            size="md"
            bcolor="mediumpurple"
            bop="1"
            ml="1rem"
            hgradient="mediumpurple-dim-light"
            agradient="mediumpurple-dim-dark"
          >
            <Text size="md" $secondary fw="600">
              {t('Save')}
            </Text>
          </Button>
        </StyledButtonsWrapper>
      </StyledForm>
    </StyledContentContainer>
  )
}

const mapStateToProps = (state) => ({
  getGroupById: (groupId: string) => selectGroupById(groupId)(state),
})

export default connect(mapStateToProps, null)(CreateManagedUserGroupModal)
