import { ReactElement, useLayoutEffect, useMemo } from 'react'
import Text from 'components/text'
import { useTranslation } from 'react-i18next'
import Button from 'components/button'
import { useQueryParam, StringParam } from 'use-query-params'
import { ColumnsContainer } from 'components/container'
import { CloseIcon, InfoIcon, RegularTickIcon } from 'theme'
import { connect, useDispatch } from 'react-redux'
import { modalQueryParam, modalGuardianRequestAcceptedQueryParam, guardianRequestIdQueryParam } from 'const'
import {
  acceptGuardianRequest,
  declineGuardianRequest,
  selectGuardianRequestById,
} from 'reducers/guardianRequestsSlice'
import { selectAuthUser } from 'reducers/usersSlice'
import {
  StyledContentContainer,
  StyledTextContainer,
  StyledDescriptionContainer,
  StyledCloseButton,
  StyledHeaderContainer,
  StyledRowsContainer,
  StyledIconTickWrapper,
  StyledButtonsWrapper,
} from './style'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const GuardianRequestModal = ({ authUser, getGuardianRequestById, onClose }): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [, setModalParam] = useQueryParam(modalQueryParam, StringParam)
  const [guardianRequestIdParam, setGuardianRequestIdParam] = useQueryParam(guardianRequestIdQueryParam, StringParam)

  const guardianRequest = useMemo(
    () => (guardianRequestIdParam ? getGuardianRequestById(guardianRequestIdParam) : null),
    [getGuardianRequestById, guardianRequestIdParam],
  )

  const handleClose = () => {
    setGuardianRequestIdParam(null, 'replaceIn')
    onClose()
  }

  useLayoutEffect(() => {
    if (!guardianRequestIdParam) handleClose()
  })

  const handleAccept = async () => {
    dispatch(acceptGuardianRequest({ user: authUser, request: guardianRequest }))
    setModalParam(modalGuardianRequestAcceptedQueryParam, 'replaceIn')
  }

  const handleDecline = async () => {
    dispatch(declineGuardianRequest(guardianRequest))
    handleClose()
  }

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <InfoIcon size="xxl" />
      <StyledTextContainer>
        <StyledDescriptionContainer>
          <Text size="lg" gradient="radiance">
            {t('AreYouSureYouWouldLikeToAcceptGuardianRequestFrom')}&nbsp;
            {guardianRequest?.from.displayName}?
          </Text>
          <StyledContentContainer>
            <StyledTextContainer>
              <ColumnsContainer mt="0.5rem">
                <Text mb="1rem">
                  {t('AcceptanceWillAllow')}&nbsp;
                  {guardianRequest?.from.displayName}&nbsp;
                  {t('To').toLowerCase()}:
                </Text>
                <StyledRowsContainer>
                  <StyledIconTickWrapper>
                    <RegularTickIcon success="true" color="purplehearth" />
                  </StyledIconTickWrapper>
                  <Text ml="0.5rem" fw="500" color="brandblack">
                    {t('ManageYourContactsAndGroupLists')}
                  </Text>
                </StyledRowsContainer>
                <StyledRowsContainer>
                  <StyledIconTickWrapper>
                    <RegularTickIcon success="true" color="purplehearth" />
                  </StyledIconTickWrapper>
                  <Text ml="0.5rem" fw="500" color="brandblack">
                    {t('UpdateAccountInfoandSomeSettings')}
                  </Text>
                </StyledRowsContainer>
                <StyledRowsContainer>
                  <StyledIconTickWrapper>
                    <RegularTickIcon success="true" color="purplehearth" />
                  </StyledIconTickWrapper>
                  <Text ml="0.5rem" fw="500" color="brandblack">
                    {t('ProvideRemoteTechSupport')}
                  </Text>
                </StyledRowsContainer>
              </ColumnsContainer>
            </StyledTextContainer>
          </StyledContentContainer>
        </StyledDescriptionContainer>
      </StyledTextContainer>
      <StyledButtonsWrapper>
        <Button
          size="md"
          bcolor="mediumpurple"
          bop="1"
          mt="1rem"
          hgradient="mediumpurple-dim-light"
          agradient="mediumpurple-dim-dark"
          onClick={handleAccept}
        >
          <Text size="md" $secondary fw="600">
            {t('YesIAmSure')}
          </Text>
        </Button>
        <Button mt="0.5rem" size="md" subtle="true" onClick={handleDecline}>
          <Text size="md" color="tallpoppy" fw="600">
            {t('Decline')}
          </Text>
        </Button>
      </StyledButtonsWrapper>
    </StyledContentContainer>
  )
}

const mapStateToProps = (state) => ({
  getGuardianRequestById: (id: string) => selectGuardianRequestById(id)(state),
  authUser: selectAuthUser(state),
})

export default connect(mapStateToProps, null)(GuardianRequestModal)
