import { ReactElement, FC, useLayoutEffect, useEffect } from 'react'
import Text from 'components/text'
import {
  modalQueryParam,
  providerQueryParam,
  modalImportContactsSuccessInfoQueryParam,
  modalImportContactsSelectProviderQueryParam,
  isElectronRuntime,
} from 'const'
import { capFirstLetter } from 'utils'
import { useDispatch, connect } from 'react-redux'
import { selectAuthUser, UserNormalized } from 'reducers/usersSlice'
import { SignInGoogleImage, SignInOutlookImage, SignInYahooImage, CloseIcon, BackIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import { useQueryParam, StringParam } from 'use-query-params'
import { importContacts, getAllContacts } from 'reducers/contactsSlice'
import Button from 'components/button'
import {
  StyledBorder,
  StyledImageBackground,
  StyledImageWrapper,
  StyledContentContainer,
  StyledTextContainer,
  StyledCloseButton,
  StyledBackButton,
  StyledHeaderContainer,
} from './style'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cloudsponge: any
  }
}

const { $ } = window

export interface ImportContactsMoreInfoModalProps {
  /**
   * The authenticated user
   */
  authUser: UserNormalized
  /**
   * On close handler
   */
  onClose: () => void
}

const ImportContactsMoreInfoModal: FC<ImportContactsMoreInfoModalProps> = ({ authUser, onClose }): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [, setModalParam] = useQueryParam(modalQueryParam, StringParam)
  const [providerParam, setProviderParam] = useQueryParam(providerQueryParam, StringParam)

  const addressBookProviders = [
    {
      id: 'gmail',
      image: <SignInGoogleImage alt={t('ConnectGmailImageAltText')} />,
      title: t('ConnectGmailAddressBook'),
    },
    {
      id: 'windowslive',
      image: <SignInOutlookImage alt={t('ConnectOutlookOrHotmailImageAltText')} />,
      title: t('ConnectOutlookOrHotmailAddressBook'),
    },
    {
      id: 'yahoo',
      image: <SignInYahooImage alt={t('ConnectYahooImageAltText')} />,
      title: t('ConnectYahooAddressBook'),
    },
  ]

  const providerInfo = addressBookProviders.find(({ id }) => id === providerParam)

  useLayoutEffect(() => {
    window.cloudsponge.load({
      include: ['mailing_address', 'name', 'email', 'phone', 'photo'],
      skipContactsDisplay: true,
      inlineOauth: isElectronRuntime,
      skipSourceMenu: true,
      ignoreMultipleEmails: true,
      ignoreMultiplePhones: true,
      alwaysShowCTA: false,
      selectAccount: true,
      beforeDisplayContacts: async (contacts) => {
        await dispatch(importContacts({ userId: authUser.id, contacts }))
        dispatch(getAllContacts())

        window.cloudsponge.end()
        onClose()

        setModalParam(modalImportContactsSuccessInfoQueryParam, 'replaceIn')
        return false
      },
    })
  }, [authUser, dispatch, onClose, setModalParam, setProviderParam])

  const handleBack = () => {
    setModalParam(modalImportContactsSelectProviderQueryParam, 'replaceIn')
    setProviderParam(null, 'replaceIn')
  }

  const handleClose = () => {
    onClose()
    setProviderParam(null, 'replaceIn')
    window.cloudsponge.end()
  }

  useEffect(() => {
    const el = $('.cloudsponge-launch')?.[0]
    if (el) el.focus()
  }, [])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Text ml="4rem" mr="4rem" $center component="h1" mt="0" color="brandblack" font="Poppins">
          {providerInfo?.title}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
        <StyledBackButton circular="true" subtle="true" onClick={handleBack}>
          <BackIcon title={t('GoBack')} />
        </StyledBackButton>
      </StyledHeaderContainer>
      <StyledTextContainer>
        <Text fw="500" gradient="radiance" size="md">
          {t(`ImportContactsMoreInfo${capFirstLetter(providerParam)}`)}
        </Text>
      </StyledTextContainer>
      <Text size="lg">{t('ItWillLookSomethingLikeThis')}</Text>
      <StyledImageWrapper>
        <StyledBorder />
        <StyledImageBackground />
        {providerInfo?.image}
      </StyledImageWrapper>
      <Button
        size="md"
        bcolor="mediumpurple"
        bop="1"
        mt="1rem"
        hgradient="mediumpurple-dim-light"
        agradient="mediumpurple-dim-dark"
        className="cloudsponge-launch"
        data-cloudsponge-source={providerParam}
      >
        <Text size="md" $secondary fw="600">
          {t('OkSignMeIn')}
        </Text>
      </Button>
    </StyledContentContainer>
  )
}

const mapStateToProps = (state) => ({
  authUser: selectAuthUser(state),
})

export default connect(mapStateToProps, null)(ImportContactsMoreInfoModal)
