/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FC, ReactElement, useState } from 'react'
import Button from 'components/button'
import { useForm } from 'react-hook-form'
import Text from 'components/text'
import { CloseIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import { FormFieldValidation, FormFieldInput } from 'components/form'
import { connect, useDispatch } from 'react-redux'
import isEmail from 'validator/es/lib/isEmail'
import { selectAuthUser, UserNormalized, updateProfile } from 'reducers/usersSlice'
import {
  StyledButtonsWrapper,
  StyledContentWrapper,
  StyledContentContainer,
  StyledCloseButton,
  StyledHeaderContainer,
  StyledForm,
} from './style'

const EditProfileEmail: FC<{ onClose: () => void; authUser: UserNormalized }> = ({
  onClose,
  authUser,
}): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [saving, setSaving] = useState(false)
  const { register, handleSubmit: handleSumbitForm, errors } = useForm()

  const handleSubmit = async ({ email }) => {
    setSaving(true)
    await dispatch(updateProfile({ id: authUser.id, username: email }))
    setSaving(false)
    onClose()
  }

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Text component="h1" mt="0" color="brandblack" font="Poppins">
          {t('ChangeEmail')}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={onClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledContentWrapper>
        {authUser && (
          <StyledForm onSubmit={handleSumbitForm(handleSubmit)}>
            <FormFieldInput
              error={!!errors.email}
              name="email"
              defaultValue={authUser.username}
              label={t('Email')}
              maxLength={64}
              placeholder={t('Email')}
              ref={register({ required: true, validate: isEmail })}
            />
            {errors.email?.type === 'required' && (
              <FormFieldValidation mt="0.56rem" mb="1rem" error>
                {t('FormFieldValidationRequiredField')}
              </FormFieldValidation>
            )}
            {errors.email?.type === 'validate' && (
              <FormFieldValidation mt="0.56rem" mb="1rem" error>
                {t('FormFieldValidationInvalidEmail')}
              </FormFieldValidation>
            )}
            <StyledButtonsWrapper>
              <Button size="md" subtle="true" onClick={onClose}>
                <Text size="md" color="purplehearth" fw="600">
                  {t('Cancel')}
                </Text>
              </Button>
              <Button
                disabled={saving}
                $flat={saving}
                type="submit"
                size="md"
                bcolor="mediumpurple"
                bop="1"
                ml="1rem"
                hgradient="mediumpurple-dim-light"
                agradient="mediumpurple-dim-dark"
              >
                <Text size="md" $secondary fw="600">
                  {t('Save')}
                </Text>
              </Button>
            </StyledButtonsWrapper>
          </StyledForm>
        )}
      </StyledContentWrapper>
    </StyledContentContainer>
  )
}

const mapStateToProps = (state) => ({
  authUser: selectAuthUser(state),
})

export default connect(mapStateToProps, null)(EditProfileEmail)
