import { ReactElement, useCallback, useLayoutEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { selectCurrentCall, setCallNotCurrent } from 'reducers/callsSlice'
import { selectAuthUser } from 'reducers/usersSlice'
import {
  isCallCancelled,
  isCallNotStarted,
  isCallEndedOrCancelled,
  getCallId,
  getParticipantsUserIds,
  getInvitationByUserId,
  isCallHost,
  isCallInvitationPending,
  isCallInvitee,
  isCallInstant,
} from 'utils/calls'
import { useTranslation } from 'react-i18next'
import { info } from 'react-toastify-redux'
import Card from 'components/card'
import { push } from 'connected-react-router'
import { callsPath } from 'const'
import { TransparentBackground } from '../../backgrounds'
import { InviteeCallMissed, InviteeCallInvitation } from './invitees'

const { $ } = window

const DashboardCallsNotifications = ({ authUser, currentCall, setWebNotification }): ReactElement => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // Sets button focus
  const acceptInvitationButtonFocus = useCallback(() => {
    if (currentCall) {
      const el = $(`.call-invitation-accept-button-${currentCall.id}`)?.[0]
      if (el) el.focus()
    }
  }, [currentCall])

  const handleMouseDown = (e) => {
    e.preventDefault()
    acceptInvitationButtonFocus()
  }

  useLayoutEffect(() => {
    if (currentCall) {
      // If the call is canceled set it as no current
      if (isCallHost(currentCall, authUser) && isCallCancelled(currentCall)) {
        dispatch(setCallNotCurrent(currentCall.id))

        if (isCallInstant(currentCall)) {
          // Dispaly invitees are busy notification
          setTimeout(() => {
            dispatch(info(t('TheInviteesAreBusy'), { position: 'top-right' }))
          }, 300)
        }
      } else {
        const participantsUserIds = getParticipantsUserIds(currentCall)
        const shouldAuthUserJoin = participantsUserIds.includes(authUser.id)

        if (shouldAuthUserJoin) {
          const callId = getCallId(currentCall)
          dispatch(push(`${callsPath}/${callId}`))
        }
      }
    }
  }, [authUser, authUser.id, currentCall, dispatch, t])

  if (currentCall) {
    if (isCallInvitee(currentCall, authUser)) {
      const invitation = getInvitationByUserId(currentCall, authUser)

      if (invitation && isCallInvitationPending(invitation)) {
        if (isCallNotStarted(currentCall)) {
          return (
            <TransparentBackground onMouseDown={handleMouseDown} effect="blur">
              <InviteeCallInvitation call={currentCall} setWebNotification={setWebNotification} />
            </TransparentBackground>
          )
        }

        if (isCallEndedOrCancelled(currentCall)) {
          return (
            <TransparentBackground>
              <Card size="md" secondary="true">
                <InviteeCallMissed call={currentCall} />
              </Card>
            </TransparentBackground>
          )
        }
      }
    }
  }

  return null
}

const mapStateToProps = (state) => ({
  currentCall: selectCurrentCall(state),
  authUser: selectAuthUser(state),
})

export default connect(mapStateToProps, null)(DashboardCallsNotifications)
