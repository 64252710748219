import styled, { css } from 'styled-components'
import { CallNormalized } from 'reducers/callsSlice'
import { RowsContainer, ColumnsContainer } from 'components/container'
import { UserNormalized } from 'reducers/usersSlice'

export interface RecentCallListItemProps {
  /**
   * On close handler
   */
  onClose?: (recentCall: CallNormalized) => void
  /**
   * On recentCall click handler
   */
  onClick?: (recentCall: CallNormalized) => void
  /**
   * On mouse enter handler
   */
  onMouseEnter?: (recentCall: CallNormalized) => void
  /**
   * On mouse leave handler
   */
  onMouseLeave?: (recentCall: CallNormalized) => void
  /**
   * On video icon click handler
   */
  onCallClick?: (recentCall: CallNormalized) => void
  /**
   * The recentCall info
   */
  recentCall: CallNormalized
  /**
   * Background color
   */
  bcolor?: string
  /**
   * Is hovered
   */
  $hovered?: boolean
  /**
   * Ticker
   */
  ticker?: number
  /**
   * Call icon title
   */
  title?: string
  /**
   * No hover or active states
   */
  $flat?: boolean
  /**
   * No padding left or right
   */
  $wide?: boolean
  /**
   * Variant
   */
  $secondary?: boolean
  /**
   * The authenticated user
   */
  authUser?: UserNormalized
}

export const StyledListItem = styled(RowsContainer)`
  padding: 0.75rem 1.5rem;
  align-items: center;

  ${(props: RecentCallListItemProps) =>
    props.$wide &&
    css`
      padding: 0.75rem 0;
    `}

  ${(props: RecentCallListItemProps) =>
    !props.$flat &&
    css`
      &:hover {
        background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.white, '0.04')};
      }
    `}

  ${(props: RecentCallListItemProps) =>
    props.$secondary &&
    !props.$flat &&
    css`
      &:hover {
        background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.black, '0.1')};
      }
    `}
`

export const StyledTextWrapper = styled(ColumnsContainer)`
  max-width: 65%;
  justify-content: center;
`

export const StyledTextCallTypeWrapper = styled(ColumnsContainer)`
  justify-content: flex-end;
`

export const StyledControlsWrapper = styled(RowsContainer)`
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
`
