import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.37261 13.7384C5.17667 16.0031 5.12227 18.288 5.01347 22.8578C5.00468 23.227 5 23.5994 5 23.974C5 24.3187 5.00397 24.6617 5.01145 25.0019C5.11289 29.6135 5.16361 31.9193 7.35641 34.1881C9.54922 36.4569 11.9777 36.5932 16.8347 36.8658C17.7557 36.9175 18.6721 36.9479 19.5537 36.9479C20.4517 36.9479 21.3858 36.9164 22.324 36.8629C27.1486 36.5881 29.5609 36.4506 31.7513 34.1834C33.9416 31.9161 33.9927 29.6287 34.0951 25.054C34.1031 24.6973 34.1074 24.337 34.1074 23.974C34.1074 23.5811 34.1024 23.1913 34.0931 22.8059C33.9837 18.2729 33.9289 16.0064 31.7354 13.7431C29.5419 11.4799 27.1659 11.348 22.414 11.0843C21.4579 11.0313 20.4951 11 19.5537 11C18.629 11 17.6837 11.0302 16.7445 11.0815C11.9605 11.343 9.56854 11.4738 7.37261 13.7384ZM36.4217 20.7305L38.1557 19.5438L41.6238 17.1705L43.3579 15.9839V32.5969L41.6238 31.4103L38.1557 29.037L36.4217 27.8503V20.7305ZM27.4706 24.0975C27.4706 25.0321 26.713 25.7898 25.7784 25.7898H21.0654V30.7783C21.0654 31.7129 20.3078 32.4706 19.3732 32.4706C18.4386 32.4706 17.6809 31.7129 17.6809 30.7783V25.7898H12.6923C11.7577 25.7898 11 25.0321 11 24.0975C11 23.1629 11.7577 22.4052 12.6923 22.4052H17.6809V17.6923C17.6809 16.7577 18.4386 16 19.3732 16C20.3078 16 21.0654 16.7577 21.0654 17.6923V22.4052H25.7784C26.713 22.4052 27.4706 23.1629 27.4706 24.0975Z"
      fill="white"
    />
  </svg>
)
