/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { updated, managedUserRemoved, getAllManagedUsers } from './usersSlice'

export const actionsTypesMapping = {}

export const eventsMapping = {
  managedUserUpdate: {
    action: 'users/managedUserUpdate',
    actionCreator: updated,
  },
  guardianRemoved: {
    action: 'users/managedUserRemoved',
    actionCreator: managedUserRemoved,
  },
  profileUpdated: {
    action: 'users/added',
    actionCreator: updated,
    map: ({ payload }) => [payload],
  },
  guardianRequestUpdated: {
    action: 'users/getAllManagedUsers',
    actionCreator: getAllManagedUsers,
  },
}
