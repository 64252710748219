/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FC, ReactElement, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import Avatar from 'components/avatar'
import { SearchInput } from 'components/form'
import Text from 'components/text'
import { BackIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import { createFilter } from 'react-search-input'
import { selectContactsByManagedUserId, ContactNormalized } from 'reducers/contactsSlice'
import { ContactListItem } from 'components/list'
import { useQueryParam, StringParam } from 'use-query-params'
import {
  subModalQueryParam,
  groupIdQueryParam,
  modalCreateManagedUserGroupQueryParam,
  managedUserIdQueryParam,
} from 'const'
import { update as updateGroup, upsert, selectGroupById, GroupNormalized } from 'reducers/groupsSlice'
import { connect, useDispatch } from 'react-redux'
import { ContentContainer } from 'components/container'
import { isClanzyUser } from 'utils/contacts'
import { sortContactsAndGroups, getAvatarBorColor, getAvatarBorOp } from 'utils'
import {
  SearchInputWrapper,
  StyledContactsControlsWrapper,
  StyledContentWrapper,
  StyledContactsWrapper,
  StyledButton,
  StyledRowsContainer,
  StyledListColumn,
  StyledListRow,
  StyledVerticalScrollbar,
  StyledHorizontalScrollbar,
  StyledHeaderContainer,
  StyledDoneButton,
  StyledAvatarWrapper,
  StyledSelectedContactsWrapper,
} from './style'

const { $ } = window

const getListTitle = (group, searchTerm, t) => {
  if (group) return t('People')
  return searchTerm ? t('FilteredResults') : t('PeopleAndGroups')
}

const AddManagedUserPeopleModal: FC<{
  getContactsByManagedUserId: (id: string) => ContactNormalized[]
  getGroupById: (id: string) => GroupNormalized
  onClose: () => void
}> = ({ getContactsByManagedUserId, getGroupById, onClose }): ReactElement => {
  const [groupIdParam] = useQueryParam(groupIdQueryParam, StringParam)
  const [searchInputFocused, setSearchInputFocused] = useState(false)
  const [managedUserIdParam] = useQueryParam(managedUserIdQueryParam, StringParam)

  const contacts = useMemo(() => (managedUserIdParam ? getContactsByManagedUserId(managedUserIdParam) : []), [
    getContactsByManagedUserId,
    managedUserIdParam,
  ])

  const group = useMemo(() => (groupIdParam ? getGroupById(groupIdParam) : null), [getGroupById, groupIdParam])

  useLayoutEffect(() => {
    if (!managedUserIdParam) onClose()
  })

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [selected, setSelected] = useState([...(group?.contacts || [])].sort(sortContactsAndGroups))

  const [hovered, setHovered] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [filtered, setFiltered] = useState([])

  const [, setSubModalParam] = useQueryParam(subModalQueryParam, StringParam)
  const [scrollTop, setScrollTop] = useState(false)

  const selectedIds = useMemo(() => selected.map(({ id }) => id), [selected])

  const focusAddManagedUserPeopleSearch = useCallback(() => {
    const el = $(`.search-people-${managedUserIdParam} input`)?.[0]
    if (el) el.focus()
  }, [managedUserIdParam])

  useEffect(() => {
    focusAddManagedUserPeopleSearch()
  }, [focusAddManagedUserPeopleSearch])

  const searchUpdated = (str) => setSearchTerm(str)
  const onScroll = ({ srcElement }) => {
    if ((srcElement.scrollTop && !scrollTop) || (!srcElement.scrollTop && scrollTop)) {
      setScrollTop(!!srcElement.scrollTop)
    }
  }

  const handleBackClick = () => {
    if (groupIdParam) {
      setSubModalParam(modalCreateManagedUserGroupQueryParam, 'replaceIn')
    } else {
      onClose()
    }
  }

  const handleMouseEnter = ({ id }) => {
    setHovered(id)
  }

  const handleMouseLeave = () => {
    setHovered(null)
  }

  const handleSelectedAvatarClose = (selId) => {
    setSelected((prevSelected) => prevSelected.filter(({ id }) => id !== selId))

    focusAddManagedUserPeopleSearch()
  }

  const handleClick = (item) => {
    setSelected((prevSelected) => {
      if (prevSelected.map(({ id }) => id).includes(item.id)) {
        return prevSelected.filter(({ id }) => id !== item.id)
      }
      return [...prevSelected, item]
    })

    focusAddManagedUserPeopleSearch()
  }

  const handleDone = () => {
    if (group) {
      dispatch(updateGroup([{ id: group.id, changes: { contacts: selected } }]))
    } else {
      dispatch(upsert([{ id: groupIdParam, managedUserId: managedUserIdParam, contacts: selected }]))
    }

    setSubModalParam(modalCreateManagedUserGroupQueryParam, 'replaceIn')
  }

  useEffect(() => {
    if (!groupIdParam) {
      setSubModalParam(modalCreateManagedUserGroupQueryParam, 'replaceIn')
    }
  }, [dispatch, group, groupIdParam, setSubModalParam])

  useEffect(() => {
    if (searchTerm)
      setFiltered(
        [...(contacts || []).filter(createFilter(searchTerm, ['displayName', 'email', 'phone']))].sort(
          sortContactsAndGroups,
        ),
      )
    else setFiltered([...(contacts || [])].sort(sortContactsAndGroups))
  }, [contacts, searchTerm])

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleDone()
    }
  }

  return (
    <ContentContainer onKeyDown={handleKeyDown}>
      <StyledHeaderContainer component="header">
        <StyledButton circular="true" subtle="true" onClick={handleBackClick}>
          <BackIcon title={t('GoBack')} />
        </StyledButton>
        <Text component="h1" mt="0" color="brandblack" font="Poppins">
          {t('ChoosePeopleToInvite')}
        </Text>
        <StyledDoneButton
          disabled={!selected.length}
          $flat={!selected.length}
          onClick={handleDone}
          type="submit"
          size="md"
          bcolor="mediumpurple"
          bop="1"
          ml="1rem"
          hgradient="mediumpurple-dim-light"
          agradient="mediumpurple-dim-dark"
        >
          <Text size="md" $secondary fw="600">
            {t('Done')}
          </Text>
        </StyledDoneButton>
      </StyledHeaderContainer>
      <StyledContentWrapper>
        <StyledContactsControlsWrapper shadow={scrollTop}>
          <SearchInputWrapper $focused={searchInputFocused}>
            <SearchInput
              $secondary
              className={`search-people-${managedUserIdParam}`}
              $interactive
              onFocus={setSearchInputFocused}
              placeholder={t('ChooseFromTheListOrTypeToFilter')}
              onChange={searchUpdated}
            />
          </SearchInputWrapper>
          {!!selected.length && (
            <StyledSelectedContactsWrapper>
              <StyledHorizontalScrollbar options={{ overflowBehavior: { y: 'scroll' }, className: 'os-theme-light' }}>
                <StyledListRow>
                  {selected.map((item) => (
                    <StyledAvatarWrapper key={item.id}>
                      <Avatar
                        id={item.id}
                        size="lg"
                        buttonSize="xl"
                        fontSize="xxl"
                        $secondary
                        bcolor="transparent"
                        bop="0"
                        borcolor={getAvatarBorColor(isClanzyUser(item), true)}
                        borop={getAvatarBorOp(isClanzyUser(item), true)}
                        name={(item as ContactNormalized).displayName}
                        title={(item as ContactNormalized).firstName || (item as ContactNormalized).displayName}
                        alt={t('PhotoOfContact')}
                        $noPresence
                        img={item.photo}
                        onClose={handleSelectedAvatarClose}
                      />
                    </StyledAvatarWrapper>
                  ))}
                </StyledListRow>
              </StyledHorizontalScrollbar>
            </StyledSelectedContactsWrapper>
          )}
        </StyledContactsControlsWrapper>
        <StyledContactsWrapper>
          <StyledVerticalScrollbar options={{ callbacks: { onScroll }, className: 'os-theme-light' }}>
            <StyledRowsContainer>
              <Text size="lg" color="brandblack" cop="0.6" fw="600">
                {getListTitle(group, searchTerm, t)}
              </Text>
            </StyledRowsContainer>
            <StyledListColumn>
              {filtered.map((item) => (
                <ContactListItem
                  $secondary
                  $hovered={item.id === hovered}
                  $selected={selectedIds.includes(item.id)}
                  key={item.id}
                  contact={item}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={handleClick}
                  $noPresence
                />
              ))}
            </StyledListColumn>
          </StyledVerticalScrollbar>
        </StyledContactsWrapper>
      </StyledContentWrapper>
    </ContentContainer>
  )
}

const mapStateToProps = (state) => ({
  getContactsByManagedUserId: (userId: string) => selectContactsByManagedUserId(userId)(state),
  getGroupById: (groupId: string) => selectGroupById(groupId)(state),
})

export default connect(mapStateToProps, null)(AddManagedUserPeopleModal)
