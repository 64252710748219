/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components'
import Button from 'components/button'
import { RowsContainer } from 'components/container'

export interface FormFieldInputProps {
  /**
   * Error state
   */
  error?: boolean
  /**
   * The name of the field
   */
  name: string
  /**
   * The default value of the field
   */
  defaultValue?: string
  /**
   * The for field label
   */
  label?: string
  /**
   * The for field placeholder
   */
  placeholder?: string
  /**
   * The field type
   */
  type?: string
  /**
   * The max length of the field
   */
  maxLength?: number
  /**
   * Can revealPassword password
   */
  revealPassword?: string
  /**
   * Margin top
   */
  mt?: string
  /**
   * A refference of the input
   */
  elRef?: {
    current: HTMLInputElement
  }
  /**
   * Background color
   */
  bcolor?: string
  /**
   * Background opacity
   */
  bop?: string
  /**
   * Padding
   */
  p?: string
  /**
   * Button title
   */
  btnTitle?: string
  /**
   * Is checked
   */
  checked?: string
  /**
   * Checked title
   */
  checkedTitle?: string
  /**
   * Is disabled
   */
  disabled?: string
  /**
   * No hover and active states
   */
  $flat?: boolean
  /**
   * Rounded
   */
  $rounded?: boolean
  /**
   * Uppercase
   */
  $uppercase?: boolean
  /**
   * Varinat for the button
   */
  $btnSecondary?: boolean
  /**
   * Pattern
   */
  pattern?: string
  /**
   * Button type
   */
  btnType?: string
  /**
   * Checked color
   */
  checkedColor?: string
  /**
   * On field focus
   */
  onFocus?: () => void
  /**
   * On field blur
   */
  onBlur?: () => void
  /**
   * On button click
   */
  onBtnClick?: () => void
  /**
   * On change
   */
  onChange?: (el: any) => void
}

export const StyledFormFieldInput = styled.input.attrs((props) => ({
  type: props.type,
  tabIndex: 0,
}))`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transform: none;
  font-family: ${({ theme }) => theme.fonts.Inter};
  color: ${({ theme: { fn, palette } }) => fn.color(palette.text.primary)};
  font-size: 1.125rem;
  padding: 1rem;
  border-radius: 0.375rem;
  border: 2px solid ${({ theme: { fn, colors } }) => fn.color(colors.black, '0.2')};
  background-color: transparent;
  display: block;
  outline: none;
  display: inline-block;
  margin: 0.4rem 0;

  ${(props: FormFieldInputProps) =>
    !!props.$rounded &&
    css`
      border-radius: 2rem;

      ::-webkit-input-placeholder {
        text-align: center;
      }

      :-moz-placeholder {
        text-align: center;
      }

      ::-moz-placeholder {
        text-align: center;
      }

      :-ms-input-placeholder {
        text-align: center;
      }
    `}

  ${(props: FormFieldInputProps) =>
    !!props.p &&
    css`
      padding: ${props.p};
    `}

  ${(props: FormFieldInputProps) =>
    props.$uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${(props: FormFieldInputProps) =>
    !props.$flat &&
    css`
      ::selection {
        background: ${({ theme: { fn, palette } }) => fn.color(palette.background.mediumpurple, '0.3')};
      }
      ::-moz-selection {
        background: ${({ theme: { fn, palette } }) => fn.color(palette.background.mediumpurple, '0.3')};
      }

      &:hover {
        transition: border-color 0.1s linear;
        border-color: ${({ theme: { fn, colors } }) => fn.color(colors.brandblack)};
      }

      &:focus,
      &:active {
        transition: none;
        border-color: ${({ theme: { fn, colors } }) => fn.color(colors.mediumpurple)};
      }
    `}

  ${(props: FormFieldInputProps) =>
    !!props.revealPassword &&
    css`
      & + button {
        display: none;
      }

      &:active + button,
      &:focus + button {
        display: block;
      }
    `}

  ${(props: FormFieldInputProps) =>
    !!props.bcolor &&
    css`
      background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background[props.bcolor], props.bop || '1')};
    `}

  ${(props: FormFieldInputProps) =>
    props?.error &&
    css`
      border-color: ${({ theme: { fn, colors } }) => fn.color(colors.tallpoppy)};
      color: ${({ theme: { fn, palette } }) => fn.color(palette.text.tallpoppy)};

      &:hover {
        border-color: ${({ theme: { fn, colors } }) => fn.color(colors.tallpoppy)};
      }

      &:focus,
      &:active {
        border-color: ${({ theme: { fn, colors } }) => fn.color(colors.tallpoppy)};
      }

      ::selection {
        background: ${({ theme: { fn, palette } }) => fn.color(palette.background.black, '0.1')};
      }
      ::-moz-selection {
        background: ${({ theme: { fn, palette } }) => fn.color(palette.background.black, '0.1')};
      }
    `}
`

export const StyledFormFieldInputLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.Inter};
  color: ${({ theme: { fn, palette } }) => fn.color(palette.text.primary, '0.6')};
  transform: none;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 4%;
  margin-top: 1.5rem;

  input:-webkit-autofill::first-line {
    font-size: 0.875rem;
  }
`

export const StyledFormFieldInputWrapper = styled.div`
  position: relative;
  display: flex;

  & input {
    flex-grow: 1;
  }
`

export const StyledCheckedWrapper = styled(RowsContainer)`
  position: absolute;
  right: 1rem;
  top: 0rem;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const StyledFormFieldInputButton = styled(Button)`
  position: absolute;
  right: 0.5rem;
  top: 0.875rem;

  &:active {
    display: block;
  }
`
