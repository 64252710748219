/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement } from 'react'
import Text from 'components/text'
import { useTranslation } from 'react-i18next'
import { termsOfUsePath, privacyPolicyPath, contactUsPath } from 'const'
import StyledTextContainer from './style'

export default ({ $primary }: { $primary?: boolean }): ReactElement => {
  const { t } = useTranslation()
  return (
    <StyledTextContainer>
      <Text color={$primary ? 'black' : 'white'} td="none" cop="0.3" size="xs" linkBlank="true" link={termsOfUsePath}>
        {t('TermsOfUse')}
      </Text>
      <Text
        ml="0.5rem"
        td="none"
        color={$primary ? 'black' : 'white'}
        cop="0.3"
        size="xs"
        linkBlank="true"
        link={privacyPolicyPath}
      >
        {t('PrivacyPolicy')}
      </Text>
      <Text
        ml="0.5rem"
        mr="0.5rem"
        td="none"
        color={$primary ? 'black' : 'white'}
        cop="0.3"
        size="xs"
        linkBlank="true"
        link={contactUsPath}
      >
        {t('ContactUs')}
      </Text>
    </StyledTextContainer>
  )
}
