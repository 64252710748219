import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="90" height="68" viewBox="0 0 90 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path d="M6 27.3L34.8108 56L84 7" stroke="url(#paint0_linear)" strokeWidth="17" />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="9.34286"
        y1="54.8936"
        x2="43.6675"
        y2="17.686"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0520833" stopColor="#F6BF4F" />
        <stop offset="0.5625" stopColor="#FF00E7" />
        <stop offset="1" stopColor="#935DEA" />
      </linearGradient>
    </defs>
  </svg>
)
