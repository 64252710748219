import { ReactElement } from 'react'
import { withTheme } from 'styled-components'

export default withTheme(
  ({ theme: { fn, colors }, $secondary, title }): ReactElement => {
    const color = (fn.color as (arg0: string, arg1: string) => string)(
      $secondary ? colors.brandblack : colors.white,
      $secondary ? '0.6' : '1',
    )
    return (
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 14.5C22 18.6421 18.6421 22 14.5 22C10.3579 22 7 18.6421 7 14.5C7 10.3579 10.3579 7 14.5 7C18.6421 7 22 10.3579 22 14.5ZM20.2724 23.2724C18.6161 24.3644 16.6323 25 14.5 25C8.70101 25 4 20.299 4 14.5C4 8.70101 8.70101 4 14.5 4C20.299 4 25 8.70101 25 14.5C25 16.7209 24.3105 18.7807 23.1339 20.4771L28.8284 26.1716L26 29L20.2724 23.2724Z"
          fill={color}
        />
      </svg>
    )
  },
)
