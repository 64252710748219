/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import Button from 'components/button'
import { ContentContainer, ColumnsContainer, RowsContainer } from 'components/container'
import Form from 'components/form'

export const StyledContentContainer = styled(ContentContainer)`
  margin: 1.125rem 0;
`

export const StyledContentWrapper = styled(ColumnsContainer)`
  justify-content: center;
  text-align: left;
  box-sizing: border-box;
  margin: 1rem 1rem 0 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.mediumpurple, '0.1')};
`

export const StyledHeaderContainer = styled(RowsContainer)`
  margin: 0 1.125rem;
  height: 2.8125rem;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  text-align: center;
  max-width: 80%;
`

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

export const StyledButtonsWrapper = styled(ColumnsContainer)`
  margin: 2.5rem 1.125rem 0 1.125rem;
`

export const StyledForm = styled(Form)`
  width: 100%;

  ${({ theme }) => theme.fn.down(theme.breakpoints.md)} {
    margin-top: 0.5rem;
  }
`

export const StyledTitleContainer = styled.title`
  display: inline-block;
  text-align: left;
  position: relative;
  padding: 1rem 1.5rem;
`

export const StyledTextContainer = styled.span`
  text-align: left;
  position: relative;
  padding: 0.5rem 1.5rem;
`

export const StyledLogoWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  min-width: 5.5rem;
  height: 5.5rem;
  border-radius: 5.5rem;
  background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.white)};
`

export const StyledContactsWrapper = styled(ContentContainer)`
  width: 100%;
`
