/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement, useLayoutEffect } from 'react'
import Text from 'components/text'
import Button from 'components/button'
import { useTranslation } from 'react-i18next'
import { CloseIcon, LineWaveIcon } from 'theme'
import {
  StyledButtonsWrapper,
  StyledContentContainer,
  StyledTextContainer,
  StyledTitleContainer,
  StyledCloseButton,
  StyledHeaderContainer,
} from './style'

export default ({ onClose, permissionPromptIsShown }): ReactElement => {
  const { t } = useTranslation()

  useLayoutEffect(() => {
    if (!permissionPromptIsShown) onClose()
  }, [permissionPromptIsShown, onClose])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <StyledCloseButton circular="true" subtle="true" onClick={onClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledTitleContainer>
        <Text size="xl" gradient="radiance" fw="600" font="Poppins" $block>
          {t('AllowClanzyToUseYourCameraAndMicrophone')}
        </Text>
        &nbsp;
        <Text size="xl" fw="600" font="Poppins" $block>
          {t('SoOtherCallParticipantsCanSeeAndHearYou')}
        </Text>
      </StyledTitleContainer>
      <StyledTextContainer>
        <Text color="black" cop="0.6" fw="500" $block>
          {t('AllowCamAndMicAdditionalText')}
        </Text>
        <LineWaveIcon />
      </StyledTextContainer>
      <StyledButtonsWrapper>
        <Button size="md" subtle="true" onClick={onClose}>
          <Text size="md" color="purplehearth" fw="600">
            {t('Cancel')}
          </Text>
        </Button>
        <Button
          size="md"
          bcolor="mediumpurple"
          bop="1"
          ml="1rem"
          hgradient="mediumpurple-dim-light"
          agradient="mediumpurple-dim-dark"
          onClick={onClose}
        >
          <Text size="md" $secondary fw="600">
            {t('Ok')}
          </Text>
        </Button>
      </StyledButtonsWrapper>
    </StyledContentContainer>
  )
}
