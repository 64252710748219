import styled, { css } from 'styled-components'

export interface RowsContainerProps {
  /**
   * Component type
   */
  component?: string
  /**
   * Align content
   */
  $center?: boolean
  /**
   * Justify content
   */
  $vcenter?: boolean
  /**
   * Margin top
   */
  mt?: string
  /**
   * Margin left
   */
  ml?: string
}

export default styled(({ children, component, ...rest }) => {
  switch (component) {
    case 'header':
      return <header {...rest}>{children}</header>
    case 'footer':
      return <footer {...rest}>{children}</footer>
    default:
      return <section {...rest}>{children}</section>
  }
})<RowsContainerProps>`
  display: flex;
  flex-direction: row;

  ${(props: RowsContainerProps) =>
    !!props.mt &&
    css`
      margin-top: ${props.mt}};
    `}

  ${(props: RowsContainerProps) =>
    !!props.ml &&
    css`
      margin-left: ${props.ml}};
    `}

  ${(props: RowsContainerProps) =>
    props.$center &&
    css`
      align-items: center;
      vertical-align: middle;
    `}

  ${(props: RowsContainerProps) =>
    props.$vcenter &&
    css`
      justify-content: center;
    `}
`
