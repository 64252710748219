/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const setToLS = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export const removeFromLS = (key) => {
  window.localStorage.removeItem(key)
}

export const getFromLS = (key) => {
  const value = window.localStorage.getItem(key)

  if (value) {
    try {
      return JSON.parse(value)
    } catch (e) {
      return null
    }
  }

  return null
}

export const getUserFromLS = (isAnon) => {
  const authFromLs = getFromLS('auth')
  if (authFromLs) {
    const userId = Object.keys(authFromLs).find((id) => authFromLs[id].isAnon === isAnon)
    if (!userId) return null
    const tokelFromLs = authFromLs[userId]?.token
    return [userId, tokelFromLs] || null
  }
  return null
}
