import { ReactElement } from 'react'

export default (): ReactElement => (
  <svg width="70" height="61" viewBox="0 0 70 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M67.9363 59.0953C69.0368 59.0001 69.8517 58.0308 69.7564 56.9303L68.2043 38.9974C68.1091 37.8969 67.1398 37.082 66.0393 37.1773C64.9388 37.2725 64.124 38.2418 64.2192 39.3423L65.5989 55.2827L49.6585 56.6623C48.558 56.7576 47.7431 57.7269 47.8384 58.8273C47.9336 59.9278 48.9029 60.7427 50.0034 60.6474L67.9363 59.0953ZM0.648546 3.29261L66.4769 58.6337L69.0509 55.5719L3.22255 0.230828L0.648546 3.29261Z"
      fill="#13BF4D"
    />
  </svg>
)
