import { ReactElement, FC, useEffect, useState } from 'react'
import GroupAvatar from 'components/avatar/group'
import Text from 'components/text'
import { RightArrowIcon } from 'theme'
import { getCallTitle, hasOnlyOneGroup, isScheduledStartingSoon } from 'utils/calls'
import { useTranslation } from 'react-i18next'
import { RowsContainer } from 'components/container'
import { useTicker } from 'hooks'
import {
  StyledCircleIcon,
  StyledButton,
  StyledControlsWrapper,
  ActiveCallListItemProps,
  StyledTextWrapper,
  StyledListItem,
} from './style'

const ActiveCallListItem: FC<ActiveCallListItemProps> = ({
  authUser,
  children,
  activeCall,
  $flat,
  $hovered,
  onMouseEnter,
  onMouseLeave,
  onJoinClick,
  onClick,
  bcolor,
  title,
  $secondary,
  ...rest
}): ReactElement => {
  const ticker = useTicker()
  const { t } = useTranslation()
  const [callStatusMsg, setCallStatusMsg] = useState('')
  const callHasOnlyOneGroup = hasOnlyOneGroup(activeCall)

  const handleMouseEnter = () => {
    if (onMouseEnter) onMouseEnter(activeCall)
  }
  const handleMouseLeave = () => {
    if (onMouseLeave) onMouseLeave(activeCall)
  }
  const handleJoinClick = () => {
    onJoinClick(activeCall)
  }
  const handleClick = () => {
    if (onClick) onClick(activeCall)
  }

  const [activeCallTitle, restCount] = getCallTitle(activeCall, 3)

  useEffect(() => {
    setCallStatusMsg(
      isScheduledStartingSoon(activeCall) ? t('CallStartingSoon').toUpperCase() : t('CallInProgress').toUpperCase(),
    )
  }, [activeCall, t, ticker])

  const callColor = isScheduledStartingSoon(activeCall) ? 'malachite' : 'tallpoppy'

  return (
    <StyledListItem
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      $secondary={$secondary}
      $flat={$flat}
      onClick={handleClick}
      {...rest}
    >
      {(!!activeCall.groups?.length || !!activeCall.contacts?.length) && (
        <GroupAvatar
          bcolor={bcolor}
          $secondary={$secondary}
          group={activeCall.groups?.length ? activeCall.groups[0] : { contacts: activeCall.contacts }}
          $hovered={$hovered}
          $noPresence
        />
      )}
      <StyledTextWrapper>
        {callHasOnlyOneGroup ? (
          <Text ml="1rem" size="md" $secondary={!$secondary}>
            {activeCall.groups[0].name}
          </Text>
        ) : (
          <Text ml="1rem" size="md" $secondary={!$secondary}>
            {activeCallTitle}
            {!!restCount && ` + ${restCount}`}
          </Text>
        )}
        <RowsContainer mt="0.2rem" ml="1rem" $center>
          <StyledCircleIcon color={callColor} />
          <Text fw="400" ml="0.5rem" size="xs" color={callColor}>
            {callStatusMsg}
          </Text>
        </RowsContainer>
      </StyledTextWrapper>
      <StyledControlsWrapper>
        {onJoinClick && (
          <StyledButton bcolor="transparent" $flat size="md" borcolor={callColor} onClick={handleJoinClick}>
            <Text fw="600" size="md" mr="0.5rem" color={callColor}>
              {t('JoinNow')}
            </Text>
            <RightArrowIcon color={callColor} title={t('ButtonToJoinThisCall')} />
          </StyledButton>
        )}
      </StyledControlsWrapper>
    </StyledListItem>
  )
}

export default ActiveCallListItem
