import { ReactElement } from 'react'

// eslint-disable-next-line react/require-default-props
export default ({ $gradient, title }: { $gradient?: boolean; title: string }): ReactElement => (
  <svg
    style={{ minWidth: '36px', minHeight: '36px' }}
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.64833 0.5H26.9312C29.0296 0.5 30.7309 2.20132 30.7309 4.29973V31.7003C30.7309 33.7987 29.0296 35.5 26.9312 35.5H7.64833C7.53543 35.5 7.44199 35.4101 7.44199 35.2972V0.702445C7.44199 0.589543 7.53543 0.5 7.64833 0.5ZM12.6166 23.6405C12.6166 20.1075 15.5073 17.2168 19.0403 17.2168C22.5734 17.2168 25.4641 20.1075 25.4641 23.6405V26.0494H12.6166V23.6405ZM19.0401 16.4118C20.8067 16.4118 22.252 14.9665 22.252 13.1999C22.252 11.4334 20.8067 9.98806 19.0401 9.98806C17.2736 9.98806 15.8283 11.4334 15.8283 13.1999C15.8283 14.9665 17.2736 16.4118 19.0401 16.4118ZM4.8086 0.50011H3.95989C1.86536 0.50011 0.160156 2.20143 0.160156 4.29984V31.7C0.160156 33.7984 1.86536 35.4997 3.95989 35.4997H4.8086C4.9215 35.4997 5.01105 35.4102 5.01105 35.2969V0.702555C5.01105 0.589653 4.9215 0.50011 4.8086 0.50011ZM32.4234 5.65446H32.9295C34.1598 5.65446 35.1603 6.65501 35.1607 7.88525V12.6777C35.1607 12.7906 35.0708 12.8802 34.9579 12.8802H32.4234C32.314 12.8802 32.221 12.7906 32.221 12.6777V5.85691C32.221 5.744 32.3144 5.65446 32.4234 5.65446ZM34.9579 14.3649H32.4234C32.3144 14.3649 32.221 14.4584 32.221 14.5674V21.4272C32.221 21.5401 32.314 21.63 32.4234 21.63H34.9579C35.0708 21.63 35.1607 21.5404 35.1607 21.4272V14.5674C35.1603 14.4584 35.0708 14.3649 34.9579 14.3649ZM34.9579 23.1185H32.4234C32.3144 23.1185 32.221 23.2076 32.221 23.3209V30.1418C32.221 30.2547 32.314 30.3442 32.4234 30.3442H32.9295C34.1598 30.3442 35.1603 29.3436 35.1603 28.1138V23.3209C35.1603 23.208 35.0708 23.1185 34.9579 23.1185Z"
      fill={$gradient ? 'url(#paint0_linear)' : 'white'}
    />
    {$gradient && (
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="6.64647"
          y1="44.3183"
          x2="40.5935"
          y2="23.6809"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6BF4F" />
          <stop offset="1" stopColor="#FF00E7" />
        </linearGradient>
      </defs>
    )}
  </svg>
)
