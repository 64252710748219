import { useEffect, useState } from 'react'

export interface ImageProps {
  /**
   * Image source
   */
  src: string
  /**
   * Alt text
   */
  alt: string
}

export default ({ src, alt, ...rest }: ImageProps): React.ReactElement => {
  const [dismounted, setDismounted] = useState(false)
  const [wide, setWide] = useState(false)
  const img = new Image()
  img.onload = () => {
    if (!dismounted && img.width > img.height) setWide(true)
  }
  img.src = src

  useEffect(() => {
    return () => {
      img.onload = null
      setDismounted(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <img width={wide ? 'auto' : '100%'} height={wide ? '100%' : 'auto'} src={src} {...rest} alt={alt} />
}
