/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement, useMemo } from 'react'
import Button from 'components/button'
import Text from 'components/text'
import { CloseIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import {
  StyledContentWrapper,
  StyledFormFieldSelect,
  StyledContentContainer,
  StyledCloseButton,
  StyledHeaderContainer,
} from './style'

const AdvancedSettinsModal = ({
  changeCamera,
  changeMicrophone,
  setAudioOutputDevice,
  selectedAudioInputDeviceId,
  selectedAudioOutputDeviceId,
  selectedVideoDeviceId,
  audioDevices,
  videoDevices,
  onClose,
}): ReactElement => {
  const { t } = useTranslation()

  const audioInputDevices = useMemo(
    () =>
      audioDevices
        .filter((device) => device.kind === 'audioinput')
        .map(({ deviceId, label }) => ({ id: deviceId, label })),
    [audioDevices],
  )

  const cameras = useMemo(
    () =>
      videoDevices
        .filter((device) => device.kind === 'videoinput')
        .map(({ deviceId, label }) => ({ id: deviceId, label })),
    [videoDevices],
  )

  const speakers = useMemo(
    () =>
      audioDevices
        .filter((device) => device.kind === 'audiooutput')
        .map(({ deviceId, label }) => ({ id: deviceId, label })),
    [audioDevices],
  )

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Text component="h1" mt="0" color="brandblack" font="Poppins">
          {t('Settings')}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={onClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledContentWrapper size="sm">
        <StyledFormFieldSelect
          name="mic"
          label={t('Microphone')}
          emptyLabel={t('NoMicrophoneDetected')}
          items={audioInputDevices}
          value={selectedAudioInputDeviceId}
          onChange={changeMicrophone}
        />
        <StyledFormFieldSelect
          name="cam"
          label={t('Camera')}
          emptyLabel={t('NoCameraDetected')}
          items={cameras}
          value={selectedVideoDeviceId}
          onChange={changeCamera}
        />
        <StyledFormFieldSelect
          name="speaker"
          label={t('Speakers')}
          emptyLabel={t('NoSpeakersDetected')}
          items={speakers}
          value={selectedAudioOutputDeviceId}
          onChange={setAudioOutputDevice}
        />
      </StyledContentWrapper>
      <Button
        size="md"
        bcolor="mediumpurple"
        bop="1"
        hgradient="mediumpurple-dim-light"
        agradient="mediumpurple-dim-dark"
        mt="2rem"
        onClick={onClose}
      >
        <Text size="md" $secondary fw="600">
          {t('Done')}
        </Text>
      </Button>
    </StyledContentContainer>
  )
}

export default AdvancedSettinsModal
