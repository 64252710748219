import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V36C40 38.2091 38.2091 40 36 40H4C1.79086 40 0 38.2091 0 36V4Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M30.25 17.8332C31.9167 18.7954 31.9167 21.2011 30.25 22.1633L15.25 30.8236C13.5833 31.7858 11.5 30.583 11.5 28.6585L11.5 11.338C11.5 9.4135 13.5833 8.21068 15.25 9.17294L30.25 17.8332Z"
      stroke="white"
      strokeWidth="3"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="7.41281"
        y1="50.0781"
        x2="46.2092"
        y2="26.493"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6BF4F" />
        <stop offset="1" stopColor="#FF00E7" />
      </linearGradient>
    </defs>
  </svg>
)
