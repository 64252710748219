const colors = {
  white: '255, 255, 255', // #fff
  black: '0, 0, 0', // #000
  brandblack: '17, 17, 27', // #11111b
  cinder: '12, 13, 17', // #0c0d13
  concrete: '242, 242, 242', // #f2f2f2
  mercury: '230, 230, 230', // #e6e6e6
  jumbo: '112, 112, 118', // #707076
  charade: '29, 29, 39', // #1d1d27
  gray: '128, 128, 128', // #808080
  codgray: '10, 10, 10', // #0a0a0a
  shipgray: '65, 65, 73', // #414149
  woodsmoke: '24, 25, 29', // #18191d
  silcer: '196, 196, 196', // #c4c4c4
  mercurylight: '229, 229, 229', // #e5e5e5
  athensgray: '243, 243, 244', // #f3f3f4 #d9d9d9
  alto: '217, 217, 217', // #d9d9d9

  mulberry: '200, 65, 139', // #c8418b
  portage: '171, 129, 238', // #ab81ee
  purple: '147, 93, 234', // #935dea
  darkpurple: '34, 13, 66', // #220d42
  mediumpurple: '141, 83, 233', // #8d53e9
  purplehearth: '126, 61, 230', // #7e3de6
  tallpoppy: '200, 46, 37', // #c82e25
  pink: '252, 78, 170', // #fc4eaa
  salem: '8, 131, 72', // #088348
  tuna: '52, 52, 62', // #34343e
  darktuna: '49, 49, 66', // #313142
  selago: '243, 237, 253', // #f3edfd

  malachite: '19, 191, 77', // #13bf4d

  casablanca: '246, 191, 79', // #f6bf4f
  magentafuchsia: '255, 0, 231', // #ff00e7
  azureradiance: '0, 153, 255', // #0099ff
  hollywoodcerise: '204, 0, 185', // #cc00b9
  jagger: '49, 19, 99', // #311363
  violet: '25, 9, 51', // #190933
  dodgerblue: '64, 153, 247', // #4099F7
}

const fn = {
  up: (breakpoint: string): string => `@media (min-width: ${breakpoint}px)`,
  down: (breakpoint: string): string => `@media (max-width: ${breakpoint}px)`,
  color: (rgb: string, op?: string): string => `rgba(${rgb}, ${op || '1'})`,
  dimLight: (gradientcolor: string, bcolor: string): string =>
    `linear-gradient(0deg, ${fn.color(gradientcolor, '0.08')}, ${fn.color(gradientcolor, '0.08')}), ${fn.color(
      bcolor,
    )}`,
  dimDark: (gradientcolor: string, bcolor: string): string =>
    `linear-gradient(0deg, ${fn.color(gradientcolor, '0.16')}, ${fn.color(gradientcolor, '0.16')}), ${fn.color(
      bcolor,
    )}`,
}

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}

const sizes = {
  xs: '15rem',
  sm: '30rem',
  md: '40rem',
  lg: '50rem',
  xl: '80%',
  xxl: '100%',
}

const shadows = {
  square: `0px 4px 6px -1px ${fn.color(colors.cinder, '0.24')}`,
  outbound: `0px 4px 4px ${fn.color(colors.black, '0.25')}`,
  box: `0px 4px 28px ${fn.color(colors.black, '0.55')}`,
  text: `0px 1px 2px ${fn.color(colors.black, '0.45')}`,
  switch: `0 0 0.125rem 0 ${fn.color(colors.codgray, '0.29')}`,
}

const gradients = {
  magenta: `linear-gradient(58.7deg, ${fn.color(colors.casablanca)} 2%, ${fn.color(colors.magentafuchsia)} 84.61%)`,
  cerise: `linear-gradient(90deg, #0099FF -40.78%, ${fn.color(colors.hollywoodcerise)} 76.54%, ${fn.color(
    colors.magentafuchsia,
  )} 117.97%);`,
  'mediumpurple-dim-light': fn.dimLight(colors.cinder, colors.mediumpurple),
  'mediumpurple-dim-dark': fn.dimDark(colors.cinder, colors.mediumpurple),
  'pink-dim-light': fn.dimLight(colors.cinder, colors.pink),
  'pink-dim-dark': fn.dimDark(colors.cinder, colors.pink),
  radiance: `linear-gradient(90deg, ${fn.color(colors.azureradiance)} -40.78%, ${fn.color(
    colors.hollywoodcerise,
  )} 76.54%, ${fn.color(colors.magentafuchsia)} 117.97%)`,
  'radiance-hov': `linear-gradient(90deg, ${fn.color(colors.azureradiance)} -122.81%, ${fn.color(
    colors.hollywoodcerise,
  )} 65.24%, ${fn.color(colors.magentafuchsia)} 131.64%)`,
  malachite: `linear-gradient(0deg, ${fn.color(colors.black, '0.08')}, ${fn.color(colors.black, '0.08')}), ${fn.color(
    colors.malachite,
  )}`,
  'malachite-dim-light': fn.dimLight(colors.black, colors.malachite),
  'malachite-dim-dark': fn.dimDark(colors.black, colors.malachite),
  'salem-dim-light': fn.dimLight(colors.black, colors.salem),
  'salem-dim-dark': fn.dimDark(colors.black, colors.salem),
  'tallpoppy-dim-light': fn.dimLight(colors.black, colors.tallpoppy),
  'tallpoppy-dim-dark': fn.dimDark(colors.black, colors.tallpoppy),
  radial: `radial-gradient(155.34% 300.28% at 156.48% 158.39%, ${fn.color(colors.hollywoodcerise)} 0%, ${fn.color(
    colors.jagger,
  )} 52.4%, ${fn.color(colors.darkpurple)} 73.24%, ${fn.color(colors.violet)} 96.35%)`,
  shadow: `linear-gradient(180deg, ${fn.color(colors.black, '0')} 0%, ${fn.color(colors.black, '0.6')} 100%)`,
  purple: `linear-gradient(55.75deg, ${fn.color(colors.casablanca)} -6.43%, ${fn.color(
    colors.magentafuchsia,
  )} 6.49%, ${fn.color(colors.purple)} 60.4%)`,
  'purple-yellow': `linear-gradient(55.75deg, ${fn.color(colors.casablanca)} 6.43%, ${fn.color(
    colors.magentafuchsia,
  )} 35.49%, ${fn.color(colors.purple)} 60.4%)`,
  'purple-blue': `linear-gradient(
    276.81deg,
    ${fn.color(colors.casablanca, '0.1')} -5.53%,
    ${fn.color(colors.magentafuchsia, '0.1')} 22.36%,
    ${fn.color(colors.purple, '0.1')} 53.51%,
    ${fn.color(colors.dodgerblue, '0.1')} 87.75%
  )`,
}

const allThemes = {
  data: {
    default: {
      id: 'T_000',
      name: 'Default',
      palette: {
        primary: {
          main: colors.black,
        },
        secondary: {
          main: colors.white,
        },
        text: {
          primary: colors.brandblack,
          secondary: colors.white,
          ...colors,
        },
        background: {
          primary: colors.darkpurple,
          secondary: colors.white,
          ...colors,
        },
      },
      breakpoints,
      shadows,
      sizes,
      gradients,
      fonts: {
        Inter: 'Inter',
        Poppins: 'Poppins',
      },
      colors,
      fn,
    },
  },
}

export default allThemes
