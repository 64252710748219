/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { meetUrl } from 'const'
import useMeetApi from './useMeetApi'

export default (shouldConnect: boolean): any => {
  const api = useMeetApi(shouldConnect)
  const [connection, setConnection] = useState(null)

  useEffect(() => {
    if (api && shouldConnect) {
      const url = meetUrl
      const { JitsiConnection } = api
      const options = {
        hosts: {
          domain: url,
          muc: `conference.${url}`,
        },
        serviceUrl: `https://${url}/http-bind`,
        clientNode: 'https://jitsi.org/jitsimeet',
      }

      const newConnection = new JitsiConnection(null, null, options)

      const onConnectionSuccess = () => {
        setConnection(newConnection)
      }

      const onConnectionFailed = (err) => {
        console.error(err)
      }

      const disconnect = () => {
        newConnection.removeEventListener(api.events.connection.CONNECTION_ESTABLISHED, onConnectionSuccess)
        newConnection.removeEventListener(api.events.connection.CONNECTION_FAILED, onConnectionFailed)
        newConnection.removeEventListener(api.events.connection.CONNECTION_DISCONNECTED, disconnect)
      }

      newConnection.addEventListener(api.events.connection.CONNECTION_ESTABLISHED, onConnectionSuccess)
      newConnection.addEventListener(api.events.connection.CONNECTION_FAILED, onConnectionFailed)
      newConnection.addEventListener(api.events.connection.CONNECTION_DISCONNECTED, disconnect)

      newConnection.connect()
      newConnection.unsubscribe = disconnect
    }
  }, [api, shouldConnect])

  return [api, connection]
}
