import { ReactElement, useEffect, useLayoutEffect } from 'react'
import Text from 'components/text'
import { useTranslation } from 'react-i18next'
import Button from 'components/button'
import { useQueryParam, StringParam } from 'use-query-params'
import { CloseIcon } from 'theme'
import { useDispatch } from 'react-redux'
import { sendGuardianRequest } from 'reducers/guardianRequestsSlice'
import { modalQueryParam, emailQueryParam, modalManagedUserRequestSentQueryParam } from 'const'
import {
  StyledContentContainer,
  StyledTextContainer,
  StyledDescriptionContainer,
  StyledCloseButton,
  StyledHeaderContainer,
} from './style'

const { $ } = window

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ExistingManagedUserModal = ({ onClose }): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [, setModalParam] = useQueryParam(modalQueryParam, StringParam)
  const [emailParam, setEmailQueryParam] = useQueryParam(emailQueryParam, StringParam)

  const handleClose = () => {
    setEmailQueryParam(null, 'replaceIn')
    onClose()
  }

  useLayoutEffect(() => {
    if (!emailParam) handleClose()
  })

  const handleSendRequest = async () => {
    await dispatch(sendGuardianRequest({ email: decodeURIComponent(emailParam) }))
    setModalParam(modalManagedUserRequestSentQueryParam, 'replaceIn')
  }

  useEffect(() => {
    const el = $('.existing-managed-user-send-request-button')?.[0]
    if (el) el.focus()
  }, [])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledTextContainer>
        <StyledDescriptionContainer>
          <Text size="lg">{t('SendAnEmailAndNotificationTo')}&nbsp;</Text>
          <Text size="lg" gradient="radiance">
            {decodeURIComponent(emailParam)}&nbsp;
          </Text>
          <Text size="lg">{t('RequestingThemToAcceptYourInvitationToBecomeTheirGuardianUser')}&nbsp;</Text>
        </StyledDescriptionContainer>
      </StyledTextContainer>
      <Button
        size="md"
        bcolor="mediumpurple"
        bop="1"
        mt="3rem"
        hgradient="mediumpurple-dim-light"
        agradient="mediumpurple-dim-dark"
        onClick={handleSendRequest}
        className="existing-managed-user-send-request-button"
      >
        <Text size="md" $secondary fw="600">
          {t('YesSendRequest')}
        </Text>
      </Button>
    </StyledContentContainer>
  )
}

export default ExistingManagedUserModal
