import { ReactElement } from 'react'

export default ({ title, $haveNewChatMessage }: { title: string; $haveNewChatMessage: boolean }): ReactElement => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <circle cx="24" cy="24" r="24" fill={$haveNewChatMessage ? '#FFC700' : 'white'} fillOpacity="0.38" />
    <path
      d="M33.9512 32.5332C31.6833 34.8012 28.7954 36.0713 25.8331 36.3436C24.0919 36.5037 22.3251 36.319 20.6433 35.7896L15.5628 37.5L15.2841 32.5332C10.1293 27.3785 10.1293 19.0209 15.2841 13.8661C20.4389 8.7113 28.7964 8.7113 33.9512 13.8661C39.106 19.0209 39.106 27.3785 33.9512 32.5332Z"
      stroke={$haveNewChatMessage ? '#FFC700' : 'white'}
      strokeOpacity="0.6"
      strokeWidth="3.15208"
    />
    <line
      x1="19.5449"
      y1="20.8633"
      x2="30.5176"
      y2="20.8633"
      stroke={$haveNewChatMessage ? '#FFC700' : 'white'}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="19.5449"
      y1="25.1875"
      x2="27.2745"
      y2="25.1875"
      stroke={$haveNewChatMessage ? '#FFC700' : 'white'}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)
