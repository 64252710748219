/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, FC, useEffect, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CallNormalized, updateInvitation, setCallNotCurrent } from 'reducers/callsSlice'
import Button from 'components/button'
import Text from 'components/text'
import Avatar from 'components/avatar'
import { useDispatch } from 'react-redux'
import { CallInviteeStatuses } from 'apis'
import { isElectronRuntime } from 'const'
import { ColumnsContainer } from 'components/container'
import { getHost, isOneToOne, getInvitees, beep } from 'utils/calls'
import { StyledRowsContainer, StyledContentContainer } from './style'

const { $ } = window

// On instant call invitation via socket.io
const InviteeCallInvitation: FC<{
  call: CallNormalized
  setWebNotification: (notif: any) => void
}> = ({ call, setWebNotification }): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const host = getHost(call)
  const invitees = getInvitees(call)
  const inviteesCountWithoutTheCurrent = invitees.length - 1
  const beepInterval = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    return () => {
      if (beepInterval.current) clearInterval(beepInterval.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Acepts call invitation
  const handleAccept = useCallback(() => {
    dispatch(
      updateInvitation({
        callId: call.id,
        invitation: { status: CallInviteeStatuses.accepted },
      }),
    )
  }, [call, dispatch])

  // Rejects call invitation
  const handleReject = async () => {
    await dispatch(
      updateInvitation({
        callId: call.id,
        invitation: { status: CallInviteeStatuses.rejected },
      }),
    )
    dispatch(setCallNotCurrent(call.id))
  }

  useEffect(() => {
    try {
      if (call && host) {
        setWebNotification({
          id: call.id,
          title: t('ClanzyCallNotification'),
          options: {
            body: `${host.displayName} ${t('IsCallingYou')}\n\n${t('ClickHereToJoinTheCall')}`,
            requireInteraction: true,
          },
          ignore: false,
          disableActiveWindow: true,
          timeout: 0,
          onClick: () => {
            if (isElectronRuntime) {
              const win = window.electronNodeAPI?.remote?.getCurrentWebContents()

              if (win) {
                window.electronNodeAPI?.show()
                win.focus()
              }
            } else {
              window.focus()
            }

            handleAccept()
          },
        })
      }

      beep()
      let cnt = 1
      beepInterval.current = setInterval(() => {
        if (cnt === 4) {
          clearInterval(beepInterval.current)
          beepInterval.current = null
        } else {
          beep()
          cnt += 1
        }
      }, 3000)
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, [call, handleAccept, host, setWebNotification, t])

  useEffect(() => {
    if (call) {
      const el = $(`.call-invitation-accept-button-${call.id}`)?.[0]
      if (el) el.focus()
    }
  }, [call])

  return (
    <StyledContentContainer>
      <ColumnsContainer>
        <StyledRowsContainer>
          <Avatar size="xxxl" fontSize="xxxxl" name={host.displayName} img={host.photo} alt={t('PhotoOfContact')} />
          {!isOneToOne(call) ? (
            <Text ml="1.5625rem" size="xxl" $secondary maxw="50%">
              {host.displayName}&nbsp;{t('IsCallingYou')}
            </Text>
          ) : (
            <>
              <Text ml="1.5625rem" size="xxl" $secondary maxw="50%">
                <>
                  {host.displayName}&nbsp;{t('IsInvitingYouToJoinACall')}
                  {!!inviteesCountWithoutTheCurrent && (
                    <>
                      &nbsp;{t('With')}&nbsp;{inviteesCountWithoutTheCurrent}&nbsp;
                      {inviteesCountWithoutTheCurrent === 1 ? t('Other') : t('Others')}
                    </>
                  )}
                </>
              </Text>
            </>
          )}
        </StyledRowsContainer>
        <StyledRowsContainer mt="5.1875rem">
          <Button
            hgradient="tallpoppy-dim-light"
            bophov="1"
            bopact="1"
            agradient="tallpoppy-dim-dark"
            size="md"
            bcolor="tallpoppy"
            bop="1"
            onClick={handleReject}
          >
            <Text fw="600" size="md" $secondary>
              {t('NotNow')}
            </Text>
          </Button>
          <Button
            ml="1.5rem"
            hgradient="salem-dim-light"
            bophov="1"
            bopact="1"
            agradient="salem-dim-dark"
            size="md"
            bcolor="salem"
            bop="1"
            onClick={handleAccept}
            className={`call-invitation-accept-button-${call.id}`}
          >
            <Text fw="600" size="md" $secondary>
              {t('AnswerThisCall')}
            </Text>
          </Button>
        </StyledRowsContainer>
      </ColumnsContainer>
    </StyledContentContainer>
  )
}

export default InviteeCallInvitation
