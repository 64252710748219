/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { ReactElement, FC } from 'react'
import { Link } from 'react-router-dom'
import Button from 'components/button'
import StyledListItem, { MenuListItemProps } from './style'

const MenuListItem: FC<MenuListItemProps> = ({ children, onClick, replace, link, ...rest }): ReactElement => {
  const listItem = <StyledListItem {...rest}>{children}</StyledListItem>

  if (onClick)
    return (
      <Button $link onClick={onClick}>
        {listItem}
      </Button>
    )

  return link ? (
    <Link replace={replace} style={{ textDecoration: 'none' }} to={link}>
      {listItem}
    </Link>
  ) : (
    listItem
  )
}

export * from './style'

export default MenuListItem
