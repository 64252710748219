import { ReactElement } from 'react'
import { withTheme } from 'styled-components'

export default withTheme(
  ({ theme: { fn, palette }, secondary, title }): ReactElement => {
    const color = (fn.color as (arg0: string, arg1: string) => string)(
      secondary ? palette.secondary.main : palette.primary.main,
      '0.6',
    )
    return (
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H32C36.4183 0 40 3.58172 40 8V32C40 36.4183 36.4183 40 32 40H8C3.58172 40 0 36.4183 0 32V8Z"
          fill={color}
          fillOpacity="0.1"
        />
        <path d="M28 19L28 12L21 12" stroke="white" strokeOpacity="0.6" strokeWidth="3" strokeLinecap="round" />
        <path d="M12 21L12 28L19 28" stroke="white" strokeOpacity="0.6" strokeWidth="3" strokeLinecap="round" />
      </svg>
    )
  },
)
