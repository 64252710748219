import { ReactElement } from 'react'
import { ContentContainer } from 'components/container'
import Button from 'components/button'
import Text from 'components/text'
import { NewCallIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import { modalQueryParam, modalCreateCallQueryParam } from 'const'
import { useQueryParam, StringParam } from 'use-query-params'
import { StyledColumnsContainer, StyledRowsContainder } from './style'

export default (): ReactElement => {
  const { t } = useTranslation()
  const [, setModalParam] = useQueryParam(modalQueryParam, StringParam)

  const handleClick = () => {
    setModalParam(modalCreateCallQueryParam, 'replaceIn')
  }

  return (
    <StyledColumnsContainer mt="2rem">
      <ContentContainer>
        <StyledRowsContainder>
          <Button
            data-tour="first-step"
            size="lg"
            gradient="radiance"
            hgradient="radiance-hov"
            bcoloract="magentafuchsia"
            onClick={handleClick}
          >
            <NewCallIcon title={t('NewCall')} />
            <Text size="md" ml="0.1rem" $secondary fw="600">
              {t('NewCall')}
            </Text>
          </Button>
        </StyledRowsContainder>
      </ContentContainer>
    </StyledColumnsContainer>
  )
}
