/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Card from 'components/card'
import { connect } from 'react-redux'
import { selectAuthUser } from 'reducers/usersSlice'
import { selectAllIncomingNotifications } from 'reducers/guardianRequestsSlice'
import { getOnePending } from 'utils/guardianRequests'
import { useMemo } from 'react'
import { TransparentBackground } from '../../backgrounds'
import PermissionRequest from './permission-request'

const DashboardGuardianToolsNotifications = ({ authUser, getIncomingGuardianRequestsNotificationsByUserId }) => {
  const incomingGuardianRequestsNotifications = useMemo(
    () => (authUser ? getIncomingGuardianRequestsNotificationsByUserId(authUser.id) : null),
    [authUser, getIncomingGuardianRequestsNotificationsByUserId],
  )
  const pendingGuardianRequest = getOnePending(incomingGuardianRequestsNotifications)

  if (pendingGuardianRequest) {
    return (
      <TransparentBackground>
        <Card size="md" secondary="true">
          <PermissionRequest authUser={authUser} pendingGuardianRequest={pendingGuardianRequest} />
        </Card>
      </TransparentBackground>
    )
  }

  return null
}

const mapStateToProps = (state) => ({
  authUser: selectAuthUser(state),
  getIncomingGuardianRequestsNotificationsByUserId: (userId: string) => selectAllIncomingNotifications(userId)(state),
})

export default connect(mapStateToProps, null)(DashboardGuardianToolsNotifications)
