import { ReactElement } from 'react'

export default (): ReactElement => (
  <svg width="78" height="91" viewBox="0 0 78 91" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M75.9707 1.81264C75.8668 0.712972 74.8911 -0.0942669 73.7915 0.00962225L55.8713 1.70265C54.7716 1.80654 53.9643 2.78222 54.0682 3.8819C54.1721 4.98158 55.1478 5.78881 56.2475 5.68492L72.1765 4.18001L73.6815 20.1091C73.7854 21.2087 74.761 22.016 75.8607 21.9121C76.9604 21.8082 77.7676 20.8325 77.6637 19.7328L75.9707 1.81264ZM3.54096 90.2749L75.5205 3.27568L72.4386 0.72583L0.459039 87.7251L3.54096 90.2749Z"
      fill="#13BF4D"
    />
  </svg>
)
