/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import { RowsContainer, ColumnsContainer, ContentContainer } from 'components/container'
import Button from 'components/button'

export const StyledContentContainer = styled(ContentContainer)`
  margin: 1.125rem 0;
`

export const StyledHeaderContainer = styled(RowsContainer)`
  height: 2.8125rem;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
`

export const StyledTextWrapper = styled(ColumnsContainer)`
  align-items: flex-start;
  justify-content: center;
  margin-left: 1rem;
`

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

export const StyledRowsContainer = styled(RowsContainer)`
  margin-top: 1.5rem;
  align-items: center;
  justify-content: center;
`

export const StyledRowsRightContainer = styled(RowsContainer)`
  box-sizing: border-box;
  padding: 0 1rem;
  margin-top: 1.5rem;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`

export const StyledTextContainer = styled(ColumnsContainer)`
  text-align: center;
  position: relative;
  padding: 0 3rem;
`
