/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import Button from 'components/button'
import Text from 'components/text'
import List from 'components/list'
import { ColumnsContainer, ContentContainer, RowsContainer } from 'components/container'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

export const StyledContentContainer = styled(ContentContainer)`
  margin: 1.125rem;
`

export const StyledIconTickWrapper = styled.div`
  width: 1rem;
  margin-right: 1rem;
`

export const StyledFooterWrapper = styled(ColumnsContainer)`
  margin-top: 0.5rem;
  width: 100%;
`

export const StyledContentWrapper = styled(ContentContainer)`
  margin-top: 0.5rem;
  width: 100%;
`

export const StyledHeaderContainer = styled(RowsContainer)`
  height: 2.8125rem;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
`

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

export const StyledButtonsWrapper = styled(ColumnsContainer)`
  width: 100%;
`

export const StyledBackButton = styled(Button)`
  position: absolute;
  left: 1rem;
  top: 1rem;
`

export const StyledText = styled(Text)`
  text-align: left;
  width: 100%;
`

export const StyledParticipantsWrapper = styled(ContentContainer)`
  width: 100%;
`

export const StyledVerticalScrollbar = styled(OverlayScrollbarsComponent).attrs((props) => ({
  ...props,
  resize: 'vertical',
}))`
  height: 13rem;
  width: 100%;
  overflow-x: hidden;
  margin-right: 0.3rem;

  &.os-host-scrollbar-vertical-hidden .os-content > section {
    margin-right: 0 !important;
  }

  &.os-host-scrollbar-vertical-hidden {
    margin-right: 0 !important;
  }

  .os-scrollbar-horizontal {
    display: none;
  }
`

export const StyledListItem = styled(RowsContainer)`
  padding: 0.75rem 0;
  align-items: center;
`

export const StyledTextWrapper = styled(RowsContainer)`
  max-width: 65%;
  justify-content: center;
  align-items: center;
`

export const StyledListColumn = styled(List)`
  flex-direction: column;
  margin-right: 1rem;
  width: auto;
`

export const StyledControlsWrapper = styled(RowsContainer)`
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
`
