/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
export default (
  socket: { emit: { bind: (arg0: any) => any }; on: (arg0: string, arg1: any) => void },
  criteria = [],
  { eventName = 'action', execute = null, serverEventsMapping = null } = {},
): any => {
  const emitBound = socket.emit.bind(socket)

  // eslint-disable-next-line no-param-reassign
  execute =
    execute ||
    ((action: any, emit: (arg0: string, arg1: any, arg2?: any) => void, next: (arg0: any) => any) => {
      const { cb } = action
      delete action.cb
      emit(eventName, action, cb)
      return next(action)
    })

  const evaluate: (arg0: any, arg1: any) => any = (action, option) => {
    if (!action || !action.type) {
      return false
    }

    const { type } = action
    let matched = false
    if (typeof option === 'function') {
      matched = option(type, action)
    } else if (typeof option === 'string') {
      matched = type.indexOf(option) === 0
    } else if (Array.isArray(option)) {
      matched = option.some((item) => type.indexOf(item) === 0)
    }
    return matched
  }

  return ({ dispatch, getState }) => {
    if (serverEventsMapping) {
      const handleDispatch = (key) => (type) => {
        let action = type

        if (typeof action !== 'string') {
          if (typeof action?.listen !== 'undefined' && !action.listen) return
          action = action?.action
        }

        socket.on(key, (payload) => {
          if (typeof type !== 'string' && type?.map) {
            payload = type.map({ state: getState(), payload })
          }

          if (type.actionCreator) {
            if (Array.isArray(type.actionCreator)) {
              type.actionCreator.forEach(() => dispatch(type.actionCreator(payload)))
            } else {
              dispatch(type.actionCreator(payload))
            }
          } else {
            dispatch(action ? { type: action, payload } : payload)
          }
        })
      }

      Object.keys(serverEventsMapping).forEach((key: string) => {
        const type = serverEventsMapping[key]
        const action = type

        if (Array.isArray(action)) {
          action.forEach(handleDispatch(key))
        } else {
          handleDispatch(key)(action)
        }
      })
    } else {
      socket.on(eventName, dispatch)
    }

    return (next: (arg0: any) => any) => (action: any) => {
      if (evaluate(action, criteria)) {
        return execute(action, emitBound, next, dispatch)
      }
      return next(action)
    }
  }
}
