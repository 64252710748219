/* eslint-disable react/style-prop-object */
import { ReactElement } from 'react'
import { withTheme } from 'styled-components'

export default withTheme(
  ({ theme: { fn, palette }, secondary, title }): ReactElement => {
    const color = (fn.color as (arg0: string) => string)(secondary ? palette.secondary.main : palette.primary.main)
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        version="1.1"
        id="svg97"
      >
        <title>{title}</title>
        <circle cx="24" cy="24" r="24" fill={color} fillOpacity="0.38" id="circle84" />
        <g id="g1093" transform="matrix(0.5, 0, 0, 0.5, 12, 9)">
          <path
            id="path1099"
            fill="none"
            stroke={color}
            strokeWidth="8.1912"
            strokeLinejoin="round"
            d="m 6.6184,7.7326 c -1.0713,0 -1.9337,0.8624 -1.9337,1.9337 v 38.207 c 0,1.072 0.8624,1.934 1.9337,1.934 h 25.905 c 1.071,0 1.934,-0.862 1.934,-1.934 V 9.6663 c 0,-1.071 -0.863,-1.9334 -1.934,-1.9334 H 6.6184 Z"
          />
          <path
            id="rect1107"
            fillRule="evenodd"
            fill={color}
            stroke="#000000"
            strokeWidth="3.2584"
            strokeLinejoin="round"
            d="m 6.8352,7.0678 c -1.108,0 -2,0.892 -2,2 v 39.516 c 0,1.108 0.892,2 2,2 h 26.792 c 1.108,0 2,-0.892 2,-2 V 9.0678 c 0,-1.1082 -0.892,-2.0002 -2,-2.0002 H 6.8352 Z"
            transform="matrix(0.96686,0,0,0.96686,0.009721,0.899)"
          />
          <path
            id="path1101"
            fill="none"
            stroke={color}
            strokeWidth="8.1912"
            strokeLinejoin="round"
            d="M 32.321,5.3051 C 31.393,4.7695 30.215,5.0852 29.679,6.0129 l -19.103,33.088 c -0.536,0.928 -0.22,2.106 0.707,2.642 l 22.434,12.952 c 0.928,0.536 2.106,0.22 2.642,-0.708 l 19.103,-33.088 c 0.536,-0.928 0.22,-2.106 -0.707,-2.642 L 32.321,5.3049 Z"
          />
          <path
            id="rect715"
            fillRule="evenodd"
            fill={color}
            stroke="#000000"
            strokeWidth="3.1505"
            strokeLinejoin="round"
            d="M 32.321,5.3051 C 31.393,4.7695 30.215,5.0852 29.679,6.0129 l -19.103,33.088 c -0.536,0.928 -0.22,2.106 0.707,2.642 l 22.434,12.952 c 0.928,0.536 2.106,0.22 2.642,-0.708 l 19.103,-33.088 c 0.536,-0.928 0.22,-2.106 -0.707,-2.642 L 32.321,5.3049 Z"
          />
          <path
            id="path717"
            fillRule="evenodd"
            fill="#000000"
            stroke="000000"
            strokeWidth="1.2602"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m 38.007,21.095 c -2.285,-1.319 -5.206,-0.536 -6.526,1.749 -0.221,0.383 -0.371,0.785 -0.477,1.193 -2.257,-1.182 -5.07,-0.407 -6.355,1.82 -1.32,2.285 -0.527,5.212 1.758,6.532 1.027,0.593 2.173,0.705 3.255,0.503 -3.07,2.045 -6.071,2.776 -5.887,2.726 l 8.86,5.115 c 0,0 -0.852,-2.973 -0.464,-6.643 0.339,1.124 1.063,2.135 2.159,2.767 2.285,1.32 5.207,0.537 6.526,-1.748 1.319,-2.285 0.537,-5.207 -1.748,-6.526 -0.021,-0.012 -0.045,-0.013 -0.066,-0.024 0.27,-0.278 0.511,-0.588 0.714,-0.938 1.319,-2.285 0.536,-5.206 -1.749,-6.526 z"
          />
        </g>
      </svg>
    )
  },
)
