import { ReactElement } from 'react'
import { withTheme } from 'styled-components'

export default withTheme(
  ({ theme, $noSuccess = false, title }): ReactElement => {
    const color = (theme.fn.color as (arg0: string) => string)($noSuccess ? theme.colors.tallpoppy : theme.colors.white)
    const cop = $noSuccess ? '1' : '0.4'
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <g opacity={cop}>
          <path d="M17.1071 6.39258L5.5 17.9997" stroke={color} strokeWidth="2" strokeLinecap="round" />
          <path
            d="M9.07227 5.5L17.5008 5.5C17.777 5.5 18.0008 5.72386 18.0008 6L18.0008 14.4286"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </g>
      </svg>
    )
  },
)
