/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement, useEffect, useMemo } from 'react'
import Text from 'components/text'
import Button from 'components/button'
import { useTranslation } from 'react-i18next'
import { selectUserById } from 'reducers/usersSlice'
import { managedUserIdQueryParam, isNewQueryParam } from 'const'
import { useQueryParam, StringParam, BooleanParam } from 'use-query-params'
import { CloseIcon, TickIcon } from 'theme'
import { connect } from 'react-redux'
import {
  StyledButtonsWrapper,
  StyledContentContainer,
  StyledTextContainer,
  StyledTitleContainer,
  StyledCloseButton,
  StyledHeaderContainer,
} from './style'

const { $ } = window

const NewManagedUserUpdated = ({ getUserById, onClose }): ReactElement => {
  const { t } = useTranslation()
  const [, setIsNew] = useQueryParam(isNewQueryParam, BooleanParam)
  const [managedUserIdParam, setManagedUserIdQueryParam] = useQueryParam(managedUserIdQueryParam, StringParam)

  const user = useMemo(() => (managedUserIdParam ? getUserById(managedUserIdParam) : null), [
    getUserById,
    managedUserIdParam,
  ])

  const handleClose = () => {
    setIsNew(null, 'replaceIn')
    setManagedUserIdQueryParam(null, 'replaceIn')
    onClose()
  }

  useEffect(() => {
    if (!managedUserIdParam) handleClose()
  })

  useEffect(() => {
    const el = $('.new-managed-user-updated-done-button')?.[0]
    if (el) el.focus()
  }, [])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledTitleContainer>
        <Text size="xl" gradient="purple" fw="600" font="Poppins" $block>
          {`${user?.firstName} ${user?.lastName}'s ${t('ClanzyProfileIsNowReady')}`}
        </Text>
      </StyledTitleContainer>
      <TickIcon title={t('TickIcon')} />
      <StyledTextContainer>
        <Text mt="1rem" color="brandblack" $block>
          {t('ToStartUsingClanzyTheyNeedToClickTheButtonInTheEmailDescriptionText')}
        </Text>
      </StyledTextContainer>
      <StyledButtonsWrapper>
        <Button
          size="md"
          bcolor="mediumpurple"
          bop="1"
          ml="1rem"
          hgradient="mediumpurple-dim-light"
          agradient="mediumpurple-dim-dark"
          onClick={handleClose}
          className="new-managed-user-updated-done-button"
        >
          <Text size="md" $secondary fw="600">
            {t('Done')}
          </Text>
        </Button>
      </StyledButtonsWrapper>
    </StyledContentContainer>
  )
}

const mapStateToProps = (state) => ({
  getUserById: (userId: string) => selectUserById(userId)(state),
})

export default connect(mapStateToProps, null)(NewManagedUserUpdated)
