/* eslint-disable import/prefer-default-export */
import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  html {
    font-size: 16px;
  }

  body {
    transition: all 0.30s linear;
    outline: none;
  }

  #root {
    position: relative;
  }

  #cloudsponge-root-holder {
    visibility: hidden;
  }

  [role="tooltip"] {
    z-index: 9999;
  }

  .react-calendar {
    border: none;
    color: black;
  } 
  
  // .focus-visible {
  //   border: 1px solid rgba(141, 83, 233, 1);
  // }

  .os-theme-light > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
    background: rgba(0, 0, 0, 0.2);
  }

  .os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
    background: rgba(255, 255, 255, 0.2);
  }
`
