/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import CallsEntertainmentNotifications from './entertainment'
import CallsGuardianToolsNotifications from './guardian-tools'

export default ({
  declineScreenShareRequest,
  shareScreen,
  user,
  call,
  guardianRequests,
  screenShareId,
  declineUnmuteRequest,
  declineCameraOnRequest,
  acceptUnmuteRequest,
  acceptCameraOnRequest,
}) => {
  return (
    <>
      <CallsEntertainmentNotifications user={user} call={call} />
      <CallsGuardianToolsNotifications
        shareScreen={shareScreen}
        declineScreenShareRequest={declineScreenShareRequest}
        guardianRequests={guardianRequests}
        screenShareId={screenShareId}
        declineUnmuteRequest={declineUnmuteRequest}
        declineCameraOnRequest={declineCameraOnRequest}
        acceptUnmuteRequest={acceptUnmuteRequest}
        acceptCameraOnRequest={acceptCameraOnRequest}
      />
    </>
  )
}
