/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FC, ReactElement, useEffect } from 'react'
import Button from 'components/button'
import { useDispatch } from 'react-redux'
import Text from 'components/text'
import { games } from 'const'
import { CloseIcon, RummyImage } from 'theme'
import { useTranslation } from 'react-i18next'
import { CallNormalized, updateEntertainmentParticipant } from 'reducers/callsSlice'
import { getEntertainmentHost, getCurrentEntertainment, getCallParticipantById } from 'utils/calls'
import { CallEntertainmentParticipantStatuses } from 'apis'
import { UserNormalized } from 'reducers/usersSlice'
import {
  StyledTextWrapper,
  StyledButtonsWrapper,
  StyledContentWrapper,
  StyledContentContainer,
  StyledCloseButton,
  StyledHeaderContainer,
} from './style'

const { $ } = window

const GameInvitation: FC<{ call: CallNormalized; user: UserNormalized }> = ({ call, user }): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentEntertainment = getCurrentEntertainment(call, user)
  const entertainmentHost = getEntertainmentHost(currentEntertainment)
  const callParticipant = getCallParticipantById(call, entertainmentHost.callParticipantId)

  const handleJoin = () => {
    dispatch(
      updateEntertainmentParticipant({
        callId: call.id,
        entertainmentId: currentEntertainment.id,
        participant: { status: CallEntertainmentParticipantStatuses.accepted },
      }),
    )
  }

  const handleClose = () => {
    dispatch(
      updateEntertainmentParticipant({
        callId: call.id,
        entertainmentId: currentEntertainment.id,
        participant: { status: CallEntertainmentParticipantStatuses.rejected },
      }),
    )
  }

  useEffect(() => {
    if (call) {
      const el = $(`.join-game-${call.id}`)?.[0]
      if (el) el.focus()
    }
  }, [call])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Text component="h1" mt="0" color="brandblack" font="Poppins">
          {currentEntertainment?.name === games.rummy ? t('Rummy') : ''}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledContentWrapper>
        <RummyImage />
        <StyledTextWrapper>
          <Text mt="1rem" color="brandblack" size="lg" fw="600">
            {`${callParticipant?.displayName} ${t('IsInvitingYouToPlay')} ${
              currentEntertainment?.name === games.rummy ? t('Rummy') : ''
            }. ${t('DoYouWantToJoinTheTable')}`}
          </Text>
        </StyledTextWrapper>
        <StyledButtonsWrapper>
          <Button
            mt="2rem"
            type="submit"
            size="md"
            bcolor="mediumpurple"
            bop="1"
            hgradient="mediumpurple-dim-light"
            agradient="mediumpurple-dim-dark"
            onClick={handleJoin}
            className={`join-game-${call.id}`}
          >
            <Text size="md" $secondary fw="600">
              {t('IAmIn')}
            </Text>
          </Button>
          <Button mt="0.5rem" size="md" subtle="true" borcolor="mediumpurple" borsize="0.125rem" onClick={handleClose}>
            <Text size="md" color="brandblack" fw="600">
              {t('NotNow')}
            </Text>
          </Button>
        </StyledButtonsWrapper>
      </StyledContentWrapper>
    </StyledContentContainer>
  )
}

export default GameInvitation
