/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import Button from 'components/button'
import { ColumnsContainer, ContentContainer, RowsContainer } from 'components/container'

export const StyledImageWrapper = styled.div`
  padding-top: 2rem;
  position: relative;
`

export const StyledTextContainer = styled.article`
  text-align: center;
  position: relative;
  padding: 0 1.8rem;
`

export const StyledContentContainer = styled(ContentContainer)`
  margin: 1.125rem 0 2rem 0;
`

export const StyledHeaderContainer = styled(RowsContainer)`
  height: 2.8125rem;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
`

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

export const StyledContentWrapper = styled(ColumnsContainer)`
  margin-top: 2rem;
  padding: 2rem 0;
  width: 100%;
  text-align: center;
  background: ${({ theme: { gradients } }) => gradients.magenta};
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
`

export const StyledIconWrapper = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 11rem;
`
