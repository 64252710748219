/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import Button from 'components/button'
import Text from 'components/text'
import { ColumnsContainer, ContentContainer, RowsContainer } from 'components/container'

export const StyledTextContainer = styled(ColumnsContainer)`
  text-align: center;
  position: relative;
`

export const StyledButtonsWrapper = styled(ColumnsContainer)`
  width: 100%;
  box-sizing: border-box;
  padding 0 1.5rem;

  & button {
    flex-grow: 1;
  }
`

export const StyledContentContainer = styled(ContentContainer)`
  margin: 1.125rem 0 1rem 0;
`

export const StyledHeaderContainer = styled(RowsContainer)`
  height: 2.8125rem;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
`

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

export const StyledDescriptionContainer = styled.div`
  margin-top: 1rem;
  box-sizing: border-box;
  padding 0 1rem;
`

export const StyledIconTickWrapper = styled.div`
  width: 1rem;
`

export const StyledRowsContainer = styled(RowsContainer)`
  margin-top: 0.5rem;
  align-items: center;
  justify-content: center;
`

export const StyledText = styled(Text)`
  padding: 0 2rem;
`
