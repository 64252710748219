/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import { apiUrl } from 'const'
import { setTokenInterceptor, responseInterceptorError } from 'utils/apis'

export interface ManagedUserData {
  id: string
  username?: string
  firstName?: string
  lastName?: string
  flags?: string[]
}

export interface ApiCreateManagedUser {
  email: string
}

export interface ApiUpdateManagedUser {
  id: string
  username?: string
  firstName?: string
  lastName?: string
  photo?: string
}

export interface ApiGetManagedContacts {
  id: string
}

export interface ApiGetManagedGroups {
  id: string
}

export interface ManagedContactData {
  id?: string
  firstName?: string
  lastName?: string
  email: string
}

export interface ManagedGroupData {
  id?: string
  description?: string
  name?: string
  photo?: string
  contacts: any[]
}

export interface ApiPostManagedContacts {
  userId: string
  contacts: ManagedContactData[]
}

export interface ApiPostManagedGroups {
  userId: string
  groups: ManagedGroupData[]
}

export interface ApiAddManagedContacts {
  userId: string
  contactIds: string[]
}

export interface ApiAddManagedGroups {
  userId: string
  groupIds: string[]
}

const instance = axios.create({
  baseURL: `${apiUrl}/guardians`,
})

export const apiCreateManagedUserError = 'User with email already present'

instance.interceptors.request.use(setTokenInterceptor(true, false))
instance.interceptors.response.use(undefined, responseInterceptorError)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const apiCreateManagedUser = async (data: ApiCreateManagedUser) => {
  const res = await instance({
    url: '/users',
    method: 'POST',
    data,
  })
  return res.data
}

export const apiUpdateManagedUser = async (data: ApiUpdateManagedUser): Promise<ManagedUserData> => {
  const res = await instance({
    url: `/profile/${data.id}`,
    method: 'PUT',
    data,
  })
  return res.data
}

export const apiRemoveManagedUser = async (userId: string): Promise<void> => {
  await instance({
    url: `/users/${userId}`,
    method: 'DELETE',
  })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const apiGetAllManagedUsers = async () => {
  const res = await instance({
    url: '/users',
    method: 'GET',
  })
  return res.data
}

export const apiGetManagedUser = async (userId: string): Promise<ManagedUserData> => {
  const res = await instance({
    url: `/profile/${userId}`,
    method: 'GET',
  })
  return res.data
}

export const apiGetManagedContacts = async (userId: string): Promise<ManagedContactData[]> => {
  const res = await instance({
    url: `/contacts/${userId}`,
    method: 'GET',
  })
  return res.data?.map((contact) => ({ ...contact, managedUserId: userId }))
}

export const apiGetManagedGroups = async (userId: string): Promise<ManagedGroupData[]> => {
  const res = await instance({
    url: `/groups/${userId}`,
    method: 'GET',
  })
  return res.data?.map((contact) => ({ ...contact, managedUserId: userId }))
}

export const apiPostManagedContacts = async (data: ApiPostManagedContacts): Promise<ManagedContactData[]> => {
  const res = await instance({
    url: `contacts/${data.userId}/new`,
    method: 'POST',
    data: data.contacts[0],
  })
  return res.data ? [res.data].map((contact) => ({ ...contact, managedUserId: data.userId })) : []
}

export const apiAddManagedContacts = async (data: ApiAddManagedContacts): Promise<ManagedContactData[]> => {
  const res = await instance({
    url: `contacts/${data.userId}/import`,
    method: 'POST',
    data: { contactIds: data.contactIds },
  })
  return res.data?.map((contact) => ({ ...contact, managedUserId: data.userId }))
}

export const apiAddManagedGroups = async (data: ApiAddManagedGroups): Promise<ManagedGroupData[]> => {
  const res = await instance({
    url: `groups/${data.userId}/import`,
    method: 'POST',
    data: { groupIds: data.groupIds },
  })
  return res.data?.map((group) => ({ ...group, managedUserId: data.userId }))
}

export const apiPostManagedGroups = async (data: ApiPostManagedGroups): Promise<ManagedGroupData[]> => {
  const res = await instance({
    url: `groups/${data.userId}`,
    method: 'POST',
    data: data.groups[0],
  })
  return res.data ? [res.data].map((group) => ({ ...group, managedUserId: data.userId })) : []
}
