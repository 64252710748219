import { ReactElement } from 'react'
import { withTheme } from 'styled-components'
import Text from 'components/text'
import { ErrorTickIcon, RegularTickIcon } from 'theme'
import { RowsContainer } from 'components/container'
import { StyledIconWrapper, StyledTextContainer } from './style'

const FormFieldValidation = ({ theme, children, error, success, ...rest }): ReactElement => {
  let color = theme.fn.color(theme.colors.black, '0.6')
  if (error) color = theme.fn.color(theme.colors.tallpoppy)
  return (
    <StyledTextContainer {...rest}>
      <RowsContainer>
        <StyledIconWrapper>{error ? <ErrorTickIcon /> : <RegularTickIcon success={success} />}</StyledIconWrapper>
        <Text ml="0.86rem" color={color} size="xs">
          {children}
        </Text>
      </RowsContainer>
    </StyledTextContainer>
  )
}

export default withTheme(FormFieldValidation)
