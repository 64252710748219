/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import Button from 'components/button'
import { ColumnsContainer, ContentContainer, RowsContainer } from 'components/container'

export const StyledContentContainer = styled(ContentContainer)`
  margin: 1.125rem;
`

export const StyledContentWrapper = styled(ContentContainer)`
  margin-top: 0.5rem;
  width: 100%;
`

export const StyledHeaderContainer = styled(RowsContainer)`
  height: 2.8125rem;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
`

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

export const StyledButtonsWrapper = styled(ColumnsContainer)`
  width: 100%;
`

export const StyledTextWrapper = styled.div`
  padding: 0 1rem;
  box-sizing: border-box;
  text-align: center;
  margin-top: 1.125rem;
`

export const StyledTextAgreementWrapper = styled.div`
  padding: 0 1rem;
  box-sizing: border-box;
  text-align: center;
  margin-top: 1.125rem;
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
`

export const StyledIconWrapper = styled.div`
  background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.darktuna)};
  border-radius: 1rem;
  box-shadow: ${({ theme: { shadows } }) => shadows.box};
  min-width: 80%;
  padding: 3.125rem 0;
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
`
