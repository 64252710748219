/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSelector, createEntityAdapter, EntityState, createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { error } from 'react-toastify-redux'
import {
  apiGetAllGuardianRequests,
  apiUpdateGuardianRequest,
  GuardianRequestStatuses,
  apiSendGuardianRequest,
  apiAckGuardianRequest,
  apiRemoveGuardianRequest,
  ApiSendGuardianRequest,
} from 'apis'
import { UserNormalized, updated as userUpdated } from './usersSlice'

const guardianRequestsAdapter = createEntityAdapter()
const initialState = guardianRequestsAdapter.getInitialState([])

interface GuardianRequest {
  id?: string
  status?: GuardianRequestStatuses
  from?: UserNormalized
  to?: UserNormalized
}

const isNotification = Symbol.for('isNotification')

export interface GuardianRequestNormalized extends GuardianRequest {
  [isNotification]?: boolean
}

export const getAllGuardianRequests = createAsyncThunk(
  'guardianRequests/getAll',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      return await apiGetAllGuardianRequests()
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null)
    }
  },
)

export const acceptGuardianRequest = createAsyncThunk(
  'guardianRequests/accept',
  async (
    { user, request }: { user: UserNormalized; request: GuardianRequestNormalized },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const guardianRequest = await apiUpdateGuardianRequest({
        id: request.id,
        status: GuardianRequestStatuses.accepted,
      })
      dispatch(userUpdated([{ id: user.id, guardianId: request.from.id }]))
      return guardianRequest
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(e)
    }
  },
)

export const declineGuardianRequest = createAsyncThunk(
  'guardianRequests/decline',
  async (data: GuardianRequestNormalized, { rejectWithValue, dispatch }) => {
    try {
      return await apiUpdateGuardianRequest({ id: data.id, status: GuardianRequestStatuses.rejected })
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(e)
    }
  },
)

export const sendGuardianRequest = createAsyncThunk(
  'guardianRequests/send',
  async (data: ApiSendGuardianRequest, { rejectWithValue }) => {
    try {
      return await apiSendGuardianRequest(data)
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const ackGuardianRequest = createAsyncThunk('guardianRequests/ack', async (id: string, { rejectWithValue }) => {
  try {
    return await apiAckGuardianRequest(id)
  } catch (e) {
    return rejectWithValue(e)
  }
})

export const removeGuardianRequest = createAsyncThunk(
  'guardianRequests/remove',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      return await apiRemoveGuardianRequest(id)
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(e)
    }
  },
)

interface GruardianRequestsState {
  guardianRequests: EntityState<GuardianRequestNormalized>
}

const guardianRequestsUpdated = (state, action) => {
  if (!action.payload) return
  ;(Array.isArray(action.payload) ? action.payload : [action.payload]).forEach(({ id, changes, ...rest }) => {
    if (state.ids.includes(id)) {
      state.entities[id] = {
        ...state.entities[id],
        ...(changes || rest),
      }
    }
  })
}

const guardianRequestRemoved = (state, action) => {
  guardianRequestsAdapter.removeOne(state, action.meta.arg)
}

const guardianRequestsSlice = createSlice({
  name: 'guardianRequests',
  initialState,
  reducers: {
    added: guardianRequestsAdapter.addOne,
    addedNotification: (state, action) => {
      guardianRequestsAdapter.addOne(state, { ...action, payload: { ...action.payload, [isNotification]: true } })
    },
    setNotNotification: (state, action) => {
      state.entities[action.payload.id][isNotification] = false
    },
    updated: guardianRequestsUpdated,
    removed: (state, action) => {
      guardianRequestsAdapter.removeOne(state, action.payload.id)
    },
  },
  extraReducers: {
    [getAllGuardianRequests.fulfilled.toString()]: guardianRequestsAdapter.setAll,
    [ackGuardianRequest.fulfilled.toString()]: guardianRequestRemoved,
    [acceptGuardianRequest.fulfilled.toString()]: guardianRequestsUpdated,
    [declineGuardianRequest.fulfilled.toString()]: guardianRequestsUpdated,
    [removeGuardianRequest.fulfilled.toString()]: guardianRequestRemoved,
    [sendGuardianRequest.fulfilled.toString()]: guardianRequestsAdapter.addOne,
  },
})

export const { added, addedNotification, setNotNotification, updated, removed } = guardianRequestsSlice.actions

const guardianRequestsSelectors = guardianRequestsAdapter.getSelectors(
  (state: GruardianRequestsState) => state.guardianRequests,
)
export const selectAllGuardianRequests = (state: GruardianRequestsState): GuardianRequestNormalized[] =>
  guardianRequestsSelectors.selectAll(state) as GuardianRequestNormalized[]

export const selectAllIncoming = (userId: string) =>
  createSelector(selectAllGuardianRequests, (guardianRequests) =>
    guardianRequests.filter((guardianRequest) => guardianRequest.to.id === userId),
  )
export const selectAllOutgoing = (userId: string) =>
  createSelector(selectAllGuardianRequests, (guardianRequests) =>
    guardianRequests.filter((guardianRequest) => guardianRequest.from.id === userId),
  )
export const selectAllIncomingNotifications = (userId: string) =>
  createSelector(selectAllIncoming(userId), (guardianRequests) =>
    guardianRequests.filter((guardianRequest) => guardianRequest[isNotification]),
  )
export const selectGuardianRequestById = (id: string) =>
  createSelector(selectAllGuardianRequests, (guardianRequests) =>
    guardianRequests.find((guardianRequest) => guardianRequest.id === id),
  )

export default guardianRequestsSlice.reducer
