/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import Card from 'components/card'
import { ViewContainer, ColumnsContainer } from 'components/container'

export const StyledModal = styled(ViewContainer)`
  z-index: 1000;
  position: fixed;
  justify-content: center;
  background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.black, '0.8')};
`

export const StyledColumnsContainer = styled(ColumnsContainer)`
  width: 100%;
  align-items: center;
`

export const StyledCard = styled(Card)`
  border-radius: 1rem;
  box-shadow: ${({ theme: { shadows } }) => shadows.box};
`
