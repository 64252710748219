import { ReactElement, FC, useEffect, useState } from 'react'
import GroupAvatar from 'components/avatar/group'
import Text from 'components/text'
import { ClockIcon, RightArrowIcon, CopyIcon } from 'theme'
import { getCallTitle, formatScheduledTime, hasOnlyOneGroup } from 'utils/calls'
import { useTranslation } from 'react-i18next'
import { RowsContainer } from 'components/container'
import { getTextColor } from 'utils'
import { callsShortPath, isElectronRuntime, clanzyMeetPath } from 'const'
import { useDispatch } from 'react-redux'
import { info } from 'react-toastify-redux'
import {
  StyledCopyButton,
  StyledButton,
  StyledControlsWrapper,
  ScheduledCallListItemProps,
  StyledTextWrapper,
  StyledListItem,
} from './style'

const ScheduledCallListItem: FC<ScheduledCallListItemProps> = ({
  authUser,
  children,
  scheduledCall,
  $flat,
  $hovered,
  onMouseEnter,
  onMouseLeave,
  onJoinClick,
  onClick,
  bcolor,
  title,
  $secondary,
  ticker,
  ...rest
}): ReactElement => {
  const { t } = useTranslation()
  const callHasOnlyOneGroup = hasOnlyOneGroup(scheduledCall)
  const [scheduledTime, setScheduledTime] = useState('')
  const dispatch = useDispatch()

  const handleMouseEnter = () => {
    if (onMouseEnter) onMouseEnter(scheduledCall)
  }
  const handleMouseLeave = () => {
    if (onMouseLeave) onMouseLeave(scheduledCall)
  }
  const handleJoinClick = () => {
    onJoinClick(scheduledCall)
  }
  const handleClick = () => {
    if (onClick) onClick(scheduledCall)
  }

  useEffect(() => {
    if (scheduledCall) setScheduledTime(formatScheduledTime(scheduledCall, t('Today'), t('At')))
  }, [scheduledCall, t, ticker])

  const [scheduledCallTitle, restCount] = getCallTitle(scheduledCall, 3)

  const handleClickCopyCallUrl = () => {
    try {
      navigator.clipboard.writeText(
        `${isElectronRuntime ? clanzyMeetPath : window.location.origin.toString()}${callsShortPath}/${
          scheduledCall.callId
        }`,
      )
      dispatch(info(t('UrlHasBeenCopiedToYourClipboard'), { position: 'top-right' }))
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  return (
    <StyledListItem
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      $secondary={$secondary}
      $flat={$flat}
      onClick={handleClick}
      {...rest}
    >
      {(!!scheduledCall.groups?.length || !!scheduledCall.contacts?.length) && (
        <GroupAvatar
          bcolor={bcolor}
          $secondary={$secondary}
          group={scheduledCall.groups?.length ? scheduledCall.groups[0] : { contacts: scheduledCall.contacts }}
          $hovered={$hovered}
          $noPresence
        />
      )}
      <StyledTextWrapper>
        {callHasOnlyOneGroup ? (
          <Text ml="1rem" size="md" $secondary={!$secondary}>
            {scheduledCall.groups[0].name}
          </Text>
        ) : (
          <Text ml="1rem" size="md" $secondary={!$secondary}>
            {scheduledCallTitle}
            {!!restCount && ` + ${restCount}`}
          </Text>
        )}
        {scheduledCall.description && (
          <Text mt="0.2rem" ml="1rem" fw="400" size="xs" color={getTextColor($secondary)} cop="0.6">
            {scheduledCall.description}
          </Text>
        )}
        <RowsContainer mt="0.2rem" ml="1rem" $center>
          <ClockIcon />
          <Text fw="400" ml="0.2rem" size="xs" color={getTextColor($secondary)} cop="0.6">
            {t('ScheduledFor')}&nbsp;
            {scheduledTime}
          </Text>
        </RowsContainer>
      </StyledTextWrapper>
      <StyledControlsWrapper>
        {onJoinClick && scheduledCall.canJoin && (
          <StyledButton bcolor="transparent" $flat size="md" borcolor="malachite" onClick={handleJoinClick}>
            <Text fw="600" size="md" mr="0.5rem" color="malachite">
              {t('JoinNow')}
            </Text>
            <RightArrowIcon color="malachite" title={t('JoinTheCall')} />
          </StyledButton>
        )}
        <StyledCopyButton
          size="md"
          ml="0.5rem"
          circular="true"
          bcolor="white"
          bop="0.05"
          onClick={handleClickCopyCallUrl}
        >
          <CopyIcon bcolor="transparent" secondary="true" title={t('IconToClickToCopyTheCallUrlToYourClipboard')} />
        </StyledCopyButton>
      </StyledControlsWrapper>
    </StyledListItem>
  )
}

export default ScheduledCallListItem
