import { ReactElement } from 'react'

export default ({ $haveScheduledCall, title }: { $haveScheduledCall: boolean; title: string }): ReactElement => (
  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path
      d="M0.160156 8C0.160156 3.58172 3.74188 0 8.16016 0H32.1602C36.5784 0 40.1602 3.58172 40.1602 8V32C40.1602 36.4183 36.5784 40 32.1602 40H8.16016C3.74188 40 0.160156 36.4183 0.160156 32V8Z"
      fill="url(#paint0_linear)"
    />
    {$haveScheduledCall && <circle cx="34" cy="6" r="3" fill="#FFC700" />}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.1602 20C31.1602 26.0751 26.2353 31 20.1602 31C14.085 31 9.16016 26.0751 9.16016 20C9.16016 13.9249 14.085 9 20.1602 9C26.2353 9 31.1602 13.9249 31.1602 20ZM34.1602 20C34.1602 27.732 27.8921 34 20.1602 34C12.4282 34 6.16016 27.732 6.16016 20C6.16016 12.268 12.4282 6 20.1602 6C27.8921 6 34.1602 12.268 34.1602 20ZM21.1602 12C21.1602 11.4477 20.7124 11 20.1602 11C19.6079 11 19.1602 11.4477 19.1602 12V21V21.4806L19.5355 21.7809L24.5355 25.7809C24.9667 26.1259 25.596 26.056 25.941 25.6247C26.286 25.1934 26.2161 24.5641 25.7849 24.2191L21.1602 20.5194V12Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="1.87444"
        y1="39.0968"
        x2="28.0309"
        y2="21.285"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0520833" stopColor="#F6BF4F" />
        <stop offset="0.5625" stopColor="#FF00E7" />
        <stop offset="1" stopColor="#935DEA" />
      </linearGradient>
    </defs>
  </svg>
)
