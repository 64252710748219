import { ReactElement } from 'react'
import { withTheme } from 'styled-components'

export default withTheme(
  ({ theme: { fn, palette }, secondary, title }): ReactElement => {
    const color = (fn.color as (arg0: string) => string)(secondary ? palette.secondary.main : palette.primary.main)
    return (
      <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0164 5.60653C14.0164 8.70294 11.82 11.2131 9.11067 11.2131C6.40132 11.2131 4.20496 8.70294 4.20496 5.60653C4.20496 2.51013 6.40132 0 9.11067 0C11.82 0 14.0164 2.51013 14.0164 5.60653ZM18.2212 19.823C18.2212 23.8041 14.1423 23.8277 9.11061 23.8277C4.07896 23.8277 0 23.8041 0 19.823C0 15.8419 4.07896 12.6146 9.11061 12.6146C14.1423 12.6146 18.2212 15.8419 18.2212 19.823ZM21 4C21.8284 4 22.5 4.67157 22.5 5.5V7.5H24.5C25.3284 7.5 26 8.17157 26 9C26 9.82843 25.3284 10.5 24.5 10.5H22.5V12.5C22.5 13.3284 21.8284 14 21 14C20.1716 14 19.5 13.3284 19.5 12.5V10.5H17.5C16.6716 10.5 16 9.82843 16 9C16 8.17157 16.6716 7.5 17.5 7.5L19.5 7.5V5.5C19.5 4.67157 20.1716 4 21 4Z"
          fill={color}
          fillOpacity="0.8"
        />
      </svg>
    )
  },
)
