/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import Button from 'components/button'
import Text from 'components/text'
import { ColumnsContainer, ContentContainer, RowsContainer } from 'components/container'

export const StyledTitleContainer = styled.title`
  display: inline-block;
  text-align: center;
  position: relative;
  padding: 1rem 5rem 0 5rem;
`

export const StyledTextContainer = styled(ColumnsContainer)`
  text-align: center;
  position: relative;
  padding: 0 3rem;
`

export const StyledContentContainer = styled(ContentContainer)`
  margin: 1.125rem 0 2rem 0;
`

export const StyledHeaderContainer = styled(RowsContainer)`
  height: 2.8125rem;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
`

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

export const StyledDescriptionContainer = styled.div`
  margin-top: 1rem;
`

export const StyledText = styled(Text)`
  padding: 0 2rem;
`
