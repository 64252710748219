/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import { RowsContainer, ContentContainer } from 'components/container'

export const StyledContentContainer = styled(ContentContainer)`
  margin: 1.125rem;
`

export const StyledRowsContainer = styled(RowsContainer)`
  justify-content: center;
  align-items: center;
`
