import styled, { css } from 'styled-components'

export interface MenuListItemProps {
  /**
   * Href
   */
  link?: string
  /**
   * Replace path
   */
  replace?: boolean
  /**
   * On click handler
   */
  onClick?: () => void
  /**
   * If the item is selected
   */
  active?: boolean
  /**
   * Variant
   */
  secondary?: string
}

export default styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;

  ${(props: MenuListItemProps) =>
    !props.secondary &&
    css`
      color: ${({ theme: { fn, palette } }) => fn.color(palette.text.white, '0.6')};

      &:hover {
        background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.white, '0.04')};
      }

      ${(props: MenuListItemProps) =>
        !!props.active &&
        css`
          color: ${({ theme: { fn, palette } }) => fn.color(palette.text.secondary)};
          background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.white, '0.04')};
        `}
    `}

  ${(props: MenuListItemProps) =>
    !!props.secondary &&
    css`
      color: ${({ theme: { fn, palette } }) => fn.color(palette.text.brandblack)};

      &:hover {
        background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.purple, '0.16')};
      }

      ${(props: MenuListItemProps) =>
        !!props.active &&
        css`
          color: ${({ theme: { fn, palette } }) => fn.color(palette.text.purplehearth)};
          background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.purple, '0.16')};
        `}
    `}
`
