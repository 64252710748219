/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, FC, useLayoutEffect } from 'react'
import { effectQueryParam } from 'const'
import { ContentContainer } from 'components/container'
import { useQueryParam, StringParam } from 'use-query-params'
import { StyledCard, StyledColumnsContainer, StyledNotification } from './style'

export interface TransparentBackgroundProps {
  /**
   * Effect
   */
  effect?: string
  /**
   * On mouse down
   */
  onMouseDown?: (e: any) => void
}

const TransparentBackground: FC<TransparentBackgroundProps> = ({ onMouseDown, effect, children }): ReactElement => {
  const [, setEfectParam] = useQueryParam(effectQueryParam, StringParam)

  useLayoutEffect(() => {
    if (effect) setEfectParam(effect, 'replaceIn')

    return () => setEfectParam(null, 'replaceIn')
  }, [effect, setEfectParam])

  return (
    <StyledNotification component="dialog" onMouseDown={onMouseDown}>
      <StyledColumnsContainer>
        <ContentContainer>
          <StyledCard size="sm">{children}</StyledCard>
        </ContentContainer>
      </StyledColumnsContainer>
    </StyledNotification>
  )
}

export default TransparentBackground
