import { ReactElement } from 'react'

export default (): ReactElement => (
  <svg width="56" height="94" viewBox="0 0 56 94" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M42.1629 6.0652C41.6473 5.08835 40.4375 4.71443 39.4606 5.23002L23.5419 13.6321C22.5651 14.1477 22.1911 15.3575 22.7067 16.3344C23.2223 17.3113 24.4322 17.6852 25.409 17.1696L39.559 9.70108L47.0275 23.851C47.5431 24.8279 48.753 25.2018 49.7298 24.6862C50.7067 24.1706 51.0806 22.9608 50.565 21.9839L42.1629 6.0652ZM16.9108 89.7546L42.305 7.58933L38.4834 6.4082L13.0892 88.5735L16.9108 89.7546Z"
      fill="#13BF4D"
    />
  </svg>
)
