/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getUserFromLS, removeFromLS } from 'utils/storage'
import { signInPath } from 'const'

// eslint-disable-next-line import/prefer-default-export
export const setTokenInterceptor = (isAuth, isAnon) => (config: any): any => {
  let authFromLs = null
  if (isAuth) authFromLs = getUserFromLS(false)
  if (!authFromLs && isAnon) authFromLs = getUserFromLS(true)
  if (authFromLs) {
    const [, tokelFromLs] = authFromLs
    config.headers = {
      Authorization: `Bearer ${tokelFromLs}`,
      Accept: 'application/json',
    }
  }
  return config
}

export const responseInterceptorError = (error) => {
  if (error?.response?.status === 401) {
    removeFromLS('auth')
    window.location.href = signInPath
  }

  let message = 'Something bad happened'
  if (error?.message) message = error.message
  if (error?.response?.data?.message) message = error.response.data.message
  return Promise.reject(new Error(message))
}
