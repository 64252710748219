import styled, { css } from 'styled-components'

export interface CheckCircleProps {
  /**
   * Is checked
   */
  $checked?: boolean
  /**
   * Icon title
   */
  alt?: string
}

export const StyledWrapper = styled.div`
  position: relative;
  width: 2rem;
  height: 2rem;
`

export const StyledIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledInput = styled.input.attrs(() => ({
  type: 'checkbox',
  tabIndex: 0,
}))<CheckCircleProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transform: none;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  border: 2px solid ${({ theme: { fn, colors } }) => fn.color(colors.black, '0.4')};

  ${(props: CheckCircleProps) =>
    props.$checked &&
    css`
      background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.mediumpurple)};
    `}
`
