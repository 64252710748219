import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="44" height="40" viewBox="0 0 44 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path
      d="M15.4701 0.228516L11.486 4.58268H4.58464C2.18984 4.58268 0.230469 6.54206 0.230469 8.93685V35.0618C0.230469 37.4566 2.18984 39.416 4.58464 39.416H39.418C41.8128 39.416 43.7721 37.4566 43.7721 35.0618V8.93685C43.7721 6.54206 41.8128 4.58268 39.418 4.58268H32.5166L28.5326 0.228516H15.4701ZM22.0013 32.8848C15.9926 32.8848 11.1159 28.0081 11.1159 21.9993C11.1159 15.9906 15.9926 11.1139 22.0013 11.1139C28.0101 11.1139 32.8867 15.9906 32.8867 21.9993C32.8867 28.0081 28.0101 32.8848 22.0013 32.8848Z"
      fill="black"
      fillOpacity="0.4"
    />
  </svg>
)
