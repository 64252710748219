/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/prefer-as-const */
import { useMemo, ReactElement } from 'react'
import Joyride from 'react-joyride'
import {
  CloseIcon,
  Theme,
  AddToTheCallTourImage,
  ContactsTourImage,
  GroupsTourImage,
  RecentCallsTourImage,
  ScheduledCallsTourImage,
  EntertainmentTourImage,
  TourArrow1,
  TourArrow2,
  TourArrow3,
  TourArrow4,
  TourArrow5,
  TourArrow6,
} from 'theme'
import Button from 'components/button'
import { selectAuthUser, flags, updateFlags, UserNormalized } from 'reducers/usersSlice'
import { useTranslation } from 'react-i18next'
import { tourQueryParam } from 'const'
import Text from 'components/text'
import { RowsContainer, ColumnsContainer } from 'components/container'
import { withTheme } from 'styled-components'
import { useQueryParam, BooleanParam } from 'use-query-params'
import { useDispatch, connect } from 'react-redux'

import {
  StyledVisualImage1Wrapper,
  StyledTourArrow1Wrapper,
  StyledTourArrow2Wrapper,
  StyledTourArrow3Wrapper,
  StyledTourArrow4Wrapper,
  StyledTourArrow5Wrapper,
  StyledTourArrow6Wrapper,
  StyledVisualImageWrapper,
  StyledImageWrapper,
  StyledTextContainer,
  StyledVisual,
  StyledVisualWrapper,
  StyledContentContainer,
  StyledCard,
  StepWrapper,
  StyledRowsLeftContainer,
  StyledFooterWrapper,
  StyledCloseButton,
  StyledDescriptionWrapper,
} from './style'

const tourStepContent = ({ header, title, description }: { header: string; title: string; description: string }) => {
  return (
    <StepWrapper>
      <Text font="Poppins" size="md" mr="auto" fw="600" color="brandblack">
        {header}
      </Text>
      <Text component="h1" font="Poppins" mt="1.5rem" mr="auto" fw="600" color="pink">
        {title}
      </Text>
      <StyledDescriptionWrapper>
        <Text color="black" cop="0.6">
          {description}
        </Text>
      </StyledDescriptionWrapper>
    </StepWrapper>
  )
}

const Tour = ({
  theme,
  authUser,
  onClose,
}): ReactElement<{ theme: Theme; authUser: UserNormalized; onClose: () => void }> => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [tourParam, setTourParam] = useQueryParam(tourQueryParam, BooleanParam)

  const stepsTexts = useMemo(
    () => ({
      step1: {
        header: t('HowItWorks'),
        title: t('StartAVideoCall'),
        description: t('TourStepVideoCallDescription'),
        visual: t('TourStepVideoCallVisualDescription'),
        previous: null,
        next: 1,
      },
      step2: {
        header: t('HowItWorks'),
        title: t('RecentCallsList'),
        description: t('TourStepRecentCallsDescription'),
        visual: t('TourStepRecentCallsVisualDescription'),
        previous: 0,
        next: 2,
      },
      step3: {
        header: t('HowItWorks'),
        title: t('Contacts'),
        description: t('TourStepContactsDescription'),
        visual: t('TourStepContactsVisualDescription'),
        previous: 1,
        next: 3,
      },
      step4: {
        header: t('HowItWorks'),
        title: t('Groups'),
        description: t('TourStepGroupsDescription'),
        visual: t('TourStepGroupsVisualDescription'),
        previous: 2,
        next: 4,
      },
      step5: {
        header: t('HowItWorks'),
        title: t('ScheduledCalls'),
        description: t('TourStepScheduledCallsDescription'),
        visual: t('TourStepScheduledCallsVisualDescription'),
        previous: 3,
        next: 5,
      },
      step6: {
        header: t('HowItWorks'),
        title: t('Entertainment'),
        description: t('TourStepEntertainmentDescription'),
        visual: t('TourStepEntertainmentVisualDescription'),
        previous: 4,
        next: null,
      },
    }),
    [t],
  )

  const handleClose = () => {
    setTourParam(null, 'replaceIn')
    dispatch(updateFlags({ id: authUser.id, flags: { add: [flags.welcomeTourShown] } }))
    onClose()
  }

  const steps = [
    {
      placement: 'auto' as 'auto',
      target: '[data-tour="first-step"]',
      content: tourStepContent(stepsTexts.step1),
      visual: (
        <StyledVisual>
          <RowsContainer>
            <StyledVisualImage1Wrapper>
              <AddToTheCallTourImage />
              <StyledTourArrow1Wrapper>
                <TourArrow1 />
              </StyledTourArrow1Wrapper>
            </StyledVisualImage1Wrapper>
            <StyledTextContainer>
              <Text $center fw="600" color="white" mr="1rem" shadow="text">
                {stepsTexts.step1.visual}
              </Text>
            </StyledTextContainer>
          </RowsContainer>
        </StyledVisual>
      ),
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
    },
    {
      placement: 'auto' as 'auto',
      target: '[data-tour="second-step"]',
      content: tourStepContent(stepsTexts.step2),
      visual: (
        <StyledVisual>
          <ColumnsContainer $center>
            <StyledVisualImageWrapper>
              <RecentCallsTourImage />
              <StyledTourArrow2Wrapper>
                <TourArrow2 />
              </StyledTourArrow2Wrapper>
            </StyledVisualImageWrapper>
            <StyledTextContainer>
              <Text $center ml="1rem" mr="1rem" mt="1.5rem" mb="1rem" fw="600" color="white" shadow="text">
                {stepsTexts.step2.visual}
              </Text>
            </StyledTextContainer>
          </ColumnsContainer>
        </StyledVisual>
      ),
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
    },
    {
      placement: 'auto' as 'auto',
      target: '[data-tour="third-step"]',
      content: tourStepContent(stepsTexts.step3),
      visual: (
        <StyledVisual>
          <ColumnsContainer $center>
            <StyledVisualImageWrapper>
              <ContactsTourImage />
              <StyledTourArrow3Wrapper>
                <TourArrow3 />
              </StyledTourArrow3Wrapper>{' '}
            </StyledVisualImageWrapper>
            <StyledTextContainer>
              <Text $center ml="1rem" mr="1rem" mt="1.5rem" mb="1rem" fw="600" color="white" shadow="text">
                {stepsTexts.step3.visual}
              </Text>
            </StyledTextContainer>
          </ColumnsContainer>
        </StyledVisual>
      ),
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
    },
    {
      placement: 'auto' as 'auto',
      target: '[data-tour="fourth-step"]',
      content: tourStepContent(stepsTexts.step4),
      visual: (
        <StyledVisual>
          <ColumnsContainer $center>
            <StyledVisualImageWrapper>
              <GroupsTourImage />
              <StyledTourArrow4Wrapper>
                <TourArrow4 />
              </StyledTourArrow4Wrapper>{' '}
            </StyledVisualImageWrapper>
            <StyledTextContainer>
              <Text $center ml="1rem" mr="1rem" mt="2rem" mb="1.5rem" fw="600" color="white" shadow="text">
                {stepsTexts.step4.visual}
              </Text>
            </StyledTextContainer>
          </ColumnsContainer>
        </StyledVisual>
      ),
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
    },
    {
      placement: 'auto' as 'auto',
      target: '[data-tour="fifth-step"]',
      content: tourStepContent(stepsTexts.step5),
      visual: (
        <StyledVisual>
          <ColumnsContainer $center>
            <StyledVisualImageWrapper>
              <StyledImageWrapper>
                <ScheduledCallsTourImage />
                <StyledTourArrow5Wrapper>
                  <TourArrow5 />
                </StyledTourArrow5Wrapper>
              </StyledImageWrapper>{' '}
            </StyledVisualImageWrapper>
            <StyledTextContainer>
              <Text $center ml="1rem" mr="1rem" mt="2rem" mb="1.5rem" fw="600" color="white" shadow="text">
                {stepsTexts.step5.visual}
              </Text>
            </StyledTextContainer>
          </ColumnsContainer>
        </StyledVisual>
      ),
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
    },
    {
      placement: 'auto' as 'auto',
      target: '[data-tour="sixth-step"]',
      content: tourStepContent(stepsTexts.step6),
      visual: (
        <StyledVisual>
          <ColumnsContainer $center>
            <StyledVisualImageWrapper>
              <EntertainmentTourImage />
              <StyledTourArrow6Wrapper>
                <TourArrow6 />
              </StyledTourArrow6Wrapper>
            </StyledVisualImageWrapper>
            <StyledTextContainer>
              <Text $center ml="1rem" mr="1rem" mt="2rem" mb="1.5rem" fw="600" color="white" shadow="text">
                {stepsTexts.step6.visual}
              </Text>
            </StyledTextContainer>
          </ColumnsContainer>
        </StyledVisual>
      ),
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
    },
  ]

  const Tooltip = ({ backProps, primaryProps, index, step, tooltipProps }) => (
    <StyledCard secondary="true" {...tooltipProps}>
      <StyledContentContainer>
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} bcolor="black" />
        </StyledCloseButton>
        {step.content}
      </StyledContentContainer>
      <StyledVisualWrapper>{step.visual}</StyledVisualWrapper>
      <StyledContentContainer>
        <StyledFooterWrapper>
          <StyledRowsLeftContainer>
            <Button size="sm" subtle="true" onClick={handleClose}>
              <Text size="md" color="purplehearth" fw="600">
                {t('Close')}
              </Text>
            </Button>
          </StyledRowsLeftContainer>
          <RowsContainer>
            {!!index && (
              <Button size="sm" subtle="true" onClick={backProps.onClick}>
                <Text size="md" color="purplehearth" fw="600">
                  {t('Previous')}
                </Text>
              </Button>
            )}
            {index < steps.length - 1 && (
              <Button
                size="sm"
                bcolor="mediumpurple"
                bop="1"
                ml="1rem"
                hgradient="mediumpurple-dim-light"
                agradient="mediumpurple-dim-dark"
                onClick={primaryProps.onClick}
              >
                <Text size="md" $secondary fw="600">
                  {t('Next')}
                </Text>
              </Button>
            )}
            {index === steps.length - 1 && (
              <Button
                size="sm"
                bcolor="mediumpurple"
                bop="1"
                ml="1rem"
                hgradient="mediumpurple-dim-light"
                agradient="mediumpurple-dim-dark"
                onClick={handleClose}
              >
                <Text size="md" $secondary fw="600">
                  {t('Finish')}
                </Text>
              </Button>
            )}
          </RowsContainer>
        </StyledFooterWrapper>
      </StyledContentContainer>
    </StyledCard>
  )

  return (
    <Joyride
      styles={{
        options: {
          overlayColor: theme.fn.color(theme.colors.black, '0.8'),
        },
        buttonNext: {
          display: 'none',
        },
        buttonBack: {
          display: 'none',
        },
        buttonClose: {
          display: 'none',
        },
      }}
      continuous
      tooltipComponent={Tooltip}
      run={!!tourParam}
      hideBackButton
      steps={steps}
    />
  )
}

const mapStateToProps = (state) => ({
  authUser: selectAuthUser(state),
})

export default connect(mapStateToProps, null)(withTheme(Tour))
