import { ReactElement } from 'react'
import { withTheme } from 'styled-components'

export default withTheme(
  ({ theme: { fn, palette }, secondary, title }): ReactElement => {
    const color = (fn.color as (arg0: string) => string)(secondary ? palette.secondary.main : palette.primary.main)
    return (
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <circle cx="24" cy="24" r="24" fill={color} fillOpacity="0.38" />
        <g clipPath="url(#clip0)">
          <path
            d="M10.4375 17.625C10.4375 16.5 10.4719 16.059 11 15.375C11.5281 14.691 12.6875 14.25 13.8125 14.25C13.5339 13.6821 13.0359 12.5111 13.025 11.8757C13.025 10.8217 13.4339 9.81084 14.1618 9.06554C14.8897 8.32024 15.8769 7.90153 16.9062 7.90153C17.519 7.90926 18.1215 8.06349 18.6658 8.35194C19.1558 7.25058 19.9993 6.3542 21.056 5.81184C22.1126 5.26949 23.3187 5.11385 24.4738 5.37082C25.6288 5.62779 26.6631 6.28188 27.4047 7.22431C28.1463 8.16673 28.5505 9.3407 28.55 10.551C28.5642 10.7804 28.5642 11.0104 28.55 11.2398C29.3469 11.7078 30.0078 12.3845 30.4647 13.2004C30.6888 13.1859 30.9135 13.1859 31.1375 13.2004C32.3741 13.1831 33.5759 13.6198 34.5252 14.4314C35.4745 15.2431 36.3125 16.5 36.3125 17.625"
            stroke={color}
            strokeWidth="3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 19L11.9385 38.5473C12.0856 39.5261 12.9265 40.25 13.9163 40.25H32.8337C33.8235 40.25 34.6644 39.5261 34.8115 38.5473L37.75 19H9ZM20.4375 26.1594C20.4375 25.8695 20.5731 25.6722 20.7185 25.5754C20.8561 25.4837 21.0024 25.4734 21.1505 25.5577L27.1279 28.9609C27.3076 29.0632 27.4375 29.2782 27.4375 29.5625C27.4375 29.8468 27.3076 30.0619 27.1279 30.1642L21.1505 33.5673C21.0024 33.6517 20.8561 33.6413 20.7185 33.5497C20.5731 33.4528 20.4375 33.2556 20.4375 32.9657V26.1594ZM22.1401 23.8197C21.2821 23.3312 20.3245 23.4347 19.6097 23.9109C18.9027 24.3818 18.4375 25.2063 18.4375 26.1594V32.9657C18.4375 33.9187 18.9027 34.7432 19.6097 35.2142C20.3245 35.6903 21.2821 35.7939 22.1401 35.3054L28.1174 31.9023C29.0242 31.386 29.4375 30.4396 29.4375 29.5625C29.4375 28.6854 29.0242 27.7391 28.1174 27.2228L22.1401 23.8197Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <path
              d="M4 8C4 5.79086 5.79086 4 8 4H40C42.2091 4 44 5.79086 44 8V40C44 42.2091 42.2091 44 40 44H8C5.79086 44 4 42.2091 4 40V8Z"
              fill={color}
            />
          </clipPath>
        </defs>
      </svg>
    )
  },
)
