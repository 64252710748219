/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import { apiUrl } from 'const'
import { setTokenInterceptor, responseInterceptorError } from 'utils/apis'

export interface GuardianProfile {
  id: string
  firstName?: string
  lastName?: string
  photo?: string
  email?: string
}

const instance = axios.create({
  baseURL: `${apiUrl}/guardians`,
})

instance.interceptors.request.use(setTokenInterceptor(true, false))
instance.interceptors.response.use(undefined, responseInterceptorError)

export const apiGetGuardianProfile = async (): Promise<GuardianProfile> => {
  const res = await instance({
    url: '/guardian',
    method: 'GET',
  })
  return res.data
}

export const apiRemoveGuardian = async (): Promise<void> => {
  const res = await instance({
    url: '/guardian',
    method: 'DELETE',
  })
  return res.data
}
