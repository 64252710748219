/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FC, ReactElement, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import Button from 'components/button'
import Avatar from 'components/avatar'
import Text from 'components/text'
import { modalNewGameQueryParam, entertainmentQueryParam, modalQueryParam, games } from 'const'
import { CloseIcon, BackIcon, RegularTickIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import { useQueryParam, StringParam } from 'use-query-params'
import { getAvatarBorColor, getAvatarBorOp } from 'utils'
import { CallNormalized, addCallEntertainment } from 'reducers/callsSlice'
import { getPresentParticipants, isParticipantClanzyUser } from 'utils/calls'
import { useDispatch } from 'react-redux'
import { UserNormalized } from 'reducers/usersSlice'
import {
  StyledListItem,
  StyledListColumn,
  StyledTextWrapper,
  StyledVerticalScrollbar,
  StyledParticipantsWrapper,
  StyledText,
  StyledContentWrapper,
  StyledBackButton,
  StyledButtonsWrapper,
  StyledFooterWrapper,
  StyledContentContainer,
  StyledCloseButton,
  StyledHeaderContainer,
  StyledControlsWrapper,
  StyledIconTickWrapper,
} from './style'

const { $ } = window

const GameInvite: FC<{ user: UserNormalized; onClose: () => void; call: CallNormalized; clanzyUsersIds: any }> = ({
  user,
  onClose,
  call,
  clanzyUsersIds,
}): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [, setModalParam] = useQueryParam(modalQueryParam, StringParam)
  const [entertainmentParam, setEntertainmentParam] = useQueryParam(entertainmentQueryParam, StringParam)
  const callParticipants = getPresentParticipants(call, user, Object.values(clanzyUsersIds))
  const [invited, setInvited] = useState([])

  const focusStartGameButton = useCallback(() => {
    if (call) {
      const el = $(`.start-game-${call.id}`)?.[0]
      if (el) el.focus()
    }
  }, [call])

  useLayoutEffect(() => {
    if (!entertainmentParam || !Object.values(games).includes(entertainmentParam)) onClose()
  }, [entertainmentParam, onClose])

  const handleStart = () => {
    dispatch(
      addCallEntertainment({
        callId: call.id,
        entertainment: { type: 'game', name: entertainmentParam, participants: invited },
      }),
    )
    setEntertainmentParam(null, 'replaceIn')
    onClose()
  }

  const handleBack = () => {
    setModalParam(modalNewGameQueryParam, 'replaceIn')
  }

  const handleInvite = (id) => () => {
    setInvited((prevInvitedIds) => [...prevInvitedIds, id])
  }

  useEffect(() => {
    focusStartGameButton()
  }, [invited, focusStartGameButton])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Text component="h1" mt="0" color="brandblack" font="Poppins">
          {`${t('InvitePlayers')} (${t('Max')}: 4)`}
        </Text>
        <StyledBackButton circular="true" subtle="true" onClick={handleBack}>
          <BackIcon title={t('GoBack')} />
        </StyledBackButton>
        <StyledCloseButton circular="true" subtle="true" onClick={onClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledContentWrapper>
        <StyledText size="lg" color="brandblack" cop="0.6" fw="600" mt="1rem" mb="0.5rem" tt="lowercase">
          {`${callParticipants?.length || 0} ${t('Participants')}`}
        </StyledText>
        {!!callParticipants?.length && (
          <StyledParticipantsWrapper>
            <StyledVerticalScrollbar options={{ className: 'os-theme-light' }}>
              <StyledListColumn>
                {callParticipants.map((participant) => (
                  <StyledListItem key={participant.id}>
                    <Avatar
                      size="lg"
                      buttonSize="xl"
                      fontSize="xxl"
                      $secondary
                      alt={t('PhotoOfParticipant')}
                      borcolor={getAvatarBorColor(isParticipantClanzyUser(participant), true)}
                      borop={getAvatarBorOp(isParticipantClanzyUser(participant), true)}
                      name={participant.displayName}
                      img={participant.photo}
                      $noPresence
                    />
                    <StyledTextWrapper>
                      <Text fw="600" ml="1rem" size="md">
                        {participant.displayName}
                      </Text>
                    </StyledTextWrapper>
                    <StyledControlsWrapper>
                      {invited.includes(participant.id) ? (
                        <StyledIconTickWrapper>
                          <RegularTickIcon success="true" color="purplehearth" />
                        </StyledIconTickWrapper>
                      ) : invited.length < 3 ? (
                        <Text
                          fw="600"
                          size="md"
                          mr="1rem"
                          color="mediumpurple"
                          td="none"
                          onClick={handleInvite(participant.id)}
                        >
                          {t('InviteToPlay')}
                        </Text>
                      ) : null}
                    </StyledControlsWrapper>
                  </StyledListItem>
                ))}
              </StyledListColumn>
            </StyledVerticalScrollbar>
          </StyledParticipantsWrapper>
        )}
      </StyledContentWrapper>
      <StyledFooterWrapper>
        <StyledButtonsWrapper>
          <Button
            disabled={!invited.length}
            $flat={!invited.length}
            mt="2rem"
            type="submit"
            size="md"
            bcolor="mediumpurple"
            bop="1"
            hgradient="mediumpurple-dim-light"
            agradient="mediumpurple-dim-dark"
            onClick={handleStart}
            className={`start-game-${call.id}`}
          >
            <Text size="md" $secondary fw="600">
              {t('SendInvitesAndStartGame')}
            </Text>
          </Button>
        </StyledButtonsWrapper>
      </StyledFooterWrapper>
    </StyledContentContainer>
  )
}

export default GameInvite
