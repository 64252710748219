/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import reduxSocketIoMiddleware from 'utils/reduxSocketIoMiddleware'
import { actionsTypesMapping as callsActionsTypesMapping, eventsMapping as callsEventsMapping } from './callsEvents'
import { actionsTypesMapping as usersActionsTypesMapping, eventsMapping as usersEventsMapping } from './usersEvents'
import {
  actionsTypesMapping as guardianRequestsActionsTypesMapping,
  eventsMapping as guardianRequestsEventsMapping,
} from './guardianRequestsEvents'
import {
  actionsTypesMapping as contactsActionsTypesMapping,
  eventsMapping as contactsEventsMapping,
} from './contactsEvents'
import { actionsTypesMapping as groupsActionsTypesMapping, eventsMapping as groupsEventsMapping } from './groupsEvents'

export const isAuth = Symbol.for('isAuth')

const combineMappings = (res, obj) => {
  Object.keys(obj).forEach((key) => {
    if (!res[key]) {
      res[key] = obj[key]
    } else {
      res[key] = [...(Array.isArray(res[key]) ? res[key] : [res[key]]), obj[key]]
    }
  })
  return res
}

const clientToServerActionsTypesMapping = [
  usersActionsTypesMapping,
  callsActionsTypesMapping,
  contactsActionsTypesMapping,
  groupsActionsTypesMapping,
  guardianRequestsActionsTypesMapping,
].reduce(combineMappings, {})

const serverEventsMapping = [
  usersEventsMapping,
  callsEventsMapping,
  contactsEventsMapping,
  groupsEventsMapping,
  guardianRequestsEventsMapping,
].reduce(combineMappings, {})

export default (socket, criteria) => {
  const execute = (action, emit, next) => {
    const type = action.type.split('/')[1]
    const { cb } = action
    delete action.cb
    if (Array.isArray(clientToServerActionsTypesMapping[type])) {
      clientToServerActionsTypesMapping[type].forEach((eventName) => {
        socket.emit(eventName, action.payload, (res) => {
          ;(cb || (() => {}))(res)
        })
      })
    } else {
      socket.emit(clientToServerActionsTypesMapping[type] || type, action.payload, (res) => {
        ;(cb || (() => {}))(res)
      })
    }
    next(action)
  }

  return reduxSocketIoMiddleware(socket, criteria, {
    execute,
    serverEventsMapping,
  })
}
