/* eslint-disable @typescript-eslint/no-explicit-any */
import { ArrowUpIcon, ArrowDownIcon } from 'theme'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import {
  StyledText,
  StyledVerticalScrollbar,
  StyledPopperWrapper,
  StyledTimePickerWrapper,
  StyledWrapper,
  FormFieldTimeProps,
  StyledFormFieldLabel,
  StyledFormField,
  StyledInputWrapper,
  StyledIconDropdownWrapper,
  StyledListColumn,
} from './style'

const timelineLabels = (desiredStartTime = moment().startOf('day')) => {
  const timeLabels = [desiredStartTime.format('h:mm A')]
  const minDiff = desiredStartTime.minutes() < 30 ? 30 - desiredStartTime.minutes() : 60 - desiredStartTime.minutes()

  const startTimeMoment = moment(desiredStartTime, 'hh:mm A')
  for (let i = 0; ; i += 1) {
    startTimeMoment.add(i === 0 ? minDiff : 30, 'minutes')
    if (!startTimeMoment.isSame(moment(desiredStartTime), 'day')) {
      timeLabels.push(moment(startTimeMoment.subtract(1, 'minute')).format('h:mm A'))
      break
    } else {
      timeLabels.push(moment(startTimeMoment).format('h:mm A'))
    }
  }

  return timeLabels
}

export default ({
  minValue,
  name,
  value = moment(),
  onChange,
  error,
  onError,
  ...rest
}: FormFieldTimeProps): React.ReactElement => {
  const { t } = useTranslation()
  const anchorEl = useRef(null)
  const [time, setTime] = useState(value.format('h:mm A'))
  const options = useMemo(() => [].concat(...timelineLabels(minValue)), [minValue])
  const [openDropdown, setOpenDropdown] = useState(false)

  useEffect(() => {
    setTime(value.format('h:mm A'))
  }, [value])

  const handleClickDropdown = (e) => {
    e.preventDefault()
    setOpenDropdown(!openDropdown)
    return false
  }

  const handleClickAway = (e) => {
    e.preventDefault()
    if (openDropdown) setOpenDropdown(false)
  }

  // TODO: debounce
  const tryUpdate = (val) => {
    try {
      const newTime = moment(value)
      const apm = val.trim().slice(-3).trim().toUpperCase()
      if (apm === 'AM' || apm === 'PM') {
        const hm = val.slice(0, val.length - 3).trim()
        const splited = hm.split(':')
        const hours = parseInt(splited[0], 10)
        const minutes = parseInt(splited[1], 10)
        if (
          splited[0].length >= 1 &&
          splited[1].length === 2 &&
          hm.length >= 4 &&
          hours > 0 &&
          hours <= 12 &&
          minutes >= 0 &&
          minutes <= 59
        ) {
          newTime.set({
            hours: apm === 'PM' && hours < 12 ? hours + 12 : hours,
            minutes,
            seconds: 0,
          })

          if (newTime.isValid() && (!minValue || !newTime.isBefore(moment(minValue).subtract(1, 'minutes')))) {
            onChange(newTime)
          } else {
            throw new Error('Invalid time')
          }
        } else {
          throw new Error('Invalid time')
        }
      } else {
        throw new Error('Invalid time')
      }
    } catch (e) {
      onError('Invalid time')
    }
  }

  const handleDropdownChange = (val) => (e) => {
    e.preventDefault()
    setTime(val)
    tryUpdate(val)
    setOpenDropdown(false)
    return false
  }

  const handleChange = (e) => {
    setTime(e.target.value)
    tryUpdate(e.target.value)
  }

  return (
    <StyledWrapper ref={anchorEl} {...rest}>
      <StyledFormFieldLabel htmlFor={name}>{t('time')}</StyledFormFieldLabel>
      <StyledInputWrapper>
        <StyledFormField $error={!!error} value={time} name={name} onChange={handleChange} />
        <StyledIconDropdownWrapper onClick={handleClickDropdown}>
          {openDropdown ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </StyledIconDropdownWrapper>
      </StyledInputWrapper>
      {openDropdown && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <StyledTimePickerWrapper>
            <StyledPopperWrapper placement="bottom-start" anchorEl={anchorEl.current} open={openDropdown}>
              <StyledVerticalScrollbar options={{ className: 'os-theme-light' }}>
                <StyledListColumn>
                  {options.map((option) => (
                    <StyledText
                      td="none"
                      $selected={option === time}
                      size="md"
                      fw="500"
                      key={option}
                      onClick={handleDropdownChange(option)}
                    >
                      {option}
                    </StyledText>
                  ))}
                </StyledListColumn>
              </StyledVerticalScrollbar>
            </StyledPopperWrapper>
          </StyledTimePickerWrapper>
        </ClickAwayListener>
      )}
    </StyledWrapper>
  )
}
