import { ReactElement, useLayoutEffect } from 'react'
import {
  // dashboardHomePath,
  // dashboardScheduledCallsPath,
  // dashboardEntertainmentPath,
  tabQueryParam,
  tabRecentCallsQueryParam,
  tabContactsQueryParam,
  tabGroupsQueryParam,
} from 'const'
import Text from 'components/text'
import { useQueryParam, StringParam } from 'use-query-params'
import { StyledList, StyledListItemWrapper } from './style'

const DashboardTabsMenu = (): ReactElement => {
  const [tabParam, setTabParam] = useQueryParam(tabQueryParam, StringParam)

  useLayoutEffect(() => {
    if (!tabParam) setTabParam(tabRecentCallsQueryParam, 'replaceIn')
  }, [setTabParam, tabParam])

  const recentCallsActive = tabParam === tabRecentCallsQueryParam
  const contactsActive = tabParam === tabContactsQueryParam
  const groupsActive = tabParam === tabGroupsQueryParam

  const handleClick = (tabName: string) => () => {
    setTabParam(tabName, 'replaceIn')
  }

  const renderTabMenuItem = (children) => (
    <Text size="md" mb="0.2rem" $secondary color="inherit" fw="600" font="Poppins">
      {children}
    </Text>
  )

  return (
    <StyledList>
      <StyledListItemWrapper
        data-tour="second-step"
        active={recentCallsActive}
        onClick={handleClick(tabRecentCallsQueryParam)}
      >
        {renderTabMenuItem('Recent calls')}
      </StyledListItemWrapper>
      <StyledListItemWrapper
        data-tour="third-step"
        active={contactsActive}
        onClick={handleClick(tabContactsQueryParam)}
      >
        {renderTabMenuItem('Contacts')}
      </StyledListItemWrapper>
      <StyledListItemWrapper data-tour="fourth-step" active={groupsActive} onClick={handleClick(tabGroupsQueryParam)}>
        {renderTabMenuItem('Groups')}
      </StyledListItemWrapper>
    </StyledList>
  )
}

export default DashboardTabsMenu
