import styled from 'styled-components'
import Button from 'components/button'

export default styled(Button).attrs((props) => ({
  type: 'submit',
  mt: '1rem',
  size: 'lg',
  square: 'true',
  bop: '1',
  bcolor: 'mediumpurple',
  hgradient: 'mediumpurple-dim-light',
  agradient: 'mediumpurple-dim-dark',
  ...props,
}))``
