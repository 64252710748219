/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactElement } from 'react'
import { Route } from 'react-router'
import { selectAuthUser, selectAnonUser } from 'reducers/usersSlice'
import { connect } from 'react-redux'
import useUser from 'hooks/useUser'
import themeComponents from './theme'

interface AllRouteProps {
  authUser?: any
  anonUser?: any
  render?: any
  component?: FC
  exact?: boolean
  path: string
  themeRoute?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AllRoute: FC<AllRouteProps> = ({
  themeRoute,
  authUser,
  anonUser,
  render,
  component: Component,
  exact,
  ...rest
}): ReactElement => {
  const loadingAuthUser = useUser(!!authUser)
  const loadingAnonUser = useUser(!!authUser && !!anonUser, true)

  if (loadingAuthUser || loadingAnonUser) return <></>

  const ThemeComponent = themeComponents[themeRoute] || null

  return (
    <Route
      {...rest}
      render={(props: unknown) => <ThemeComponent>{render ? render() : <Component {...props} />}</ThemeComponent>}
    />
  )
}

const mapStateToProps = (state) => ({
  authUser: selectAuthUser(state),
  anonUser: selectAnonUser(state),
})

export default connect(mapStateToProps, null)(AllRoute)
