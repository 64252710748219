import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

export interface TextProps {
  /**
   * Text align center
   */
  $center?: boolean
  /**
   * Display block
   */
  $block?: boolean
  /**
   * Max width
   */
  maxw?: string
  /**
   * Variant
   */
  $secondary?: boolean
  /**
   * Size
   */
  size?: string
  /**
   * Font size
   */
  fs?: string
  /**
   * Component component
   */
  component?: string
  /**
   * Text shadow
   */
  shadow?: string
  /**
   * Color
   */
  color?: string
  /**
   * Color opacity
   */
  cop?: string
  /**
   * Gradient color
   */
  gradient?: string
  /**
   * Margin top
   */
  mt?: string
  /**
   * Margin bottom
   */
  mb?: string
  /**
   * Margin left
   */
  ml?: string
  /**
   * Margin right
   */
  mr?: string
  /**
   * Line height
   */
  lh?: string
  /**
   * Font weight
   */
  fw?: string
  /**
   * Font family
   */
  font?: string
  /**
   * Letter spacing
   */
  ls?: string
  /**
   * Href
   */
  link?: string
  /**
   * Width
   */
  width?: string
  /**
   * Text decoration
   */
  td?: string
  /**
   * Text transform
   */
  tt?: string
  /**
   * Text overflow
   */
  $ellipsis?: boolean
  /**
   * Open link in a new window
   */
  linkBlank?: string
  /**
   * On click handler
   */
  onClick?: () => void
}

export default styled(({ children, component, onClick, linkBlank, link, ...rest }) => {
  let element = <></>
  const isLink = link || onClick
  switch (component) {
    case 'title':
      element = <title {...(!isLink ? rest : {})}>{children}</title>
      break
    case 'h1':
      element = <h1 {...(!isLink ? rest : {})}>{children}</h1>
      break
    case 'h2':
      element = <h2 {...(!isLink ? rest : {})}>{children}</h2>
      break
    case 'h3':
      element = <h3 {...(!isLink ? rest : {})}>{children}</h3>
      break
    default:
      element = <span {...(!isLink ? rest : {})}>{children}</span>
      break
  }
  if (link) {
    if (link.startsWith('http')) {
      element = (
        <Link {...rest} target={linkBlank ? '_blank' : '_top'} rel="noopener noreferrer" to={{ pathname: link }}>
          {children}
        </Link>
      )
    } else {
      element = (
        <Link {...rest} to={link}>
          {children}
        </Link>
      )
    }
  } else if (onClick) {
    element = (
      <button type="button" onClick={onClick} {...rest}>
        {element}
      </button>
    )
  }
  return element
})<TextProps>`
  letter-spacing: 0.007rem;
  color: ${({ theme: { fn, palette } }) => fn.color(palette.text.primary)};
  font-style: normal;
  font-weight: normal;
  background-color: transparent;
  border: none;
  outline: none;
  margin-bottom: 0;
  font-family: ${({ theme: { fonts } }) => fonts.Inter};

  font-size: 1rem;
  line-height: 1.1875rem;

  ${(props: TextProps) =>
    !!props.size &&
    props.size === 'default' &&
    css`
      font-size: 1rem;
    `}

  ${(props: TextProps) =>
    !!props.component &&
    props.component === 'h1' &&
    css`
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 2.25rem;
    `}

  ${(props: TextProps) =>
    !!props.component &&
    props.component === 'h2' &&
    css`
      font-weight: 600;
      font-size: 2rem;
      line-height: 2.6rem;
    `}

  ${(props: TextProps) =>
    !!props.component &&
    props.component === 'h3' &&
    css`
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.1875rem;
    `}

  ${(props: TextProps) =>
    !!props.size &&
    props.size === 'xs' &&
    css`
      font-size: 0.875rem;
      line-height: 1.0588rem;
    `}

  ${(props: TextProps) =>
    !!props.size &&
    props.size === 'md' &&
    css`
      font-size: 1.125rem;
      line-height: 1.375rem;
      letter-spacing: 0.05rem;
    `}

  ${(props: TextProps) =>
    !!props.size &&
    props.size === 'lg' &&
    css`
      font-size: 1.25rem;
      line-height: 1.5125rem;
      font-weight: 600;
    `}

  ${(props: TextProps) =>
    !!props.size &&
    props.size === 'xl' &&
    css`
      font-size: 1.5rem;
      line-height: 2.25rem;
      letter-spacing: 0.05rem;
    `}

  ${(props: TextProps) =>
    !!props.size &&
    props.size === 'xxl' &&
    css`
      display: inline-block;
      font-family: ${({ theme: { fonts } }) => fonts.Poppins};
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 2.25rem;
      letter-spacing: 0.05rem;
    `}

  ${(props: TextProps) =>
    !!props.size &&
    props.size === 'xxxl' &&
    css`
      display: inline-block;
      font-family: ${({ theme: { fonts } }) => fonts.Poppins};
      font-size: 2rem;
      font-weight: 600;
      line-height: 3rem;
      letter-spacing: 0.05rem;
    `}

  ${(props: TextProps) =>
    !!props.size &&
    props.size === 'xxxxl' &&
    css`
      display: inline-block;
      font-family: ${({ theme: { fonts } }) => fonts.Poppins};
      font-size: 3rem;
      font-weight: 600;
      line-height: 3.3rem;
      letter-spacing: 0.05rem;
    `}

  ${(props: TextProps) =>
    !!props.ls &&
    css`
      letter-spacing: ${props.ls};
    `}

  ${(props: TextProps) =>
    !!props.font &&
    css`
      font-family: ${props.font};
    `}

  ${(props: TextProps) =>
    !!props.onClick &&
    css`
      text-decoration: underline;
      cursor: pointer;
    `}

  ${(props: TextProps) =>
    (!!props.link || !!props.onClick) &&
    css`
      font-weight: 600;
    `}

  ${(props: TextProps) =>
    !!props.fs &&
    css`
      font-size: ${props.fs};
    `}

  ${(props: TextProps) =>
    !!props.fw &&
    css`
      font-weight: ${props.fw};
    `}

  ${(props: TextProps) =>
    !!props.lh &&
    css`
      line-height: ${props.lh};
    `}

  ${(props: TextProps) =>
    !!props.width &&
    css`
      width: ${props.width};
    `}

  ${(props: TextProps) =>
    !!props.td &&
    css`
      text-decoration: ${props.td};
    `}

  ${(props: TextProps) =>
    !!props.tt &&
    css`
      text-transform: ${props.tt};
    `}

  ${(props: TextProps) =>
    props.$secondary &&
    css`
      color: ${({ theme: { fn, palette } }) => fn.color(palette.text.secondary)};
    `}

  ${(props: TextProps) =>
    !!props.color &&
    css`
      color: ${({ theme: { fn, palette } }) =>
        palette.text[props.color] ? fn.color(palette.text[props.color], props.cop || '1') : props.color};
    `}

  ${(props: TextProps) =>
    !!props.mt &&
    css`
      margin-top: ${props.mt}};
    `}

  ${(props: TextProps) =>
    !!props.mb &&
    css`
      margin-bottom: ${props.mb}};
    `}

  ${(props: TextProps) =>
    !!props.ml &&
    css`
      margin-left: ${props.ml}};
    `}

  ${(props: TextProps) =>
    !!props.mr &&
    css`
      margin-right: ${props.mr}};
    `}

  ${(props: TextProps) =>
    !!props.maxw &&
    css`
      max-width: ${props.maxw}};
    `}

  ${(props: TextProps) =>
    props.$block &&
    css`
      display: inline-block;
    `}

  ${(props: TextProps) =>
    !!props.$ellipsis &&
    css`
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${(props: TextProps) =>
    !!props.gradient &&
    css`
      background-image: ${({ theme: { gradients } }) => gradients[props.gradient]};
      background-size: 100%;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
    `}

  ${(props: TextProps) =>
    props.$center &&
    css`
      text-align: center;
    `}

  ${(props: TextProps) =>
    props.shadow &&
    css`
      text-shadow: ${({ theme: { shadows } }) => shadows[props.shadow]};
    `}
`
