/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ComponentType, lazy, ReactElement, useState } from 'react'
import { Switch } from 'react-router'
import { Redirect } from 'react-router-dom'
import AuthenticatedRoute from 'hocs/authenticated-route'
import Footer from 'components/footer'
import { DashboardTour, DashboardAlerts } from 'components/dashboard'
import { DashboardNotifications } from 'notifications'
import { useQueryParam, StringParam } from 'use-query-params'
import { DashboardModals } from 'modals'
import Notification from 'react-web-notification'
import {
  callsJoinEnterCodePath,
  callsJoinEnterNamePath,
  callsJoinPath,
  callsEndedPath,
  callsWaitingPath,
  callsJoinSignInPath,
  signInPath,
  signUpUsernamePath,
  signUpPasswordPath,
  forgotPasswordPath,
  forgotPasswordSuccessPath,
  dashboardPath,
  homePath,
  callsPath,
  callsShortPath,
  callsDeeplinkPath,
  setPasswordPath,
  resetPasswordPath,
  signUpPath,
  modalQueryParam,
  modalAllowWebNotificationsQueryParam,
} from 'const'
import AnonymousRoute from 'hocs/anonymous-route'
import AllRoute from 'hocs/all-route'

declare global {
  interface Window {
    electronNodeAPI: any
  }
}

function retry(fn, retriesLeft = 5, interval = 1000): Promise<{ default: ComponentType<any> }> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error)
            return
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

const SignIn = lazy(() => retry(() => import('views/sign-in')))
const SignUpUsername = lazy(() => retry(() => import('views/sign-up/username')))
const SignUpPassword = lazy(() => retry(() => import('views/sign-up/password')))
const Dashboard = lazy(() => retry(() => import('views/dashboard')))
const Calls = lazy(() => retry(() => import('views/calls')))
const JoinCallEnterCode = lazy(() => retry(() => import('views/calls/join/enter-code')))
const CallsDeeplink = lazy(() => retry(() => import('views/calls/join/deep-link')))
const JoinCallEnterName = lazy(() => retry(() => import('views/calls/join/enter-name')))
const JoinCallSignIn = lazy(() => retry(() => import('views/calls/join/sign-in')))
const JoinCallsInvite = lazy(() => retry(() => import('views/calls/join/invite')))
const CallsEnded = lazy(() => retry(() => import('views/calls/ended')))
const CallsWaiting = lazy(() => retry(() => import('views/calls/join/waiting')))
const ForgotPassword = lazy(() => retry(() => import('views/forgot-password')))
const ForgotPasswordSuccess = lazy(() => retry(() => import('views/forgot-password/success')))
const SetPassword = lazy(() => retry(() => import('views/set-password')))
const ResetPassword = lazy(() => retry(() => import('views/reset-password')))

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Routes = (): ReactElement => {
  const [webNotification, setWebNotification] = useState(null)
  const [, setModalParam] = useQueryParam(modalQueryParam, StringParam)

  const onAskWebNotificationPermission = () => {
    if (window?.Notification?.permission === 'default') {
      setModalParam(modalAllowWebNotificationsQueryParam, 'replaceIn')
    }
  }

  return (
    <Switch>
      <AnonymousRoute exact path={homePath} themeRoute="home" render={() => <Redirect to={signInPath} />} />
      <AnonymousRoute
        exact
        path={signInPath}
        themeRoute="home"
        render={() => (
          <>
            <SignIn />
            <Footer $primary />
          </>
        )}
      />
      <AnonymousRoute exact path={signUpPath} themeRoute="home" render={() => <Redirect to={signUpUsernamePath} />} />
      <AnonymousRoute exact path={signUpUsernamePath} themeRoute="home" component={SignUpUsername} />
      <AnonymousRoute
        exact
        path={signUpPasswordPath}
        themeRoute="home"
        render={() => (
          <>
            <SignUpPassword />
            <Footer $primary />
          </>
        )}
      />
      <AnonymousRoute
        exact
        path={setPasswordPath}
        themeRoute="home"
        render={() => (
          <>
            <SetPassword />
            <Footer $primary />
          </>
        )}
      />
      <AnonymousRoute
        exact
        path={resetPasswordPath}
        themeRoute="home"
        render={() => (
          <>
            <ResetPassword />
            <Footer $primary />
          </>
        )}
      />
      <AnonymousRoute
        exact
        path={forgotPasswordPath}
        themeRoute="home"
        render={() => (
          <>
            <ForgotPassword />
            <Footer $primary />
          </>
        )}
      />
      <AnonymousRoute
        exact
        path={forgotPasswordSuccessPath}
        themeRoute="home"
        render={() => (
          <>
            <ForgotPasswordSuccess />
            <Footer $primary />
          </>
        )}
      />
      <AuthenticatedRoute
        path={dashboardPath}
        themeRoute="dashboard"
        render={() => (
          <>
            {webNotification && <Notification {...webNotification} />}
            <DashboardNotifications setWebNotification={setWebNotification} />
            <DashboardModals
              setWebNotification={setWebNotification}
              onAskWebNotificationPermission={onAskWebNotificationPermission}
            />
            <DashboardTour onClose={onAskWebNotificationPermission} />
            <DashboardAlerts />
            <Dashboard onAskWebNotificationPermission={onAskWebNotificationPermission} />
            <Footer />
          </>
        )}
      />
      <AllRoute
        exact
        path={callsJoinPath}
        themeRoute="home"
        render={() => (
          <>
            <JoinCallEnterCode />
            <Footer $primary />
          </>
        )}
      />
      <AllRoute
        exact
        path={callsJoinEnterCodePath}
        themeRoute="home"
        render={() => (
          <>
            <JoinCallEnterCode />
            <Footer $primary />
          </>
        )}
      />
      <AnonymousRoute
        exact
        path={callsJoinEnterNamePath}
        themeRoute="home"
        render={() => (
          <>
            <JoinCallEnterName />
            <Footer $primary />
          </>
        )}
      />
      <AnonymousRoute
        exact
        path={callsJoinSignInPath}
        themeRoute="home"
        render={() => (
          <>
            <JoinCallSignIn />
            <Footer $primary />
          </>
        )}
      />
      <AllRoute
        exact
        path={callsEndedPath}
        themeRoute="home"
        render={() => (
          <>
            <CallsEnded />
            <Footer $primary />
          </>
        )}
      />
      <AllRoute
        exact
        path={`${callsWaitingPath}/:callId`}
        themeRoute="home"
        render={() => (
          <>
            <CallsWaiting />
            <Footer $primary />
          </>
        )}
      />
      <AllRoute
        exact
        path={`${callsDeeplinkPath}/:callId`}
        themeRoute="home"
        render={() => (
          <>
            <CallsDeeplink />
            <Footer $primary />
          </>
        )}
      />
      <AllRoute exact path={callsPath} themeRoute="home" component={Calls} />
      <AllRoute
        exact
        path={callsShortPath}
        themeRoute="home"
        render={() => (
          <>
            <JoinCallEnterCode />
            <Footer $primary />
          </>
        )}
      />
      <AllRoute
        exact
        path={`${callsShortPath}/:callId`}
        themeRoute="home"
        render={() => (
          <>
            <JoinCallsInvite />
            <Footer $primary />
          </>
        )}
      />
      <AllRoute exact path={`${callsPath}/:callId`} themeRoute="home" component={Calls} />
      <Redirect to="/" />
    </Switch>
  )
}

export default Routes
