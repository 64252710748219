/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import { apiUrl } from 'const'
import { setTokenInterceptor, responseInterceptorError } from 'utils/apis'

export enum GuardianRequestStatuses {
  pending = 'pending',
  accepted = 'accepted',
  rejected = 'rejected',
}

export interface GuardianRequestData {
  id: string
  status: GuardianRequestStatuses
}

export interface ApiSendGuardianRequest {
  email: string
}

const instance = axios.create({
  baseURL: `${apiUrl}/guardians/request`,
})

instance.interceptors.request.use(setTokenInterceptor(true, false))
instance.interceptors.response.use(undefined, responseInterceptorError)

export const apiGetAllGuardianRequests = async (): Promise<GuardianRequestData[]> => {
  const res = await instance({
    method: 'GET',
  })
  return res.data
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const apiUpdateGuardianRequest = async (data): Promise<GuardianRequestData[]> => {
  const res = await instance({
    url: `/${data.id}`,
    method: 'POST',
    data: { status: data.status },
  })
  return [res.data]
}

export const apiSendGuardianRequest = async (data: ApiSendGuardianRequest): Promise<GuardianRequestData> => {
  const res = await instance({
    method: 'POST',
    data,
  })
  return res.data
}

export const apiAckGuardianRequest = async (id: string): Promise<GuardianRequestData> => {
  const res = await instance({
    url: `/${id}/acknowledge`,
    method: 'POST',
  })
  return res.data
}

export const apiRemoveGuardianRequest = async (id: string): Promise<GuardianRequestData> => {
  const res = await instance({
    url: `/${id}`,
    method: 'DELETE',
  })
  return res.data
}
