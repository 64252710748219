import { ReactElement, useLayoutEffect, useMemo, useState } from 'react'
import {
  modalQueryParam,
  subModalQueryParam,
  modalTourQueryParam,
  modalImportContactsSelectProviderQueryParam,
  modalImportContactsMoreInfoQueryParam,
  modalImportContactsOtherProvidersQueryParam,
  modalImportContactsSuccessInfoQueryParam,
  modalCreateContactQueryParam,
  modalEditContactQueryParam,
  modalCreateCallQueryParam,
  modalCreateGroupQueryParam,
  modalAddPeopleQueryParam,
  modalStartCallQueryParam,
  modalStartGroupCallQueryParam,
  modalContactInfoQueryParam,
  modalEditProfileNameQueryParam,
  modalEditManagedUserNameQueryParam,
  modalEditProfileEmailQueryParam,
  modalEditProfilePasswordQueryParam,
  modalCallScheduledQueryParam,
  modalAddManagedUserQueryParam,
  modalManagedUserCreatedQueryParam,
  modalExistingManagedUserQueryParam,
  modalManagedUserRequestSentQueryParam,
  modalManagedUserEnterNameQueryParam,
  modalUpdateManagedUserInfoQueryParam,
  modalUpdateManagedUserQueryParam,
  modalNewManagedUserUpdatedQueryParam,
  modalAddManagedUserContactsQueryParam,
  modalCreateManagedUserContactQueryParam,
  modalAddManagedUserGroupsQueryParam,
  modalCreateManagedUserGroupQueryParam,
  modalAddManagedUserPeopleQueryParam,
  modalGuardianRequestQueryParam,
  modalGuardianRequestAcceptedQueryParam,
  modalAllowWebNotificationsQueryParam,
} from 'const'
import { useQueryParam, StringParam } from 'use-query-params'
import Tour from './tour'
import ImportContactsSelectProvider from './import-contacts/select-provider'
import ImportContactsMoreInfo from './import-contacts/more-info'
import ImportContactsOtherProviders from './import-contacts/other-providers'
import ImportContactsSuccessInfo from './import-contacts/success-info'
import CreateContact from './create-contact'
import EditContact from './edit-contact'
import CreateCall from './create-call'
import CreateGroup from './create-group'
import AddPeople from './add-people'
import StartCall from './start-call'
import StartGroupCall from './start-group-call'
import ContactInfo from './contact-info'
import EditProfileName from './edit-profile-name'
import EditProfileEmail from './edit-profile-email'
import EditProfilePassword from './edit-profile-password'
import CallScheduled from './call-scheduled'
import AllowWebNotifications from './allow-web-notifications'
import {
  AddManagedUser,
  ManagedUserCreated,
  ExistingManagedUser,
  ManagedUserRequestSent,
  ManagedUserEnterName,
  UpdateManagedUserInfo,
  UpdateManagedUser,
  NewManagedUserUpdated,
  EditManagedUserName,
  AddManagedUserContacts,
  CreateManagedUserContact,
  AddManagedUserGroups,
  CreateManagedUserGroup,
  AddManagedUserPeople,
  GuardianRequest,
  GuardianRequestAccepted,
} from './guardian-tools'
import {
  StyledOverlayScrollbarsComponent,
  StyledContentContainer,
  StyledCard,
  StyledColumnsContainer,
  StyledModal,
} from './style'

const mdSizes = [
  modalTourQueryParam,
  modalImportContactsSelectProviderQueryParam,
  modalImportContactsMoreInfoQueryParam,
  modalImportContactsOtherProvidersQueryParam,
  modalImportContactsSuccessInfoQueryParam,
  modalCreateCallQueryParam,
  modalCreateGroupQueryParam,
  modalAddPeopleQueryParam,
  modalStartCallQueryParam,
  modalStartGroupCallQueryParam,
  modalCallScheduledQueryParam,
  modalAddManagedUserQueryParam,
  modalManagedUserCreatedQueryParam,
  modalExistingManagedUserQueryParam,
  modalManagedUserRequestSentQueryParam,
  modalManagedUserEnterNameQueryParam,
  modalNewManagedUserUpdatedQueryParam,
  modalAddManagedUserContactsQueryParam,
  modalAddManagedUserGroupsQueryParam,
  modalCreateManagedUserGroupQueryParam,
  modalAddManagedUserPeopleQueryParam,
  modalGuardianRequestQueryParam,
  modalGuardianRequestAcceptedQueryParam,
  modalAllowWebNotificationsQueryParam,
]

const xlSizes = [modalUpdateManagedUserQueryParam]

const getModal = (param, onClose, onTourClose, setWebNotification) => {
  if (param === modalTourQueryParam) return <Tour onClose={onTourClose} />
  if (param === modalImportContactsSelectProviderQueryParam) return <ImportContactsSelectProvider onClose={onClose} />
  if (param === modalImportContactsMoreInfoQueryParam) return <ImportContactsMoreInfo onClose={onClose} />
  if (param === modalImportContactsOtherProvidersQueryParam) return <ImportContactsOtherProviders onClose={onClose} />
  if (param === modalImportContactsSuccessInfoQueryParam) return <ImportContactsSuccessInfo onClose={onClose} />
  if (param === modalCreateContactQueryParam) return <CreateContact onClose={onClose} />
  if (param === modalEditContactQueryParam) return <EditContact onClose={onClose} />
  if (param === modalCreateCallQueryParam) return <CreateCall onClose={onClose} />
  if (param === modalCreateGroupQueryParam) return <CreateGroup onClose={onClose} />
  if (param === modalAddPeopleQueryParam) return <AddPeople onClose={onClose} />
  if (param === modalStartCallQueryParam) return <StartCall onClose={onClose} />
  if (param === modalStartGroupCallQueryParam) return <StartGroupCall onClose={onClose} />
  if (param === modalContactInfoQueryParam) return <ContactInfo onClose={onClose} />
  if (param === modalEditProfileNameQueryParam) return <EditProfileName onClose={onClose} />
  if (param === modalEditProfileEmailQueryParam) return <EditProfileEmail onClose={onClose} />
  if (param === modalEditProfilePasswordQueryParam) return <EditProfilePassword onClose={onClose} />
  if (param === modalCallScheduledQueryParam) return <CallScheduled onClose={onClose} />
  if (param === modalAddManagedUserQueryParam) return <AddManagedUser onClose={onClose} />
  if (param === modalManagedUserCreatedQueryParam) return <ManagedUserCreated onClose={onClose} />
  if (param === modalExistingManagedUserQueryParam) return <ExistingManagedUser onClose={onClose} />
  if (param === modalManagedUserRequestSentQueryParam) return <ManagedUserRequestSent onClose={onClose} />
  if (param === modalManagedUserEnterNameQueryParam) return <ManagedUserEnterName onClose={onClose} />
  if (param === modalUpdateManagedUserInfoQueryParam) return <UpdateManagedUserInfo onClose={onClose} />
  if (param === modalUpdateManagedUserQueryParam) return <UpdateManagedUser onClose={onClose} />
  if (param === modalNewManagedUserUpdatedQueryParam) return <NewManagedUserUpdated onClose={onClose} />
  if (param === modalEditManagedUserNameQueryParam) return <EditManagedUserName onClose={onClose} />
  if (param === modalAddManagedUserContactsQueryParam) return <AddManagedUserContacts onClose={onClose} />
  if (param === modalCreateManagedUserContactQueryParam) return <CreateManagedUserContact onClose={onClose} />
  if (param === modalAddManagedUserGroupsQueryParam) return <AddManagedUserGroups onClose={onClose} />
  if (param === modalCreateManagedUserGroupQueryParam) return <CreateManagedUserGroup onClose={onClose} />
  if (param === modalAddManagedUserPeopleQueryParam) return <AddManagedUserPeople onClose={onClose} />
  if (param === modalGuardianRequestQueryParam) return <GuardianRequest onClose={onClose} />
  if (param === modalGuardianRequestAcceptedQueryParam) return <GuardianRequestAccepted onClose={onClose} />
  if (param === modalAllowWebNotificationsQueryParam)
    return <AllowWebNotifications setWebNotification={setWebNotification} onClose={onClose} />

  return null
}

const getSize = (modal) => {
  if (xlSizes.includes(modal)) return 'xl'
  return mdSizes.includes(modal) ? 'md' : 'sm'
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const DashboardModals = ({ setWebNotification, onAskWebNotificationPermission }): ReactElement => {
  const [modalSize, setModalSize] = useState('md')
  const [subModalSize, setSubModalSize] = useState('md')
  const [modalParam, setModalParam] = useQueryParam(modalQueryParam, StringParam)
  const [subModalParam, setSubModalParam] = useQueryParam(subModalQueryParam, StringParam)

  const modalContainer = useMemo(() => {
    const handleModalClose = () => {
      setModalParam(null, 'replaceIn')
    }
    const handleModalTourClose = () => {
      handleModalClose()
      onAskWebNotificationPermission()
    }
    return getModal(modalParam, handleModalClose, handleModalTourClose, setWebNotification)
  }, [modalParam, onAskWebNotificationPermission, setModalParam, setWebNotification])

  const subModalContainer = useMemo(() => {
    const handleSubModalClose = () => {
      setSubModalParam(null, 'replaceIn')
    }
    const handleModalTourClose = () => {
      handleSubModalClose()
      onAskWebNotificationPermission()
    }
    return getModal(subModalParam, handleSubModalClose, handleModalTourClose, setWebNotification)
  }, [onAskWebNotificationPermission, setSubModalParam, setWebNotification, subModalParam])

  useLayoutEffect(() => {
    if (modalParam) setModalSize(getSize(modalParam))
  }, [modalParam])

  useLayoutEffect(() => {
    if (subModalParam) setSubModalSize(getSize(subModalParam))
  }, [subModalParam])

  const getModalWrapper = (size, container) => (
    <StyledModal component="dialog">
      <StyledColumnsContainer>
        <StyledOverlayScrollbarsComponent options={{ className: 'os-theme-dark' }}>
          <StyledContentContainer>
            <StyledCard size={size} secondary="true">
              {container}
            </StyledCard>
          </StyledContentContainer>
        </StyledOverlayScrollbarsComponent>
      </StyledColumnsContainer>
    </StyledModal>
  )

  return (
    <>
      {modalParam && getModalWrapper(modalSize, modalContainer)}
      {subModalParam && getModalWrapper(subModalSize, subModalContainer)}
    </>
  )
}

export default DashboardModals
