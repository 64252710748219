/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { added, updated, CallNormalized } from './callsSlice'

export const actionsTypesMapping = {}

export const eventsMapping = {
  callAdded: {
    action: 'calls/added',
    actionCreator: added,
  },
  callUpdated: {
    action: 'calls/updated',
    actionCreator: updated,
  },
  // Updates calls contacts and calls groups contacts presence statuses(online/offline)
  userStatus: {
    action: 'calls/updated',
    actionCreator: updated,
    map: ({ state, payload: { userId, status } }) => {
      if (userId) {
        const calls: CallNormalized[] = Object.values(state?.calls?.entities || {}).filter(
          (call: CallNormalized) =>
            call.contacts?.some((contact) => contact.userId === userId) ||
            call.groups?.some(({ contacts }) => contacts.some((contact) => contact.userId === userId)),
        ) as null | CallNormalized[]
        if (calls)
          return calls.map((call) => ({
            id: call.id,
            changes: {
              contacts: call.contacts?.map((contact) => ({
                ...contact,
                status: contact.userId === userId ? status : contact.status,
              })),
              groups: call.groups?.map((group) => ({
                ...group,
                contacts: group.contacts?.map((contact) => ({
                  ...contact,
                  status: contact.userId === userId ? status : contact.status,
                })),
              })),
            },
          }))
      }

      return null
    },
  },
}
