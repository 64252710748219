import { ReactElement } from 'react'

export default (): ReactElement => (
  <svg width="52" height="30" viewBox="0 0 52 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M50.7659 16.4091C51.5387 15.6199 51.5254 14.3536 50.7362 13.5808L37.8756 0.986981C37.0864 0.214161 35.8202 0.227433 35.0473 1.01662C34.2745 1.80582 34.2878 3.07208 35.077 3.8449L46.5086 15.0394L35.3141 26.4711C34.5413 27.2603 34.5546 28.5265 35.3438 29.2993C36.133 30.0722 37.3992 30.0589 38.172 29.2697L50.7659 16.4091ZM0.176022 17.4642C12.3144 17.3675 24.6098 17.2538 33.8705 17.1644C38.5008 17.1196 42.3726 17.081 45.0868 17.0535C46.4439 17.0397 47.5116 17.0288 48.2401 17.0213C48.6043 17.0175 48.8837 17.0146 49.0721 17.0126C49.1663 17.0117 49.2377 17.0109 49.2856 17.0104C49.3096 17.0102 49.3276 17.01 49.3397 17.0098C49.3457 17.0098 49.3503 17.0097 49.3533 17.0097C49.3548 17.0097 49.356 17.0097 49.3567 17.0097C49.3571 17.0097 49.3574 17.0097 49.3576 17.0097C49.3578 17.0097 49.3579 17.0097 49.3369 15.0098C49.316 13.0099 49.3159 13.0099 49.3157 13.0099C49.3155 13.0099 49.3152 13.0099 49.3148 13.0099C49.3141 13.0099 49.3129 13.0099 49.3114 13.0099C49.3084 13.01 49.3039 13.01 49.2978 13.0101C49.2858 13.0102 49.2678 13.0104 49.2439 13.0106C49.196 13.0111 49.1246 13.0119 49.0305 13.0129C48.8422 13.0148 48.5629 13.0177 48.1988 13.0215C47.4706 13.029 46.4031 13.0399 45.0463 13.0537C42.3326 13.0812 38.4615 13.1198 33.8318 13.1646C24.5724 13.254 12.2794 13.3676 0.144135 13.4644L0.176022 17.4642Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="18.423"
        y1="-10.5153"
        x2="8.92425"
        y2="28.8934"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6BF4F" />
        <stop offset="1" stopColor="#FF00E7" />
      </linearGradient>
    </defs>
  </svg>
)
