/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement, useLayoutEffect, useMemo, useState } from 'react'
import {
  modalQueryParam,
  subModalQueryParam,
  modalSettingsQueryParam,
  modalAllowCamAndMicQueryParam,
  modalMuteQueryParam,
  modalNewGameQueryParam,
  modalGameInviteQueryParam,
  modalNewMediaQueryParam,
  modalStopVideoQueryParam,
  modalUnmuteQueryParam,
  modalCameraOnQueryParam,
  modalRemoveFromCallQueryParam,
  modalScreenShareSendRequestQueryParam,
  modalScreenShareRequestSentQueryParam,
  modalScreenShareSelectSourceQueryParam,
} from 'const'
import { useQueryParam, StringParam } from 'use-query-params'
import { ContentContainer } from 'components/container'
import AdvancedSettings from './advanced/settings'
import AllowCamAndMic from './allow-cam-and-mic'
import Mute from './moderator/mute'
import StopVideo from './moderator/stop-video'
import Unmute from './moderator/unmute'
import CameraOn from './moderator/camera-on'
import RemoveFromCall from './moderator/remove-from-call'
import ScreenShareSendRequest from './moderator/screen-share/send-request'
import ScreenShareRequestSent from './moderator/screen-share/request-sent'
import ScreenShareSelectSource from './advanced/screen-share-select-source'
import NewGame from './entertainment/games/new'
import NewMedia from './entertainment/media/new'
import GameInvite from './entertainment/games/invite'
import { StyledCard, StyledColumnsContainer, StyledModal } from './style'

const mdSizes = [
  modalAllowCamAndMicQueryParam,
  modalMuteQueryParam,
  modalStopVideoQueryParam,
  modalRemoveFromCallQueryParam,
  modalScreenShareSendRequestQueryParam,
  modalScreenShareRequestSentQueryParam,
  modalUnmuteQueryParam,
  modalCameraOnQueryParam,
  modalScreenShareSelectSourceQueryParam,
]

const getModal = (
  param,
  onClose,
  {
    user,
    call,
    changeCamera,
    changeMicrophone,
    setAudioOutputDevice,
    selectedAudioInputDeviceId,
    selectedAudioOutputDeviceId,
    selectedVideoDeviceId,
    audioDevices,
    videoDevices,
    permissionPromptIsShown,
    mute,
    stopVideo,
    removeFromCall,
    displayNames,
    clanzyUsersIds,
    managedUsers,
    sendScreenShareRequest,
    sendUnmuteRequest,
    sendCameraOnRequest,
    cancelScreenShareRequest,
    screenShareId,
    shareScreen,
  },
) => {
  if (param === modalSettingsQueryParam) {
    return (
      <AdvancedSettings
        onClose={onClose}
        changeCamera={changeCamera}
        changeMicrophone={changeMicrophone}
        setAudioOutputDevice={setAudioOutputDevice}
        audioDevices={audioDevices}
        videoDevices={videoDevices}
        selectedAudioInputDeviceId={selectedAudioInputDeviceId}
        selectedAudioOutputDeviceId={selectedAudioOutputDeviceId}
        selectedVideoDeviceId={selectedVideoDeviceId}
      />
    )
  }
  if (param === modalAllowCamAndMicQueryParam)
    return <AllowCamAndMic onClose={onClose} permissionPromptIsShown={permissionPromptIsShown} />
  if (param === modalMuteQueryParam)
    return (
      <Mute
        onClose={onClose}
        clanzyUsersIds={clanzyUsersIds}
        managedUsers={managedUsers}
        displayNames={displayNames}
        mute={mute}
      />
    )
  if (param === modalStopVideoQueryParam)
    return (
      <StopVideo
        onClose={onClose}
        clanzyUsersIds={clanzyUsersIds}
        managedUsers={managedUsers}
        displayNames={displayNames}
        stopVideo={stopVideo}
      />
    )
  if (param === modalUnmuteQueryParam)
    return <Unmute onClose={onClose} user={user} displayNames={displayNames} sendUnmuteRequest={sendUnmuteRequest} />
  if (param === modalCameraOnQueryParam)
    return (
      <CameraOn onClose={onClose} user={user} displayNames={displayNames} sendCameraOnRequest={sendCameraOnRequest} />
    )
  if (param === modalRemoveFromCallQueryParam)
    return <RemoveFromCall onClose={onClose} displayNames={displayNames} removeFromCall={removeFromCall} />
  if (param === modalScreenShareSendRequestQueryParam)
    return (
      <ScreenShareSendRequest
        onClose={onClose}
        displayNames={displayNames}
        user={user}
        sendScreenShareRequest={sendScreenShareRequest}
      />
    )
  if (param === modalScreenShareRequestSentQueryParam)
    return (
      <ScreenShareRequestSent
        onClose={onClose}
        screenShareId={screenShareId}
        displayNames={displayNames}
        cancelScreenShareRequest={cancelScreenShareRequest}
      />
    )
  if (param === modalNewGameQueryParam) return <NewGame call={call} onClose={onClose} />
  if (param === modalNewMediaQueryParam) return <NewMedia call={call} onClose={onClose} />
  if (param === modalGameInviteQueryParam)
    return <GameInvite clanzyUsersIds={clanzyUsersIds} call={call} user={user} onClose={onClose} />
  if (param === modalScreenShareSelectSourceQueryParam)
    return <ScreenShareSelectSource shareScreen={shareScreen} screenShareId={screenShareId} onClose={onClose} />

  return null
}

const getSize = (modal) => {
  return mdSizes.includes(modal) ? 'md' : 'sm'
}

const CallsModals = (props): ReactElement => {
  const [modalSize, setModalSize] = useState('md')
  const [subModalSize, setSubModalSize] = useState('md')
  const [modalParam, setModalParam] = useQueryParam(modalQueryParam, StringParam)
  const [subModalParam, setSubModalParam] = useQueryParam(subModalQueryParam, StringParam)

  const modalContainer = useMemo(() => {
    const handleModalClose = () => {
      setModalParam(null, 'replaceIn')
    }
    return getModal(modalParam, handleModalClose, props)
  }, [modalParam, props, setModalParam])

  const subModalContainer = useMemo(() => {
    const handleSubModalClose = () => {
      setSubModalParam(null, 'replaceIn')
    }
    return getModal(subModalParam, handleSubModalClose, props)
  }, [props, setSubModalParam, subModalParam])

  useLayoutEffect(() => {
    if (modalParam) setModalSize(getSize(modalParam))
  }, [modalParam])

  useLayoutEffect(() => {
    if (subModalParam) setSubModalSize(getSize(subModalParam))
  }, [subModalParam])

  const getModalWrapper = (size, container) => (
    <StyledModal component="dialog">
      <StyledColumnsContainer>
        <ContentContainer>
          <StyledCard size={size} secondary="true">
            {container}
          </StyledCard>
        </ContentContainer>
      </StyledColumnsContainer>
    </StyledModal>
  )

  return (
    <>
      {modalParam && getModalWrapper(modalSize, modalContainer)}
      {subModalParam && getModalWrapper(subModalSize, subModalContainer)}
    </>
  )
}

export default CallsModals
