import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg
    style={{ minWidth: '91px', minHeight: '96px' }}
    width="91"
    height="96"
    viewBox="0 0 91 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <rect y="0.0488281" width="82" height="95" rx="13" fill="white" />
    <rect x="26.8936" y="12.6895" width="64.1068" height="68.6214" rx="9" fill="white" />
    <path
      d="M86.0223 60.0039H80.1443C79.8915 60.0039 79.6748 60.2222 79.6748 60.4996V77.2001C79.6748 77.4765 79.8906 77.6957 80.1443 77.6957H81.3181C84.1713 77.6957 86.4918 75.246 86.4918 72.2347V60.4996C86.4918 60.2231 86.2841 60.0039 86.0223 60.0039Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M86.0223 38.5742H80.1443C79.8915 38.5742 79.6748 38.803 79.6748 39.0699V55.8657C79.6748 56.1421 79.8906 56.3623 80.1443 56.3623H86.0223C86.2841 56.3623 86.4927 56.143 86.4927 55.8657V39.0699C86.4918 38.803 86.2841 38.5742 86.0223 38.5742Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M81.3181 17.2422H80.1443C79.8915 17.2422 79.6748 17.4614 79.6748 17.7379V34.4383C79.6748 34.7147 79.8906 34.9339 80.1443 34.9339H86.0223C86.2841 34.9339 86.4927 34.7147 86.4927 34.4383V22.7041C86.4918 19.692 84.1713 17.2422 81.3181 17.2422Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M16.1011 4.62109H14.1327C9.27507 4.62109 5.32031 8.78667 5.32031 13.9245V81.0123C5.32031 86.1501 9.27507 90.3157 14.1327 90.3157H16.1011C16.3629 90.3157 16.5706 90.0965 16.5706 89.8191V5.11677C16.5706 4.84033 16.3629 4.62109 16.1011 4.62109Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M67.4137 4.62109H22.6924C22.4306 4.62109 22.2139 4.84033 22.2139 5.11677V89.82C22.2139 90.0965 22.4306 90.3167 22.6924 90.3167H67.4137C72.2804 90.3167 76.2261 86.1511 76.2261 81.0132V13.9245C76.2261 8.78667 72.2804 4.62109 67.4137 4.62109Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.56 35.7215C56.56 40.0467 53.2079 43.5856 49.1109 43.5856C45.014 43.5856 41.6619 40.0467 41.6619 35.7215C41.6619 31.3963 45.014 27.8574 49.1109 27.8574C53.2079 27.8574 56.56 31.3963 56.56 35.7215ZM34.2129 61.2816C34.2129 52.6311 40.917 45.5535 49.1109 45.5535C57.3049 45.5535 64.009 52.6311 64.009 61.2816V67.1797H34.2129V61.2816Z"
      fill="white"
    />
  </svg>
)
