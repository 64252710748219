/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import DashboardCallsNotifications from './calls'
import DashboardGuardianToolsNotifications from './guardian-tools'
import DashboardWebNotificationsPermissionShownNotifications from './web-notifications-permission-shown'

export default ({ setWebNotification }) => {
  return (
    <>
      <DashboardCallsNotifications setWebNotification={setWebNotification} />
      <DashboardGuardianToolsNotifications />
      <DashboardWebNotificationsPermissionShownNotifications />
    </>
  )
}
