import { ReactElement, FC, useEffect, useState } from 'react'
import GroupAvatar from 'components/avatar/group'
import CallButton from 'components/button/call'
import Button from 'components/button'
import { CloseIcon, CallTypeIncoming, CallTypeOutgoing } from 'theme'
import {
  isNewCallClanzy,
  getCallTitle,
  isMissed,
  isCanceled,
  isOutgoing,
  hasOnlyOneGroup,
  formatCallTimeFromNow,
} from 'utils/calls'
import { useTranslation } from 'react-i18next'
import { RowsContainer } from 'components/container'
import { getTextColor } from 'utils'
import Text from 'components/text'
import {
  StyledControlsWrapper,
  RecentCallListItemProps,
  StyledTextWrapper,
  StyledTextCallTypeWrapper,
  StyledListItem,
} from './style'

const getRecentCallTypeIcon = (call, user) => {
  if (isOutgoing(call, user)) {
    return <CallTypeOutgoing $noSuccess={isMissed(call, user) || isCanceled(call)} />
  }
  return <CallTypeIncoming $noSuccess={isMissed(call, user) || isCanceled(call)} />
}

const RecentCallListItem: FC<RecentCallListItemProps> = ({
  authUser,
  children,
  recentCall,
  $flat,
  $hovered,
  onMouseEnter,
  onMouseLeave,
  onCallClick,
  onClick,
  onClose,
  bcolor,
  title,
  $secondary,
  ticker,
  ...rest
}): ReactElement => {
  const { t } = useTranslation()
  const newCallIsClanzy = isNewCallClanzy(recentCall)
  const [callTimeFromNow, setCallTimeFromNow] = useState('')
  const callHasOnlyOneGroup = hasOnlyOneGroup(recentCall)

  const getRecentCallTypeTitle = (call, user) => {
    if (isMissed(call, user)) {
      return t('Missed')
    }
    if (isCanceled(call)) {
      return t('Canceled')
    }
    if (isOutgoing(call, user)) {
      return t('Outgoing')
    }
    return t('Incoming')
  }

  const handleMouseEnter = () => {
    if (onMouseEnter) onMouseEnter(recentCall)
  }
  const handleMouseLeave = () => {
    if (onMouseLeave) onMouseLeave(recentCall)
  }
  const handleCallClick = () => {
    onCallClick(recentCall)
  }
  const handleClick = () => {
    if (onClick) onClick(recentCall)
  }
  const handleClose = () => {
    if (onClose) onClose(recentCall)
  }

  useEffect(() => {
    if (callTimeFromNow) setCallTimeFromNow(formatCallTimeFromNow(recentCall, authUser))
  }, [authUser, callTimeFromNow, recentCall, t, ticker])

  const [recentCallTitle, restCount] = getCallTitle(recentCall, 3)

  return (
    <StyledListItem
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      $secondary={$secondary}
      $flat={$flat}
      onClick={handleClick}
      {...rest}
    >
      {(!!recentCall.groups?.length || !!recentCall.contacts?.length) && (
        <GroupAvatar
          bcolor={bcolor}
          $secondary={$secondary}
          group={recentCall.groups?.length ? recentCall.groups[0] : { contacts: recentCall.contacts }}
          $hovered={$hovered}
        />
      )}
      <StyledTextWrapper>
        {callHasOnlyOneGroup ? (
          <Text ml="1rem" size="md" $secondary={!$secondary}>
            {recentCall.groups[0].name}
          </Text>
        ) : (
          <Text ml="1rem" size="md" $secondary={!$secondary}>
            {recentCallTitle}
            {!!restCount && ` + ${restCount}`}
          </Text>
        )}
        <RowsContainer mt="0.2rem" ml="1rem" $center>
          {getRecentCallTypeIcon(recentCall, authUser)}
          <Text fw="400" ml="0.2rem" size="xs" color={getTextColor($secondary)} cop="0.6">
            {getRecentCallTypeTitle(recentCall, authUser)}
            &nbsp;·&nbsp;
            {formatCallTimeFromNow(recentCall)}
          </Text>
        </RowsContainer>
      </StyledTextWrapper>
      <StyledControlsWrapper>
        {onCallClick && $hovered && (
          <StyledTextCallTypeWrapper>
            <Text size="md" mr="1rem" $secondary={!$secondary} onClick={handleCallClick} td="none">
              {t(newCallIsClanzy ? 'ClanzyCall' : 'Call')}
            </Text>
          </StyledTextCallTypeWrapper>
        )}
        {onCallClick && (
          <CallButton title={title} $active={$hovered} clanzy={newCallIsClanzy} onClick={handleCallClick} />
        )}
        {onClose && (
          <Button circular="true" subtle="true" onClick={handleClose}>
            <CloseIcon />
          </Button>
        )}
      </StyledControlsWrapper>
    </StyledListItem>
  )
}

export default RecentCallListItem
