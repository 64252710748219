import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path
      d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
      fill="white"
      fillOpacity="0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3619 12.9655C16.3619 10.7754 18.1373 9 20.3274 9C22.5175 9 24.2929 10.7754 24.2929 12.9655V19.3103C24.2929 21.5004 22.5175 23.2759 20.3274 23.2759C18.1373 23.2759 16.3619 21.5004 16.3619 19.3103V12.9655ZM13.1895 18.0603C13.8798 18.0603 14.4395 18.62 14.4395 19.3103C14.4395 22.5622 17.0756 25.1983 20.3274 25.1983C23.5792 25.1983 26.2153 22.5622 26.2153 19.3103C26.2153 18.62 26.775 18.0603 27.4653 18.0603C28.1557 18.0603 28.7153 18.62 28.7153 19.3103C28.7153 23.358 25.8483 26.736 22.0343 27.5245V32H19.5343V27.6613C15.2737 27.2617 11.9395 23.6754 11.9395 19.3103C11.9395 18.62 12.4991 18.0603 13.1895 18.0603Z"
      fill="white"
      fillOpacity="0.6"
    />
  </svg>
)
