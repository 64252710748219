import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V36C40 38.2091 38.2091 40 36 40H4C1.79086 40 0 38.2091 0 36V4Z"
      fill="white"
      fillOpacity="0.05"
    />
    <path
      d="M19.5034 36C29.0033 31.5 33.6451 23.9111 32.9361 10.4L19.4654 4L6.0706 10.4C5.36162 23.9111 10.0033 31.5 19.5034 36Z"
      stroke="white"
      strokeOpacity="0.6"
      strokeWidth="3"
    />
    <ellipse cx="19.5826" cy="13.9047" rx="3.52598" ry="4.0297" fill="white" fillOpacity="0.6" />
    <path
      d="M26.1317 24.1227C26.1317 26.9841 23.1999 27.001 19.5834 27.001C15.9669 27.001 13.0352 26.9841 13.0352 24.1227C13.0352 21.2613 15.9669 18.9417 19.5834 18.9417C23.1999 18.9417 26.1317 21.2613 26.1317 24.1227Z"
      fill="white"
      fillOpacity="0.6"
    />
  </svg>
)
