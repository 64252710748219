/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback, useRef } from 'react'

export default (cb: (arg0: any) => void) => {
  const ref = useRef(null)
  const setRef = useCallback((node) => {
    if (node) {
      cb(node)
    }

    ref.current = node
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [setRef]
}
