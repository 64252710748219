import { ReactElement, FC } from 'react'
import { withTheme } from 'styled-components'
import { Link } from 'react-router-dom'
import { Theme } from 'theme'

export interface LogoProps {
  /**
   * Size
   */
  size?: string
  /**
   * Variant
   */
  secondary?: string
  /**
   * Href
   */
  link?: string
  /**
   * Functionality
   */
  $small?: boolean
  /**
   * Theme
   */
  theme: Theme
}

const Logo: FC<LogoProps> = ({
  $small = false,
  theme: { fn, palette },
  secondary,
  link,
  size = 'md',
}): ReactElement => {
  const color = (fn.color as (arg0: string) => string)(palette.text[secondary ? 'secondary' : 'primary'])

  const logo =
    size === 'md' ? (
      <svg
        width={$small ? '46' : '170'}
        height="46"
        viewBox={$small ? '0 0 46 46' : '0 0 170 46'}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.0801 14.0151L38.0069 18.0505L36.4709 19.071C36.3073 16.4794 35.992 14.9437 34.5457 13.4581C32.788 11.6526 30.81 11.5074 26.8539 11.2171C25.5762 11.1233 24.2606 11.0632 22.987 11.0632C21.7275 11.0632 20.427 11.122 19.1627 11.214C15.1818 11.5036 13.1913 11.6484 11.4323 13.4537C9.6733 15.2591 9.58487 17.1545 9.40801 20.9453C9.3767 21.6164 9.35883 22.3042 9.35883 22.9999C9.35883 23.6711 9.37546 24.3348 9.40473 24.9832C9.57729 28.8052 9.66358 30.7161 11.4196 32.5241C13.1756 34.332 15.1957 34.4823 19.236 34.7828C20.4969 34.8766 21.7769 34.9366 22.987 34.9366C24.2108 34.9366 25.5062 34.8752 26.7809 34.7796C30.7959 34.4784 32.8035 34.3278 34.5582 32.5198C35.8853 31.1524 36.2574 29.7352 36.4337 27.4861L38.0069 28.5315L43.8834 32.4361C40.296 40.433 32.2889 46 22.987 46C10.3358 46 0.0800781 35.7025 0.0800781 23C0.0800781 10.2975 10.3358 0 22.987 0C32.4629 0 40.595 5.77723 44.0801 14.0151Z"
            fill="url(#paint0_radial)"
          />
          {!$small && (
            <>
              <path
                d="M69.6757 36.6302C65.6686 36.6302 62.3379 35.2957 59.6835 32.6266C57.0547 29.9829 55.7402 26.6911 55.7402 22.751C55.7402 18.7855 57.0547 15.4936 59.6835 12.8754C62.3379 10.2063 65.6686 8.87179 69.6757 8.87179C72.1004 8.87179 74.3337 9.44374 76.3755 10.5876C78.4429 11.7061 80.0508 13.2313 81.1993 15.1632L76.6435 17.7941C75.9799 16.5994 75.0356 15.6716 73.8105 15.0107C72.5854 14.3243 71.2071 13.9812 69.6757 13.9812C67.0724 13.9812 64.9668 14.7946 63.3588 16.4215C61.7764 18.0738 60.9852 20.1836 60.9852 22.751C60.9852 25.293 61.7764 27.3901 63.3588 29.0424C64.9668 30.6693 67.0724 31.4827 69.6757 31.4827C71.2071 31.4827 72.5854 31.1522 73.8105 30.4913C75.0611 29.805 76.0054 28.8772 76.6435 27.7079L81.1993 30.3388C80.0508 32.2707 78.4429 33.8086 76.3755 34.9525C74.3337 36.071 72.1004 36.6302 69.6757 36.6302Z"
                fill={color}
              />
              <path d="M88.3044 36.0964H83.3657V8.26172H88.3044V36.0964Z" fill={color} />
              <path
                d="M106.224 19.2812V17.0315H111.163V36.0964H106.224V33.8467C104.744 35.7024 102.664 36.6302 99.9838 36.6302C97.4315 36.6302 95.2365 35.6642 93.3989 33.7323C91.5868 31.775 90.6807 29.3856 90.6807 26.564C90.6807 23.7678 91.5868 21.391 93.3989 19.4337C95.2365 17.4764 97.4315 16.4977 99.9838 16.4977C102.664 16.4977 104.744 17.4256 106.224 19.2812ZM97.1125 30.4532C98.1079 31.4446 99.3712 31.9403 100.903 31.9403C102.434 31.9403 103.697 31.4446 104.693 30.4532C105.714 29.4364 106.224 28.14 106.224 26.564C106.224 24.9879 105.714 23.7042 104.693 22.7129C103.697 21.6961 102.434 21.1877 100.903 21.1877C99.3712 21.1877 98.1079 21.6961 97.1125 22.7129C96.1171 23.7042 95.6194 24.9879 95.6194 26.564C95.6194 28.14 96.1171 29.4364 97.1125 30.4532Z"
                fill={color}
              />
              <path
                d="M125.136 16.4977C127.254 16.4977 128.977 17.1968 130.304 18.5949C131.682 19.993 132.371 21.9249 132.371 24.3906V36.0964H127.433V25.0006C127.433 23.7551 127.088 22.7891 126.399 22.1028C125.736 21.4165 124.817 21.0733 123.643 21.0733C122.341 21.0733 121.307 21.48 120.542 22.2934C119.801 23.0815 119.431 24.2508 119.431 25.8014V36.0964H114.493V17.0315H119.431V19.1668C120.605 17.3874 122.507 16.4977 125.136 16.4977Z"
                fill={color}
              />
              <path
                d="M149.38 20.3107L141.264 31.4827H149.763V36.0964H134.64V32.8172L142.718 21.6452H135.023V17.0315H149.38V20.3107Z"
                fill={color}
              />
              <path
                d="M160.468 29.8431L164.718 17.0315H170.001L163.033 36.0964C162.038 38.8417 160.711 40.8245 159.052 42.0446C157.418 43.2902 155.376 43.8494 152.926 43.7223V39.1468C154.228 39.1722 155.274 38.8926 156.066 38.3079C156.857 37.7233 157.482 36.7827 157.942 35.4863L150.093 17.0315H155.491L160.468 29.8431Z"
                fill={color}
              />
            </>
          )}
        </g>
        <defs>
          <radialGradient
            id="paint0_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(8.55008 45.7454) scale(47.9424 50.1171)"
          >
            <stop stopColor="#0099FF" />
            <stop offset="0.6098" stopColor="#A033FF" />
            <stop offset="0.9348" stopColor="#FF5280" />
            <stop offset="1" stopColor="#FF7061" />
          </radialGradient>
          <clipPath id="clip0">
            <rect width="170" height="46" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ) : (
      <svg width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.5705 40.8465C51.0602 50.886 40.9937 57.875 29.299 57.875C13.3938 57.875 0.5 44.9472 0.5 29C0.5 13.0528 13.3938 0.125 29.299 0.125C41.2124 0.125 51.4362 7.37794 55.8177 17.72L48.1824 22.7862L46.2225 24.0866C45.9245 20.3413 45.7454 18.438 44.0552 16.7196C42.3341 14.9697 40.3039 14.7746 36.2435 14.3843C34.0191 14.1705 31.6044 14.0141 29.299 14.0141C27.0087 14.0141 24.6105 14.1684 22.3982 14.3801C18.3148 14.7708 16.2732 14.9661 14.5513 16.715C12.8294 18.464 12.6729 20.4221 12.3597 24.3383V24.3383C12.2418 25.8122 12.1654 27.389 12.1654 28.9999C12.1654 30.5843 12.2394 32.1356 12.3539 33.5885C12.6651 37.5353 12.8207 39.5087 14.5408 41.2596C16.2609 43.0105 18.3315 43.2107 22.4727 43.6113C24.7056 43.8272 27.0946 43.9856 29.299 43.9856C31.5178 43.9856 33.9237 43.8252 36.169 43.607C40.2871 43.207 42.3462 43.007 44.0656 41.2551C45.636 39.6551 45.8999 37.8841 46.1674 34.6074L48.1824 35.9444L55.5705 40.8465ZM58.098 28.9762C58.098 28.9841 58.098 28.9921 58.098 29C58.098 29.0079 58.098 29.0159 58.098 29.0238V28.9762Z"
          fill="url(#paint0_radial)"
        />
        <defs>
          <radialGradient
            id="paint0_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(11.5876 57.5554) scale(62.7588 62.9187)"
          >
            <stop stopColor="#0099FF" />
            <stop offset="0.6098" stopColor="#A033FF" />
            <stop offset="0.9348" stopColor="#FF5280" />
            <stop offset="1" stopColor="#FF7061" />
          </radialGradient>
        </defs>
      </svg>
    )

  if (!link) return logo

  return link.startsWith('http') ? (
    <Link style={{ display: 'inline-block' }} target="_top" rel="noopener noreferrer" to={{ pathname: link }}>
      {logo}
    </Link>
  ) : (
    <Link style={{ display: 'inline-block' }} to={link}>
      {logo}
    </Link>
  )
}

export default withTheme(Logo)
