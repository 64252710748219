import ReactDOM from 'react-dom'
import './index.css'
import * as Sentry from '@sentry/react'
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals'
import store from './store'
import { App } from './app'
import './i18n'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  enabled: process.env.NODE_ENV === 'production',
})

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
