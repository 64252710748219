import styled from 'styled-components'

export default styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  color: black;
  padding: 0 0 0.2rem 0;
  box-sizing: border-box;
  background: transparent;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  font-family: ${({ theme: { fonts } }) => fonts.Inter};
`
