import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V36C40 38.2091 38.2091 40 36 40H4C1.79086 40 0 38.2091 0 36V4Z"
      fill="white"
      fillOpacity="0.04"
    />
    <path
      d="M30.25 17.8351C31.9167 18.7974 31.9167 21.203 30.25 22.1653L15.25 30.8255C13.5833 31.7878 11.5 30.585 11.5 28.6605L11.5 11.3399C11.5 9.41545 13.5833 8.21264 15.25 9.17489L30.25 17.8351Z"
      stroke="white"
      strokeOpacity="0.6"
      strokeWidth="3"
    />
  </svg>
)
