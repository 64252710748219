/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Suspense, useEffect, useState } from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { AnimatePresence } from 'framer-motion'
import { Helmet } from 'react-helmet'
import Routes from 'routes'
import { ThemeProvider } from 'styled-components'
import { Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import SnackBar from 'components/snack-bar'
import { toast, Slide } from 'react-toastify'
import { history } from '../store'
import { GlobalStyles } from '../theme/global-styles'
import { useTheme } from '../hooks/useTheme'
import 'react-toastify/dist/ReactToastify.css'
import 'overlayscrollbars/css/OverlayScrollbars.css'
import 'react-calendar/dist/Calendar.css'
import 'normalize.css'
import '@fontsource/inter'
import '@fontsource/poppins'

window.ResizeObserver = undefined

const App = (): any => {
  const { theme, themeLoaded } = useTheme()
  const [selectedTheme, setSelectedTheme] = useState(theme)

  useEffect(() => {
    setSelectedTheme(theme)
  }, [theme, themeLoaded])

  useEffect(() => {
    const unregisterHistoryListener = history.listen(() => toast.dismiss())

    return () => {
      unregisterHistoryListener()
    }
  }, [])

  return (
    <ConnectedRouter history={history}>
      <QueryParamProvider ReactRouterRoute={Route}>
        {themeLoaded && (
          <ThemeProvider theme={selectedTheme}>
            <GlobalStyles />
            <Helmet>
              <title>Clanzy</title>
            </Helmet>
            <AnimatePresence>
              <Suspense fallback={null}>
                <Routes />
                <SnackBar
                  closeButton={false}
                  pauseOnHover
                  hideProgressBar
                  closeOnClick
                  transition={Slide}
                  position="bottom-right"
                  autoClose={3000}
                />
              </Suspense>
            </AnimatePresence>
          </ThemeProvider>
        )}
      </QueryParamProvider>
    </ConnectedRouter>
  )
}

export { App }
