/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components'
import { ColumnsContainer, ContentContainer, RowsContainer } from 'components/container'
import List, { MenuListItem } from 'components/list'
import Card from 'components/card'
import Divider from 'components/divider'
import Text from 'components/text'

export const StyledContentContainer = styled(ContentContainer)`
  margin: 1.125rem 0;
`

export const StyledDivider = styled(Divider)`
  background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.white)};
`

export const StyledControlsWrapper = styled(RowsContainer)`
  align-items: center;
  vertical-align: middle;
  justify-content: flex-end;
`

export const StyledHeaderContainer = styled(RowsContainer)`
  height: 2.8125rem;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  align-self: flex-end;
  margin-right: 1.125rem;
`

export const StyledRowsContainer = styled(RowsContainer)``

export const StyledContentWrapper = styled(RowsContainer)`
  padding: 0 2rem;
  box-sizing: border-box;
  width: 100%;
`

export const StyledColumnsContainer = styled(ColumnsContainer)`
  padding: 1rem 0.5rem;
`

export const StyledTabContainer = styled(ColumnsContainer)`
  flex-grow: 1;
  padding: 0.125rem 1.5rem 0 3rem;
`

export const StyledTextWrapper = styled(ColumnsContainer)`
  align-items: flex-start;
  justify-content: center;
`

export const StyledAddPhotoWrapper = styled.div`
  margin-top: 0.3rem;
`

export const StyledText = styled(Text)`
  padding: 0;
`

export const StyledComponentWrapper = styled(ColumnsContainer)`
  padding: 2rem 2rem 1rem 2rem;
  box-sizing: border-box;
  width: 100%;
`

export const StyledCard = styled(Card)`
  margin: 2rem 0 1rem 0;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.black, '0.05')};
`

export const StyledVerList = styled(List).attrs(() => ({
  component: 'nav',
}))`
  margin: 0;
  flex-direction: column;

  ${({ theme }) => theme.fn.up(theme.breakpoints.md)} {
    margin: 1.25rem 0 0;
  }
`
export const StyledListItem = styled(MenuListItem)`
  width: 100%;
  padding 1rem;
`

export const StyledListItemWrapper = styled(MenuListItem)`
  margin: 0 1.5rem 1rem 0;
  border-bottom: 0.1875rem solid transparent;

  &:hover {
    background-color: transparent;
  }

  ${(props) =>
    !!props.active &&
    css`
      background-color: transparent;
      border-bottom: 0.1875rem solid ${({ theme: { fn, colors } }) => fn.color(colors.mediumpurple)};
    `}
`

export const StyledHorList = styled(List)`
  flex-direction: row;
  height: 3rem;
`

export const StyledAsideContainer = styled(ColumnsContainer)`
  min-width: 25%;
  margin-top: 1rem;
`
