import styled, { css } from 'styled-components'
import Button from 'components/button'
import { ColumnsContainer } from 'components/container'

export interface AvatarProps {
  /**
   * Id
   */
  id?: string
  /**
   * Status
   */
  status?: string
  /**
   * Alt text
   */
  alt?: string
  /**
   * Variant
   */
  $secondary?: boolean
  /**
   * Size
   */
  size?: string
  /**
   * Font size
   */
  fontSize?: string
  /**
   * Button size
   */
  buttonSize?: string
  /**
   * Background color
   */
  bcolor?: string
  /**
   * Background opacity
   */
  bop?: string
  /**
   * Border color
   */
  borcolor?: string
  /**
   * Border opacity
   */
  borop?: string
  /**
   * Base 64 image
   */
  img?: string
  /**
   * Title
   */
  title?: string
  /**
   * Name
   */
  name?: string
  /**
   * Box sizing
   */
  bs?: string
  /**
   * Name characters count
   */
  nameCharsCnt?: number
  /**
   * Hide presence
   */
  $noPresence?: boolean
  /**
   * Base image url
   */
  baseUrl?: string
  /**
   * On click handler
   */
  onClick?: (id: string) => void
  /**
   * On close handler
   */
  onClose?: (id: string) => void
}

export const StyledAvatarWrapper = styled.div`
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: transparent;
  position: relative;

  ${(props: AvatarProps) =>
    !!props.bcolor &&
    css`
      background-color: ${({ theme: { fn, palette } }) =>
        props.bcolor === 'transparent' ? 'transparent' : fn.color(palette.background[props.bcolor], props.bop)};
    `}

  ${(props: AvatarProps) =>
    !!props.size &&
    props.size === 'md' &&
    css`
      height: 2.5rem;
      width: 2.5rem;
      max-width: 2.5rem;
      border-radius: 2.5rem;
    `}

  ${(props: AvatarProps) =>
    !!props.size &&
    props.size === 'lg' &&
    css`
      height: 4rem;
      width: 4rem;
      max-width: 4rem;
      border-radius: 4rem;
    `}

  ${(props: AvatarProps) =>
    !!props.size &&
    props.size === 'xl' &&
    css`
      height: 5.5rem;
      width: 5.5rem;
      max-width: 5.5rem;
      border-radius: 5.5rem;
    `}

  ${(props: AvatarProps) =>
    !!props.size &&
    props.size === 'xxxl' &&
    css`
      height: 10rem;
      width: 10rem;
      max-width: 10rem;
      border-radius: 10rem;
    `}

  ${(props: AvatarProps) =>
    !!props.borcolor &&
    css`
      border: 0.1875rem solid ${({ theme: { fn, colors } }) => fn.color(colors[props.borcolor], props.borop)};
    `}

  ${(props: AvatarProps) =>
    !!props.bs &&
    css`
      box-sizing: ${props.bs}};
    `}
`

export const StyledCloseButton = styled(Button)`
  padding: 0.3rem;
  position: absolute;
  right: -0.5rem;
  top: -0.5rem;
`

export const StyledWrapper = styled.div`
  position: relative;
  text-align: center;
`

export const StyledColumnsContainer = styled(ColumnsContainer)`
  position: relative;
  text-align: center;
`
