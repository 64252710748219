import { ReactElement, FC, useEffect } from 'react'
import Text from 'components/text'
import { modalQueryParam, modalImportContactsSelectProviderQueryParam } from 'const'
import { OtherProvidersImage, CloseIcon, BackIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import { useQueryParam, StringParam } from 'use-query-params'
import Button from 'components/button'
import {
  StyledBorder,
  StyledImageBackground,
  StyledImageWrapper,
  StyledContentContainer,
  StyledTextContainer,
  StyledCloseButton,
  StyledBackButton,
  StyledHeaderContainer,
} from './style'

const { $ } = window

const ImportContactsOtherProvidersModal: FC<{ onClose: () => void }> = ({ onClose }): ReactElement => {
  const { t } = useTranslation()
  const [, setModalParam] = useQueryParam(modalQueryParam, StringParam)

  const handleBack = () => {
    setModalParam(modalImportContactsSelectProviderQueryParam, 'replaceIn')
  }

  useEffect(() => {
    const el = $('.import-contacts-other-providers-ok-button')?.[0]
    if (el) el.focus()
  }, [])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Text component="h1" mt="0" color="brandblack" font="Poppins">
          {t('OtherEmailProviders')}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={onClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
        <StyledBackButton circular="true" subtle="true" onClick={handleBack}>
          <BackIcon title={t('GoBack')} />
        </StyledBackButton>
      </StyledHeaderContainer>
      <StyledTextContainer>
        <Text fw="500" gradient="radiance" size="md">
          {t('ConnectAddressOtherProviders')}
        </Text>
      </StyledTextContainer>
      <Text size="lg">{t('ItWillLookSomethingLikeThis')}</Text>
      <StyledImageWrapper>
        <StyledBorder />
        <StyledImageBackground />
        <OtherProvidersImage alt={t('OtherEmailProvidersImageAltText')} />
      </StyledImageWrapper>
      <Button
        size="md"
        bcolor="mediumpurple"
        bop="1"
        mt="1rem"
        hgradient="mediumpurple-dim-light"
        agradient="mediumpurple-dim-dark"
        onClick={onClose}
        className="import-contacts-other-providers-ok-button"
      >
        <Text size="md" $secondary fw="600">
          {t('OkGotIt')}
        </Text>
      </Button>
    </StyledContentContainer>
  )
}

export default ImportContactsOtherProvidersModal
