import { ReactElement } from 'react'
import { withTheme } from 'styled-components'

export default withTheme(
  ({ theme: { fn, palette }, secondary, title }): ReactElement => {
    const color = (fn.color as (arg0: string) => string)(secondary ? palette.secondary.main : palette.primary.main)
    return (
      <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <circle cx="24" cy="24" r="24" fill={color} fillOpacity="0.38" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1818 9.43934C13.596 8.85355 12.6462 8.85355 12.0604 9.43934C11.4746 10.0251 11.4746 10.9749 12.0604 11.5607L19.4998 19V23C19.4998 25.7614 21.7384 28 24.4998 28C25.6255 28 26.6643 27.628 27.5 27.0002L29.2796 28.7798C27.9818 29.8543 26.3162 30.5 24.4998 30.5C20.3576 30.5 16.9998 27.1421 16.9998 23C16.9998 22.1716 16.3282 21.5 15.4998 21.5C14.6713 21.5 13.9998 22.1716 13.9998 23C13.9998 28.4617 18.1698 32.9494 23.4998 33.453V39H26.4998V33.3098C28.3563 32.9517 30.0388 32.1046 31.4076 30.9079L36.9391 36.4393C37.5249 37.0251 38.4746 37.0251 39.0604 36.4393C39.6462 35.8536 39.6462 34.9038 39.0604 34.318L33.3675 28.6251C34.4011 26.999 34.9998 25.0694 34.9998 23C34.9998 22.1716 34.3282 21.5 33.4998 21.5C32.6713 21.5 31.9998 22.1716 31.9998 23C31.9998 24.2356 31.701 25.4015 31.1716 26.4292L29.264 24.5216C29.4171 24.0418 29.4998 23.5306 29.4998 23V15C29.4998 12.2386 27.2612 10 24.4998 10C21.8178 10 19.6291 12.1116 19.5053 14.7629L14.1818 9.43934ZM22.4998 22V23C22.4998 24.1046 23.3952 25 24.4998 25C24.7932 25 25.0719 24.9368 25.323 24.8232L22.4998 22ZM26.4998 21.7574L22.4998 17.7574V15C22.4998 13.8954 23.3952 13 24.4998 13C25.6043 13 26.4998 13.8954 26.4998 15V21.7574Z"
          fill="white"
        />
      </svg>
    )
  },
)
