import styled from 'styled-components'
import { RowsContainer } from 'components/container'

export const StyledAlertWrapper = styled(RowsContainer)`
  padding: 0.5rem 1rem;

  background: ${({ theme }) => theme.fn.color(theme.colors.mulberry)};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.fn.down(theme.breakpoints.md)} {
    flex-direction: column;
  }
`

export const StyledIconWrapper = styled.div`
  margin-right: 1rem;
`

export const StyledRowsLeftContainer = styled(RowsContainer)`
  justify-content: center;
  align-items: center;
`

export const StyledRowsRightContainer = styled(RowsContainer)`
  justify-content: center;
  align-items: center;
`

export const StyledTextWrapper = styled.div`
  max-width: 70%;
`
