/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Text from 'components/text'
import { useTranslation } from 'react-i18next'
import { webNotificationsPromptQueryParam } from 'const'
import { AllowPromptArrowIcon } from 'theme'
import Logo from 'components/logo'
import { useQueryParam, BooleanParam } from 'use-query-params'
import { TransparentBackground } from '../../backgrounds'
import { StyledIconWrapper, StyledNotificationContent, StyledLogoWrapper, StyledNotificationContainer } from './style'

export default () => {
  const { t } = useTranslation()
  const [webNotificationsPrompt] = useQueryParam(webNotificationsPromptQueryParam, BooleanParam)

  if (webNotificationsPrompt) {
    return (
      <TransparentBackground effect="blur">
        <StyledNotificationContainer center="true">
          <StyledLogoWrapper>
            <Logo secondary="true" />
          </StyledLogoWrapper>
          <StyledNotificationContent $center>
            <StyledIconWrapper>
              <AllowPromptArrowIcon />
            </StyledIconWrapper>
            <Text component="h2" size="xl" $secondary secondary="true" mt="1rem" fw="600" font="Poppins">
              {t('PleaseClickAllowToEnableCallNotifications')}
            </Text>
          </StyledNotificationContent>
        </StyledNotificationContainer>
      </TransparentBackground>
    )
  }

  return null
}
