import styled, { css } from 'styled-components'
import List, { MenuListItem, MenuListItemProps } from 'components/list'

export const StyledList = styled(List)`
  flex-direction: row;
  height: 4rem;
`

export const StyledListItemWrapper = styled(MenuListItem)`
  margin: 1rem 0.7rem 1.2rem 1.5rem;

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.main};
    background-color: transparent;
  }

  ${(props: MenuListItemProps) =>
    !!props.active &&
    css`
      background-color: transparent;
      border-bottom: 0.1875rem solid ${({ theme: { fn, colors } }) => fn.color(colors.mediumpurple)};
    `}
`
