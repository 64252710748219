import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  en: {
    translation: {
      MuteMicWarningText: 'Your microphone has been automatically muted.',
      MobileWarningNoteText:
        'Please note: Clanzy is not currently optimized for use on mobile devices. It is preferable to use a laptop or desktop computer.',
      AUserWithThatEmailAlreadyExists: 'A user with that email already exists',
      ContactsTabImageAltText: 'Graphic showing Contacts tab with imported contacts listed',
      ConnectGmailImageAltText: 'Graphic illustrating Google’s permission screen to grant access to Clanzy',
      ConnectYahooImageAltText: 'Graphic illustrating Yahoo’s permission screen to grant access to Clanzy',
      ConnectOutlookOrHotmailImageAltText:
        'Graphic illustrating Microsoft’s permission screen to grant access to Clanzy',
      ConnectGmailAddressBook: 'Connect Gmail address book',
      ConnectYahooAddressBook: 'Connect Yahoo address book',
      ConnectOutlookOrHotmailAddressBook: 'Connect Outlook.com or Hotmail address book',
      OtherEmailProvidersImageAltText:
        'Graphic showing Contacts tab with list of contacts who have been manually entered',
      ButtonToImportFromGmail: 'Button to import from Gmail',
      ButtonToImportFromOutlook: 'Button to import from Outlook.com',
      ButtonToImportFromYahoo: 'Button to import from Yahoo',
      ImportContactsPrivacyText:
        'During this import we won’t send anything to your contacts. Your contact info is kept secure and private, and is not used for any purpose other than improving your Clanzy experience. See our commitment to privacy in our',
      ConnectYourAddressBook: 'Connect your address book',
      GetWindowsApp: 'Get Windows App',
      JoinTheCallNow: 'Join the call now',
      Notifications: 'Notifications',
      GetNotifications: 'Get notifications',
      WarningIcon: 'Warning icon',
      ShieldIconDepictingAGuardian: 'Shield icon depicting a Guardian',
      PhotoDepictingGuardianUser: 'Photo depicting Guardian user',
      TickIcon: 'Tick icon',
      IconDepictingAnAddressBook: 'Icon depicting an address book',
      AddIcon: 'Add icon',
      CreateManagedAccountImageAltText: 'Icon depicting other users',
      YouTubeEntertainmentImageAltText: 'Graphic of a watch YouTube icon',
      RummyEntertainmentImageAltText: 'Graphic of Rummy card game',
      EntertainmentTutor1ImageAltText: 'Graphic depicting creating a new video call',
      EntertainmentTutor2ImageAltText: 'Graphic depicting clicking the Entertainment icon during a video call',
      EntertainmentTutor3ImageAltText: 'Graphic depicting watching a video during a video call',
      ScheduleIcon: 'Schedule icon',
      ButtonToJoinThisCall: 'Button to join this call',
      PhotoOfManagedUser: 'Photo of managed user',
      PhotoOfParticipant: 'Photo of participant',
      PhotoOfUser: 'Photo of user',
      GroupPhoto: 'Group photo',
      PhotoOfContact: 'Photo of contact',
      ClanzyCallButton: 'Clanzy Call button',
      CallButton: 'Call button',
      ClanzyGroupCallButton: 'Clanzy Group Call button',
      GroupCallButton: 'Group Call button',
      SearchIcon: 'Search icon',
      YourCallStartsIn: 'Your call starts in:',
      PleaseClickAllowToEnableCallNotifications: "Please click 'Allow' to enable call notifications",
      AskWebNotificationsPermissionText1:
        'To make it easier to know when your friends and family are calling you on Clanzy,',
      AskWebNotificationsPermissionText2:
        'we can display a message on your screen when they call. Would you like to enable this functionality?',
      ClanzyCallNotification: 'Clanzy call notification',
      UnmuteRequest: 'Unmute request',
      CameraOnRequest: 'Turn camera on request',
      IsRequestingPermissionToTurnOnYourCamera: 'is requesting permission to turn your camera on.',
      IsRequestingPermissionToUnmuteYourselves: 'is requesting permission to unmute yourselves.',
      ToUmuteThemselves: 'to unmute themselves.',
      ToTurnOnTheirCamera: 'to turn their camera on.',
      CameraOn: 'Camera on',
      Unmute: 'Unmute',
      ScreenShareRequestWarningText1: 'Do not accept unless you are completely sure you trust',
      ScreenShareRequestWarningText2: 'and you are happy to let',
      ScreenShareRequestWarningText3: "to see everything on your computer's screen.",
      IsRequestingPermissionToScreenShareYourComputer: 'is requesting permission to screen share your computer.',
      YourGuardian: 'Your Guardian',
      ScreenShareRequest: 'Screen share request',
      ToAcceptTheRequest: 'to accept the request.',
      ScreenShareRequestSentTo: 'Screen share request sent to',
      WillHaveToAcceptTheRequestWithinTheirClanzyApp: 'will have to accept the request within their Clanzy app.',
      WeWillSendRequestTo: 'We will send request to',
      ToStartScreenSharingTheirComputer: 'to start screen sharing their computer.',
      SendRequest: 'Send request',
      CanStillUnmuteThemSelf: 'can still unmute them self.',
      UnmuteThemOr: 'unmute them or',
      CanStillTurnItOnThemSelf: 'can still turn it on them self.',
      TurnOnTheirVideoOr: 'turn on their video or',
      IsUnderYourManagedUsersListSoYouWillBeAbleTo: 'is under your managed users list, so you will be able to',
      ActionsAffectParticipantDevice: "Actions affect participant's device",
      RequestScreenShare: 'Request screen share',
      GuardianSupportTools: 'Guardian support Tools',
      FromCall: 'from call',
      RemoveFromCall: 'Remove from call',
      Declined: 'Declined',
      CancelRequest: 'Cancel request',
      AwaitingConfirmation: 'Awaiting confirmation',
      ExplainingHowToSetANewPassword: 'explaining how to set a new password.',
      ThisWillEmailInstructionsTo: 'This will email instructions to',
      ManageAccount: 'Manage account',
      RevokeGuardianAccess: 'Revoke guardian access',
      Guardian: 'Guardian',
      YourClanzyAccountIsManagedBy: 'Your Clanzy account is managed by',
      YouCanRevoke: 'You can revoke',
      AccessFromYourAccountAtAnyTimeByGoingToGuardianTools:
        'access from your account at any time by going to Guardian Tools.',
      YourClanzyAccountIsNowManagedBy: 'Your Clanzy account is now managed by',
      YesIAmSure: 'Yes, I am sure',
      ProvideRemoteTechSupport: 'Provide remote tech support',
      UpdateAccountInfoandSomeSettings: 'Update account info & some settings',
      ManageYourContactsAndGroupLists: 'Manage your contacts and group lists',
      AcceptanceWillAllow: 'Acceptance will allow',
      AreYouSureYouWouldLikeToAcceptGuardianRequestFrom: 'Are you sure you would like to accept Guardian request from',
      ProvideRemoteTechSupportByAccessingYourComputerAndUseItAsThoughTheyWhereThere:
        'Provide remote tech support by accessing your computer and use it as though they where there',
      UpdateAccountInfoAndSomeSettings: 'Update account info & some settings',
      PopulateContactListAndCreateFavorites: 'Populate contact list and create favorites',
      WhatGuardianDoesForYou: 'What Guardian does for you:',
      GuardianRequest: 'Guardian request',
      GuardianPermissionRequestWarningText:
        'Do not accept unless you know this person, and are expecting them to be asking to become a Guardian of your Clanzy account',
      IsRequestingPermissionToBecomeGuardianOfYourClanzyAccount:
        'is requesting permission to become Guardian of your Clanzy account.',
      GuardianPermissionRequest: 'Guardian permission request',
      ResetPassword: 'Reset password',
      WillResetTheirPasswordText: 'will receive instruction how to how to set new password to their email address.',
      AwaitingAccountVerification: 'Awaiting account verification',
      Add: 'Add',
      AddGroups: 'Add groups',
      GroupsInYourAddressBook: 'Groups in your address book',
      AddContacts: 'Add contacts',
      PeopleInYourAddressBook: 'People in your address book',
      ToStartUsingClanzyTheyNeedToClickTheButtonInTheEmailDescriptionText:
        'To start using Clanzy they need to click the button in the email we sent them.  When they login, the profile and contact info you have set up will be ready for them to start using right away.',
      ClanzyProfileIsNowReady: 'Clanzy profile is now ready',
      NoGroupsCreated: 'No groups created',
      YouCanCopySelectedContactsFromYourAddressBookTo: 'You can copy selected contacts from your address book to',
      YouCanCopySelectedGroupsFromYourAddressBookTo: 'You can copy selected groups from your address book to',
      RemoveFromYourManagedUsersList: 'Remove from your managed users list',
      NotSetYet: 'Not set yet',
      AccountInfo: 'Account info',
      ContactList: 'Contact list',
      AddPhoto: 'Add photo',
      ChangePhoto: 'Change photo',
      AndPrePopulateTheirContactsListForThem: 'and pre-populate their Contacts List for them.',
      OnTheNextScreenYouCanOptionallyAddAProfilePhotoFor:
        'On the next screen you can optionally add a Profile Photo for',
      EnterManagedPersonName: 'Enter managed person name',
      YouWillBeNotifiedOnceTheyHaveAcceptedYourRequest: 'You will be notified once they have accepted your request.',
      RequestSent: 'Request sent',
      TheClanzyUser: 'The Clanzy user',
      WillNeedToConfirmDescriptionText:
        'will need to confirm your request by clicking the button in the email we just sent, or accepting the request in their Clanzy app.',
      YesSendRequest: 'Yes, send request',
      RequestingThemToAcceptYourInvitationToBecomeTheirGuardianUser:
        'requesting them to accept your invitation to become their Guardian User?',
      SendAnEmailAndNotificationTo: 'Send an email and notification to',
      SkipForNow: 'Skip for now',
      InTheMeantimeYouCanContinueSettingUpTheirProfileText:
        'In the meantime, you can continue setting up their profile and building their Contact List on their behalf.',
      WeHaveEmailedThemAnInvitationText:
        'We have emailed them an invitation to join Clanzy. They’ll need to click on the button in the email to accept your invitation and start using Clanzy.',
      YouHaveCreatedAClanzyAccountFor: 'You have created a Clanzy account for',
      WhatIsTheEmailAddressOfThePersonYouWouldLikeToManage:
        'What is the email address of the person you would like to manage?',
      LearnMore: 'Learn more',
      ScheduleACallAndWatch: 'Schedule a Call & Watch',
      WatchYouTubeVideosDescriptionText:
        'Watch YouTube videos and chat at the same time with your friends and family during a video call.',
      WatchYouTubeVideosTogether: 'Watch YouTube videos together',
      ScheduleACallAndPlay: 'Schedule a Call & Play',
      PlayThisPopularCardGameDescriptionText:
        'Play this popular card game that has only a few simple rules and is a lot of fun.',
      PlayClassicRummy: 'Play Classic Rummy',
      MoreComingSoon: 'more coming soon',
      Games: 'Games',
      VideoStreamingPlatforms: 'Video streaming platforms',
      PlayTheGameOrWatchAVideoText:
        'Play the game or watch a video at the same time as everyone else while simultaneously chatting together',
      AtAnyTimeDuringARegularVideoCallClickTheEntertainmentButton:
        'At any time during a regular video call, click the "Entertainment" button',
      StartByOrganizingARegularVideoCallAndInvitePeopleToJoin:
        'Start by organizing a regular video call and invite people to join.',
      ClanzyMakesItEasyToPlayGamesAndWatchVideosWhileVideoChattingTogether:
        'Clanzy makes it easy to play games and watch videos while video chatting together',
      TypeAMessageHere: 'Type a message here',
      Chat: 'Chat',
      Send: 'Send',
      NotAClanzyUser: 'Not a Clanzy user',
      ClanzyUser: 'Clanzy user',
      IfTheyDonNotYetHaveAnAccountText:
        'If they don’t yet have an account, you can create it for them in the next steps.',
      DoesAPersonYouWannaAddAlreadyHasAClanzyAccount: 'Does a person you wanna add already has a Clanzy Account?',
      Max: 'Max',
      CallInProgress: 'Call in progress',
      CallStartingSoon: 'Call starting soon',
      NowActiveCalls: 'Now Active Calls',
      PlayYouTube: 'Play YouTube',
      PasteYouTubeLinkHere: 'Paste YouTube link here',
      Start: 'Start',
      AddYouTubeLinkToWatch: 'Add YouTube link to watch',
      WatchPartyGuidelines: 'Watch Party Guidelines',
      YouTubeWatchPartyAdditionalText:
        "All participants' microphones are automatically muted during the video, and unmuted at the end.",
      CreateYouTubeWatchParty: 'Create YouTube Watch Party',
      YouTubePartyDescriptionText:
        'Watch YouTube videos together with your friends and family, while chatting with each other.',
      YouTubeWatchParty: 'YouTube Watch Party',
      RemoteMeetingControlsManagedAccountImageAltText: 'Icon depicting a video camera with settings',
      RemoteMeetingControlFunctionsText:
        'During the call you can perform standard call functions for another person. As Mute, Minimize window, Turn on / off camera & Microphone, etc...',
      RemoteMeetingControlFunctions: 'Remote meeting control functions',
      SeeWhatTheySeeAndControlTheirComputerText:
        'See what they see and control their computer as you are in front of it.',
      RemoteControlComputerOfManagedUserImageAltText: 'Icon depicting a remote computer',
      RemoteControlComputerOfManagedUser: 'Remote control computer of managed user',
      CreateManagedAccount: 'Create managed account',
      YouCanCreateNewClanzyAccountsText:
        'You can create new Clanzy accounts, populate contact list, create groups & other similar functions.',
      HelpCreateSetUpAndManageOtherClanzyAccounts: 'Help create, set up and manage other Clanzy accounts',
      WhatYouCanDoForYourManagedUsers: 'What you can do for your managed users?',
      AddManagedUser: 'Add managed user',
      ManagedUser: 'Managed user',
      AddManagedUsers: 'Add managed users',
      ManagedUsers: 'Managed users',
      ProvideInstantTechnicalSupportToOtherClanzyMembersBeTheirGuardian:
        'Provide Instant Technical Support to other Clanzy members. Be their Guardian.',
      GuardianTools: 'Guardian Tools',
      Today: 'Today',
      At: 'at',
      ScheduledFor: 'Scheduled for',
      JoinNow: 'Join now',
      StopSharing: 'Stop sharing',
      YouAreSharingYourScreen: 'You are sharing your screen',
      UrlHasBeenCopiedToYourClipboard: 'URL has been copied to your clipboard',
      IconToClickToCopyTheCallUrlToYourClipboard: 'Icon to click to copy the call url to your clipboard',
      CopyCallUrl: 'Copy Call URL',
      NoNeedToCreateContactsFromScratch: 'No need to create contacts from scratch.',
      ImportContactsText:
        'No need to create contacts from scratch. Connect your email address book to automatically import your contacts.',
      Video: 'video',
      TurnOff: 'Turn off',
      ExitTheGame: 'Exit the game',
      LogOut: 'Log out',
      Options: 'Options',
      StopVideo: 'Stop video',
      Calls: 'calls',
      Menu: 'Menu',
      ConfusionlessVideo: 'Confusionless video',
      DoYouWantToJoinTheTable: 'Do you want to join the table?',
      IsInvitingYouToPlay: ' is inviting you to play',
      WaitingForInvitedParticipantsToJoinTheGame: 'Waiting for invited participants to join the game',
      Invited: 'Invited',
      InviteToPlay: 'Invite to play',
      IAmIn: "I'm in",
      SendInvitesAndStartGame: 'Send invites & start game',
      RummyGameDescriptionText:
        'The goal of Rummy is to lay “melds” on the table which consist of sets: three or four of a kind of the same rank; or runs of three or more cards in sequence of the same suit. The first player to lay all their cards on the table is the winner of the round.',
      RummyGameMoreInstructionsText: 'Click the "question mark" icon in the game for more instructions.',
      InvitePlayers: 'Invite players',
      Rummy: 'Rummy',
      PlayRummy: 'Play Rummy',
      SelectWhatToShare: 'Select what to share',
      ScreenShareSource: 'Screen share source',
      Share: 'Share',
      EnterYourNewPassword: 'Enter your new password',
      ResettingYourPasswordSuccessText: 'We sent you an email with instructions to follow.',
      ResettingYourPasswordText:
        'To reset your password, please input your email address below and hit "reset". We\'ll send you an email with instructions to follow.',
      ResettingYourPassword: 'Resetting your password',
      YouCanFindthisCallInYourListOfScheduledCalls: 'You can find this call in your list of scheduled calls',
      AndInvitationsToCallParticipantsSentOut: 'and invitations to call participants sent out.',
      ThisCallHasBeenScheduledFor: 'This call has been scheduled for',
      WouldBeAbleToTurnOnTheirVideo: 'would be able to turn on their video.',
      WouldBeAbleToUnmuteThemselves: 'would be able to unmute themselves.',
      Only: 'Only',
      ForEveryoneInTheCall: 'for everyone in the call',
      Mute: 'Mute',
      Reset: 'Reset',
      SendCallInviteEmailsAndStartTheCall: 'Send call invite emails and start the call',
      GroupMembersAreNotYetAClanzyUsersButTheyCanStillJoinYouOnThisCall:
        'Group members are not yet a Clanzy users. But they can still join you on this call!',
      WeWillSendThemAnEmailInviteAndAllTheyNeedToDoIsClickALinkInTheEmail:
        "We'll send them an email invite and all they need to do is click a link in the email",
      NotAllGroupMembersAreClanzyUsersButTheyCanStillJoinYouOnThisCall:
        'Not all Group members are Clanzy users. But they can still join you on this call!',
      ParticipantsAreNotClanzyUsers: 'Participants are not Clanzy users',
      ToJoinTheCall: 'to join the call.',
      TheOthers: 'the others',
      NotAllParticipantsAreClanzyUsers: 'Not all participants are Clanzy users',
      SoWeSentAnInviteToThisCallViaEmailText:
        'so we sent an invite to this call via email – please allow some time for',
      WeWillEmailAnInviteAndAll: "We'll email an invite and all",
      IsNotAClanzyUser: 'is not a Clanzy user',
      ButCanStillJoinYouOnThisCall: 'but can still join you on this call!',
      Canceled: 'Canceled',
      Missed: 'Missed',
      EnterEmail: 'Enter email',
      Incoming: 'Incoming',
      Outgoing: 'Outgoing',
      ComingSoon: 'Coming soon',
      GetRemindedToJoinUsLater: 'Get reminded to join us later',
      DownloadWindowsApp: 'Download Windows App',
      ClanzyDesktopAppText: 'Clanzy gets more powerful with the desktop app',
      JoinClanzyNow: 'Join Clanzy Now',
      ClanzyWindowsApp: 'Clanzy Windows App',
      ClickOnTheAllowButtonSoOthersCanSeeAndHearYouDuringTheCall:
        'Click on the “Allow” button, so others can see and hear you during the call',
      JoinACallYouHaveBeenInvitedTo: 'Join a call you have been invited to',
      ChangePicture: 'Change picture',
      LinkCopied: 'Link copied',
      CopyLink: 'Copy link',
      ScheduleThisCall: 'Schedule this call',
      CopyCallLink: 'Copy call link',
      AdvancedOptions: 'Advanced options',
      EditContact: 'Edit contact',
      IconToClickToSeeMoreAboutAndEditThisContact: 'Icon to click to see more about and edit this contact',
      PleaseTellUsYourNameText: 'Please tell us your name so we can display it to others during the call',
      AlreadyHaveAClanzyAccountSignIn: 'Already have a Clanzy account? Sign in',
      EnterCode: 'Enter code',
      YourName: 'Your name',
      IfYouReceivedACallCodePleaseEnterItHereToJoinTheCallYouHaveBeenInvitedTo:
        'If you received a call code, please enter it here to join the call you have been invited to',
      YourCallHasnotStartedYet: "Your call hasn't started yet",
      YourCallHasEnded: 'Your call has ended',
      PleaseWait: 'Please wait',
      JoinACall: 'Join a call',
      JoinTheCall: 'Join the call',
      UseGuardianToolsToHelpOthers: 'Use Guardian Tools to help others',
      UseClanzyAndTheirComputerInGeneral: 'use Clanzy and their computer in general',
      WatchMoviesAndTVShowsTogether: 'Watch movies and TV shows together',
      PlayGamesDuringVideoCalls: 'Play games during video calls',
      EasyToSetupVideoCalling: 'Easy to setup video calling',
      EmpowerYourselfToBringYourFamilyAndCloseFriendsTogether:
        'Empower yourself to bring your family and close friends together',
      YouWillFindYourContactsUnderTheContactsTab: 'You will find your contacts under the “Contacts” tab.',
      Success: 'Success',
      YourAddressBookContactsHaveBeenImported: 'Your Address Book contacts have been imported.',
      AllowSomeTimeFor: 'Allow some time for',
      AppearsToBeOfflineAndCantAnswerTheCallInstantlyText:
        'appears to be offline and can’t answer the call instantly. They received email invite on their email.',
      WaitingFor: 'Waiting for',
      All: 'All',
      NeedsToDoIsClickTheButtonInTheEmailToJoinText:
        'needs to do is click the button in the email to automatically join the call.',
      AppearsToBeOfflineTitle:
        'appears to be offline and can’t answer the call instantly. We can send a call invite via email instead.',
      SendCallInviteEmailAndStartTheCall: 'Send call invite email and start the call',
      CallWith: 'Call with',
      AllowCamAndMicAdditionalText: 'Please click on the "Allow" button in the browser window.',
      SoOtherCallParticipantsCanSeeAndHearYou: 'so other call participants can see and hear you.',
      AllowClanzyToUseYourCameraAndMicrophone: 'Allow Clanzy to use your camera and microphone',
      Saved: 'Saved',
      PleaseProvideGroupNameAndOptionalGroupPicture: 'Please provide group name and optional group picture',
      SaveTheGroupForFutureCalls: 'Save the group for future calls',
      Remove: 'Remove',
      FilteredResults: 'Filtered results',
      PeopleAndGroups: 'People and groups',
      StartThisCallNow: 'Start this call now',
      StartThisCall: 'Start this call',
      Date: 'Date',
      Time: 'Time',
      CameraIcon: 'Camera icon',
      GraphicDepictingTheScheduledCallsTab: 'Graphic depicting the Scheduled Calls tab',
      GoBackToCallDetails: 'Go back to Call details',
      SetDateAndTime: 'Set date & time',
      ScheduledForNow: 'Scheduled for now',
      Show: 'Show',
      Hide: 'Hide',
      Change: 'Change',
      Optional: 'Optional',
      optional: 'optional',
      ButtonToAddAdditionalParticipantsToTheCall: 'Button to add additional participants to the call',
      TypeACallDescription: 'Type a call description',
      CallDetails: 'Call Details',
      With: 'with',
      IsInvitingYouToJoinACall: 'is inviting you to join a call',
      WaitingForOthersToJoinTheCall: 'Waiting for others to join the call',
      RetrievingYourListOfRecentCalls: 'Retrieving your list of recent calls',
      NoRecentCallsYet: 'No recent calls yet',
      ScheduleACall: 'Schedule a call',
      NoScheduledCalls: 'No scheduled calls coming up',
      NoScheduledCallsComingUp: 'No scheduled calls coming up',
      Other: 'other',
      Others: 'others',
      GroupCall: 'Group call',
      ClanzyGroupCall: 'Clanzy Group call',
      Photo: 'Photo',
      AddPicture: 'Add picture',
      Call: 'Call',
      IsSelected: 'is selected',
      People: 'People',
      ChooseFromTheListOrTypeToFilter: 'Choose from the list or type to filter',
      ChoosePeopleToInvite: 'Choose people to invite',
      AddGroupContacts: 'Add group contacts',
      Participants: 'Participants',
      GroupName: 'Group name',
      CreateNewGroup: 'Create new group',
      CreateFirstGroup: 'Create first group',
      NoGroupsYet: 'No groups yet',
      NewGroup: 'New group',
      You: 'You',
      GoFullScreen: 'Go full screen',
      ShareYourScreen: 'Share your screen',
      MuteAll: 'Mute all',
      AddCallParticipants: 'Add call participants',
      TheInviteesAreBusy: 'The invitees are busy',
      ClanzyCall: 'Clanzy call',
      NewContact: 'New contact',
      Password: 'Password',
      Email: 'Email',
      FirstName: 'First name',
      LastName: 'Last name',
      CreateANewContact: 'Create a new contact',
      Speakers: 'Speakers',
      Done: 'Done',
      Settings: 'Settings',
      CallInviteHasBeenSent: 'Call invite has been sent',
      ChangeLayout: 'Change layout',
      Advanced: 'Advanced',
      LeaveCall: 'Leave call',
      NavigateToDashboardHome: 'Navigate to dashboard home',
      NavigateToDashboardScheduledCalls: 'Navigate to dashboard schedulled calls',
      NavigateToDashboardEntertainment: 'Navigate to dashboard entertainment',
      IsOn: 'is on',
      IsOff: 'is off',
      IsON: 'is ON',
      IsOFF: 'is OFF',
      Microphone: 'Microphone',
      Camera: 'Camera',
      NoMicrophoneDetected: 'No microphone detected',
      NoSpeakersDetected: 'No speakers detected',
      NoCameraDetected: 'No camera detected',
      NoCameraDetectedIcon: 'No camera detected icon',
      EndCall: 'End call',
      Calling: 'Calling',
      ThisIsHowYouWillAppearInTheCall: 'This is how you will appear in the call',
      YouMissedACallFrom: 'You missed a call from',
      CallBack: 'Call back',
      MissedVideoCall: 'Missed video call',
      IsCallingYou: 'is calling you',
      AnswerThisCall: 'Answer this call',
      SearchForPeopleOrGroups: 'Search for people or groups',
      RecentCallsList: 'Recent calls list',
      TourStepEntertainmentDescription: 'During a Clanzy video call you can play games and watch videos together.',
      TourStepScheduledCallsDescription:
        'Any calls you have scheduled for the future, or have been invited to by another Clanzy user, will appear here.  When the time comes, you can also join a call with a single click.',
      TourStepGroupsDescription:
        "If you frequently call the same group of people, you can group them altogether here.  Then when you want to organize a call with them it's much faster to organize because you just need to click on the group name, instead of each person's name one by one.",
      TourStepContactsDescription:
        'Here you will find all the contacts you have been created or imported.  You can add new contacts, edit existing contact details or delete contacts.',
      TourStepRecentCallsDescription:
        'Here you will find all your recent calls made and received.  You can immediately call the same person or group again.',
      TourStepVideoCallDescription:
        "Press this button to schedule a call or to start an instant call. You'll be guided through adding the person or people you want to invite to the call, whether they're also a Clanzy user or not.",
      TourStepVideoCallVisualDescription:
        "After pressing the “New call” button, you'll choose who you want to invite to the call.",
      TourStepRecentCallsVisualDescription:
        'To place a call from the Recent calls list, you need to click on the “Clanzy call” button.',
      TourStepContactsVisualDescription: 'To edit the contact you need to click on the info icon.',
      TourStepGroupsVisualDescription: 'To create a new group, you need to click on “New group” button.',
      TourStepScheduledCallsVisualDescription:
        'To join a scheduled call when the time comes, click on the “Join now” button.',
      TourStepEntertainmentVisualDescription:
        'At any time during a regular video call, click the "Entertainment" button and choose to play multi-player games or watch videos together.',
      StartAVideoCall: 'Start a video call',
      HowItWorks: 'How it works',
      NoContactsYet: 'No contacts yet',
      NewCall: 'New Call',
      GoBack: 'Go back',
      MicrophoneIsOnIcon: 'Microphone is on icon',
      MicrophoneIsOffIcon: 'Microphone is off icon',
      CameraIsOnIcon: 'Camera is on icon',
      CameraIsOffIcon: 'Camera is off icon',
      SkipProductTour: 'Skip product tour',
      ShowMeAround: 'Show me around',
      WelcomeToClanzy: 'Welcome to Clanzy',
      OtherEmailProviders: 'Other email providers',
      ChooseYourEmailProvider: 'Choose your email provider',
      ConnectAddressBookTitle:
        'Automatically import your saved contacts from your email provider, so you can make Clanzy video calls to them with just a single click.',
      ConnectAddressBook: 'Connect address book',
      AddressBook: 'Address book',
      Home: 'Home',
      CreateFirstContact: 'Create first contact',
      Entertainment: 'Entertainment',
      ScheduledCalls: 'Scheduled Calls',
      SignInPleaseEnterEmailAndPassword: 'Please enter your username and password for your Clanzy account',
      ForgotYourPassword: 'Forgot your password?',
      PasswordSpecialChar: 'Password needs at least one number or symbol (like !@#$%&).',
      PasswordMinLength: 'Password needs at least 8 characters',
      HomePromoFooter: 'Video chat, play games and watch videos with your family and close friends',
      WelcomeTitle: 'Confusionless, stress free video calls that everyone knows how to use!',
      TourTitle: 'Confusionless, video calls everyone knows how to use!',
      ConfusionlessVideoCalls: 'Confusionless video calls',
      KnowsHowToUse: 'knows how to use!',
      Knows: 'knows',
      Here: 'here',
      HowToUse: 'how to use!',
      Everyone: 'everyone',
      DonNotHaveAnAccountRegister: "Don't have an account, register",
      ClickHereToJoinTheCall: 'Click here to join the call.',
      LoginToYourClanzyAccountToJoinTheCall: 'Login to your Clanzy account to join the Call',
      WelcomeBack: 'Welcome back',
      ChangeName: 'Change name',
      ChangeEmail: 'Change email',
      SetYourPassword: 'Set your password',
      TypeYourNewPassword: 'Type your new password',
      Close: 'Close',
      Finish: 'Finish',
      LogIn: 'Log in',
      SignUp: 'Sign up',
      Previous: 'Previous',
      Next: 'Next',
      Submit: 'Submit',
      FormFieldValidationInvalidEmail: 'Invalid email',
      FormFieldValidationRequiredField: 'This field is required',
      SignUpLetUsKnowEmail: 'Please let us know your email address you will use to log in into Clanzy',
      SignUpLetUsKnowPassword: 'Please enter your name, and set a password, to continue.',
      HomeSignInButton: 'Login',
      HomeSignUpButton: "Join Clanzy - It's FREE",
      EasilyConnectAddressBook: 'Easily Connect with Friends & Family on Clanzy',
      ConnectEmailAddressBook:
        "Connect your email Address Book to save typing and make easy 'one-click' Calls to your close friends and family on the Clanzy app.",
      NotNow: 'Not now',
      ImportContacts: 'Import contacts',
      YourContactsWillBeSafe:
        'Your contacts will be safe and not in use for any other purposes other than for improving your experience when using Clanzy app. See our commitment to security in our Privacy Policy',
      ImportContactsMoreInfoGmail:
        'In the next screen, you will see a page from Google asking you to grant permission to Clanzy to access your Contacts. Google may also ask you to sign in to your Gmail account.',
      ImportContactsMoreInfoYahoo:
        'In the next screen, you will see a page from Yahoo asking you to grant permission to Clanzy to access your Contacts. Yahoo may also ask you to sign in to your Yahoo account.',
      ImportContactsMoreInfoWindowslive:
        'In the next screen, you will see a page from Microsoft asking you to grant permission to Clanzy to access your Contacts. Microsoft may also ask you to sign in to your Outlook.com account.',
      ConnectAddressOtherProviders:
        'Currently we can only automatically import contacts from Gmail, Outlook.com and Yahoo, so you’ll need to manually add your Contacts to your Contacts List.',
      OkSignMeIn: 'Ok, Sign me in',
      OkGotIt: 'Ok, got it',
      ItWillLookSomethingLikeThis: 'It will look something like this:',

      // Single sentence
      BySignUpYouAgree: 'By signing up you agree to our',
      PrivacyPolicy: 'Privacy Policy',
      And: 'and',
      Ok: 'Ok',
      TermsOfUse: 'Terms of Use',
      Name: 'Name',
      Security: 'Security',
      PersonalInfo: 'Personal info',
      EmailAddress: 'Email address',
      In: 'in',
      AddFromYourAddressBook: 'Add from your address book',
      ContactUs: 'Contact Us',
    },
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
})

export default i18n
