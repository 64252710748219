/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FC, useLayoutEffect, ReactElement, useEffect, useRef, useState } from 'react'
import Button from 'components/button'
import { useForm } from 'react-hook-form'
import Text from 'components/text'
import { CloseIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import { createManagedContacts } from 'reducers/contactsSlice'
import { FormFieldValidation, FormFieldInput } from 'components/form'
import isEmail from 'validator/es/lib/isEmail'
import { useDispatch } from 'react-redux'
import { managedUserIdQueryParam } from 'const'
import { useQueryParam, StringParam } from 'use-query-params'
import {
  StyledButtonsWrapper,
  StyledContentWrapper,
  StyledContentContainer,
  StyledCloseButton,
  StyledHeaderContainer,
  StyledForm,
} from './style'

const CreateManagedUserContactModal: FC<{ onClose: () => void }> = ({ onClose }): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [saving, setSaving] = useState(false)
  const firstNameRef = useRef<HTMLInputElement | null>(null)
  const { register, handleSubmit: handleSumbitForm, errors } = useForm()
  const [managedUserIdParam] = useQueryParam(managedUserIdQueryParam, StringParam)

  useLayoutEffect(() => {
    if (!managedUserIdParam) onClose()
  })

  const handleSubmit = async ({ firstname, lastname, email }) => {
    setSaving(true)
    await dispatch(
      createManagedContacts({
        userId: managedUserIdParam,
        contacts: [{ firstName: firstname, lastName: lastname, email }],
      }),
    )
    setSaving(false)
    onClose()
  }

  useEffect(() => {
    firstNameRef.current.focus()
  }, [])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Text component="h1" mt="0" color="brandblack" font="Poppins">
          {t('CreateANewContact')}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={onClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledContentWrapper>
        <StyledForm onSubmit={handleSumbitForm(handleSubmit)}>
          <FormFieldInput
            maxLength={64}
            name="firstname"
            label={t('FirstName')}
            placeholder={t('FirstName')}
            ref={(e) => {
              register(e)
              firstNameRef.current = e
            }}
          />
          <FormFieldInput
            maxLength={64}
            name="lastname"
            label={t('LastName')}
            placeholder={t('LastName')}
            ref={register}
          />
          <FormFieldInput
            error={!!errors.email}
            name="email"
            label={t('Email')}
            maxLength={64}
            placeholder={t('Email')}
            ref={register({ required: true, validate: isEmail })}
          />
          {errors.email?.type === 'required' && (
            <FormFieldValidation mt="0.56rem" mb="1rem" error>
              {t('FormFieldValidationRequiredField')}
            </FormFieldValidation>
          )}
          {errors.email?.type === 'validate' && (
            <FormFieldValidation mt="0.56rem" mb="1rem" error>
              {t('FormFieldValidationInvalidEmail')}
            </FormFieldValidation>
          )}
          <StyledButtonsWrapper>
            <Button size="md" subtle="true" onClick={onClose}>
              <Text size="md" color="purplehearth" fw="600">
                {t('Cancel')}
              </Text>
            </Button>
            <Button
              disabled={saving}
              $flat={saving}
              type="submit"
              size="md"
              bcolor="mediumpurple"
              bop="1"
              ml="1rem"
              hgradient="mediumpurple-dim-light"
              agradient="mediumpurple-dim-dark"
            >
              <Text size="md" $secondary fw="600">
                {t('Save')}
              </Text>
            </Button>
          </StyledButtonsWrapper>
        </StyledForm>
      </StyledContentWrapper>
    </StyledContentContainer>
  )
}

export default CreateManagedUserContactModal
