/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { userNormalizedFlagSuffix } from 'const'

export const getNormalizedFlag = (flag: string): string => `${flag}${userNormalizedFlagSuffix}`

export const normalizeFlags = (flags: string[]): { [flag: string]: boolean } =>
  flags
    ?.map((flag: string) => getNormalizedFlag(flag))
    ?.reduce((flagsObj, flag) => {
      flagsObj[flag] = true
      return flagsObj
    }, {})
