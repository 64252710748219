/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement, useEffect, useState } from 'react'
import Text from 'components/text'
import Button from 'components/button'
import { useTranslation } from 'react-i18next'
import { NewContactIcon } from 'theme'
import { SearchInput } from 'components/form'
import { GroupListItem } from 'components/list'
import { subModalQueryParam, modalCreateManagedUserGroupQueryParam } from 'const'
import { useQueryParam, StringParam } from 'use-query-params'
import { createFilter } from 'react-search-input'
import NoGroups from './no-groups'
import {
  StyledList,
  StyledListWrapper,
  StyledRowsContainer,
  StyledListControlsWrapper,
  StyledOverlayScrollbarsComponent,
  StyledContentContainer,
} from './style'

const ManagedUserGroups = ({ groups, user }): ReactElement => {
  const { t } = useTranslation()
  const [filteredGroups, setFilteredGroups] = useState(groups)
  const [searchTerm, setSearchTerm] = useState('')
  const [hovered, setHovered] = useState(null)
  const [, setSubModalParam] = useQueryParam(subModalQueryParam, StringParam)

  const handleMouseEnter = ({ id }) => setHovered(id)
  const handleMouseLeave = () => setHovered(null)

  const searchUpdated = (str) => setSearchTerm(str)

  const handleNewGroupClick = () => {
    setSubModalParam(modalCreateManagedUserGroupQueryParam, 'replaceIn')
  }

  useEffect(() => {
    if (searchTerm)
      setFilteredGroups(
        groups.filter(
          createFilter(searchTerm, ['name', 'description', 'contacts.displayName', 'contacts.email', 'contacts.phone']),
        ),
      )
    else setFilteredGroups(groups)
  }, [groups, searchTerm])

  return (
    <>
      <StyledListControlsWrapper>
        <SearchInput $secondary placeholder={t('SearchForPeopleOrGroups')} onChange={searchUpdated} />
        <StyledRowsContainer>
          <Button mt="1rem" bcolor="purple" bop="0.16" onClick={handleNewGroupClick}>
            <NewContactIcon $secondary title={t('NewGroup')} />
            <Text ml="0.2rem" font="Inter" size="md" $secondary fw="600" color="purplehearth">
              {t('NewGroup')}
            </Text>
          </Button>
        </StyledRowsContainer>
      </StyledListControlsWrapper>
      <StyledContentContainer>{!groups?.length && <NoGroups user={user} />}</StyledContentContainer>
      {!!filteredGroups?.length && (
        <>
          <StyledListWrapper>
            <StyledOverlayScrollbarsComponent options={{ className: 'os-theme-light' }}>
              <StyledList>
                {filteredGroups.map((group) => (
                  <GroupListItem
                    $hovered={hovered === group.id}
                    $secondary
                    bcolorhov="alto"
                    bcolor="concrete"
                    key={group.id}
                    group={group}
                    onMouseEnter={() => handleMouseEnter(group)}
                    onMouseLeave={handleMouseLeave}
                    $noPresence
                  />
                ))}
              </StyledList>
            </StyledOverlayScrollbarsComponent>
          </StyledListWrapper>
        </>
      )}
    </>
  )
}

export default ManagedUserGroups
