import { ReactElement } from 'react'
import { withTheme } from 'styled-components'

export default withTheme(
  ({ theme: { fn, palette }, $secondary, onClick }): ReactElement => {
    const color = (fn.color as (arg0: string) => string)($secondary ? palette.secondary.main : palette.primary.main)
    return (
      <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <path d="M5 8l4 4 4-4z" fill={color} />
      </svg>
    )
  },
)
