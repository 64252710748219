/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import Button from 'components/button'
import Card from 'components/card'
import { ContentContainer, RowsContainer, ViewContainer, ColumnsContainer } from 'components/container'

export const StyledContentWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
`

export const StyledModal = styled(ViewContainer)`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.black, '0.8')};
`

export const StyledColumnsContainer = styled(ColumnsContainer)`
  width: 100%;
  align-items: center;
`

export const StyledCard = styled(Card)`
  border-radius: 1rem;
  box-shadow: ${({ theme: { shadows } }) => shadows.box};
`

export const StyledContentContainer = styled(ContentContainer)`
  margin: 1.125rem;
`

export const StyledHeaderContainer = styled(RowsContainer)`
  height: 2.8125rem;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
`

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

export const StyledBackButton = styled(Button)`
  position: absolute;
  left: 1rem;
  top: 1rem;
`

export const StyledButtonsWrapper = styled(RowsContainer)`
  width: 100%;
  justify-content: flex-end;
  margin: 1.7rem 0 0 0;
`
