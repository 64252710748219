import { ReactElement } from 'react'
import Text from 'components/text'
import { subModalQueryParam, modalAddManagedUserContactsQueryParam } from 'const'
import { ContactsIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import { ContentContainer } from 'components/container'
import { useQueryParam, StringParam } from 'use-query-params'
import { StyledTextWrapper, StyledColumnsContainer, StyledButton } from './style'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NoContacts = ({ user }): ReactElement => {
  const { t } = useTranslation()
  const [, setSubModalParam] = useQueryParam(subModalQueryParam, StringParam)

  const handleClick = () => {
    setSubModalParam(modalAddManagedUserContactsQueryParam, 'replaceIn')
  }

  return (
    <StyledColumnsContainer>
      <ContentContainer>
        <Text mt="4rem" font="Inter" size="md" fw="600" color="brandblack" cop="0.6">
          {t('NoContactsYet')}&nbsp;
          {t('In')}&nbsp;
          {user?.firstName}&nbsp;
          {t('AddressBook').toLowerCase()}.
        </Text>
        <StyledButton
          size="lg"
          mt="3.75rem"
          mb="1rem"
          gradient="purple-yellow"
          hgradient="purple-yellow-dim-light"
          agradient="purple-yellow-dim-dark"
          bop="0.16"
          onClick={handleClick}
        >
          <Text mr="1rem" font="Inter" size="md" fw="600" $secondary>
            {t('AddFromYourAddressBook')}
          </Text>
          <ContactsIcon title={t('AddFromYourAddressBook')} />
        </StyledButton>
        <StyledTextWrapper>
          <Text size="xs" color="brandblack" cop="0.6">
            {t('YouCanCopySelectedContactsFromYourAddressBookTo')}&nbsp;
            {user?.firstName}&nbsp;
            {t('AddressBook').toLowerCase()}.
          </Text>
        </StyledTextWrapper>
      </ContentContainer>
    </StyledColumnsContainer>
  )
}

export default NoContacts
