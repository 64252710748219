import styled, { css } from 'styled-components'
import { TextContainer } from 'components/container'

export interface FormFieldValidationProps {
  /**
   * Margin top
   */
  mt?: string
  /**
   * Margin bottom
   */
  mb?: string
}

export const StyledTextContainer = styled(TextContainer)`
  text-align: left;
  margin-left: 0.5rem;

  ${(props: FormFieldValidationProps) =>
    !!props.mt &&
    css`
      margin-top: ${props.mt};
    `}

  ${(props: FormFieldValidationProps) =>
    !!props.mb &&
    css`
      margin-bottom: ${props.mb};
    `}
`

export const StyledIconWrapper = styled.div`
  width: 1.25rem;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > svg {
    flex-grow: 1;
  }
`
