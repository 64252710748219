import axios from 'axios'
import { apiUrl } from 'const'
import { setTokenInterceptor, responseInterceptorError } from 'utils/apis'

export enum ContactsStatuses {
  online = 'online',
  offline = 'offline',
}

export interface ImportedContactData {
  [prop: string]: unknown
}

export interface ApiImportContacts {
  userId: string
  contacts: ImportedContactData[]
}

export interface ContactData {
  id?: string
  firstName?: string
  lastName?: string
  email: string
}

export interface ApiPostContacts {
  contacts: ContactData[]
}

export interface ApiPutContacts {
  contacts: ContactData[]
}

const instance = axios.create({
  baseURL: `${apiUrl}/users-contacts`,
})

instance.interceptors.request.use(setTokenInterceptor(true, false))
instance.interceptors.response.use(undefined, responseInterceptorError)

export const apiPostContacts = async (contacts: ContactData[]): Promise<ContactData[]> => {
  const res = await instance({
    url: `/new`,
    method: 'POST',
    data: contacts[0],
  })
  return [res.data]
}

export const apiPutContacts = async (contacts: ContactData[]): Promise<ContactData[]> => {
  const res = await instance({
    url: `/contact/${contacts[0].id}`,
    method: 'POST',
    data: contacts[0],
  })
  return [res.data]
}

export const apiImportContacts = async ({
  contacts,
}: {
  contacts: ImportedContactData[]
}): Promise<ImportedContactData[]> => {
  const res = await instance({
    url: `/import`,
    method: 'POST',
    data: contacts,
  })
  return res.data.success
}

export const apiGetAllContacts = async (): Promise<ContactData[]> => {
  const res = await instance({
    url: `/`,
    method: 'GET',
  })
  return res.data
}
