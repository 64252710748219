import { ReactElement } from 'react'

export default (): ReactElement => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 29.5C8.54416 29.5 2.5 23.4558 2.5 16C2.5 8.54415 8.54416 2.5 16 2.5C23.4558 2.5 29.5 8.54416 29.5 16C29.5 23.4558 23.4558 29.5 16 29.5Z"
      stroke="black"
      strokeOpacity="0.4"
      strokeWidth="3"
    />
    <path
      d="M21.6569 21.6569C18.5327 24.781 13.4673 24.781 10.3431 21.6569"
      stroke="black"
      strokeOpacity="0.4"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <ellipse cx="12" cy="13.5" rx="2" ry="2.5" fill="black" fillOpacity="0.4" />
    <ellipse cx="20" cy="13.5" rx="2" ry="2.5" fill="black" fillOpacity="0.4" />
  </svg>
)
