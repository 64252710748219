import styled, { css } from 'styled-components'

export interface ViewContainerProps {
  /**
   * Variant
   */
  secondary?: string
  /**
   * Center content
   */
  center?: string
  /**
   * Background color
   */
  bcolor?: string
  /**
   * Flex direction row
   */
  row?: string
}

export default styled(({ children, component, ...rest }) => {
  switch (component) {
    case 'dialog':
      return <dialog {...rest}>{children}</dialog>
    case 'section':
      return <section {...rest}>{children}</section>
    default:
      return <main {...rest}>{children}</main>
  }
})<ViewContainerProps>`
  border: none;
  height: 100%;
  min-height: 100vh;
  outline: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.fn.color(theme.palette.background.primary)};

  ${(props: ViewContainerProps) =>
    !!props.secondary &&
    css`
      background-color: ${({ theme }) => theme.fn.color(theme.palette.background.secondary)};
    `}

  ${(props: ViewContainerProps) =>
    !!props.row &&
    css`
      flex-direction: row;
    `}

  ${(props: ViewContainerProps) =>
    !!props.center &&
    css`
      text-align: center;
      align-items: center;
    `}
  

  ${(props: ViewContainerProps) =>
    !!props.bcolor &&
    css`
      background-color: ${({ theme }) => theme.fn.color(theme.palette.background[props.bcolor])};
    `}
`
