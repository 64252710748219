import { combineReducers } from '@reduxjs/toolkit'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import callsReducer from 'reducers/callsSlice'
import { toastsReducer } from 'react-toastify-redux'
import usersReducer from './usersSlice'
import contactsReducer from './contactsSlice'
import groupsReducer from './groupsSlice'
import guardianRequestsReducer from './guardianRequestsSlice'

export type RootState = ReturnType<typeof combineReducers>

const createRootReducer = (history: History): RootState =>
  combineReducers({
    router: connectRouter(history),
    calls: callsReducer,
    users: usersReducer,
    contacts: contactsReducer,
    groups: groupsReducer,
    guardianRequests: guardianRequestsReducer,
    toasts: toastsReducer,
  })

export default createRootReducer
