/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement } from 'react'
import Card from 'components/card'
import { useQueryParam, StringParam } from 'use-query-params'
import { modalQueryParam, modalScreenShareSelectSourceQueryParam } from 'const'
import { TransparentBackground } from '../../backgrounds'
import ScreenShareRequest from './screen-share-request'
import UnmuteRequest from './unmute-request'
import CameraOnRequest from './camera-on-request'

export default ({
  declineScreenShareRequest,
  shareScreen,
  guardianRequests,
  screenShareId,
  declineUnmuteRequest,
  declineCameraOnRequest,
  acceptCameraOnRequest,
  acceptUnmuteRequest,
}): ReactElement => {
  const [modalParam] = useQueryParam(modalQueryParam, StringParam)

  if (guardianRequests?.length) {
    const unmuteGuardianRequest = guardianRequests.find(({ type }) => type === 'unmute')
    const cameraOnGuardianRequest = guardianRequests.find(({ type }) => type === 'cameraon')

    if (unmuteGuardianRequest) {
      return (
        <TransparentBackground>
          <Card size="md" secondary="true">
            <UnmuteRequest
              declineUnmuteRequest={declineUnmuteRequest}
              guardianRequest={unmuteGuardianRequest}
              acceptUnmuteRequest={acceptUnmuteRequest}
            />
          </Card>
        </TransparentBackground>
      )
    }

    if (cameraOnGuardianRequest) {
      return (
        <TransparentBackground>
          <Card size="md" secondary="true">
            <CameraOnRequest
              declineCameraOnRequest={declineCameraOnRequest}
              guardianRequest={cameraOnGuardianRequest}
              acceptCameraOnRequest={acceptCameraOnRequest}
            />
          </Card>
        </TransparentBackground>
      )
    }

    if (!screenShareId && (!modalParam || modalParam !== modalScreenShareSelectSourceQueryParam)) {
      const screenShareGuardianRequest = guardianRequests.find(({ type }) => type === 'screenshare')

      if (screenShareGuardianRequest) {
        return (
          <TransparentBackground>
            <Card size="md" secondary="true">
              <ScreenShareRequest
                declineScreenShareRequest={declineScreenShareRequest}
                shareScreen={shareScreen}
                guardianRequest={screenShareGuardianRequest}
              />
            </Card>
          </TransparentBackground>
        )
      }
    }
  }

  return null
}
