/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement, useEffect, useRef } from 'react'
import Text from 'components/text'
import Button from 'components/button'
import { useTranslation } from 'react-i18next'
import {
  modalQueryParam,
  managedUserIdQueryParam,
  emailQueryParam,
  modalManagedUserCreatedQueryParam,
  modalExistingManagedUserQueryParam,
} from 'const'
import { connect, useDispatch } from 'react-redux'
import { FormFieldValidation, FormFieldInput } from 'components/form'
import { useForm } from 'react-hook-form'
import { useQueryParam, StringParam } from 'use-query-params'
import { CloseIcon } from 'theme'
import isEmail from 'validator/es/lib/isEmail'
import { createManagedUser, selectAuthUser } from 'reducers/usersSlice'
import {
  StyledTitleContainer,
  StyledButtonsWrapper,
  StyledContentContainer,
  StyledCloseButton,
  StyledHeaderContainer,
  StyledForm,
} from './style'

const AddManagedUserModal = ({ authUser, onClose }): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const emailRef = useRef<HTMLInputElement | null>(null)
  const [, setModalParam] = useQueryParam(modalQueryParam, StringParam)
  const { register, handleSubmit: handleSumbitForm, errors } = useForm()
  const [, setManagedUserIdQueryParam] = useQueryParam(managedUserIdQueryParam, StringParam)
  const [, setEmailQueryParam] = useQueryParam(emailQueryParam, StringParam)

  useEffect(() => {
    emailRef.current.focus()
  }, [])

  const handleSubmit = async ({ email }) => {
    if (email?.toLowerCase() !== authUser.username.toLowerCase()) {
      const res = (await dispatch(createManagedUser({ email }))) as any

      if (res?.payload === 400) {
        setEmailQueryParam(encodeURIComponent(email), 'replaceIn')
        setModalParam(modalExistingManagedUserQueryParam, 'replaceIn')
      } else if (res?.payload?.id) {
        setManagedUserIdQueryParam(res?.payload?.id, 'replaceIn')
        setModalParam(modalManagedUserCreatedQueryParam, 'replaceIn')
      }
    }
  }

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <StyledCloseButton circular="true" subtle="true" onClick={onClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledTitleContainer>
        <Text component="h1" mt="0" color="brandblack" font="Poppins">
          {t('WhatIsTheEmailAddressOfThePersonYouWouldLikeToManage')}
        </Text>
      </StyledTitleContainer>
      <StyledForm onSubmit={handleSumbitForm(handleSubmit)}>
        <FormFieldInput
          error={!!errors.email}
          name="email"
          label={t('Email')}
          maxLength={64}
          placeholder={t('Email')}
          ref={(e) => {
            register({ required: true, validate: isEmail })(e)
            emailRef.current = e
          }}
        />
        {errors.email?.type === 'required' && (
          <FormFieldValidation mt="0.56rem" mb="1rem" error>
            {t('FormFieldValidationRequiredField')}
          </FormFieldValidation>
        )}
        {errors.email?.type === 'validate' && (
          <FormFieldValidation mt="0.56rem" mb="1rem" error>
            {t('FormFieldValidationInvalidEmail')}
          </FormFieldValidation>
        )}
        <StyledButtonsWrapper>
          <Button
            type="submit"
            size="md"
            bcolor="mediumpurple"
            bop="1"
            ml="1rem"
            hgradient="mediumpurple-dim-light"
            agradient="mediumpurple-dim-dark"
          >
            <Text size="md" $secondary fw="600">
              {t('Next')}
            </Text>
          </Button>
        </StyledButtonsWrapper>
      </StyledForm>
    </StyledContentContainer>
  )
}

const mapStateToProps = (state) => ({
  authUser: selectAuthUser(state),
})

export default connect(mapStateToProps, null)(AddManagedUserModal)
