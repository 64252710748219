import { ReactElement, forwardRef, ForwardRefRenderFunction, useState } from 'react'
import { ColumnsContainer } from 'components/container'
import Text from 'components/text'
import { useTranslation } from 'react-i18next'
import { RegularTickIcon } from 'theme'
import {
  StyledCheckedWrapper,
  StyledFormFieldInput,
  StyledFormFieldInputLabel,
  StyledFormFieldInputWrapper,
  StyledFormFieldInputButton,
  FormFieldInputProps,
} from './style'

const FormFieldInput: ForwardRefRenderFunction<HTMLInputElement, FormFieldInputProps> = (
  {
    type = 'text',
    bcolor,
    bop,
    onFocus,
    onBlur,
    elRef,
    error,
    revealPassword,
    maxLength,
    label,
    placeholder,
    name,
    defaultValue,
    onBtnClick,
    $btnSecondary,
    btnTitle,
    checked,
    checkedTitle,
    disabled,
    $flat,
    $rounded,
    $uppercase,
    onChange,
    pattern,
    btnType = 'button',
    checkedColor = 'salem',
    p,
    ...rest
  },
  ref,
): ReactElement => {
  const { t } = useTranslation()
  const [filedType, setFieldType] = useState(type)

  const handleClick = (e) => {
    e.preventDefault()
    elRef?.current?.focus()
    setFieldType(filedType === 'password' ? 'text' : 'password')
    return false
  }

  const input = (
    <StyledFormFieldInput
      pattern={pattern}
      readOnly={!!disabled}
      $flat={$flat || !!disabled}
      $rounded={$rounded}
      error={error}
      name={name}
      maxLength={maxLength}
      placeholder={placeholder}
      type={filedType}
      defaultValue={defaultValue}
      ref={ref}
      revealPassword={revealPassword}
      bcolor={bcolor}
      bop={bop}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      $uppercase={$uppercase}
      p={p}
    />
  )

  let inputComponent = input

  if (type === 'password') {
    inputComponent = (
      <StyledFormFieldInputWrapper>
        {input}
        {!!revealPassword && (
          <StyledFormFieldInputButton size="sm" bcolor="purple" onClick={handleClick} type={btnType}>
            <Text color="purplehearth" size="md" fw="600">
              {filedType === 'password' ? t('Show') : t('Hide')}
            </Text>
          </StyledFormFieldInputButton>
        )}
      </StyledFormFieldInputWrapper>
    )
  } else if (onBtnClick || btnType === 'submit' || !!checked) {
    inputComponent = (
      <StyledFormFieldInputWrapper>
        {input}
        {checked ? (
          <StyledCheckedWrapper>
            <Text color={checkedColor} size="md" mr="0.5rem" fw="600">
              {checkedTitle}
            </Text>
            <RegularTickIcon color={checkedColor} success="true" />
          </StyledCheckedWrapper>
        ) : (
          <StyledFormFieldInputButton
            size="sm"
            type={btnType}
            bcolor="mediumpurple"
            bop={$btnSecondary ? '0' : '1'}
            {...(!$btnSecondary
              ? {
                  hgradient: 'mediumpurple-dim-light',
                  agradient: 'mediumpurple-dim-dark',
                }
              : {})}
            onClick={onBtnClick}
          >
            <Text $secondary={!$btnSecondary} color={$btnSecondary ? 'mediumpurple' : 'white'} size="md" fw="600">
              {btnTitle}
            </Text>
          </StyledFormFieldInputButton>
        )}
      </StyledFormFieldInputWrapper>
    )
  }

  return (
    <ColumnsContainer {...rest}>
      {!!label && <StyledFormFieldInputLabel htmlFor={name}>{label}</StyledFormFieldInputLabel>}
      {inputComponent}
    </ColumnsContainer>
  )
}

export default forwardRef(FormFieldInput)
