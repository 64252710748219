import axios from 'axios'
import { apiUrl } from 'const'
import { setTokenInterceptor, responseInterceptorError } from 'utils/apis'

export enum CallEntertainmentStatuses {
  notStarted = 'notStarted',
  inProgress = 'inProgress',
  ended = 'ended',
  cancelled = 'cancelled',
}

export enum CallEntertainmentParticipantStatuses {
  pending = 'pending',
  accepted = 'accepted',
  rejected = 'rejected',
  left = 'left',
}

export enum CallEntertainmentTypes {
  game = 'game',
  media = 'media',
}

export enum CallEntertainmentParticipantRoles {
  host = 'host',
}

export interface ApiUpdateCallEntertainmentParticipant {
  callId: string
  entertainmentId: string
  participant: {
    status: CallEntertainmentParticipantStatuses
  }
}

export interface ApiAddCallEntertainment {
  callId: string
  entertainment: {
    type: string
    name: string
    participants: string[]
  }
}

export interface ApiUpdateCallEntertainment {
  callId: string
  entertainmentId: string
  entertainment: {
    data?: string
    status?: string
  }
}

export enum CallStatuses {
  notStarted = 'notStarted',
  inProgress = 'inProgress',
  ended = 'ended',
  cancelled = 'cancelled',
}

export enum CallInviteeStatuses {
  pending = 'pending',
  accepted = 'accepted',
  rejected = 'rejected',
}

export enum CallParticipantRoles {
  host = 'host',
  clanzyUser = 'clanzyUser',
}

export enum CallTypes {
  clanzyCall = 'clanzyCall',
  clanzyMeeting = 'clanzyMeeting',
}

export interface CallInviteeData {
  id: string
  userId: string
  status: CallInviteeStatuses
}

export interface CallParticipantData {
  id: string
  userId: string
  role: CallParticipantRoles
  leaveTime?: string
}

export interface CallData {
  id: string
  isInstant: boolean
  callId: string
  callStatus: CallStatuses
  callInvitees: CallInviteeData[]
  callParticipants: CallParticipantData[]
}

export interface ApiCreateCall {
  description?: string
  isInstant?: boolean
  callId?: string
  scheduledTime?: string
  callStatus?: CallStatuses
  callInvitees?: { contactId?: string; userId?: string }[]
  groupIds?: string[]
}

export interface ApiUpdateCallInvitation {
  callId: string
  invitation: {
    status: CallInviteeStatuses
  }
}

export interface ApiUpdateCallParticipant {
  callId: string
  participant: {
    leaveTime: string
  }
}

const instance = axios.create({
  baseURL: `${apiUrl}/calls`,
})

instance.interceptors.request.use(setTokenInterceptor(true, true))
instance.interceptors.response.use(undefined, responseInterceptorError)

export const apiCreateCall = async (data: ApiCreateCall): Promise<CallData> => {
  const res = await instance({
    method: 'POST',
    data,
  })
  return res.data
}

export const apiCreateParticipant = async (callId: string): Promise<CallData> => {
  const res = await instance({
    url: `/${callId}/participants`,
    method: 'POST',
  })
  res.data.callStatus = 'inProgress'
  return res.data
}

export const apiGetCall = async (callId: string): Promise<CallData> => {
  const res = await instance({
    url: `/${callId}`,
    method: 'GET',
  })
  return res.data
}

export const apiUpdateCallParticipant = async (data: ApiUpdateCallParticipant): Promise<CallData> => {
  const res = await instance({
    url: `${data.callId}/participants`,
    method: 'PATCH',
    data: data.participant,
  })
  return res.data
}

export const apiUpdateCallInvitation = async (data: ApiUpdateCallInvitation): Promise<CallData> => {
  const res = await instance({
    url: `${data.callId}/invitees`,
    method: 'POST',
    data: data.invitation,
  })
  return res.data
}

export const apiGetAllCalls = async (): Promise<CallData[]> => {
  const res = await instance({
    url: `/`,
    method: 'GET',
  })
  return res.data
}

export const getApiLeftEntertainmentNotAuthUrl = (
  callId: string,
  entertainmentId: string,
  participantId: string,
): string => `${apiUrl}/calls/${callId}/entertainments/${entertainmentId}/participants/${participantId}/left`

export const apiAddCallEntertainment = async (data: ApiAddCallEntertainment): Promise<CallData> => {
  const res = await instance({
    url: `/${data.callId}/entertainments`,
    method: 'POST',
    data: data.entertainment,
  })
  return res.data
}

export const apiUpdateCallEntertainment = async (data: ApiUpdateCallEntertainment): Promise<CallData> => {
  const res = await instance({
    url: `${data.callId}/entertainments/${data.entertainmentId}`,
    method: 'PUT',
    data: data.entertainment,
  })
  return res.data
}

export const apiUpdateCallEntertainmentParticipant = async (
  data: ApiUpdateCallEntertainmentParticipant,
): Promise<CallData> => {
  const res = await instance({
    url: `${data.callId}/entertainments/${data.entertainmentId}/participants`,
    method: 'POST',
    data: data.participant,
  })
  return res.data
}
