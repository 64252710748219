/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import Button from 'components/button'
import { ContentContainer, RowsContainer } from 'components/container'

export const StyledImageWrapper = styled.div`
  text-align: center;
  position: relative;
  padding: 1.8rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;

  position: relative;
  box-sizing: border-box;

  background-clip: padding-box;
  border: 0.25rem solid transparent;
  border-radius: 2rem;

  & img {
    margin: 0 auto;
    z-index: 1;
  }
`

export const StyledBorder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: ${({ theme: { gradients } }) => gradients.magenta};
  width: 100%;
  height: 100%;
  border-radius: 2rem;
`

export const StyledImageBackground = styled.div`
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  bottom: 0.25rem;
  right: 0.25rem;
  background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.white)};
  border-radius: 1.8rem;
`

export const StyledTextContainer = styled.article`
  text-align: center;
  position: relative;
  padding: 1.8rem;
`

export const StyledContentContainer = styled(ContentContainer)`
  margin: 1.125rem;
`

export const StyledHeaderContainer = styled(RowsContainer)`
  height: 2.8125rem;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
`

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

export const StyledBackButton = styled(Button)`
  position: absolute;
  left: 1rem;
  top: 1rem;
`
