/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { MutableRefObject, useCallback, useRef, useState } from 'react'
import { passwordRequiredAtLeastOneChar } from 'const'

export default (watch): any => {
  const [passwordFocus, setPasswordTouched] = useState(false)
  const passwordChars = passwordRequiredAtLeastOneChar
  const passwordRef = useRef() as MutableRefObject<HTMLInputElement>
  const watchPassword = watch('password')
  const handlePasswordTouched = () => setPasswordTouched(true)

  const validateSpecialChar = useCallback(
    (value: string): boolean => passwordChars.split('').some((ch) => value.indexOf(ch) !== -1),
    [passwordChars],
  )
  const validateMinLength = (value: string): boolean => !!value && value.length >= 8

  return {
    passwordFocus,
    passwordRef,
    handlePasswordTouched,
    watchPassword,
    validateMinLength,
    validateSpecialChar,
  }
}
