import React, { ReactElement, FC } from 'react'
import Avatar from 'components/avatar'
import { CheckCircle } from 'components/check'
import Button from 'components/button'
import { CloseIcon } from 'theme'
import CallButton from 'components/button/call'
import InfoButton from 'components/button/info'
import { isClanzyUser } from 'utils/contacts'
import { getAvatarBorColor, getAvatarBorOp } from 'utils'
import { useTranslation } from 'react-i18next'
import Text from 'components/text'
import {
  StyledControlsWrapper,
  ContactListItemProps,
  StyledTextWrapper,
  StyledTextCallTypeWrapper,
  StyledListItem,
} from './style'

const ContactListItem: FC<ContactListItemProps> = ({
  children,
  contact,
  $selected,
  $flat,
  $hovered,
  onMouseEnter,
  onMouseLeave,
  onCallClick,
  onInfoClick,
  onClick,
  onClose,
  $secondary,
  status,
  $noPresence,
  ...rest
}): ReactElement => {
  const { t } = useTranslation()
  const isContactClanzyUser = isClanzyUser(contact)

  const handleMouseEnter = () => {
    if (onMouseEnter) onMouseEnter(contact)
  }
  const handleMouseLeave = () => {
    if (onMouseLeave) onMouseLeave(contact)
  }
  const handleCallClick = () => {
    onCallClick(contact)
  }
  const handleClick = () => {
    if (onClick) onClick(contact)
  }
  const handleClose = () => {
    if (onClose) onClose(contact)
  }
  const handleInfoClick = () => {
    if (onInfoClick) onInfoClick(contact)
  }

  return (
    <StyledListItem
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      $secondary={$secondary}
      $flat={$flat}
      onClick={handleClick}
      {...rest}
    >
      <Avatar
        size="lg"
        buttonSize="xl"
        fontSize="xxl"
        $secondary={$secondary}
        borcolor={getAvatarBorColor(isContactClanzyUser, $secondary)}
        borop={getAvatarBorOp(isContactClanzyUser, $secondary)}
        name={contact.displayName}
        alt={t('PhotoOfContact')}
        img={contact.photo}
        status={status}
        $noPresence={$noPresence}
      />
      <StyledTextWrapper>
        <Text fw="600" ml="1rem" size="md" $secondary={!$secondary}>
          {contact.displayName}
        </Text>
        {onInfoClick && $hovered && (
          <InfoButton ml="0.5rem" title={t('IconToClickToSeeMoreAboutAndEditThisContact')} onClick={handleInfoClick} />
        )}
      </StyledTextWrapper>
      <StyledControlsWrapper>
        {typeof $selected !== 'undefined' && (
          <CheckCircle alt={`${contact.displayName} ${t('IsSelected')}`} $checked={$selected} />
        )}
        {onCallClick && $hovered && (
          <StyledTextCallTypeWrapper>
            <Text fw="600" size="md" mr="1rem" $secondary={!$secondary} onClick={handleCallClick} td="none">
              {t(isContactClanzyUser ? 'ClanzyCall' : 'Call')}
            </Text>
          </StyledTextCallTypeWrapper>
        )}
        {onCallClick && (
          <CallButton
            title={t(isContactClanzyUser ? 'ClanzyCallButton' : 'CallButton')}
            $active={$hovered}
            clanzy={isContactClanzyUser}
            onClick={handleCallClick}
          />
        )}
        {onClose && (
          <Button circular="true" subtle="true" onClick={handleClose}>
            <CloseIcon />
          </Button>
        )}
      </StyledControlsWrapper>
    </StyledListItem>
  )
}

export default ContactListItem
