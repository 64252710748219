/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement, useCallback, useEffect, useLayoutEffect } from 'react'
import Text from 'components/text'
import Button from 'components/button'
import { useTranslation } from 'react-i18next'
import { participantIdQueryParam } from 'const'
import { useQueryParam, StringParam } from 'use-query-params'
import { CloseIcon } from 'theme'
import {
  StyledButtonsWrapper,
  StyledContentContainer,
  StyledTextContainer,
  StyledTitleContainer,
  StyledCloseButton,
  StyledHeaderContainer,
} from './style'

const { $ } = window

const ScreenShareSendRequestModal = ({ onClose, displayNames, sendUnmuteRequest, user }): ReactElement => {
  const { t } = useTranslation()
  const [participantIdParam, setParticipantIdParam] = useQueryParam(participantIdQueryParam, StringParam)

  const handleClose = useCallback(() => {
    setParticipantIdParam(null, 'replaceIn')
    onClose()
  }, [onClose, setParticipantIdParam])

  const handleSendRequest = useCallback(() => {
    sendUnmuteRequest(participantIdParam, user)
    handleClose()
  }, [handleClose, participantIdParam, sendUnmuteRequest, user])

  useLayoutEffect(() => {
    if (!participantIdParam) handleClose()
  }, [handleClose, participantIdParam])

  useEffect(() => {
    if (participantIdParam) {
      const el = $(`.send-unmute-request-button-${participantIdParam}`)?.[0]
      if (el) el.focus()
    }
  }, [participantIdParam])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Text component="h1" mt="0" color="brandblack" font="Poppins">
          {t('SendRequest')}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledTitleContainer>
        <Text size="xl" gradient="purple" fw="600" font="Poppins" $block>
          {`${t('WeWillSendRequestTo')} ${displayNames[participantIdParam]} ${t('ToUmuteThemselves')}`}
        </Text>
      </StyledTitleContainer>
      <StyledTextContainer>
        <Text color="black" fw="500" mt="1rem" $block>
          {`${displayNames[participantIdParam]} ${t('WillHaveToAcceptTheRequestWithinTheirClanzyApp')}`}
        </Text>
      </StyledTextContainer>
      <StyledButtonsWrapper>
        <Button size="md" subtle="true" onClick={handleClose}>
          <Text size="md" color="purplehearth" fw="600">
            {t('Cancel')}
          </Text>
        </Button>
        <Button
          size="md"
          bcolor="mediumpurple"
          bop="1"
          ml="1rem"
          hgradient="mediumpurple-dim-light"
          agradient="mediumpurple-dim-dark"
          onClick={handleSendRequest}
          className={`send-unmute-request-button-${participantIdParam}`}
        >
          <Text size="md" $secondary fw="600">
            {t('SendRequest')}
          </Text>
        </Button>
      </StyledButtonsWrapper>
    </StyledContentContainer>
  )
}

export default ScreenShareSendRequestModal
