/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement, useMemo, useEffect, useRef } from 'react'
import Text from 'components/text'
import Button from 'components/button'
import { useTranslation } from 'react-i18next'
import { modalQueryParam, managedUserIdQueryParam, modalUpdateManagedUserInfoQueryParam } from 'const'
import { useDispatch, connect } from 'react-redux'
import { FormFieldValidation, FormFieldInput } from 'components/form'
import { useForm } from 'react-hook-form'
import { useQueryParam, StringParam } from 'use-query-params'
import { selectUserById, updateManagedUserProfile } from 'reducers/usersSlice'
import { CloseIcon } from 'theme'
import {
  StyledTitleContainer,
  StyledButtonsWrapper,
  StyledContentContainer,
  StyledCloseButton,
  StyledHeaderContainer,
  StyledForm,
} from './style'

const ManagedUserEnterName = ({ getUserById, onClose }): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const firstNameRef = useRef<HTMLInputElement | null>(null)
  const { register, handleSubmit: handleSumbitForm, errors } = useForm()
  const [, setModalParam] = useQueryParam(modalQueryParam, StringParam)
  const [managedUserIdParam, setManagedUserIdQueryParam] = useQueryParam(managedUserIdQueryParam, StringParam)

  const user = useMemo(() => (managedUserIdParam ? getUserById(managedUserIdParam) : null), [
    getUserById,
    managedUserIdParam,
  ])

  const handleClose = () => {
    setManagedUserIdQueryParam(null, 'replaceIn')
    onClose()
  }

  useEffect(() => {
    firstNameRef.current.focus()
  }, [])

  useEffect(() => {
    if (!managedUserIdParam) handleClose()
  })

  const handleSubmit = async ({ firstname, lastname }) => {
    await dispatch(updateManagedUserProfile({ id: user.id, firstName: firstname, lastName: lastname }))
    setModalParam(modalUpdateManagedUserInfoQueryParam, 'replaceIn')
  }

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledTitleContainer>
        <Text component="h1" mt="0" color="brandblack" font="Poppins">
          {t('EnterManagedPersonName')}
        </Text>
      </StyledTitleContainer>
      <StyledForm onSubmit={handleSumbitForm(handleSubmit)}>
        <FormFieldInput
          error={!!errors.firstname}
          maxLength={64}
          defaultValue={user?.firstName}
          name="firstname"
          label={t('FirstName')}
          placeholder={t('FirstName')}
          ref={(e) => {
            register({ required: true })(e)
            firstNameRef.current = e
          }}
        />
        {errors.firstname?.type === 'required' && (
          <FormFieldValidation mt="0.56rem" mb="1rem" error>
            {t('FormFieldValidationRequiredField')}
          </FormFieldValidation>
        )}
        <FormFieldInput
          error={!!errors.lastname}
          maxLength={64}
          defaultValue={user?.lastName}
          name="lastname"
          label={t('LastName')}
          placeholder={t('LastName')}
          ref={register({ required: true })}
        />
        {errors.lastname?.type === 'required' && (
          <FormFieldValidation mt="0.56rem" mb="1rem" error>
            {t('FormFieldValidationRequiredField')}
          </FormFieldValidation>
        )}
        <StyledButtonsWrapper>
          <Button
            type="submit"
            size="md"
            bcolor="mediumpurple"
            bop="1"
            ml="1rem"
            hgradient="mediumpurple-dim-light"
            agradient="mediumpurple-dim-dark"
          >
            <Text size="md" $secondary fw="600">
              {t('Next')}
            </Text>
          </Button>
        </StyledButtonsWrapper>
      </StyledForm>
    </StyledContentContainer>
  )
}

const mapStateToProps = (state) => ({
  getUserById: (userId: string) => selectUserById(userId)(state),
})

export default connect(mapStateToProps, null)(ManagedUserEnterName)
