import { ReactElement } from 'react'

export default (): ReactElement => (
  <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.27567 4.54746C9.24145 5.15841 9.24144 6.84159 8.27567 7.45254L2.96249 10.8137C2.06821 11.3794 1 10.5887 1 9.36112L1 2.63888C1 1.41125 2.06821 0.620617 2.96249 1.18634L8.27567 4.54746Z"
      stroke="#13BF4D"
      strokeWidth="2"
    />
  </svg>
)
