import { ReactElement } from 'react'

export default ({ $haveScheduledCall, title }: { $haveScheduledCall: boolean; title: string }): ReactElement => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path
      d="M0 8C0 3.58172 3.58172 0 8 0H32C36.4183 0 40 3.58172 40 8V32C40 36.4183 36.4183 40 32 40H8C3.58172 40 0 36.4183 0 32V8Z"
      fill="white"
      fillOpacity="0.04"
    />
    {$haveScheduledCall && <circle cx="34" cy="6" r="3" fill="#FFC700" />}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31 20C31 26.0751 26.0751 31 20 31C13.9249 31 9 26.0751 9 20C9 13.9249 13.9249 9 20 9C26.0751 9 31 13.9249 31 20ZM34 20C34 27.732 27.732 34 20 34C12.268 34 6 27.732 6 20C6 12.268 12.268 6 20 6C27.732 6 34 12.268 34 20ZM21 12C21 11.4477 20.5523 11 20 11C19.4477 11 19 11.4477 19 12V21V21.4806L19.3753 21.7809L24.3753 25.7809C24.8066 26.1259 25.4359 26.056 25.7809 25.6247C26.1259 25.1934 26.056 24.5641 25.6247 24.2191L21 20.5194V12Z"
      fill="white"
      fillOpacity="0.6"
    />
  </svg>
)
