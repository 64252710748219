/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import Button from 'components/button'
import Avatar from 'components/avatar'
import { SearchInput } from 'components/form'
import Text from 'components/text'
import { NewContactIcon, BackIcon, CloseIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import { createFilter } from 'react-search-input'
import { selectAllAuthUserContacts, ContactNormalized, createContacts } from 'reducers/contactsSlice'
import { ContactListItem, GroupListItem } from 'components/list'
import { useQueryParam, StringParam } from 'use-query-params'
import {
  modalQueryParam,
  modalCreateContactQueryParam,
  subModalQueryParam,
  groupIdQueryParam,
  callIdQueryParam,
  modalCreateGroupQueryParam,
  modalCreateCallQueryParam,
} from 'const'
import { selectAllAuthUserGroups, update as updateGroup, selectGroupById, GroupNormalized } from 'reducers/groupsSlice'
import { update as updateCall, deleteNewCalls, selectCallById, CallNormalized } from 'reducers/callsSlice'
import { connect, useDispatch } from 'react-redux'
import { ContentContainer } from 'components/container'
import { isClanzyUser } from 'utils/contacts'
import { sortContactsAndGroups, getAvatarBorColor, getAvatarBorOp } from 'utils'
import { isGroup, isNotGroup } from 'utils/groups'
import GroupAvatar from 'components/avatar/group'
import isEmail from 'validator/es/lib/isEmail'
import {
  SearchInputWrapper,
  StyledContactsControlsWrapper,
  StyledContentWrapper,
  StyledContactsWrapper,
  StyledButton,
  StyledRowsContainer,
  StyledListColumn,
  StyledListRow,
  StyledVerticalScrollbar,
  StyledHorizontalScrollbar,
  StyledHeaderContainer,
  StyledDoneButton,
  StyledAvatarWrapper,
  StyledSelectedContactsWrapper,
} from './style'

const getListTitle = (group, searchTerm, t) => {
  if (group) return t('People')
  return searchTerm ? t('FilteredResults') : t('PeopleAndGroups')
}

const { $ } = window

const AddPeopleModal: FC<{
  contacts: ContactNormalized[]
  groups: GroupNormalized[]
  getCallById: (id: string) => CallNormalized
  getGroupById: (id: string) => GroupNormalized
  onClose: () => void
}> = ({ contacts, groups, getCallById, getGroupById, onClose }): ReactElement => {
  const [groupIdParam] = useQueryParam(groupIdQueryParam, StringParam)
  const [callIdParam, setCallIdParam] = useQueryParam(callIdQueryParam, StringParam)
  const [searchInputFocused, setSearchInputFocused] = useState(false)

  const group = useMemo(() => (groupIdParam ? getGroupById(groupIdParam) : null), [getGroupById, groupIdParam])
  const call = useMemo(() => (callIdParam ? getCallById(callIdParam) : null), [getCallById, callIdParam])

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [selected, setSelected] = useState(
    [...((group || call)?.contacts || []), ...(call?.groups || [])].sort(sortContactsAndGroups),
  )

  const [hovered, setHovered] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [filtered, setFiltered] = useState([])

  const [, setModalParam] = useQueryParam(modalQueryParam, StringParam)
  const [, setSubModalParam] = useQueryParam(subModalQueryParam, StringParam)
  const [scrollTop, setScrollTop] = useState(false)

  const selectedIds = useMemo(() => selected.map(({ id }) => id), [selected])

  const focusAddPeopleSearch = useCallback(() => {
    const el = $(`.search-${callIdParam || groupIdParam} input`)?.[0]
    if (el) el.focus()
  }, [callIdParam, groupIdParam])

  useEffect(() => {
    focusAddPeopleSearch()
  }, [focusAddPeopleSearch])

  const searchUpdated = (str) => setSearchTerm(str)
  const onScroll = ({ srcElement }) => {
    if ((srcElement.scrollTop && !scrollTop) || (!srcElement.scrollTop && scrollTop)) {
      setScrollTop(!!srcElement.scrollTop)
    }
  }

  const handleBackClick = () => {
    if (groupIdParam) {
      setModalParam(modalCreateGroupQueryParam, 'replaceIn')
    } else if (callIdParam) {
      setModalParam(modalCreateCallQueryParam, 'replaceIn')
    } else {
      onClose()
    }
  }

  const handleCloseClick = () => {
    if (callIdParam) {
      if (callIdParam) dispatch(deleteNewCalls([callIdParam]))
      setCallIdParam(null, 'replaceIn')
      setModalParam(null, 'replaceIn')
    }

    onClose()
  }

  const handleNewContactClick = () => {
    setSubModalParam(modalCreateContactQueryParam, 'replaceIn')
  }

  const handleMouseEnter = ({ id }) => {
    setHovered(id)
  }

  const handleMouseLeave = () => {
    setHovered(null)
  }

  const handleSelectedAvatarClose = (selId) => {
    setSelected((prevSelected) => prevSelected.filter(({ id }) => id !== selId))

    focusAddPeopleSearch()
  }

  const handleClick = (item) => {
    setSelected((prevSelected) => {
      if (prevSelected.map(({ id }) => id).includes(item.id)) {
        return prevSelected.filter(({ id }) => id !== item.id)
      }

      setSearchTerm('')

      return [...prevSelected, item]
    })

    focusAddPeopleSearch()
  }

  const handleDone = () => {
    if (groupIdParam) {
      dispatch(updateGroup([{ id: group.id, changes: { contacts: selected } }]))
      setModalParam(modalCreateGroupQueryParam, 'replaceIn')
    } else if (callIdParam) {
      dispatch(
        updateCall([
          {
            id: call.id,
            changes: {
              contacts: selected.filter(isNotGroup),
              groups: selected.filter(isGroup),
              initialPeopleSelected: true,
            },
          },
        ]),
      )
      setModalParam(modalCreateCallQueryParam, 'replaceIn')
    } else {
      onClose()
    }
  }

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      if (searchTerm && isEmail(searchTerm) && !filtered?.find((item) => !isGroup(item) && item.email === searchTerm)) {
        const res = await dispatch(createContacts({ contacts: [{ email: searchTerm }] }))
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (res?.payload?.[0]) handleClick(res.payload[0])
      } else if (selected.length) {
        e.preventDefault()
        handleDone()
      }
    }
  }

  useEffect(() => {
    if (groupIdParam) {
      if (!group) {
        setModalParam(modalCreateGroupQueryParam, 'replaceIn')
      }
    } else if (callIdParam) {
      if (!call) {
        setModalParam(modalCreateCallQueryParam, 'replaceIn')
      }
    } else {
      setModalParam(null, 'replaceIn')
    }
  }, [call, callIdParam, dispatch, group, groupIdParam, setModalParam])

  useEffect(() => {
    if (searchTerm)
      setFiltered(
        [
          ...(contacts || []).filter(createFilter(searchTerm, ['displayName', 'email', 'phone'])),
          ...(call ? groups || [] : []).filter(
            createFilter(searchTerm, [
              'name',
              'description',
              'contacts.displayName',
              'contacts.email',
              'contacts.phone',
            ]),
          ),
        ].sort(sortContactsAndGroups),
      )
    else setFiltered([...(contacts || []), ...(call ? groups || [] : [])].sort(sortContactsAndGroups))
  }, [call, contacts, groups, searchTerm])

  return (
    <ContentContainer onKeyDown={handleKeyDown}>
      <StyledHeaderContainer component="header">
        {call && !call.initialPeopleSelected ? (
          <StyledButton circular="true" subtle="true" onClick={handleCloseClick}>
            <CloseIcon title={t('CloseThisWindow')} />
          </StyledButton>
        ) : (
          <StyledButton circular="true" subtle="true" onClick={handleBackClick}>
            <BackIcon title={t('GoBack')} />
          </StyledButton>
        )}
        <Text component="h1" mt="0" color="brandblack" font="Poppins">
          {t('ChoosePeopleToInvite')}
        </Text>
        <StyledDoneButton
          disabled={!selected.length}
          $flat={!selected.length}
          onClick={handleDone}
          type="submit"
          size="md"
          bcolor="mediumpurple"
          bop="1"
          ml="1rem"
          hgradient="mediumpurple-dim-light"
          agradient="mediumpurple-dim-dark"
        >
          <Text size="md" $secondary fw="600">
            {t('Done')}
          </Text>
        </StyledDoneButton>
      </StyledHeaderContainer>
      <StyledContentWrapper>
        <StyledContactsControlsWrapper shadow={scrollTop}>
          <SearchInputWrapper $focused={searchInputFocused}>
            <SearchInput
              className={`search-${groupIdParam || callIdParam}`}
              $secondary
              $interactive={!groupIdParam && !!callIdParam}
              onFocus={setSearchInputFocused}
              placeholder={t('ChooseFromTheListOrTypeToFilter')}
              value={searchTerm}
              onChange={searchUpdated}
            />
          </SearchInputWrapper>
          {!!selected.length && (
            <StyledSelectedContactsWrapper>
              <StyledHorizontalScrollbar options={{ overflowBehavior: { y: 'scroll' }, className: 'os-theme-light' }}>
                <StyledListRow>
                  {selected.map((item) => (
                    <StyledAvatarWrapper key={item.id}>
                      {isGroup(item) ? (
                        <GroupAvatar
                          $secondary
                          group={item}
                          onClose={handleSelectedAvatarClose}
                          name={(item as GroupNormalized).name}
                        />
                      ) : (
                        <Avatar
                          id={item.id}
                          size="lg"
                          buttonSize="xl"
                          fontSize="xxl"
                          $secondary
                          bcolor="transparent"
                          bop="0"
                          alt={t('PhotoOfContact')}
                          borcolor={getAvatarBorColor(isClanzyUser(item), true)}
                          borop={getAvatarBorOp(isClanzyUser(item), true)}
                          name={(item as ContactNormalized).displayName}
                          title={(item as ContactNormalized).firstName || (item as ContactNormalized).displayName}
                          status={callIdParam ? (item as ContactNormalized).status : null}
                          img={item.photo}
                          onClose={handleSelectedAvatarClose}
                        />
                      )}
                    </StyledAvatarWrapper>
                  ))}
                </StyledListRow>
              </StyledHorizontalScrollbar>
            </StyledSelectedContactsWrapper>
          )}
        </StyledContactsControlsWrapper>
        <StyledContactsWrapper>
          <StyledVerticalScrollbar options={{ callbacks: { onScroll }, className: 'os-theme-light' }}>
            <StyledRowsContainer>
              <Text size="lg" color="brandblack" cop="0.6" fw="600">
                {getListTitle(group, searchTerm, t)}
              </Text>
              <Button bcolor="purple" bop="0.16" onClick={handleNewContactClick}>
                <NewContactIcon $secondary title={t('NewContact')} />
                <Text ml="0.2rem" font="Inter" size="md" $secondary fw="600" color="purplehearth">
                  {t('NewContact')}
                </Text>
              </Button>
            </StyledRowsContainer>
            <StyledListColumn>
              {filtered.map((item) =>
                isGroup(item) ? (
                  <GroupListItem
                    $secondary
                    key={item.id}
                    group={item}
                    $hovered={item.id === hovered}
                    $selected={selectedIds.includes(item.id)}
                    title={`${t('Select')} ${(item as GroupNormalized).name}`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleClick}
                    $noPresence
                  />
                ) : (
                  <ContactListItem
                    $secondary
                    $hovered={item.id === hovered}
                    $selected={selectedIds.includes(item.id)}
                    key={item.id}
                    contact={item}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleClick}
                    $noPresence
                  />
                ),
              )}
            </StyledListColumn>
          </StyledVerticalScrollbar>
        </StyledContactsWrapper>
      </StyledContentWrapper>
    </ContentContainer>
  )
}

const mapStateToProps = (state) => ({
  contacts: selectAllAuthUserContacts(state),
  groups: selectAllAuthUserGroups(state),
  getCallById: (callId: string) => selectCallById(callId)(state),
  getGroupById: (groupId: string) => selectGroupById(groupId)(state),
})

export default connect(mapStateToProps, null)(AddPeopleModal)
