import styled from 'styled-components'
import { ToastContainer } from 'react-toastify-redux'

export default styled(ToastContainer).attrs({
  toastClassName: 'toast',
})`
  .toast {
    font-family: ${({ theme: { fonts } }) => fonts.Inter};
    border-radius: 0.5rem;
    font-weignt: 500;
    font-size: 1rem;
    line-height: 1.1875rem;

    &.Toastify__toast--info {
      color: ${({ theme: { fn, palette } }) => fn.color(palette.text.secondary)} !important;
      background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.shipgray)}; !important
    }

    &.Toastify__toast--error {
      color: ${({ theme: { fn, palette } }) => fn.color(palette.text.primary)} !important;
      background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.white)} !important;
    }
  }
`
