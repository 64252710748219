/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Button from 'components/button'
import Text from 'components/text'
import { getAvatarBorColor, getAvatarBorOp } from 'utils'
import Avatar from 'components/avatar'
import { CloseIcon, GuardianWarningIcon, GuardianBadgeIcon } from 'theme'
import { acceptGuardianRequest, declineGuardianRequest, setNotNotification } from 'reducers/guardianRequestsSlice'
import {
  StyledIconWrapper,
  StyledWarningWrapper,
  StyledRowsRightContainer,
  StyledHeaderContainer,
  StyledCloseButton,
  StyledContentContainer,
  StyledTextContainer,
  StyledTextWrapper,
  StyledRowsContainer,
} from './style'

export default ({ authUser, pendingGuardianRequest }): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(setNotNotification(pendingGuardianRequest))
  }

  const handleAccept = async () => {
    dispatch(acceptGuardianRequest({ user: authUser, request: pendingGuardianRequest }))
  }

  const handleDecline = async () => {
    dispatch(declineGuardianRequest(pendingGuardianRequest))
  }

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Text size="xl" mt="1rem" bcolor="brandblack" font="Poppins" fw="600" $block>
          {t('GuardianPermissionRequest')}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledRowsContainer>
        <Avatar
          size="xl"
          fontSize="xxxl"
          name={pendingGuardianRequest.from.displayName}
          alt={t('PhotoDepictingGuardianUser')}
          img={pendingGuardianRequest.from.photo}
          borcolor={getAvatarBorColor(true, true)}
          borop={getAvatarBorOp(true, true)}
        />
        <GuardianBadgeIcon title={t('ShieldIconDepictingAGuardian')} />
        <StyledTextWrapper>
          <Text size="md" fw="600" color="brandblack" mt="0.3rem">
            {pendingGuardianRequest.from.displayName}
          </Text>
          <Text fw="500" size="xs" color="brandblack" cop="0.6" mt="0.3rem">
            {pendingGuardianRequest.from.email}
          </Text>
        </StyledTextWrapper>
      </StyledRowsContainer>
      <StyledTextContainer>
        <Text size="lg" mt="1.5rem" gradient="purple">
          {t('ClanzyUser')}&nbsp;
          {pendingGuardianRequest.from.displayName}&nbsp;
          {t('IsRequestingPermissionToBecomeGuardianOfYourClanzyAccount')}
        </Text>
      </StyledTextContainer>
      <StyledWarningWrapper>
        <StyledIconWrapper>
          <GuardianWarningIcon title={t('WarningIcon')} />
        </StyledIconWrapper>
        <Text mt="0.4rem" ml="1rem" mr="1rem" fw="500" color="tallpoppy">
          {t('GuardianPermissionRequestWarningText')}
        </Text>
      </StyledWarningWrapper>
      <StyledRowsRightContainer>
        <Button
          bophov="1"
          bopact="1"
          hgradient="tallpoppy-dim-light"
          agradient="tallpoppy-dim-dark"
          size="md"
          bcolor="tallpoppy"
          bop="1"
          mr="1rem"
          onClick={handleDecline}
        >
          <Text fw="600" size="md" $secondary>
            {t('Decline')}
          </Text>
        </Button>
        <Button
          bophov="1"
          bopact="1"
          hgradient="purple-dim-light"
          agradient="purple-dim-dark"
          size="md"
          bcolor="purple"
          bop="1"
          mr="1rem"
          onClick={handleAccept}
        >
          <Text fw="600" size="md" $secondary>
            {t('Accept')}
          </Text>
        </Button>
      </StyledRowsRightContainer>
    </StyledContentContainer>
  )
}
