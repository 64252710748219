/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isClanzyGroup, getContactsAvatarsInfo, getContactsCount, isOnline } from 'utils/groups'
import { CloseIcon } from 'theme'
import { useTranslation } from 'react-i18next'
import { ContactsStatuses } from 'apis'
import { getAvatarBorColor, getAvatarBorOp } from 'utils'
import Avatar from '..'
import Presence from '../presence'
import {
  StyledAvatarsWrapper,
  StyledText,
  StyledCloseButton,
  GroupAvatarProps,
  Avatar1Wrapper,
  Avatar2Wrapper,
  GroupMultipleAvatarWrapper,
} from './style'

export default ({ $noPresence, bcolor, bcolorhov, group, $hovered, $secondary, onClose, name }: GroupAvatarProps) => {
  const isGroupClanzyUsers = isClanzyGroup(group)
  const contactsCount = getContactsCount(group)
  const { t } = useTranslation()

  const handleClose = onClose ? () => onClose(group.id) : null

  if (group.photo || contactsCount === 1) {
    return (
      <Avatar
        size="lg"
        buttonSize="xl"
        $secondary={$secondary}
        borcolor={getAvatarBorColor(isGroupClanzyUsers, $secondary)}
        borop={getAvatarBorOp(isGroupClanzyUsers, $secondary)}
        name={group.contacts[0].displayName}
        alt={t('GroupPhoto')}
        img={group.photo || group.contacts[0].photo}
        title={name}
        status={isOnline(group) ? ContactsStatuses.online : ContactsStatuses.offline}
        onClose={handleClose}
        $noPresence={$noPresence}
      />
    )
  }

  const avatarsInfo = getContactsAvatarsInfo(group)
  if (!avatarsInfo || avatarsInfo.length !== 2) return null

  const avatar1 = (
    <Avatar1Wrapper>
      <Avatar
        size="md"
        buttonSize="md"
        $secondary={$secondary}
        alt={t('PhotoOfContact')}
        borcolor={getAvatarBorColor(avatarsInfo[1].isClanzy, $secondary)}
        borop={getAvatarBorOp(avatarsInfo[1].isClanzy, $secondary)}
        name={avatarsInfo[1].displayName}
        img={avatarsInfo[1].photo}
      />
    </Avatar1Wrapper>
  )

  const avatar2 = (
    <Avatar2Wrapper bcolor={bcolor} bcolorhov={bcolorhov} name={name} $secondary={$secondary} $hovered={$hovered}>
      <Avatar
        size="md"
        buttonSize="md"
        alt={t('PhotoOfContact')}
        $secondary={$secondary}
        fontSize="lg"
        bcolor="white"
        bop="0.05"
        borcolor={getAvatarBorColor(avatarsInfo[0].isClanzy, $secondary)}
        borop={getAvatarBorOp(avatarsInfo[0].isClanzy, $secondary)}
        name={avatarsInfo[0].displayName}
        img={avatarsInfo[0].photo}
      />
    </Avatar2Wrapper>
  )

  return (
    <GroupMultipleAvatarWrapper name={name}>
      <StyledAvatarsWrapper>
        {avatar1}
        {avatar2}
        {onClose && (
          <>
            <StyledCloseButton circular="true" borcolor="white" bcolor="black" bop="1" $flat onClick={handleClose}>
              <CloseIcon $secondary />
            </StyledCloseButton>
          </>
        )}
        {isOnline(group) && !$noPresence && <Presence $secondary={$secondary} />}
      </StyledAvatarsWrapper>
      {name && (
        <StyledText mt="0.5rem" $block $ellipsis>
          {name}
        </StyledText>
      )}
    </GroupMultipleAvatarWrapper>
  )
}

export * from './style'
