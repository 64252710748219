/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import Button from 'components/button'
import Text from 'components/text'
import { ColumnsContainer, ContentContainer, RowsContainer } from 'components/container'

export const StyledImageWrapper = styled.div`
  padding-top: 2rem;
  position: relative;
  bottom: -0.25rem;
`

export const StyledTextContainer = styled(ColumnsContainer)`
  text-align: center;
  position: relative;
  padding: 0 1.8rem;
`

export const StyledContentContainer = styled(ContentContainer)`
  margin: 1.125rem 0 2rem 0;
`

export const StyledHeaderContainer = styled(RowsContainer)`
  height: 2.8125rem;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
`

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

export const StyledContentWrapper = styled(RowsContainer)`
  margin-top: 2rem;
  padding: 2rem 0 0 0;
  width: 100%;
  text-align: center;
  background: ${({ theme: { fn, palette } }) => fn.color(palette.background.black, '0.1')};
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
`

export const StyledIconWrapper = styled.div`
  margin-right: 1rem;
`

export const StyledCallTimeInfoContainer = styled.div`
  margin-top: 1rem;
`

export const StyledOptionsWrapper = styled(ColumnsContainer)`
  box-sizing: border-box;
  width: 100%;
  padding: 2rem 1.625rem;
  align-items: flex-start;
`

export const StyledText = styled(Text)`
  padding: 0 2rem;
`
