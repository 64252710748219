/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createAsyncThunk, createEntityAdapter, createSlice, createSelector, EntityState } from '@reduxjs/toolkit'
import { error } from 'react-toastify-redux'
import {
  apiSignIn,
  apiSignOut,
  apiSignUp,
  apiForgottenPassword,
  apiResetPassword,
  apiSetPassword,
  apiSignUpAnon,
  apiUpdateUser,
  apiUpdateManagedUser,
  apiRemoveManagedUser,
  apiUpdatePassword,
  ApiUpdateUser,
  ApiUpdateManagedUser,
  ApiUpdatePassword,
  ApiSignIn,
  ApiSignUp,
  ApiForgottenPassword,
  ApiResetPassword,
  ApiSetPassword,
  ApiSignUpAnon,
  ApiGetUser,
  ApiCreateManagedUser,
  apiCreateManagedUser,
  apiCreateManagedUserError,
  ApiUpdateFlags,
  apiGetUser,
  apiGetAnonUser,
  apiUpdateFlags,
  UserData,
  apiGetAllManagedUsers,
  apiGetManagedUser,
  apiRemoveGuardian,
} from 'apis'
import { setToLS } from 'utils/storage'
import { getNormalizedFlag, normalizeFlags } from 'utils/users'

const isNew = Symbol.for('isNew')
const isAuth = Symbol.for('isAuth')
const isAnon = Symbol.for('isAnon')
const isManaged = Symbol.for('isManaged')

interface User {
  id: string
  username: string
  displayName: string
  photo?: string
  firstName?: string
  lastName?: string
  flags?: string[]
  guardianId?: string
}

export interface UserNormalized extends User {
  [isNew]?: boolean
  [isAuth]?: boolean
  [isAnon]?: boolean
  [isManaged]?: boolean
}

export const flags = {
  contactsImported: 'contactsImported',
  welcomeTourShown: 'welcomeTourShown',
  allowWebNotificationsPromptShown: 'allowWebNotificationsPromptShown',
  importContactsBannerClosed: 'importContactsBannerClosed',
}

interface UsersState {
  users: EntityState<UserNormalized>
}

const usersAdapter = createEntityAdapter()
const initialState = usersAdapter.getInitialState({
  managedUsersLoading: 'idle',
})

export const usersUpdated = (state, action) => {
  if (!action.payload) return
  ;(Array.isArray(action.payload) ? action.payload : [action.payload]).forEach(({ id, changes, ...rest }) => {
    if (state.ids.includes(id)) {
      state.entities[id] = {
        ...state.entities[id],
        ...(changes || rest),
      }
    }
  })
}

const normalizeAuthUsers = (users: UserData[]) => {
  return users.map(({ flags, ...rest }) => ({
    ...rest,
    ...normalizeFlags(flags),
    [isNew]: false,
    [isAuth]: true,
    [isAnon]: false,
    [isManaged]: false,
  }))
}

const normalizeAnonUsers = (users: UserData[]) => {
  return users.map(({ flags, ...rest }) => ({
    ...rest,
    ...normalizeFlags(flags),
    [isNew]: false,
    [isAuth]: false,
    [isAnon]: true,
    [isManaged]: false,
  }))
}

const normalizeManagedUsers = (users: UserData[]) => {
  return users.map(({ flags, ...rest }) => ({
    ...rest,
    ...normalizeFlags(flags),
    [isNew]: false,
    [isAuth]: false,
    [isAnon]: false,
    [isManaged]: true,
  }))
}

export const updateProfile = createAsyncThunk(
  'users/update',
  async (userData: ApiUpdateUser, { rejectWithValue, dispatch }) => {
    try {
      return await apiUpdateUser(userData)
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null)
    }
  },
)

export const updateManagedUserProfile = createAsyncThunk(
  'users/updateManagedUser',
  async (userData: ApiUpdateManagedUser, { rejectWithValue, dispatch }) => {
    try {
      return await apiUpdateManagedUser(userData)
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null)
    }
  },
)

export const removeManagedUser = createAsyncThunk(
  'users/removeManagedUser',
  async (userId: string, { rejectWithValue, dispatch }) => {
    try {
      return await apiRemoveManagedUser(userId)
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null)
    }
  },
)

export const updatePassword = createAsyncThunk(
  'users/updatePassword',
  async (userData: ApiUpdatePassword, { rejectWithValue, dispatch }) => {
    try {
      return await apiUpdatePassword(userData)
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null)
    }
  },
)

export const signInUser = createAsyncThunk(
  'users/signIn',
  async (userData: ApiSignIn, { rejectWithValue, dispatch }) => {
    try {
      const data = await apiSignIn(userData)
      dispatch({ type: 'server/auth', payload: { token: data.token } })
      return data
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(null)
    }
  },
)

export const signOutUser = createAsyncThunk('users/signOut', async (_, { rejectWithValue }) => {
  try {
    return await apiSignOut()
  } catch (e) {
    return rejectWithValue(null)
  }
})

export const signUpUser = createAsyncThunk(
  'users/signUp',
  async (userData: ApiSignUp, { rejectWithValue, dispatch }) => {
    try {
      const data = await apiSignUp(userData)
      dispatch({ type: 'server/auth', payload: { token: data.token } })
      return data
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(e)
    }
  },
)

export const forgottenPassword = createAsyncThunk(
  'users/forgottenPassword',
  async (userData: ApiForgottenPassword, { rejectWithValue, dispatch }) => {
    try {
      return await apiForgottenPassword(userData)
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(e)
    }
  },
)

export const resetPassword = createAsyncThunk(
  'users/resetPassword',
  async (userData: ApiResetPassword, { rejectWithValue, dispatch }) => {
    try {
      return await apiResetPassword(userData)
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(e)
    }
  },
)

export const setPassword = createAsyncThunk(
  'users/setPassword',
  async (userData: ApiSetPassword, { rejectWithValue, dispatch }) => {
    try {
      return await apiSetPassword(userData)
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(e)
    }
  },
)

export const signUpAnonUser = createAsyncThunk(
  'users/signUpAnon',
  async (userData: ApiSignUpAnon, { rejectWithValue, dispatch }) => {
    try {
      const data = await apiSignUpAnon(userData)
      dispatch({ type: 'server/auth', payload: { token: data.token } })
      return data
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(e)
    }
  },
)

export const updateFlags = createAsyncThunk(
  'users/updateFlags',
  async ({ id: userId, flags }: ApiUpdateFlags, { rejectWithValue, dispatch }) => {
    try {
      const user = await apiUpdateFlags({ id: userId, flags })
      return user
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(e)
    }
  },
)

export const getUser = createAsyncThunk(
  'users/getUser',
  async ({ id: userId }: ApiGetUser, { rejectWithValue, dispatch }) => {
    try {
      const user = await apiGetUser(userId)
      return user
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(e)
    }
  },
)

export const getAllManagedUsers = createAsyncThunk(
  'users/getAllManagedUsers',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const users = await apiGetAllManagedUsers()
      return users
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(e)
    }
  },
)

export const getManagedUser = createAsyncThunk(
  'users/getManagedUser',
  async ({ id: userId }: ApiGetUser, { rejectWithValue, dispatch }) => {
    try {
      const user = await apiGetManagedUser(userId)
      return user
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(e)
    }
  },
)

export const getAnonUser = createAsyncThunk('users/getAnonUser', async (_, { rejectWithValue, dispatch }) => {
  try {
    const user = await apiGetAnonUser()
    return user
  } catch (e) {
    dispatch(error(e.message))
    return rejectWithValue(e)
  }
})

export const createManagedUser = createAsyncThunk(
  'users/createManagedUser',
  async (userData: ApiCreateManagedUser, { rejectWithValue, dispatch }) => {
    try {
      const data = await apiCreateManagedUser(userData)
      return data
    } catch (e) {
      if (e?.message === apiCreateManagedUserError) return rejectWithValue(400)
      dispatch(error(e.message))
      return rejectWithValue(e)
    }
  },
)

const updateUsersFlags = (state, action) => {
  const { id, flags } = action.payload
  if (state.ids.includes(id)) {
    const user = state.entities[id]
    if (flags.add) {
      flags.add.forEach((flag: string) => {
        user[getNormalizedFlag(flag)] = true
      })
    }
    if (flags.remove) {
      flags.remove.forEach((flag: string) => {
        delete user[getNormalizedFlag(flag)]
      })
    }
  }
}

const userLoaded = (state, action) => usersAdapter.setAll(state, normalizeAuthUsers([action.payload]))
const anonUserLoaded = (state, action) => usersAdapter.setAll(state, normalizeAnonUsers([action.payload]))
const managedUsersLoaded = (state, action) => {
  usersAdapter.addMany(state, normalizeManagedUsers(action.payload))
  state.managedUserLoaded = 'finished'
}
const managedUserLoaded = (state, action) => usersAdapter.addMany(state, normalizeManagedUsers([action.payload]))
const userSignedIn = (state, action) => {
  userLoaded(state, { payload: action.payload.user })
  setToLS('auth', { [action.payload.user.id]: { token: action.payload.token, isAnon: false } })
}
const anonUserSignedIn = (state, action) => {
  anonUserLoaded(state, { payload: action.payload.user })
  setToLS('auth', { [action.payload.user.id]: { token: action.payload.token, isAnon: true } })
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    upsert: {
      reducer: usersAdapter.upsertMany,
      prepare: (users: UserNormalized[]) => {
        return { payload: users.map((user) => ({ ...user, [isNew]: true })) }
      },
    },
    setAuthenticated: (state, action) => {
      const id = action.payload
      if (state.ids.includes(id)) state.entities[id][isAuth] = true
    },
    flagsUpdated: updateUsersFlags,
    updated: usersUpdated,
    managedUserRemoved: (state, action) => {
      usersAdapter.removeOne(state, action.payload.id)
    },
    deleteAll: usersAdapter.removeAll,
  },
  extraReducers: {
    [getAllManagedUsers.pending.toString()]: (state) => {
      state.managedUsersLoading = 'loading'
    },
    [updateFlags.pending.toString()]: (state, action) => updateUsersFlags(state, { payload: action.meta.arg }),
    [updateProfile.fulfilled.toString()]: usersUpdated,
    [updateManagedUserProfile.fulfilled.toString()]: usersUpdated,
    [updateFlags.fulfilled.toString()]: usersUpdated,
    [getUser.fulfilled.toString()]: userLoaded,
    [getAnonUser.fulfilled.toString()]: anonUserLoaded,
    [signInUser.fulfilled.toString()]: userSignedIn,
    [signUpUser.fulfilled.toString()]: userSignedIn,
    [signUpAnonUser.fulfilled.toString()]: anonUserSignedIn,
    [getAllManagedUsers.fulfilled.toString()]: managedUsersLoaded,
    [getManagedUser.fulfilled.toString()]: managedUserLoaded,
    [createManagedUser.fulfilled.toString()]: (state, action) => {
      usersAdapter.addOne(state, { ...action.payload, [isManaged]: true })
    },
    [removeManagedUser.fulfilled.toString()]: (state, action) => {
      usersAdapter.removeOne(state, action.meta.arg)
    },
    [getAllManagedUsers.rejected.toString()]: (state) => {
      state.managedUsersLoading = 'finished'
    },
  },
})

export const { deleteAll, updated, upsert, setAuthenticated, flagsUpdated, managedUserRemoved } = usersSlice.actions

export const removeGuardian = createAsyncThunk(
  'users/removeGuardian',
  async (user: UserNormalized, { rejectWithValue, dispatch }) => {
    try {
      const data = await apiRemoveGuardian()
      dispatch(updated([{ id: user.id, guardianId: null }]))
      return data
    } catch (e) {
      dispatch(error(e.message))
      return rejectWithValue(e)
    }
  },
)

const selectSelf = (state) => state
const usersSelectors = usersAdapter.getSelectors((state: UsersState) => state.users)
export const selectAllUsers = (state: UsersState): UserNormalized[] =>
  usersSelectors.selectAll(state) as UserNormalized[]
export const selectUserById = (userId: string) =>
  createSelector(selectAllUsers, (users) => users.find((user) => user.id === userId))
export const selectAuthUser = createSelector(selectAllUsers, (users) => users.find((user) => user[isAuth]))
export const selectAnonUser = createSelector(selectAllUsers, (users) => users.find((user) => user[isAnon]))
export const selectManagedUsers = createSelector(selectAllUsers, (users) => users.filter((user) => user[isManaged]))
export const selectUser = createSelector(selectAllUsers, (users) => (users.length ? users[0] : null))
export const selectAuthUserHasFlag = (flag: string) =>
  createSelector(selectAuthUser, (user) => !!user?.[getNormalizedFlag(flag)])

export const selectAreManagedUsersLoading = createSelector(
  selectSelf,
  (state) => state.users.managedUsersLoading === 'loading',
)
export const selectAreManagedUsersLoaded = createSelector(
  selectSelf,
  (state) => state.users.managedUsersLoading === 'finished',
)

export default usersSlice.reducer
