import { ReactElement, FC, useState } from 'react'
import { ColumnsContainer } from 'components/container'
import Text from 'components/text'
import { useTranslation } from 'react-i18next'
import { ScheduleIcon } from 'theme'
import moment from 'moment'
import SetDateAndTimeModal from './set-date-and-time-modal'
import {
  StyledIconWrapper,
  StyledFormFieldSchedule,
  StyledFormFieldScheduleWrapper,
  FormFieldScheduleProps,
  StyledButton,
} from './style'

const FormFieldSchedule: FC<FormFieldScheduleProps> = ({
  bcolor,
  bop,
  placeholder,
  name,
  title,
  value,
  p,
  onChange,
  ...rest
}): ReactElement => {
  const { t } = useTranslation()
  const [focused, setFocused] = useState(false)
  const [dateAndTimeVisible, setDateAndTimeVisible] = useState(false)

  const handleClick = () => setDateAndTimeVisible(true)
  const onClose = () => setDateAndTimeVisible(false)
  const onFocus = () => setFocused(true)
  const onBlur = () => setFocused(false)

  return (
    <>
      <ColumnsContainer {...rest}>
        <StyledFormFieldScheduleWrapper
          mt="1rem"
          onMouseEnter={onFocus}
          onMouseLeave={onBlur}
          $focused={focused}
          bcolor={bcolor}
          bop={bop}
        >
          <StyledIconWrapper>
            <ScheduleIcon title={title} />
          </StyledIconWrapper>
          <StyledFormFieldSchedule
            name={name}
            onMouseEnter={onFocus}
            placeholder={placeholder}
            type="text"
            value={
              value
                ? ((typeof value === 'string' ? moment(value) : value).format('MMMM Do YYYY, h:mm a') as string)
                : ''
            }
            readOnly
            p={p}
          />
          <StyledButton size="sm" bcolor="purple" onMouseEnter={onFocus} onClick={handleClick}>
            <Text color="purplehearth" size="md" fw="600">
              {t('Change')}
            </Text>
          </StyledButton>
        </StyledFormFieldScheduleWrapper>
      </ColumnsContainer>
      {dateAndTimeVisible && (
        <SetDateAndTimeModal
          value={typeof value === 'string' ? moment(value) : value}
          onClose={onClose}
          onChange={onChange}
        />
      )}
    </>
  )
}

export default FormFieldSchedule
