import styled, { css } from 'styled-components'

export interface PresenceProps {
  /**
   * Variant
   */
  $secondary?: boolean
}

export default styled.div`
  width: 0.75rem;
  height: 0.75rem;
  background: ${({ theme: { fn, palette } }) => fn.color(palette.background.malachite)};
  border: 0.1875rem solid ${({ theme: { fn, colors } }) => fn.color(colors.woodsmoke)};
  border-radius: 1rem;

  position: absolute;
  bottom: 0.15rem;
  right: 0.15rem;

  ${(props: PresenceProps) =>
    props.$secondary &&
    css`
      border: 0.1875rem solid ${({ theme: { fn, colors } }) => fn.color(colors.white)};
    `}
`
