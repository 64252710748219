import styled, { css } from 'styled-components'

export interface ListProps {
  /**
   * Flex direction column
   */
  column?: string
  /**
   * Margin top
   */
  mt?: string
}

export default styled(({ children, component, ...rest }) => {
  switch (component) {
    case 'nav':
      return <nav {...rest}>{children}</nav>
    default:
      return <section {...rest}>{children}</section>
  }
})<ListProps>`
  outline: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;

  ${(props: ListProps) =>
    !!props.column &&
    css`
      flex-direction: column;
    `}

  ${(props: ListProps) =>
    !!props.mt &&
    css`
      margin-top: ${props.mt};
    `}
`
