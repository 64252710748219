import { ReactElement, FC, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { CallNormalized, setCallNotCurrent } from 'reducers/callsSlice'
import { CloseIcon } from 'theme'
import Button from 'components/button'
import Text from 'components/text'
import Avatar from 'components/avatar'
import { useDispatch } from 'react-redux'
import { getHost, formatCallTimeFromNow } from 'utils/calls'
import { ColumnsContainer } from 'components/container'
import {
  StyledRowsLeftContainer,
  StyledRowsRightContainer,
  StyledHeaderContainer,
  StyledCloseButton,
  StyledContentContainer,
} from './style'

const { $ } = window

// On call missed notification modal
const InviteeCallMissed: FC<{ call: CallNormalized }> = ({ call }): ReactElement => {
  const { t } = useTranslation()
  const host = getHost(call)
  const dispatch = useDispatch()
  const [missedTimeTitle, setMissedTimeTitle] = useState(formatCallTimeFromNow(call))
  const [updateTimeout, setUpdateTimeout] = useState(null)

  const setCallAnswerButtonFocus = useCallback(() => {
    if (call) {
      const el = $(`.missed-call-invitation-close-button-${call.id}`)?.[0]
      if (el) el.focus()
    }
  }, [call])

  useEffect(() => {
    if (call) {
      setCallAnswerButtonFocus()
    }
  }, [call, setCallAnswerButtonFocus])

  useEffect(() => {
    if (!updateTimeout) {
      setUpdateTimeout(
        setTimeout(() => {
          setMissedTimeTitle(formatCallTimeFromNow(call))
        }, 10000),
      )
    }

    return () => {
      if (updateTimeout) {
        clearTimeout(updateTimeout)
        setUpdateTimeout(null)
      }
    }
  }, [call, updateTimeout])

  const handleClose = () => {
    dispatch(setCallNotCurrent(call.id))
  }

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <Text size="xl" mt="1rem" bcolor="brandblack" font="Poppins" fw="600" $block>
          {t('MissedVideoCall')}
        </Text>
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledRowsLeftContainer>
        <Avatar
          size="lg"
          fontSize="xxl"
          name={host.displayName}
          alt={t('PhotoOfContact')}
          $secondary
          img={host.photo}
        />
        <ColumnsContainer ml="1rem">
          <Text size="md" color="brandblack" fw="600">
            {t('YouMissedACallFrom')}&nbsp;{host.displayName}
          </Text>
          <Text mt="0.2rem" size="xs" color="brandblack" cop="0.6" fw="400">
            {missedTimeTitle}
          </Text>
        </ColumnsContainer>
      </StyledRowsLeftContainer>
      <StyledRowsRightContainer>
        <Button
          size="sm"
          subtle="true"
          onClick={handleClose}
          className={`missed-call-invitation-close-button-${call.id}`}
        >
          <Text size="md" color="purplehearth" fw="600">
            {t('Close')}
          </Text>
        </Button>
        {/* <Button
          size="sm"
          bcolor="mediumpurple"
          bop="1"
          ml="1rem"
          hgradient="mediumpurple-dim-light"
          agradient="mediumpurple-dim-dark"
        >
          <Text size="md" $secondary fw="600">
            {t('CallBack')}
          </Text>
        </Button> */}
      </StyledRowsRightContainer>
    </StyledContentContainer>
  )
}

export default InviteeCallMissed
