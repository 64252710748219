import styled from 'styled-components'
import { ColumnsContainer } from 'components/container'
import Button from 'components/button'

export const StyledColumnsContainer = styled(ColumnsContainer)`
  margin-bottom: 1.5rem;
  width: 100%;
  align-items: center;
  border: 0.0625rem dashed ${({ theme }) => theme.fn.color(theme.colors.black, '0.2')};
`

export const StyledButton = styled(Button)`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`

export const StyledTextWrapper = styled.div`
  width: 70%;
  text-align: center;
  margin-bottom: 3rem;

  ${({ theme }) => theme.fn.down(theme.breakpoints.md)} {
    width: 90%;
  }
`
