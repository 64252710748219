import styled, { css } from 'styled-components'
import { Moment } from 'moment'
import Button from 'components/button'

export interface FormFieldScheduleProps {
  /**
   * The title of the icon
   */
  title?: string
  /**
   * The name of the field
   */
  name?: string
  /**
   * The value of the field
   */
  value?: Moment | string
  /**
   * The for field placeholder
   */
  placeholder?: string
  /**
   * Class name
   */
  className?: string
  /**
   * Margin top
   */
  mt?: string
  /**
   * Background color
   */
  bcolor?: string
  /**
   * Background opacity
   */
  bop?: string
  /**
   * Padding
   */
  p?: string
  /**
   * Is focused
   */
  $focused?: boolean
  /**
   * On change
   */
  onChange?: (scheduledTime: Moment) => void
}

export const StyledFormFieldSchedule = styled.input.attrs(() => ({
  tabIndex: 0,
}))`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transform: none;
  font-family: ${({ theme }) => theme.fonts.Inter};
  color: ${({ theme: { fn, palette } }) => fn.color(palette.text.primary)};
  font-size: 1.125rem;
  display: block;
  outline: none;
  display: inline-block;
  border: none;
  padding: 1rem;
  margin: 0.3rem 0;
  border-radius: 0.375rem;
  font-weight: 600;

  ${(props: FormFieldScheduleProps) =>
    !!props.p &&
    css`
      padding: ${props.p};
    `}

  ::-webkit-input-placeholder {
    /* Edge */
    color: ${({ theme: { fn, palette } }) => fn.color(palette.text.primary)};
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme: { fn, palette } }) => fn.color(palette.text.primary)};
  }

  ::placeholder {
    color: ${({ theme: { fn, palette } }) => fn.color(palette.text.primary)};
  }
`

export const StyledFormFieldScheduleWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 0.375rem;
  border: 2px solid ${({ theme: { fn, colors } }) => fn.color(colors.black, '0.2')};
  background-color: transparent;

  ${(props: FormFieldScheduleProps) =>
    !!props.mt &&
    css`
      margin-top: ${props.mt};
    `}

  ${(props: FormFieldScheduleProps) =>
    props.$focused &&
    css`
      transition: none;
      border-color: ${({ theme: { fn, colors } }) => fn.color(colors.brandblack)};
    `}

  ${(props: FormFieldScheduleProps) =>
    !!props.bcolor &&
    css`
      background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background[props.bcolor], props.bop || '1')};
    `}
`

export const StyledIconWrapper = styled.div`
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledButton = styled(Button)`
  position: absolute;
  right: 1rem;
`
