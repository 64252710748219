/* eslint-disable @typescript-eslint/no-explicit-any */
import { ArrowUpIcon, ArrowDownIcon } from 'theme'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Calendar from 'react-calendar'
import moment from 'moment'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import {
  StyledPopperWrapper,
  StyledDatePickerWrapper,
  StyledWrapper,
  FormFieldDateProps,
  StyledFormFieldLabel,
  StyledFormField,
  StyledInputWrapper,
  StyledIconDropdownWrapper,
} from './style'

export default ({ minValue, name, value = new Date(), onChange, ...rest }: FormFieldDateProps): React.ReactElement => {
  const { t } = useTranslation()
  const anchorEl = useRef(null)
  const [openDropdown, setOpenDropdown] = useState(false)

  const handleClickDropdown = (e) => {
    e.preventDefault()
    setOpenDropdown(!openDropdown)
    return false
  }

  const handleClickAway = (e) => {
    e.preventDefault()
    if (openDropdown) setOpenDropdown(false)
  }

  const handleChange = (val) => {
    onChange(val)
    setOpenDropdown(false)
  }

  return (
    <StyledWrapper ref={anchorEl} {...rest}>
      <StyledFormFieldLabel htmlFor={name}>{t('Date')}</StyledFormFieldLabel>
      <StyledInputWrapper>
        <StyledFormField value={moment(value).format('DD / MMM / YYYY')} name={name} readOnly />
        <StyledIconDropdownWrapper onClick={handleClickDropdown}>
          {openDropdown ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </StyledIconDropdownWrapper>
      </StyledInputWrapper>
      {openDropdown && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <StyledDatePickerWrapper>
            <StyledPopperWrapper placement="bottom-start" anchorEl={anchorEl.current} open={openDropdown}>
              <Calendar onChange={handleChange} minDate={minValue} value={value} />
            </StyledPopperWrapper>
          </StyledDatePickerWrapper>
        </ClickAwayListener>
      )}
    </StyledWrapper>
  )
}
