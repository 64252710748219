import styled, { css } from 'styled-components'

export interface CardProps {
  /**
   * Variant
   */
  secondary?: string
  /**
   * Size
   */
  size?: string
  /**
   * Margin top
   */
  mt?: string
}

export default styled.section<CardProps>`
  position: relative;
  outline: none;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.white, '0.05')};
  border-radius: 1.5rem;

  ${(props: CardProps) =>
    !!props.size &&
    css`
      width: ${({ theme }) => theme.sizes[props.size]};
      max-width: ${({ theme }) => theme.sizes[props.size]};
    `}
  }

  ${(props: CardProps) =>
    !!props.mt &&
    css`
      margin-top: ${props.mt}};
    `}

  ${(props: CardProps) =>
    !!props.secondary &&
    css`
      background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.white)};
    `}

  ${({ theme }) => theme.fn.down(theme.breakpoints.md)} {
    width: 100%;
    max-width: 100%;
  }
`
