/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import Card from 'components/card'
import { ContentContainer, ViewContainer, ColumnsContainer } from 'components/container'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

export const StyledOverlayScrollbarsComponent = styled(OverlayScrollbarsComponent).attrs((props) => ({
  ...props,
  resize: 'vertical',
}))`
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
`

export const StyledModal = styled(ViewContainer)`
  z-index: 1000;
  position: fixed;
  width: 100%;
  height: 100vh;
  justify-content: center;
  background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.black, '0.8')};
`

export const StyledColumnsContainer = styled(ColumnsContainer)`
  width: 100%;
  align-items: center;
`

export const StyledContentContainer = styled(ContentContainer)`
  min-height: 100%;
  justify-content: center;
`

export const StyledCard = styled(Card)`
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: ${({ theme: { shadows } }) => shadows.box};
`
