import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <circle cx="24" cy="24" r="24" fill="white" fillOpacity="0.38" />
    <circle cx="33" cy="24" r="2.5" transform="rotate(90 33 24)" fill="white" />
    <circle cx="24.5" cy="24" r="2.5" transform="rotate(90 24.5 24)" fill="white" />
    <circle cx="16" cy="24" r="2.5" transform="rotate(90 16 24)" fill="white" />
  </svg>
)
