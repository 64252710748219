import { GroupNormalized } from 'reducers/groupsSlice'
import styled, { css } from 'styled-components'
import Button from 'components/button'
import Text from 'components/text'

export interface GroupAvatarProps {
  /**
   * Group
   */
  group?: GroupNormalized
  /**
   * Is hovered
   */
  $hovered?: boolean
  /**
   * Variant
   */
  $secondary?: boolean
  /**
   * Hide presence
   */
  $noPresence?: boolean
  /**
   * Name
   */
  name?: string
  /**
   * On close handler
   */
  onClose?: (id: string) => void
  /**
   * Background color
   */
  bcolor?: string
  /**
   * Background color on hover
   */
  bcolorhov?: string
}

export const GroupMultipleAvatarWrapper = styled.div`
  position: relative;
  height: 4.5rem;
  width: 4.5rem;
  max-width: 4.5rem;

  ${(props: GroupAvatarProps) =>
    !!props.name &&
    css`
      height: 6rem;
    `}
`

export const Avatar1Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 2.5rem;

  & span {
    font-size: 1rem;
    line-height: 1.1875rem;
  }
`

export const Avatar2Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 2.5rem;
  background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.brandblack)};
  border: 0.1875rem solid ${({ theme: { fn, colors } }) => fn.color(colors.white, '0.05')};

  & span {
    font-size: 1rem;
    line-height: 1.1875rem;
  }

  ${(props: GroupAvatarProps) =>
    props.$secondary &&
    css`
      background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.white)};
      border: 0.1875rem solid ${({ theme: { fn, colors } }) => fn.color(colors.white)};
    `}

  ${(props: GroupAvatarProps) =>
    props.bcolor &&
    css`
      background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background[props.bcolor])};
      border: 0.1875rem solid ${({ theme: { fn, colors } }) => fn.color(colors[props.bcolor])};
    `}

  ${(props: GroupAvatarProps) =>
    !props.bcolorhov &&
    props.$hovered &&
    css`
      background-color: ${({ theme: { fn, palette } }) =>
        props.$secondary ? fn.color(palette.background.mercurylight) : fn.color(palette.background.charade)};
      border: 0.1875rem solid
        ${({ theme: { fn, colors } }) =>
          props.$secondary ? fn.color(colors.mercurylight) : fn.color(colors.white, '0.04')};
    `}

  ${(props: GroupAvatarProps) =>
    !!props.bcolorhov &&
    props.$hovered &&
    css`
      background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background[props.bcolorhov])};
      border: 0.1875rem solid
        ${({ theme: { fn, colors } }) =>
          props.$secondary ? fn.color(colors.mercurylight) : fn.color(colors.white, '0.04')};
    `}
`

export const StyledCloseButton = styled(Button)`
  padding: 0.3rem;
  position: absolute;
  right: -0.5rem;
  top: -0.5rem;
`

export const StyledText = styled(Text)`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
`

export const StyledAvatarsWrapper = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  height: 4.5rem;
`
