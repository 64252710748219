import { FC, ReactElement, useEffect, useState } from 'react'
import Logo from 'components/logo'
import {
  dashboardHomePath,
  dashboardScheduledCallsPath,
  dashboardProfilePath,
  dashboardEntertainmentPath,
  dashboardGuardianToolsPath,
  isElectronRuntime,
  downloadPath,
} from 'const'
import { getLetters } from 'utils'
import Avatar from 'components/avatar'
import Button from 'components/button'
import Text from 'components/text'
import { UserNormalized } from 'reducers/usersSlice'
import {
  MoreIcon,
  HomeIcon,
  HomeIconActive,
  ScheduledCallsIcon,
  EntertainmentIcon,
  GuardianToolsIcon,
  ScheduledCallsIconActive,
  EntertainmentIconActive,
  GuardianToolsIconActive,
  DownloadAppIcon,
} from 'theme'
import { useTranslation } from 'react-i18next'
import { useLocation, Link, useHistory } from 'react-router-dom'
import { RowsContainer } from 'components/container'
import {
  StyledContentWrapper,
  StyledShowMenuWrapper,
  StyledDivider,
  StyledTextWrapper,
  StyledFooterMenuWrapper,
  StyledFooterWrapper,
  StyledWrapper,
  StyledLogoWrapper,
  StyledList,
  StyledListItem,
  StyledProfileWrapper,
  StyledBannerWrapper,
} from './style'

const DashboardSidebar: FC<{ haveScheduledCall: boolean; authUser: UserNormalized }> = ({
  haveScheduledCall,
  authUser,
}): ReactElement => {
  const history = useHistory()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [menuVisible, setMenuVisible] = useState(false)
  const homeActive = pathname === dashboardHomePath
  const scheduledCallsActive = pathname === dashboardScheduledCallsPath
  const profileActive = pathname === dashboardProfilePath
  const entertainmentActive = pathname === dashboardEntertainmentPath
  const guardianToolsActive = pathname === dashboardGuardianToolsPath

  const handleMenuClick = () => {
    setMenuVisible(!menuVisible)
  }

  useEffect(() => {
    const unregisterHistoryListener = history.listen(() => {
      setMenuVisible(false)
    })

    return () => {
      unregisterHistoryListener()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledWrapper>
      <StyledShowMenuWrapper>
        <Button bcolor="transparent" $flat onClick={handleMenuClick}>
          <MoreIcon title={t('Menu')} />
        </Button>
      </StyledShowMenuWrapper>
      <StyledLogoWrapper>
        <Logo link={dashboardHomePath} secondary="true" />
      </StyledLogoWrapper>
      <StyledContentWrapper className={`${menuVisible ? 'visible' : ''}`}>
        <StyledList>
          <StyledListItem active={homeActive} link={dashboardHomePath}>
            {homeActive ? (
              <HomeIconActive title={t('NavigateToDashboardHome')} />
            ) : (
              <HomeIcon title={t('NavigateToDashboardHome')} />
            )}
            <Text $secondary color="inherit" ml="1rem" fw="500">
              {t('Home')}
            </Text>
          </StyledListItem>
          <StyledListItem data-tour="fifth-step" active={scheduledCallsActive} link={dashboardScheduledCallsPath}>
            {scheduledCallsActive ? (
              <ScheduledCallsIconActive
                $haveScheduledCall={haveScheduledCall}
                title={t('NavigateToDashboardScheduledCalls')}
              />
            ) : (
              <ScheduledCallsIcon
                $haveScheduledCall={haveScheduledCall}
                title={t('NavigateToDashboardScheduledCalls')}
              />
            )}
            <Text $secondary color="inherit" ml="1rem" fw="500">
              {t('ScheduledCalls')}
            </Text>
          </StyledListItem>
          <StyledListItem data-tour="sixth-step" active={entertainmentActive} link={dashboardEntertainmentPath}>
            {entertainmentActive ? (
              <EntertainmentIconActive title={t('NavigateToDashboardEntertainment')} />
            ) : (
              <EntertainmentIcon title={t('NavigateToDashboardEntertainment')} />
            )}
            <Text $secondary color="inherit" ml="1rem" fw="500">
              {t('Entertainment')}
            </Text>
          </StyledListItem>
        </StyledList>
        <StyledFooterWrapper>
          <StyledDivider $secondary />
          <StyledFooterMenuWrapper>
            <StyledListItem active={guardianToolsActive} link={dashboardGuardianToolsPath}>
              {guardianToolsActive ? (
                <GuardianToolsIconActive title={t('NavigateToDashboardGuardianTools')} />
              ) : (
                <GuardianToolsIcon title={t('NavigateToDashboardGuardianTools')} />
              )}
              <Text $secondary color="inherit" ml="1rem" fw="500">
                {t('GuardianTools')}
              </Text>
            </StyledListItem>
            <StyledProfileWrapper>
              <StyledListItem active={profileActive} link={dashboardProfilePath}>
                <RowsContainer>
                  <Avatar
                    size="md"
                    buttonSize="lg"
                    fontSize="default"
                    name={authUser.displayName}
                    alt={t('PhotoOfUser')}
                    img={authUser.photo}
                  />
                  <StyledTextWrapper>
                    <Text fw="500" ml="1rem" color="white" cop="0.6">
                      {authUser.firstName || getLetters(authUser.displayName, 10, true)}
                    </Text>
                  </StyledTextWrapper>
                </RowsContainer>
              </StyledListItem>
            </StyledProfileWrapper>
            {!isElectronRuntime && navigator?.userAgent?.indexOf('Win') !== -1 && (
              <StyledBannerWrapper>
                <Link target="_blank" to={{ pathname: downloadPath }}>
                  <DownloadAppIcon title={t('GetWindowsApp')} />
                  <Text size="sm" fw="600" mt="1rem" mb="0.5rem" $block color="magentafuchsia">
                    {t('GetWindowsApp')}
                  </Text>
                </Link>
              </StyledBannerWrapper>
            )}
          </StyledFooterMenuWrapper>
        </StyledFooterWrapper>
      </StyledContentWrapper>
    </StyledWrapper>
  )
}

export default DashboardSidebar
