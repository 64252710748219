/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components'
import Button from 'components/button'
import { ColumnsContainer, RowsContainer } from 'components/container'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import Form, { FormFieldInput, FormFieldSchedule, FormFieldValidation } from 'components/form'

export interface StyledHeaderContainerProps {
  /**
   * Whether should show an underlying shadow
   */
  $shadow?: boolean
}

export interface StyledButtonsWrapperProps {
  /**
   * Whether should show a shadow
   */
  $shadow?: boolean
}

export const StyledFormFieldSchedule = styled(FormFieldSchedule)`
  width: 100%;
`

export const StyledBackButton = styled(Button)`
  position: absolute;
  left: 1rem;
  top: 1rem;
`

export const StyledCheckBoxWrapper = styled.button`
  margin-top: 1.5rem;
  outline: none;
  border: none;
  background: transparent;
`

export const StyledFormFieldInput = styled(FormFieldInput)`
  width: 100%;
  margin-top: 0.5rem;
`

export const StyledContentWrapper = styled(ColumnsContainer)`
  align-items: flex-start;
  box-sizing: border-box;
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.mediumpurple, '0.1')};
`

export const StyledHeaderContainer = styled(RowsContainer)`
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  width: 100%;
  padding: 1.125rem;
  box-sizing: border-box;

  ${(props: StyledHeaderContainerProps) =>
    props.$shadow &&
    css`
      box-shadow: ${({ theme: { shadows } }) => shadows.outbound};
    `}
`

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

export const StyledButtonsWrapper = styled(ColumnsContainer)`
  padding: 1rem 1.125rem;
  box-sizing: border-box;

  ${(props: StyledButtonsWrapperProps) =>
    props.$shadow &&
    css`
      box-shadow: 0px -0.25rem 0.5rem ${({ theme: { fn, colors } }) => fn.color(colors.black, '0.1')};
    `}
`

export const StyledForm = styled(Form)`
  width: 100%;

  ${({ theme }) => theme.fn.down(theme.breakpoints.md)} {
    margin-top: 0.5rem;
  }
`

export const StyledFormFieldValidation = styled(FormFieldValidation)`
  width: 100%;
`

export const StyledVerticalScrollbar = styled(OverlayScrollbarsComponent).attrs((props) => ({
  ...props,
  resize: 'vertical',
}))`
  padding: 0 1.125rem;
  height: 35rem;
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;

  .os-scrollbar-horizontal {
    display: none;
  }
`
