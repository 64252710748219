import DashboardTheme from './dashboard-theme'
import HomeTheme from './home-theme'
import CallTheme from './call-theme'

const themeComponents = {
  dashboard: DashboardTheme,
  home: HomeTheme,
  call: CallTheme,
}

export default themeComponents
