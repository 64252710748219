/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import Button from 'components/button'
import { ContentContainer, ColumnsContainer } from 'components/container'

export const StyledContentContainer = styled(ContentContainer)`
  margin: 1.125rem;
`

export const StyledContentWrapper = styled(ContentContainer)`
  margin-top: 0.5rem;
  width: 100%;
`

export const StyledHeaderContainer = styled(ColumnsContainer)`
  align-items: center;
  vertical-align: middle;
  justify-content: center;
`

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`
