/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement, useEffect, useState } from 'react'
import Text from 'components/text'
import Button from 'components/button'
import { useTranslation } from 'react-i18next'
import { ContactsIcon, RightArrowIcon, NewContactIcon } from 'theme'
import { SearchInput } from 'components/form'
import { ContactListItem } from 'components/list'
import { createFilter } from 'react-search-input'
import {
  subModalQueryParam,
  modalCreateManagedUserContactQueryParam,
  modalAddManagedUserContactsQueryParam,
} from 'const'
import { useQueryParam, StringParam } from 'use-query-params'
import NoContacts from './no-contacts'
import {
  StyledFooterWrapper,
  StyledList,
  StyledListWrapper,
  StyledRowsContainer,
  StyledListControlsWrapper,
  StyledOverlayScrollbarsComponent,
  StyledContentContainer,
} from './style'

const ManagedUserContacts = ({ contacts, user }): ReactElement => {
  const { t } = useTranslation()
  const [filteredContacts, setFilteredContacts] = useState(contacts)
  const [, setSubModalParam] = useQueryParam(subModalQueryParam, StringParam)
  const [searchTerm, setSearchTerm] = useState('')

  const searchUpdated = (str) => setSearchTerm(str)

  const handleNewContactClick = () => {
    setSubModalParam(modalCreateManagedUserContactQueryParam, 'replaceIn')
  }

  const handleImportContacts = () => {
    setSubModalParam(modalAddManagedUserContactsQueryParam, 'replaceIn')
  }

  useEffect(() => {
    if (searchTerm) setFilteredContacts(contacts.filter(createFilter(searchTerm, ['displayName', 'email', 'phone'])))
    else setFilteredContacts(contacts)
  }, [contacts, searchTerm])

  return (
    <>
      <StyledListControlsWrapper>
        <SearchInput $secondary placeholder={t('SearchForPeopleOrGroups')} onChange={searchUpdated} />
        <StyledRowsContainer>
          <Button mt="1rem" bcolor="purple" bop="0.16" onClick={handleNewContactClick}>
            <NewContactIcon $secondary title={t('NewContact')} />
            <Text ml="0.2rem" font="Inter" size="md" $secondary fw="600" color="purplehearth">
              {t('NewContact')}
            </Text>
          </Button>
        </StyledRowsContainer>
      </StyledListControlsWrapper>
      <StyledContentContainer>{!contacts?.length && <NoContacts user={user} />}</StyledContentContainer>
      {!!filteredContacts?.length && (
        <>
          <StyledListWrapper>
            <StyledOverlayScrollbarsComponent options={{ className: 'os-theme-light' }}>
              <StyledList>
                {filteredContacts.map((contact) => (
                  <ContactListItem $secondary key={contact.id} contact={contact} $noPresence />
                ))}
              </StyledList>
            </StyledOverlayScrollbarsComponent>
          </StyledListWrapper>
        </>
      )}
      {filteredContacts?.length === 1 && (
        <StyledFooterWrapper>
          <ContactsIcon $gradient title={t('IconDepictingAnAddressBook')} />
          <Text ml="1rem" mr="1rem" fw="500" cop="0.6" color="brandblack">
            {t('NoNeedToCreateContactsFromScratch')}&nbsp;
            {t('YouCanCopySelectedContactsFromYourAddressBookTo')}&nbsp;
            {user?.firstName}&nbsp;
            {t('AddressBook').toLowerCase()}.
          </Text>
          <Button $link onClick={handleImportContacts}>
            <RightArrowIcon $gradient title={t('ImportContacts')} />
          </Button>
        </StyledFooterWrapper>
      )}
    </>
  )
}

export default ManagedUserContacts
