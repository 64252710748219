import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

export interface ButtonProps {
  /**
   * Is selected
   */
  selected?: boolean
  /**
   * Variant
   */
  $secondary?: boolean
  /**
   * Variant
   */
  subtle?: string
  /**
   * Variant
   */
  outbound?: string
  /**
   * Background color
   */
  bcolor?: string
  /**
   * Background color on hover
   */
  bcolorhov?: string
  /**
   * Background color when active
   */
  bcoloract?: string
  /**
   * Background color when selected
   */
  bcolorsel?: string
  /**
   * Background opacity
   */
  bop?: string
  /**
   * Background opacity on hover
   */
  bophov?: string
  /**
   * Background opacity when active
   */
  bopact?: string
  /**
   * Background opacity when selected
   */
  bopsel?: string
  /**
   * Border color
   */
  borcolor?: string
  /**
   * Rounded button
   */
  circular?: string
  /**
   * Button square
   */
  square?: string
  /**
   * Size
   */
  size?: string
  /**
   * Margin left
   */
  ml?: string
  /**
   * Margin top
   */
  mt?: string
  /**
   * Margin bottom
   */
  mb?: string
  /**
   * Margin right
   */
  mr?: string
  /**
   * Gradient for background color
   */
  gradient?: string
  /**
   * Gradient for background color on hover
   */
  hgradient?: string
  /**
   * Gradient for background color when active
   */
  agradient?: string
  /**
   * Gradient for background color when selected
   */
  sgradient?: string
  /**
   * Gradient for background color on hover when selected
   */
  hsgradient?: string
  /**
   * Gradient for background color when active and selected
   */
  asgradient?: string
  /**
   * Href
   */
  link?: string
  /**
   * Link variant
   */
  $link?: string
  /**
   * Button type
   */
  type?: string
  /**
   * No hover or active states
   */
  $flat?: boolean
  /**
   * Border opacity
   */
  borop?: string
  /**
   * Border size
   */
  borsize?: string
  /**
   * Box sizing
   */
  bs?: string
  /**
   * On click handler
   */
  onClick?: () => void
}

export default styled(({ children, type, link, ...rest }) => {
  let element = (
    <button type="button" {...rest} tabIndex="0">
      {children}
    </button>
  )
  if (type === 'submit') {
    element = (
      <button type="submit" {...rest} tabIndex="0">
        {children}
      </button>
    )
  }
  if (link) {
    if (link.startsWith('http')) {
      element = (
        <Link
          {...rest}
          style={{ textDecoration: 'none' }}
          tabIndex="0"
          target="_top"
          rel="noopener noreferrer"
          to={{ pathname: link }}
        >
          {children}
        </Link>
      )
    } else {
      element = (
        <Link style={{ textDecoration: 'none' }} {...rest} to={link} tabIndex="0">
          {children}
        </Link>
      )
    }
  }
  return element
})<ButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  box-sizing: border-box;
  text-align: center;
  box-shadow: none;
  cursor: pointer;
  margin: 0;

  height: 2.5rem;
  padding: 0 1rem;
  border-radius: 1.75rem;

  background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.black, '0.16')};
  ${(props: ButtonProps) =>
    !props.$flat &&
    css`
      &:hover {
        background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.black, '0.24')};
      }

      &:active {
        box-shadow: none;
        background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.black, '0.32')};
      }
    `}

  ${(props: ButtonProps) =>
    !!props.$secondary &&
    css`
      background-color: transparent;

      ${(props: ButtonProps) =>
        !props.$flat &&
        css`
          &:hover {
            background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.black, '0.05')};
          }

          &:active {
            box-shadow: none;
            background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.black, '0.1')};
          }
        `}
    `}

  ${(props: ButtonProps) =>
    !!props.bcolor &&
    !props.subtle &&
    !props.outbound &&
    css`
      background-color: ${({ theme: { fn, palette } }) =>
        props.bcolor === 'transparent'
          ? 'transparent'
          : fn.color(palette.background[props.bcolor], props.bop || '0.16')};

      &:disabled {
        background-color: ${({ theme: { fn, palette } }) =>
          fn.color(palette.background[props.bcolor || palette.background.black], '0.32')};
      }

      ${(props: ButtonProps) =>
        !props.$flat &&
        css`
          &:hover {
            background-color: ${({ theme: { fn, palette } }) =>
              fn.color(palette.background[props.bcolor], props.bophov || '0.24')};
          }

          &:active {
            box-shadow: none;
            background-color: ${({ theme: { fn, palette } }) =>
              fn.color(palette.background[props.bcolor], props.bopact || '0.32')};
          }
        `}
    `}

  ${(props: ButtonProps) =>
    !!props.circular &&
    css`
      overflow: hidden;
      height: auto;
      padding: 0.9rem;
      border-radius: 2rem;
    `}

  ${(props: ButtonProps) =>
    !!props.subtle &&
    css`
      background-color: transparent;

      ${(props: ButtonProps) =>
        !props.$flat &&
        css`
          &:hover {
            background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.black, '0.04')};
          }

          &:active {
            box-shadow: none;
            background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.black, '0.08')};
          }
        `}
    `}

  ${(props: ButtonProps) =>
    !!props.outbound &&
    css`
      background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background[props.bcolor], props.bop || '1')};
      box-shadow: ${({ theme: { shadows } }) => shadows.outbound};
    `}

  ${(props: ButtonProps) =>
    !!props.size &&
    props.size === 'xs' &&
    css`
      height: 1.375rem;
      min-height: 1.375rem;
      border-radius: 0.375rem;
      padding: 0 1rem;

      ${(props: ButtonProps) =>
        !!props.circular &&
        css`
          width: 1.375rem;
          min-width: 1.375rem;
          border-radius: 1.375rem;
          padding: 0;
        `}
    `}

    ${(props: ButtonProps) =>
    !!props.size &&
    props.size === 'sm' &&
    css`
      height: 2.5rem;
      min-height: 2.5rem;
      padding: 0 2rem;
      border-radius: 2rem;

      ${(props: ButtonProps) =>
        !props.$flat &&
        css`
          &:hover {
            box-shadow: ${({ theme: { shadows } }) => shadows.square};
          }

          &:active {
            box-shadow: none;
          }
        `}

      ${(props: ButtonProps) =>
        !!props.circular &&
        css`
          width: 2.5rem;
          min-width: 2.5rem;
          padding: 0;
        `}
    `}

  ${(props: ButtonProps) =>
    !!props.size &&
    props.size === 'md' &&
    css`
      height: 3rem;
      min-height: 3rem;
      padding: 0 2rem;
      border-radius: 2rem;

      ${(props: ButtonProps) =>
        !props.$flat &&
        css`
          &:hover {
            box-shadow: ${({ theme: { shadows } }) => shadows.square};
          }

          &:active {
            box-shadow: none;
          }
        `}

      ${(props: ButtonProps) =>
        !!props.circular &&
        css`
          width: 3rem;
          min-width: 3rem;
          padding: 0;
        `}
    `}

  ${(props: ButtonProps) =>
    !!props.size &&
    props.size === 'lg' &&
    css`
      height: 3.5rem;
      min-height: 3.5rem;
      padding: 0 1.2rem 0 1rem;
      border-radius: 2rem;

      ${(props: ButtonProps) =>
        !props.$flat &&
        css`
          &:hover {
            box-shadow: ${({ theme: { shadows } }) => shadows.square};
          }

          &:active {
            box-shadow: none;
          }
        `}

      ${(props: ButtonProps) =>
        !!props.circular &&
        css`
          width: 3.5rem;
          min-width: 3.5rem;
          padding: 0;
        `}
    `}

    ${(props: ButtonProps) =>
    !!props.size &&
    props.size === 'xl' &&
    css`
      height: 4rem;
      min-height: 4rem;
      padding: 0 1.2rem 0 1rem;
      border-radius: 4rem;

      ${(props: ButtonProps) =>
        !props.$flat &&
        css`
          &:hover {
            box-shadow: ${({ theme: { shadows } }) => shadows.square};
          }

          &:active {
            box-shadow: none;
          }
        `}

      ${(props: ButtonProps) =>
        !!props.circular &&
        css`
          width: 4rem;
          min-width: 4rem;
          padding: 0;
        `}
    `}

    ${(props: ButtonProps) =>
    !!props.size &&
    props.size === 'xxl' &&
    css`
      height: 5.5rem;
      min-height: 5.5rem;
      padding: 0 1.2rem 0 1rem;
      border-radius: 5.5rem;

      ${(props: ButtonProps) =>
        !props.$flat &&
        css`
          &:hover {
            box-shadow: ${({ theme: { shadows } }) => shadows.square};
          }

          &:active {
            box-shadow: none;
          }
        `}

      ${(props: ButtonProps) =>
        !!props.circular &&
        css`
          width: 5.5rem;
          min-width: 5.5rem;
          padding: 0;
        `}
    `}

  ${(props: ButtonProps) =>
    !!props.square &&
    !props.$flat &&
    css`
      border-radius: 0.375rem;

      ${(props: ButtonProps) =>
        !props.$flat &&
        css`
          &:hover {
            box-shadow: ${({ theme: { shadows } }) => shadows.square};
          }

          &:active {
            box-shadow: none;
          }
        `}
    `}

  ${(props: ButtonProps) =>
    !!props.gradient &&
    css`
      background: ${({ theme: { gradients } }) => gradients[props.gradient]};
    `}

  ${(props: ButtonProps) =>
    !!props.hgradient &&
    !props.$flat &&
    css`
      &:hover {
        background: ${({ theme: { gradients } }) => gradients[props.hgradient]};
      }
    `}
    
  ${(props: ButtonProps) =>
    !!props.agradient &&
    !props.$flat &&
    css`
      &:active {
        box-shadow: none;
        background: ${({ theme: { gradients } }) => gradients[props.agradient]};
      }
    `}

  ${(props: ButtonProps) =>
    props.selected &&
    !!props.hsgradient &&
    !props.$flat &&
    css`
      &:hover {
        background: ${({ theme: { gradients } }) => gradients[props.hsgradient]};
      }
    `}
    
  ${(props: ButtonProps) =>
    props.selected &&
    !!props.asgradient &&
    !props.$flat &&
    css`
      &:active {
        box-shadow: none;
        background: ${({ theme: { gradients } }) => gradients[props.asgradient]};
      }
    `}

  ${(props: ButtonProps) =>
    !!props.bcolorhov &&
    !props.$flat &&
    css`
      &:hover {
        background: ${({ theme: { fn, palette } }) =>
          fn.color(palette.background[props.bcolorhov], props.bophov || '1')};
      }
    `}

  ${(props: ButtonProps) =>
    !!props.bcoloract &&
    !props.$flat &&
    css`
      &:active {
        box-shadow: none;
        background: ${({ theme: { fn, palette } }) =>
          fn.color(palette.background[props.bcoloract], props.bopact || '1')};
      }
    `}

  ${(props: ButtonProps) =>
    props.selected &&
    !!props.bcolorsel &&
    css`
      background: ${({ theme: { fn, palette } }) => fn.color(palette.background[props.bcolorsel], props.bopsel || '1')};
    `}

  ${(props: ButtonProps) =>
    props.selected &&
    !!props.sgradient &&
    css`
      background: ${({ theme: { gradients } }) => gradients[props.sgradient]};
    `}

  ${(props: ButtonProps) =>
    props.$flat &&
    css`
      &:hover,
      &:active {
        box-shadow: none;
      }
    `}

  ${(props: ButtonProps) =>
    !!props.ml &&
    css`
      margin-left: ${props.ml}};
    `}

  ${(props: ButtonProps) =>
    !!props.mt &&
    css`
      margin-top: ${props.mt}};
    `}

  ${(props: ButtonProps) =>
    !!props.mb &&
    css`
      margin-bottom: ${props.mb}};
    `}

  ${(props: ButtonProps) =>
    !!props.mr &&
    css`
      margin-right: ${props.mr}};
    `}

  ${(props: ButtonProps) =>
    !!props.borcolor &&
    css`
      border: ${props.borsize || props.size === 'xs' ? '0.125rem' : '0.1875rem'} solid
        ${({ theme: { fn, colors } }) => fn.color(colors[props.borcolor], props.borop)};
    `}

  ${(props: ButtonProps) =>
    !!props.bs &&
    css`
      box-sizing: ${props.bs}};
    `}

  ${(props: ButtonProps) =>
    props.$link &&
    css`
      padding: 0;
      height: auto;
      width: auto;
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }

      &:active {
        box-shadow: none;
        background-color: transparent;
      }
    `}
`
