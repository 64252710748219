import { ReactElement } from 'react'

// eslint-disable-next-line react/require-default-props
export default ({ size }: { size?: string }): ReactElement => {
  return size === 'lg' ? (
    <svg width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.125 44.767C19.125 41.2072 19.2339 39.8118 20.9049 37.6474C22.5759 35.483 26.2446 34.0876 29.8043 34.0876C28.9228 32.2906 27.3471 28.5854 27.3125 26.5748C27.3125 23.2396 28.6064 20.041 30.9096 17.6827C33.2128 15.3244 36.3366 13.9995 39.5937 13.9995C41.5327 14.0239 43.4392 14.512 45.1613 15.4247C46.7119 11.9397 49.3809 9.10333 52.7245 7.38718C56.068 5.67104 59.8845 5.17858 63.5393 5.99169C67.1941 6.80481 70.4669 8.8745 72.8135 11.8566C75.1601 14.8386 76.439 18.5534 76.4375 22.383C76.4823 23.1088 76.4823 23.8369 76.4375 24.5627C78.959 26.0434 81.0502 28.1846 82.4962 30.7665C83.2051 30.7206 83.9161 30.7206 84.625 30.7665C88.538 30.7116 92.3407 32.0935 95.3446 34.6617C98.3484 37.23 101 41.2072 101 44.767"
        stroke="white"
        strokeWidth="8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9814 54.25L26.3178 122.272C26.4537 123.262 27.2997 124 28.2992 124H91.8254C92.8249 124 93.671 123.262 93.8069 122.272L103.143 54.25H16.9814ZM51.055 75.9553L72.6414 88.3971L51.055 100.839V75.9553ZM77.7214 82.0913C82.323 84.7436 82.323 92.0506 77.7214 94.7028L52.4057 109.294C48.1447 111.75 43.055 108.318 43.055 102.988V73.8059C43.055 68.4765 48.1447 65.0442 52.4057 67.5001L77.7214 82.0913Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="32.949"
          y1="141.574"
          x2="106.132"
          y2="86.6164"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6BF4F" />
          <stop offset="1" stopColor="#FF00E7" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8809 32.4912C13.8809 29.9074 13.9599 28.8946 15.1727 27.3237C16.3856 25.7528 19.0483 24.74 21.632 24.74C20.9921 23.4357 19.8485 20.7465 19.8234 19.2871C19.8234 16.8664 20.7625 14.5449 22.4342 12.8332C24.1059 11.1215 26.3731 10.1599 28.7372 10.1599C30.1445 10.1777 31.5283 10.5319 32.7781 11.1943C33.9036 8.66492 35.8408 6.60625 38.2676 5.36066C40.6943 4.11507 43.4644 3.75764 46.117 4.3478C48.7697 4.93797 51.1452 6.44016 52.8483 8.60457C54.5515 10.769 55.4797 13.4652 55.4786 16.2447C55.5112 16.7715 55.5112 17.2999 55.4786 17.8268C57.3088 18.9014 58.8266 20.4556 59.8761 22.3295C60.3906 22.2962 60.9067 22.2962 61.4212 22.3295C64.2612 22.2897 67.0213 23.2927 69.2015 25.1567C71.3817 27.0208 73.3063 29.9074 73.3063 32.4912"
        stroke="white"
        strokeWidth="8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3252 39.375L19.0365 88.272C19.1725 89.2622 20.0185 90 21.018 90H66.1692C67.1687 90 68.0147 89.2622 68.1506 88.272L74.862 39.375H12.3252ZM39.2495 58.9249L48.3308 64.1591L39.2495 69.3933V58.9249ZM56.4106 59.5823C59.7505 61.5074 59.7505 66.8108 56.4106 68.7358L38.0363 79.3262C34.9436 81.1087 31.2495 78.6176 31.2495 74.7495V53.5687C31.2495 49.7006 34.9436 47.2094 38.0363 48.992L56.4106 59.5823Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="23.9145"
          y1="102.755"
          x2="77.031"
          y2="62.8668"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6BF4F" />
          <stop offset="1" stopColor="#FF00E7" />
        </linearGradient>
      </defs>
    </svg>
  )
}
