/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import Button from 'components/button'
import { ContentContainer, ColumnsContainer, RowsContainer } from 'components/container'
import Form, { FormFieldInput, FormFieldValidation } from 'components/form'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import List from 'components/list'

export const StyledContentContainer = styled(ContentContainer)`
  margin: 1.125rem;
`

export const StyledFormFieldInput = styled(FormFieldInput)`
  width: 100%;
`

export const StyledFormFieldValidation = styled(FormFieldValidation)`
  width: 100%;
`

export const StyledContentWrapper = styled(ColumnsContainer)`
  align-items: flex-start;
  box-sizing: border-box;
  margin-top: 0.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.mediumpurple, '0.1')};
`

export const StyledHeaderContainer = styled(RowsContainer)`
  height: 2.8125rem;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
`

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

export const StyledButtonsWrapper = styled(RowsContainer)`
  justify-content: flex-end;
  margin: 1.7rem 0 0 0;
`

export const StyledForm = styled(Form)`
  width: 100%;

  ${({ theme }) => theme.fn.down(theme.breakpoints.md)} {
    margin-top: 0.5rem;
  }
`

export const StyledContactsWrapper = styled(ContentContainer)`
  width: 100%;
`

export const StyledVerticalScrollbar = styled(OverlayScrollbarsComponent).attrs((props) => ({
  ...props,
  resize: 'vertical',
}))`
  height: 17rem;
  width: 100%;
  overflow-x: hidden;
  margin-right: 0.3rem;

  &.os-host-scrollbar-vertical-hidden .os-content > section {
    margin-right: 0 !important;
  }

  &.os-host-scrollbar-vertical-hidden {
    margin-right: 0 !important;
  }

  .os-scrollbar-horizontal {
    display: none;
  }
`

export const StyledListColumn = styled(List)`
  flex-direction: column;
  margin-right: 1rem;
  width: auto;
`
