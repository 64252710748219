import styled from 'styled-components'
import { ColumnsContainer } from 'components/container'
import List, { MenuListItem } from 'components/list'
import Divider from 'components/divider'

export const StyledShowMenuWrapper = styled.div`
  align-items: center;
  vertical-align: middle;
  display: flex;
  justify-content: flex-end;
  padding: 1rem;

  ${({ theme }) => theme.fn.up(theme.breakpoints.md)} {
    display: none;
  }
`

export const StyledWrapper = styled(ColumnsContainer).attrs(() => ({
  component: 'aside',
}))`
  overflow: hidden;
  min-height: 100%;
  margin: 0;
  width: 15rem;
  min-width: 15rem;
  max-width: 15rem;
  background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.primary)};

  ${({ theme }) => theme.fn.down(theme.breakpoints.md)} {
    min-height: auto;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
`

export const StyledLogoWrapper = styled.div`
  margin: 1.5rem;

  ${({ theme }) => theme.fn.down(theme.breakpoints.md)} {
    display: none;
  }
`

export const StyledList = styled(List).attrs(() => ({
  component: 'nav',
}))`
  margin: 0;
  flex-direction: column;

  ${({ theme }) => theme.fn.up(theme.breakpoints.md)} {
    margin: 2rem 0 0;
  }
`
export const StyledListItem = styled(MenuListItem).attrs(() => ({
  replace: true,
}))`
  margin: 0.25rem 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
`

export const StyledFooterWrapper = styled(ColumnsContainer).attrs(() => ({
  component: 'footer',
}))`
  margin: 0;
  flex-grow: 1;
  justify-content: flex-end;

  ${({ theme }) => theme.fn.up(theme.breakpoints.md)} {
    margin: 0.25rem 0 0 0;
  }
`

export const StyledContentWrapper = styled(ColumnsContainer)`
  overflow: hidden;
  margin-bottom: 0.25rem;
  transition: max-height 0.3s ease;
  max-height: 0;

  &.visible {
    max-height: 40rem;
  }

  &.can-animate {
    transition: max-height 0.3s ease;
  }

  ${({ theme }) => theme.fn.up(theme.breakpoints.md)} {
    height: 100% !important;
    margin: 0.25rem 0 1rem 0;
    max-height: 100% !important;
    transition: max-height 0s;
  }
`

export const StyledProfileWrapper = styled.div`
  margin-top: 0.5rem;
`

export const StyledFooterMenuWrapper = styled.div`
  margin-top: 1rem;
`

export const StyledTextWrapper = styled(ColumnsContainer)`
  justify-content: center;
`

export const StyledDivider = styled(Divider)`
  margin: 0 1rem;
  box-sizing: border-box;
  width: auto;
`

export const StyledBannerWrapper = styled.div`
  background-color: ${({ theme: { fn, palette } }) => fn.color(palette.background.black, '0.1')};
  border: 0.125rem solid ${({ theme: { fn, colors } }) => fn.color(colors.white, '0.2')};
  box-sizing: border-box;
  border-radius: 0.5rem;
  margin: 1rem 1rem 0 1rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`
