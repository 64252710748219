import { ReactElement } from 'react'

export default (): ReactElement => (
  <svg width="82" height="66" viewBox="0 0 82 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.626 7.68359H71.376C72.7739 7.68359 73.9072 8.81687 73.9072 10.2148V55.7773C73.9072 57.1753 72.7739 58.3086 71.376 58.3086H10.626C9.22801 58.3086 8.09473 57.1753 8.09473 55.7773V10.2148C8.09473 8.81687 9.22801 7.68359 10.626 7.68359ZM0.500977 10.2148C0.500977 4.62296 5.03409 0.0898438 10.626 0.0898438H71.376C76.9679 0.0898438 81.501 4.62296 81.501 10.2148V55.7773C81.501 61.3692 76.9679 65.9023 71.376 65.9023H10.626C5.03409 65.9023 0.500977 61.3692 0.500977 55.7773V10.2148ZM42.7908 23.6113L41.001 21.8214L39.2111 23.6113L26.5549 36.2675C25.5663 37.256 25.5663 38.8587 26.5549 39.8472C27.5434 40.8358 29.1461 40.8358 30.1346 39.8472L41.001 28.9809L51.8674 39.8472C52.8559 40.8358 54.4586 40.8358 55.4471 39.8472C56.4356 38.8587 56.4356 37.256 55.4471 36.2675L42.7908 23.6113Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="15.5119"
        y1="82.484"
        x2="84.4923"
        y2="30.8723"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6BF4F" />
        <stop offset="1" stopColor="#FF00E7" />
      </linearGradient>
    </defs>
  </svg>
)
