/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FC } from 'react'
import { GuardianWarningIcon } from 'theme'
import Text from 'components/text'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import Button from 'components/button'
import { getOnePending, isNotNotification } from 'utils/guardianRequests'
import { selectAuthUser, UserNormalized } from 'reducers/usersSlice'
import { push } from 'connected-react-router'
import { dashboardGuardianToolsPath } from 'const'
import {
  acceptGuardianRequest,
  declineGuardianRequest,
  GuardianRequestNormalized,
} from 'reducers/guardianRequestsSlice'
import {
  StyledTextWrapper,
  StyledIconWrapper,
  StyledRowsLeftContainer,
  StyledRowsRightContainer,
  StyledAlertWrapper,
} from './style'

const DashboardAlert: FC<{
  authUser: UserNormalized
  incomingGuardianRequests?: GuardianRequestNormalized
  isGuardianTools?: boolean
}> = ({ authUser, incomingGuardianRequests, isGuardianTools }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const pendingGuardianRequest = getOnePending(incomingGuardianRequests)

  const onAccept = () => {
    dispatch(acceptGuardianRequest({ user: authUser, request: pendingGuardianRequest }))
  }

  const onDecline = () => {
    dispatch(declineGuardianRequest(pendingGuardianRequest))
  }

  const handleLearnMoreClick = () => {
    dispatch(push(dashboardGuardianToolsPath))
  }

  if (pendingGuardianRequest && isNotNotification(pendingGuardianRequest) && !isGuardianTools) {
    return (
      <StyledAlertWrapper>
        <StyledRowsLeftContainer>
          <StyledIconWrapper>
            <GuardianWarningIcon $secondary title={t('WarningIcon')} />
          </StyledIconWrapper>
          <StyledTextWrapper>
            <Text $block fw="500" $secondary>
              {t('ClanzyUser')}&nbsp;
              {pendingGuardianRequest?.from?.displayName}&nbsp;
              {t('IsRequestingPermissionToBecomeGuardianOfYourClanzyAccount')}
              <Text fw="500" $secondary onClick={handleLearnMoreClick}>
                {t('LearnMore')}
              </Text>
            </Text>
          </StyledTextWrapper>
        </StyledRowsLeftContainer>
        <StyledRowsRightContainer>
          <Button bophov="1" bopact="1" size="sm" bcolor="white" bop="1" mr="1rem" onClick={onDecline}>
            <Text fw="600" size="md" color="mulberry">
              {t('Decline')}
            </Text>
          </Button>
          <Button bophov="1" bopact="1" size="sm" bcolor="white" bop="1" mr="1rem" onClick={onAccept}>
            <Text fw="600" size="md" color="mulberry">
              {t('Accept')}
            </Text>
          </Button>
        </StyledRowsRightContainer>
      </StyledAlertWrapper>
    )
  }

  return null
}

const mapStateToProps = (state) => ({
  authUser: selectAuthUser(state),
})

export default connect(mapStateToProps, null)(DashboardAlert)
