/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { added, updated, ContactNormalized } from './contactsSlice'

export const actionsTypesMapping = {}

export const eventsMapping = {
  contactsAdded: {
    action: 'contacts/added',
    actionCreator: added,
  },
  // Updates contacts presence statuses(online/offline)
  userStatus: {
    action: 'contacts/updated',
    actionCreator: updated,
    map: ({ state, payload: { userId, status } }) => {
      if (userId) {
        const contactId: ContactNormalized = Object.values(state?.contacts?.ids || []).find(
          (id: string) => state.contacts.entities[id].userId === userId,
        ) as null | ContactNormalized
        if (contactId) return { id: contactId, changes: { status } }
      }

      return null
    },
  },
}
