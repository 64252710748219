/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactElement } from 'react'
import { Route } from 'react-router'
import { Redirect } from 'react-router-dom'
import { selectAuthUser } from 'reducers/usersSlice'
import { connect } from 'react-redux'
import { signInPath } from 'const'
import useUser from 'hooks/useUser'
import themeComponents from './theme'

interface AuthenticatedRouteProps {
  authUser?: any
  render?: any
  component?: FC
  exact?: boolean
  path: string
  themeRoute?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AuthenticatedRoute: FC<AuthenticatedRouteProps> = ({
  themeRoute,
  authUser,
  render,
  component: Component,
  exact,
  ...rest
}): ReactElement => {
  const loading = useUser(!!authUser)

  if (loading) return <></>

  const ThemeComponent = themeComponents[themeRoute] || null

  return (
    <Route
      {...rest}
      render={(props: unknown) =>
        authUser ? (
          <ThemeComponent>{render ? render() : <Component {...props} />}</ThemeComponent>
        ) : (
          <Redirect to={signInPath} />
        )
      }
    />
  )
}

const mapStateToProps = (state) => ({
  authUser: selectAuthUser(state),
})

export default connect(mapStateToProps, null)(AuthenticatedRoute)
