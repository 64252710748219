import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { createHashHistory, createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import createSocketIoMiddleware from 'reducers/createSocketIoMiddleware'
import createRootReducer from 'reducers/root'
import { isElectronRuntime } from 'const'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    s: any
  }
}

export const history = isElectronRuntime ? createHashHistory() : createBrowserHistory()

const store = configureStore({
  reducer: createRootReducer(history),
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    routerMiddleware(history),
    createSocketIoMiddleware(window.s, 'server/'),
  ],
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export default store
