import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path
      d="M5.5 11C5.5 9.61929 6.61929 8.5 8 8.5H32C33.3807 8.5 34.5 9.61929 34.5 11V29C34.5 30.3807 33.3807 31.5 32 31.5H8C6.61929 31.5 5.5 30.3807 5.5 29V11Z"
      stroke="white"
      strokeOpacity="0.6"
      strokeWidth="3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 15.5859L20.7071 16.293L25.7071 21.293C26.0976 21.6836 26.0976 22.3167 25.7071 22.7073C25.3166 23.0978 24.6834 23.0978 24.2929 22.7073L20 18.4144L15.7071 22.7073C15.3166 23.0978 14.6834 23.0978 14.2929 22.7073C13.9024 22.3167 13.9024 21.6836 14.2929 21.293L19.2929 16.293L20 15.5859Z"
      fill="white"
      fillOpacity="0.6"
    />
  </svg>
)
