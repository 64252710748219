import styled, { css } from 'styled-components'

export interface DividerProps {
  /**
   * Color opacity
   */
  cop?: string
  /**
   * Margin top
   */
  mt?: string
  /**
   * Variant
   */
  $secondary?: boolean
}

export default styled.div<DividerProps>`
  height: 0.125rem;
  background-color: ${({ theme: { fn, colors } }) => fn.color(colors.black, '0.1')};
  width: 100%;

  ${(props: DividerProps) =>
    !!props.mt &&
    css`
      margin-top: ${props.mt};
    `}

  ${(props: DividerProps) =>
    !!props.$secondary &&
    css`
      background-color: ${({ theme: { fn, colors } }) => fn.color(colors.white, props.cop || '0.1')};
    `}
`
