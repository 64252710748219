import { ReactElement } from 'react'
import { withTheme } from 'styled-components'

export default withTheme(
  ({ theme: { fn, colors }, $secondary, title }): ReactElement => {
    const color = (fn.color as (arg0: string) => string)($secondary ? colors.purplehearth : colors.portage)
    return (
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0164 9.60653C17.0164 12.7029 14.82 15.2131 12.1107 15.2131C9.40132 15.2131 7.20496 12.7029 7.20496 9.60653C7.20496 6.51013 9.40132 4 12.1107 4C14.82 4 17.0164 6.51013 17.0164 9.60653ZM21.2212 23.823C21.2212 27.8041 17.1423 27.8277 12.1106 27.8277C7.07896 27.8277 3 27.8041 3 23.823C3 19.8419 7.07896 16.6146 12.1106 16.6146C17.1423 16.6146 21.2212 19.8419 21.2212 23.823ZM24 8C24.8284 8 25.5 8.67157 25.5 9.5V11.5L27.5 11.5C28.3284 11.5 29 12.1716 29 13C29 13.8284 28.3284 14.5 27.5 14.5H25.5V16.5C25.5 17.3284 24.8284 18 24 18C23.1716 18 22.5 17.3284 22.5 16.5V14.5L20.5 14.5C19.6716 14.5 19 13.8284 19 13C19 12.1716 19.6716 11.5 20.5 11.5L22.5 11.5V9.5C22.5 8.67157 23.1716 8 24 8Z"
          fill={color}
        />
      </svg>
    )
  },
)
