import { ReactElement } from 'react'

export default (): ReactElement => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.798 3.32433C13.3838 2.73855 13.3838 1.7888 12.798 1.20301C12.2122 0.617225 11.2625 0.617225 10.6767 1.20301L7.00051 4.87919L3.32433 1.20301C2.73855 0.617225 1.7888 0.617225 1.20301 1.20301C0.617225 1.7888 0.617225 2.73855 1.20301 3.32433L4.87919 7.00051L1.20301 10.6767C0.617226 11.2625 0.617226 12.2122 1.20301 12.798C1.7888 13.3838 2.73855 13.3838 3.32433 12.798L7.00051 9.12183L10.6767 12.798C11.2625 13.3838 12.2122 13.3838 12.798 12.798C13.3838 12.2122 13.3838 11.2625 12.798 10.6767L9.12183 7.00051L12.798 3.32433Z"
      fill="#C82E25"
    />
  </svg>
)
