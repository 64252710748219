/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { setCallCurrent } from 'reducers/callsSlice'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { ActiveCallListItem } from 'components/list'
import Text from 'components/text'
import { callsPath, autoJoinQueryParam } from 'const'
import { isCallInvitee } from 'utils/calls'
import { StyledListControlsWrapper, StyledList, StyledComponentWrapper } from './style'

const DashboardActiveCalls = ({ authUser, activeCalls }): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [hovered, setHovered] = useState(null)

  const handleMouseEnter = ({ id }) => setHovered(id)
  const handleMouseLeave = () => setHovered(null)

  const handleJoinClick = (call) => {
    dispatch(setCallCurrent(call.id))

    if (isCallInvitee(call, authUser)) {
      dispatch(push(`${callsPath}/${call.callId}?${autoJoinQueryParam}=1`))
    }
  }

  return (
    <StyledComponentWrapper>
      {!!activeCalls?.length && (
        <>
          <StyledListControlsWrapper>
            <Text size="xxl" $secondary color="inherit" fw="600" font="Poppins">
              {t('NowActiveCalls')}
            </Text>
          </StyledListControlsWrapper>
          <StyledList>
            {activeCalls.map((call) => (
              <ActiveCallListItem
                authUser={authUser}
                key={call.id}
                activeCall={call}
                $hovered={call.id === hovered}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onJoinClick={handleJoinClick}
              />
            ))}
          </StyledList>
        </>
      )}
    </StyledComponentWrapper>
  )
}

export default DashboardActiveCalls
