/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement, useCallback, useEffect, useLayoutEffect } from 'react'
import Text from 'components/text'
import Button from 'components/button'
import { useTranslation } from 'react-i18next'
import { participantIdQueryParam } from 'const'
import { useQueryParam, StringParam } from 'use-query-params'
import { CloseIcon } from 'theme'
import Divider from 'components/divider'
import {
  StyledButtonsWrapper,
  StyledContentContainer,
  StyledTextContainer,
  StyledTitleContainer,
  StyledCloseButton,
  StyledHeaderContainer,
} from './style'

const { $ } = window

const ScreenShareRequestSentModal = ({
  screenShareId,
  displayNames,
  cancelScreenShareRequest,
  onClose,
}): ReactElement => {
  const { t } = useTranslation()
  const [participantIdParam, setParticipantIdParam] = useQueryParam(participantIdQueryParam, StringParam)

  const handleClose = useCallback(() => {
    setParticipantIdParam(null, 'replaceIn')
    onClose()
  }, [onClose, setParticipantIdParam])

  const handleCancelRequest = useCallback(() => {
    cancelScreenShareRequest(participantIdParam)
    handleClose()
  }, [cancelScreenShareRequest, participantIdParam, handleClose])

  useLayoutEffect(() => {
    if (screenShareId || !participantIdParam) handleClose()
  }, [handleClose, participantIdParam, screenShareId])

  useEffect(() => {
    if (participantIdParam) {
      const el = $(`.cancel-screen-share-request-button-${participantIdParam}`)?.[0]
      if (el) el.focus()
    }
  }, [participantIdParam])

  return (
    <StyledContentContainer>
      <StyledHeaderContainer component="header">
        <StyledCloseButton circular="true" subtle="true" onClick={handleClose}>
          <CloseIcon title={t('CloseThisWindow')} />
        </StyledCloseButton>
      </StyledHeaderContainer>
      <StyledTitleContainer>
        <Text size="xl" gradient="purple" fw="600" font="Poppins" $block>
          {t('RequestSent')}
        </Text>
      </StyledTitleContainer>
      <StyledTextContainer>
        <Text color="black" fw="500" $block mt="1.5rem">
          {`${t('ScreenShareRequestSentTo')} ${displayNames[participantIdParam]}`}
        </Text>
      </StyledTextContainer>
      <Divider mt="2rem" />
      <StyledTextContainer>
        <Text size="xs" color="brandblack" mt="1.5rem" $block>
          {`${t('WaitingFor')} ${displayNames[participantIdParam]} ${t('ToAcceptTheRequest')}`}
        </Text>
      </StyledTextContainer>
      <StyledButtonsWrapper>
        <Button
          size="md"
          bcolor="mediumpurple"
          bop="1"
          ml="1rem"
          mt="1rem"
          hgradient="mediumpurple-dim-light"
          agradient="mediumpurple-dim-dark"
          onClick={handleCancelRequest}
          className={`cancel-screen-share-request-button-${participantIdParam}`}
        >
          <Text size="md" $secondary fw="600">
            {t('CancelRequest')}
          </Text>
        </Button>
      </StyledButtonsWrapper>
    </StyledContentContainer>
  )
}

export default ScreenShareRequestSentModal
