import axios from 'axios'
import { apiUrl } from 'const'
import { setTokenInterceptor, responseInterceptorError } from 'utils/apis'

export interface UserData {
  id: string
  username?: string
  firstName?: string
  lastName?: string
  flags?: string[]
}

export interface ApiGetUser {
  id: string
}

export interface ApiUpdateUser {
  id: string
  username?: string
  firstName?: string
  lastName?: string
  photo?: string
}

export interface ApiUpdatePassword {
  id: string
  password?: string
}

export interface ApiUpdateFlags extends ApiGetUser {
  flags: {
    add?: string[]
    remove?: string[]
  }
}

const instance = axios.create({
  baseURL: `${apiUrl}/users`,
})

instance.interceptors.request.use(setTokenInterceptor(true, false))
instance.interceptors.response.use(undefined, responseInterceptorError)

export const apiGetUser = async (userId: string): Promise<UserData> => {
  const res = await instance({
    url: `/${userId}`,
    method: 'GET',
  })
  return res.data
}

export const apiUpdateFlags = async ({ id: userId, flags }: ApiUpdateFlags): Promise<UserData> => {
  const res = await instance({
    url: `/${userId}`,
    method: 'POST',
    data: { flags },
  })
  return res.data
}

export const apiUpdateUser = async (data: ApiUpdateUser): Promise<UserData> => {
  const res = await instance({
    url: `/${data.id}`,
    method: 'POST',
    data,
  })
  return res.data
}

export const apiUpdatePassword = async (data: ApiUpdatePassword): Promise<void> => {
  await instance({
    url: `/${data.id}/change-password`,
    method: 'POST',
    data,
  })
}

export const apiSignOut = async (): Promise<void> => {
  await instance({
    url: '/signout',
    method: 'POST',
  })
}
