/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from 'react'
import { mapValues } from 'lodash'
import { setToLS, getFromLS } from 'utils/storage'
import themes from '../theme'

// eslint-disable-next-line import/prefer-default-export
export const useTheme = () => {
  const [theme, setTheme] = useState(themes?.data?.default)
  const [themeLoaded, setThemeLoaded] = useState(false)

  const setMode = (mode) => {
    setToLS('theme', mode)
    setTheme(themes.data[mode])
  }

  const getFonts = () => {
    const allFonts = Object.values(mapValues(themes.data, 'fonts')).flat().map(Object.values).flat()
    return allFonts
  }

  useEffect(() => {
    const localTheme = getFromLS('theme')
    setTheme(themes.data[localTheme] || themes.data.default)
    setThemeLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { theme, themeLoaded, setMode, getFonts }
}
