import styled, { css } from 'styled-components'

export interface TextContainerProps {
  /**
   * Align center
   */
  center?: string
  /**
   * Margin top
   */
  mt?: string
  /**
   * Flex direction column
   */
  column?: string
}

export default styled(({ children, component, ...rest }) => {
  switch (component) {
    case 'footer':
      return <footer {...rest}>{children}</footer>
    default:
      return <article {...rest}>{children}</article>
  }
})<TextContainerProps>`
  outline: none;
  display: flex;
  flex-direction: row;

  ${(props: TextContainerProps) =>
    !!props.center &&
    css`
      justify-content: center;
      align-items: center;
    `}

  ${(props: TextContainerProps) =>
    !!props.column &&
    css`
      flex-direction: column;
    `}

  ${(props: TextContainerProps) =>
    !!props.mt &&
    css`
      margin-top: ${props.mt};
    `}
`
