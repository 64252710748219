/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { added, updated, GroupNormalized } from './groupsSlice'

export const actionsTypesMapping = {}

export const eventsMapping = {
  groupsAdded: {
    action: 'groups/added',
    actionCreator: added,
  },
  // Updates groups contacts presence status(online/offline)
  userStatus: {
    action: 'groups/updated',
    actionCreator: updated,
    map: ({ state, payload: { userId, status } }) => {
      if (userId) {
        const groups: GroupNormalized[] = Object.values(
          state?.groups?.entities || {},
        ).filter((group: GroupNormalized) => group.contacts?.some((contact) => contact.userId === userId)) as
          | null
          | GroupNormalized[]
        if (groups)
          return groups.map((group) => ({
            id: group.id,
            changes: {
              contacts: group.contacts.map((contact) => ({
                ...contact,
                status: contact.userId === userId ? status : contact.status,
              })),
            },
          }))
      }

      return null
    },
  },
}
