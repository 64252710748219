import { ReactElement } from 'react'

export default ({ title }: { title: string }): ReactElement => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    style={{ transform: 'scale(2.5)' }}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4C4.34315 4 3 5.34315 3 7V26C3 27.6569 4.34315 29 6 29H26C27.6569 29 29 27.6569 29 26V7C29 5.34315 27.6569 4 26 4H6ZM7 10C5.89543 10 5 10.8954 5 12V25C5 26.1046 5.89543 27 7 27H25C26.1046 27 27 26.1046 27 25V12C27 10.8954 26.1046 10 25 10H7ZM7 17H10V20H7V17ZM10 22H7V25H10V22ZM12 12H15V15H12V12ZM15 17H12V20H15V17ZM12 22H15V25H12V22ZM20 12H17V15H20V12ZM17 17H20V20H17V17ZM20 22H17V25H20V22ZM22 12H25V15H22V12ZM25 17H22V20H25V17Z"
      fill="#c4c4c4"
    />
  </svg>
)
