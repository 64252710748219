import styled from 'styled-components'
import Button from 'components/button'

export default styled(Button)`
  box-shadow: none;

  &:hover,
  &:active {
    box-shadow: none;
  }
`
